import { React } from "react";
import { evaluateParams } from "../../helpers/param";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import WidgetIcon from './WidgetIcon';
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';

export default function Scorecard({ details, data, onRefresh }) {
    const value = evaluateParams(details?.value, data);

    return <div className="flex flex-col bg-white rounded-md shadow-sm py-1 px-2 text-left h-full w-full">
        <div className="flex-none flex flex-nowrap items-start content-start justify-start justify-items-start">
            <div className="flex-none bg-gray-100 border rounded-md w-[24px] h-[24px] relative text-gray-700">
                {details?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${details?.icon}`} className="block w-full rounded-md" alt="Widget icon" /> : <WidgetIcon widgetType={details.type} />}
            </div>
            <h3 className="flex-1 pl-1.5 text-md text-gray-500">{details.title || 'Title'}</h3>
            <button className="text-gray-500 flex-none bg-transparent hover:bg-gray-50 active:bg-gray-100 rounded-md w-[24px] h-[24px] p-1" onClick={onRefresh}><ResetIcon className="block h-full fill-current" /></button>
        </div>
        <p className="flex-1 pt-1.5 text-3xl font-semibold max-h-full overflow-hidden">{value}</p>
    </div>
}