import { React, useState, useRef, useEffect } from "react";
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as GroupIcon } from '../../icons/group.svg';
import { ReactComponent as MemberIcon } from '../../icons/member.svg';

export default function AddButton({ onSelect }) {
    const popupRef = useRef(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPopupVisible(!isPopupVisible);
    }

    useEffect(() => {

        const handleDisregard = (e) => {
            const popupElem = popupRef.current;

            var el = e.target, disregard = true;
            while (el && !el.classList.contains('item-menu')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('item-menu')) {
                disregard = false;
            }
            if (disregard || el !== popupElem) {
                setIsPopupVisible(false);
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [])

    const handleClick = (item) => {
        return (e) => {
            e.preventDefault();
            if (typeof onSelect === 'function') {
                onSelect(item);
            }
            setIsPopupVisible(false);
        }
    }

    return <div className="flex-none relative">
        <ul ref={popupRef} className={`absolute text-sm z-30 item-menu top-[100%] overflow-hidden rounded-lg bg-white shadow-md right-0 w-[200px] mt-2 ${!isPopupVisible ? 'hidden' : ''}`}>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleClick('member')}><MemberIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Member</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleClick('group')}><GroupIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Group</span></a></li>
        </ul>
        <button className="m-0 rounded-md bg-primary py-1 px-2 text-sm hover:bg-primary-light active:bg-primary-dark" onClick={togglePopup}><PlusIcon className="inline-block w-[12px] h-[12px] mr-1" />Add</button>
    </div>
}