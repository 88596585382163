import { useCallback, useEffect, useRef, useState } from "react";
import { SCREEN_SIZES } from "../../constants/screen-sizes";
import { useFileFetcher } from "../../hooks/file";
import Loader from "../brand/Loader";
import Container from "./Container";
import ItemActionDialog from "./ItemActionDialog";
import ItemInsertAction from "./ItemInsertAction";
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';
import { useCancelToken } from "../../hooks/cancel-token";
import useLogout from "../../hooks/logout";
import useAccessToken from "../../hooks/access-token";
import requestHelpers from "../../helpers/request";
import AlertBox from "../common/AlertBox";

export default function Dashboard({ projectKey, fileKey }) {
    const { responseData: dashboardFileResponse, errorResponse: dashboardFileErrResponse, retry } = useFileFetcher({ projectKey, fileKey, withContent: true }, [projectKey, fileKey]);
    const [dashboardFileInfo, setDashboardFileInfo] = useState(null);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const handleDashboardFileResponseData = (responseDataToHandle) => {
        if (mounted.current) {
            if (responseDataToHandle?.key === fileKey) {
                var fileInfo = { ...responseDataToHandle }
                try {
                    fileInfo.content = JSON.parse(fileInfo.content);
                } catch {
                    fileInfo.content = { title: "", containers: [] };
                }
                setDashboardFileInfo(fileInfo);
            } else {
                setDashboardFileInfo(null);
            }
        }
    }

    useEffect(() => {
        handleDashboardFileResponseData(dashboardFileResponse);
    }, [dashboardFileResponse])

    const canvasElemRef = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [cellSize, setCellSize] = useState(0);
    const [numOfCellsPerRow, setNumOfCellsPerRow] = useState(0);
    useEffect(() => {
        setCanvasWidth(canvasElemRef.current?.clientWidth);

        const onWindowResize = (e) => {
            setCanvasWidth(canvasElemRef.current?.clientWidth);
        }
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        }
    }, [dashboardFileInfo, canvasElemRef.current]);

    useEffect(() => {
        const canvasElem = canvasElemRef.current;
        if (canvasWidth > 0) {
            var newNumOfCellsPerRow;
            if (canvasWidth <= SCREEN_SIZES.SMALL.MAX) {
                newNumOfCellsPerRow = SCREEN_SIZES.SMALL.CELLS;
            } else if (canvasWidth <= SCREEN_SIZES.MEDIUM.MAX) {
                newNumOfCellsPerRow = SCREEN_SIZES.MEDIUM.CELLS;
            } else {
                newNumOfCellsPerRow = SCREEN_SIZES.LARGE.CELLS;
            }
            const newCellSize = canvasWidth / newNumOfCellsPerRow;
            setCellSize(newCellSize);
            setNumOfCellsPerRow(newNumOfCellsPerRow);
            if (canvasElem) {
                canvasElem.style.backgroundImage = "url('/grid-pattern.svg')";
                canvasElem.style.backgroundPosition = "top left";
                canvasElem.style.backgroundRepeat = "repeat";
                canvasElem.style.backgroundSize = `${newCellSize}px ${newCellSize}px`;
            }
        } else {
            setCellSize(0);
            setNumOfCellsPerRow(0);
        }
    }, [canvasWidth]);

    const [selectedAction, setSelectedAction] = useState(null);
    const handleIemInsertAction = (itemType) => {
        setSelectedAction({ name: 'insert', data: { itemType, position: [] } });
    }

    const updateDashboardFileInfo = (updatedDashboardFileInfo) =>{
        setDashboardFileInfo(updatedDashboardFileInfo);
        save(updatedDashboardFileInfo);
    }

    const onInsert = (position, container) => {
        var parentContainer = dashboardFileInfo.content.containers;
        if (position.length === 0) {
            parentContainer.push(container);
        } else {
            for (var i = 0; i < position.length - 1; i++) {
                parentContainer = parentContainer[position[i]];
            }
            parentContainer.splice(position[position.length - 1], 0, container);
        }
        updateDashboardFileInfo({
            ...dashboardFileInfo,
            content: {
                ...dashboardFileInfo.content,
                containers: [...dashboardFileInfo.content.containers],
            }
        });
    }

    const onCancel = useCallback(() => {
        setSelectedAction(null);
    }, [setSelectedAction]);

    const [error, setError] = useState(null);
    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const [isBeingSaved, setIsBeingSaved] = useState(false);
    const save = (updatedDashboardFileInfo) => {
        if (!isBeingSaved) {
            setIsBeingSaved(true);
            if (error) {
                setError(null);
            }
            var dashboardFileInfoToSave = { ...updatedDashboardFileInfo };
            dashboardFileInfoToSave.content = JSON.stringify(dashboardFileInfoToSave.content);
            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workspace/files/${dashboardFileInfoToSave?.key}`,
                data: dashboardFileInfoToSave,
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onError: (response) => {
                    if (mounted.current) {
                        setError(response);
                    }
                },
                onSuccess: (response) => {
                    if (mounted.current) {
                        handleDashboardFileResponseData(response?.data?.data);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBeingSaved(false);
                    }
                }
            });
        }
    }

    const closeAlertBox = (e) => {
        e.preventDefault();
        if (error) {
            setError(null);
        }
    }

    return <>
        {dashboardFileInfo ?
            <div className="flex-1 flex w-full max-h-full justify-between items-stretch justify-items-stretch flex-col flex-nowrap overflow-hidden relative">
                <div ref={canvasElemRef} className="flex-1 min-h-0 max-h-full overflow-y-auto flex flex-row flex-wrap items-start justify-start justify-items-start max-w-full justify-self-start content-start">
                    {dashboardFileInfo.content.containers && dashboardFileInfo.content.containers.map((container, i) => <Container key={`container_${i}`} cellSize={cellSize} details={container} projectKey={projectKey} />)}
                </div>
                <div className="absolute bottom-2 right-1">
                    <ItemInsertAction onSelect={handleIemInsertAction} />
                </div>
            </div>
            : <div className="flex-1 flex w-full max-h-full justify-center items-stretch justify-items-center flex-col">
                {dashboardFileErrResponse ?
                    <div className="text-center">
                        <p className="text-sm">Oops, something went wrong.</p>
                        <button className="mt-2 bg-gray-200 hover:bg-gray-100 active:bg-gray-300 focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-blocktext-black shadow-sm text-sm rounded-md py-1 px-2" onClick={(e) => { e.preventDefault(); retry(); }}>
                            <ResetIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Try again
                        </button>
                    </div>
                    : <div className="text-center">
                        <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
                        <p>Reading file...</p>
                    </div>}
            </div>
        } 
        <ItemActionDialog projectKey={projectKey} onInsert={onInsert} onCancel={onCancel} action={selectedAction} />
        {error ? <AlertBox title="Error" error={error} onClose={closeAlertBox} /> : null}
    </>
}