export const SCREEN_SIZES = {
    SMALL: {
        MIN: 0,
        MAX: 480,
        CELLS: 6,
    },
    MEDIUM: {
        MIN: 481,
        MAX: 768,
        CELLS: 12,
    },
    LARGE: {
        MIN: 769,
        CELLS: 18,
    }
}