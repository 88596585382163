import { React } from 'react';
import StepIcon from './StepIcon';
import StepLabel from './StepLabel';
import { UPLOADS_STORAGE_BASE_URL } from '../../config';

export default function StepHeader({ stepType, stepIcon, stepFragmentType, title, onTitleChange, markedAsOutput, ...rest }) {
    return <div {...{ ...rest, className: "flex flex-nowrap items-start content-start justify-start justify-items-start " + (rest.className || '' ) }}>
        <div className={`flex-none  border-2 ${markedAsOutput ? 'border-primary-light bg-primary-lighter text-primary-dark ' : 'text-gray-500 bg-gray-100'} rounded-md w-[45px] h-[45px] relative`}>
            {stepIcon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${stepIcon}`} alt="Step icon" className="block w-full rounded-md" /> : <StepIcon stepType={stepType} centered={true} />}
        </div>
        <div className="flex-1 pl-2 pt-1.5">
            <StepLabel stepType={stepType} stepFragmentType={stepFragmentType} className="text-xs mb-0.5 leading-none text-gray-500 font-light" />
            {onTitleChange ?
                <input className="input input--sm input--full" type="text" value={title} onChange={onTitleChange} required placeholder="Enter step title" />
                :
                <h3 className="font-semibold w-full max-w-full text-gray-700 text-sm">{title}</h3>
            }
        </div>
    </div>
}