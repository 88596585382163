import { React, useMemo, useRef, useState, useEffect } from "react";
import { Route, Switch, NavLink, useParams } from "react-router-dom";
import WorkspacePage from "./WorkspacePage";
import PageNotFound from "./PageNotFound";
import { useProjectFetcher } from "../hooks/project";
import { ReactComponent as ProjectIcon } from '../icons/project.svg';
import { UPLOADS_STORAGE_BASE_URL } from '../config';
import { useHistory } from "react-router-dom";
import TeamPage from "./TeamPage";
import TeamMemberGroupContext from "../contexts/TeamMemberGroupContext";
import { useTeamMemberGroupListFetcher } from "../hooks/team-member-group";
import { useTeamMemberFetcher } from "../hooks/team-member";
import {useUser} from "../hooks/user";

export default function ProjectPage() {
    const { projectKey } = useParams();
    const { responseData: project } = useProjectFetcher({ projectKey }, [projectKey]);
    let history = useHistory();

    function handleClick(e) {
        e.preventDefault();
        var ignore = true;
        if (e.type === 'keydown') {
            if (e.key === 'Enter' || e.key === " " || e.key === "Spacebar") {
                ignore = false;
            }
        } else if (e.type === 'click') {
            ignore = false;
        }
        if (!ignore) {
            history.push(`/app/projects`);
        }
    }

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const [teamMemberGroups, setTeamMemberGroups] = useState(null);
    const { responseData: teamMemberGroupsData } = useTeamMemberGroupListFetcher({ projectKey });

    useEffect(() => {
        if (teamMemberGroupsData instanceof Array) {
            if (mounted.current) {
                setTeamMemberGroups(teamMemberGroupsData);
            }
        }
    }, [teamMemberGroupsData]);

    const teamMemberGroupsState = useMemo(() => ({ teamMemberGroups, setTeamMemberGroups }), [teamMemberGroups]);

    const { user, setUser } = useUser();
    const { responseData: teamMember } = useTeamMemberFetcher({ projectKey, teamMemberKey: user?.key });

    useEffect(() => {
        if (teamMember) {
            setUser({ ...user, team: { project_key: teamMember.project_key, group_key: teamMember.group }, is_admin: teamMember.group === 'admins' });
        }
    }, [teamMember]);

    return <TeamMemberGroupContext.Provider value={teamMemberGroupsState}>
        <div className="h-full flex flex-nowrap flex-col md:flex-row md:items-stretch md:justify-items-stretch">
            <aside className="md:w-1/5 md:flex-none rounded-lg md:pr-4">
                {project ? <a href="#" onClick={handleClick} onKeyDown={handleClick} className="relative rounded-lg hover:bg-gray-200 active:bg-gray-300 flex flex-row border p-1.5 items-center">
                    <div className={`${project?.icon ? 'rounded-md' : ''} inline-block max-h-8 w-8 flex-none relative`}>
                        {project?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/projects/medium/${project.icon}`} className="block w-full rounded-md" alt="Project icon" /> : <ProjectIcon className="absolute fill-current text-gray-500 block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[50%]" />}
                    </div>
                    <div className="flex-1 min-w-0 min-h-0">
                        <h1 className="p-2 text-black font-semibold text-md truncate">{project?.name}</h1>
                    </div>
                </a> : <div className="block animate-pulse bg-slate-200 rounded-lg h-12"></div>}
                <ul className="whitespace-nowrap mt-2 md:whitespace-normal overflow-x-auto max-w-full pb-2 md:pb-0">
                    <li className="inline-block mr-2 md:block md:mr-0">
                        <NavLink className="navmenu-item" to={`/app/projects/${projectKey}/dashboard`}>Dashboard</NavLink>
                    </li>
                    <li className="inline-block mr-2 md:block md:mr-0">
                        <NavLink className="navmenu-item" to={`/app/projects/${projectKey}/workspace`}>Workspace</NavLink>
                    </li>
                    <li className="inline-block mr-2 md:block md:mr-0">
                        <NavLink className="navmenu-item" to={`/app/projects/${projectKey}/team`}>Team</NavLink>
                    </li>
                    <li className="inline-block mr-2 md:block md:mr-0">
                        <NavLink className="navmenu-item" to={`/app/projects/${projectKey}/settings`}>Settings</NavLink>
                    </li>
                </ul>
            </aside>
            <div className="flex flex-col flex-nowrap items-stretch justify-items-stretch h-full flex-1 md:w-4/5">
                <Switch>
                    <Route path="/app/projects/:projectKey/workspace"><WorkspacePage projectKey={projectKey} /></Route>
                    <Route path="/app/projects/:projectKey/team"><TeamPage projectKey={projectKey} /></Route>
                    <Route path="*">
                        <PageNotFound />
                    </Route>
                </Switch>
            </div>
        </div>
    </TeamMemberGroupContext.Provider>
}