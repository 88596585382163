import { React } from 'react';
import { ReactComponent as RequestIcon } from '../../icons/step/request.svg';
import { ReactComponent as StorageIcon } from '../../icons/step/storage.svg';
import { ReactComponent as SelectorIcon } from '../../icons/step/selector.svg';
import { ReactComponent as FilterIcon } from '../../icons/step/filter.svg';
import { ReactComponent as FunctionIcon } from '../../icons/step/function.svg';
import { ReactComponent as IfIcon } from '../../icons/step/if.svg';
import { ReactComponent as TableDimensionsIcon } from '../../icons/step/table_dimensions.svg';
import { ReactComponent as RepeatIfIcon } from '../../icons/step/repeat_if.svg';
import { ReactComponent as RepeatIcon } from '../../icons/step/repeat.svg';
import { ReactComponent as GoToIcon } from '../../icons/step/go_to.svg';
import { ReactComponent as BrowserExtensionIcon } from '../../icons/step/browser_extension.svg';
import { ReactComponent as VariableIcon } from '../../icons/step/variable.svg';
import { ReactComponent as SetValueIcon } from '../../icons/step/set_value.svg';
import { ReactComponent as LastRowIcon } from '../../icons/step/last_row.svg';
import { ReactComponent as FirstRowIcon } from '../../icons/step/first_row.svg';
import { ReactComponent as EachRowIcon } from '../../icons/step/each_row.svg';
import { ReactComponent as EmbedWorkflowIcon } from '../../icons/step/embed_workflow.svg';
import { ReactComponent as TextIcon } from '../../icons/step/text.svg';
import { ReactComponent as RenameIcon } from '../../icons/step/rename.svg';
import { ReactComponent as GroupByIcon } from '../../icons/step/group_by.svg';
import { ReactComponent as SortByIcon } from '../../icons/step/sort_by.svg';
import { ReactComponent as JoinIcon } from '../../icons/step/join.svg';
import { ReactComponent as JsonIcon } from '../../icons/step/json.svg';
import { STEP_TYPES } from '../../constants/step';

function stepTypeIcon({ stepType, centered, markedAsOutput, ...rest }) {
    if (centered) {
        rest = { ...rest, className: generateClassNamesForCenteredIcon(stepType, markedAsOutput) };
    }
    switch (stepType) {
        case STEP_TYPES.REQUEST:
            return <RequestIcon {...rest} />;
        case STEP_TYPES.FUNCTION:
            return <FunctionIcon {...rest} />;
        case STEP_TYPES.SELECTOR:
            return <SelectorIcon {...rest} />;
        case STEP_TYPES.FILTER:
            return <FilterIcon {...rest} />;
        case STEP_TYPES.STORAGE:
            return <StorageIcon {...rest} />;
        case STEP_TYPES.IF:
            return <IfIcon {...rest} />;
        case STEP_TYPES.TABLE_DIMENSIONS:
            return <TableDimensionsIcon {...rest} />;
        case STEP_TYPES.REPEAT_IF:
            return <RepeatIfIcon {...rest} />;
        case STEP_TYPES.REPEAT:
            return <RepeatIcon {...rest} />;
        case STEP_TYPES.GO_TO:
            return <GoToIcon {...rest} />;
        case STEP_TYPES.BROWSER_EXTENSION:
            return <BrowserExtensionIcon {...rest} />;
        case STEP_TYPES.VARIABLE:
            return <VariableIcon {...rest} />;
        case STEP_TYPES.SET_VALUE:
            return <SetValueIcon {...rest} />;
        case STEP_TYPES.LAST_ROW:
            return <LastRowIcon {...rest} />;
        case STEP_TYPES.FIRST_ROW:
            return <FirstRowIcon {...rest} />;
        case STEP_TYPES.EMBED_WORKFLOW:
            return <EmbedWorkflowIcon {...rest} />;
        case STEP_TYPES.TEXT:
            return <TextIcon {...rest} />;
        case STEP_TYPES.RENAME:
            return <RenameIcon {...rest} />;
        case STEP_TYPES.EACH_ROW:
            return <EachRowIcon {...rest} />;
        case STEP_TYPES.GROUP_BY:
            return <GroupByIcon {...rest} />;
        case STEP_TYPES.SORT_BY:
            return <SortByIcon {...rest} />;
        case STEP_TYPES.JOIN:
            return <JoinIcon {...rest} />;
        case STEP_TYPES.JSON:
            return <JsonIcon {...rest} />;
        default:
            return null;
    }
}


function generateClassNamesForCenteredIcon(stepType) {
    var className = 'absolute fill-current block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] ';
    switch (stepType) {
        case STEP_TYPES.SELECTOR:
        case STEP_TYPES.IF:
        case STEP_TYPES.REQUEST:
        case STEP_TYPES.EMBED_WORKFLOW:
        case STEP_TYPES.JSON:
            className += 'w-[60%]';
            break;
        case STEP_TYPES.TABLE_DIMENSIONS:
        case STEP_TYPES.FIRST_ROW:
        case STEP_TYPES.LAST_ROW:
        case STEP_TYPES.EACH_ROW:
        case STEP_TYPES.BROWSER_EXTENSION:
        case STEP_TYPES.GROUP_BY:
        case STEP_TYPES.SORT_BY:
            className += 'h-[50%]';
            break;
        case STEP_TYPES.VARIABLE:
            className += 'w-[40%]';
            break;
        case STEP_TYPES.JOIN:
            className += 'w-[75%]';
            break;
        default:
            className += 'w-[50%]';
    }
    return className;
}


export default function StepIcon(props) {
    return stepTypeIcon(props);
}