export const FILE_TYPES = {
    DIRECTORY: {
        CODE: 1,
        NAME: "Directory"
    },
    WORKFLOW: {
        CODE: 2,
        NAME: "Workflow"
    },
    JSON: {
        CODE: 3,
        NAME: "JSON"
    },
    WIDGET: {
        CODE: 4,
        NAME: "Widget"
    },
    DASHBOARD: {
        CODE: 5,
        NAME: "Dashboard"
    }
};

export const FILE_ACTIONS = {
    VIEW: {
        CODE: 1,
        NAME: "View"
    },
    EDIT: {
        CODE: 2,
        NAME: "Edit"
    },
    EXECUTE: {
        CODE: 3,
        NAME: "Execute"
    }
};

export const FILE_PERMISSIONS = {
    TEAM_ONLY: {
        KEY: "_team_",
        NAME: "Team only"
    },
    EVERYONE: {
        KEY: "_everyone_",
        NAME: "Everyone"
    }
};