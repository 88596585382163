import { React, useState, useEffect, useRef, useLayoutEffect } from "react";
import StepLabel from "./StepLabel";
import StepIcon from "./StepIcon";
import keyHelpers from "../../helpers/key";
import TableSelector from '../common/TableSelector';
import { VALUE_TYPE_NAMES } from "../../constants/value-types";
import { databaseToTableTree } from "../../helpers/database";
import { ReactComponent as ZoomInIcon } from '../../icons/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from '../../icons/zoom-out.svg';
import { ReactComponent as TableIcon } from '../../icons/table.svg';
import { select, tree, hierarchy, linkHorizontal, zoom, zoomIdentity } from 'd3';
import { ROOT_TABLE_KEY } from "../../constants/database";

export default function DataSchema({ source, onClose }) {
    const sidebarRef = useRef(null);
    const tables = source?.data?.tables;
    const [selectedTable, setSelectedTable] = useState(tables ? tables[source?.tableKey] : null);

    const [isTableSelectorVisible, setTableSelectorVisibility] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);

    useLayoutEffect(() => {
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = 'auto';
        }
    });

    useLayoutEffect(() => {
        if (sidebarRef.current !== null) {
            const sidebar = sidebarRef.current;
            const sidebarConfig = {
                dragging: false,
                moveable: window.innerWidth <= 768,
                y: {
                    current: 0,
                    temp: 0,
                    diff: 0,
                    start: 0,
                    closed: window.innerHeight * 0.8,
                    opened: window.innerHeight * 0.2,
                    fixed: 0
                }
            };

            const onWindowResize = function () {
                if (window.innerWidth <= 768) {
                    sidebarConfig.moveable = true;
                    sidebarConfig.y.closed = window.innerHeight * 0.8;
                    sidebarConfig.y.opened = window.innerHeight * 0.2;
                    sidebar.style.top = `${sidebarConfig.y.closed}px`;
                } else {
                    sidebarConfig.moveable = false;
                    sidebar.style.top = `${sidebarConfig.y.fixed}px`;
                }
            }

            const onMouseDown = function (e) {
                if (sidebarConfig.moveable) {
                    if (!e) { e = window.event; }
                    var el = e.target, disregard = false;
                    while (el && el.id !== 'table-schema') {
                        el = el.parentElement;
                    }
                    if (el && el.id === 'table-schema') {
                        disregard = true;
                    }
                    if (disregard || e.target.tagName === 'A' || e.target.tagName === 'BUTTON') {
                        return false;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                    sidebarConfig.dragging = true;
                    sidebarConfig.y.start = getMousePosition(e);
                    sidebarConfig.y.diff = 0;
                    sidebarConfig.y.current = sidebar.offsetTop;
                }
            }

            const onMouseMove = function (e) {
                if (sidebarConfig.dragging && sidebarConfig.moveable) {
                    if (!e) { e = window.event; }
                    e.preventDefault();
                    e.stopPropagation();
                    sidebarConfig.y.diff = sidebarConfig.y.start - getMousePosition(e);
                    sidebarConfig.y.temp = sidebarConfig.y.current - sidebarConfig.y.diff;
                    if (sidebarConfig.y.diff > 0 && sidebarConfig.y.temp < sidebarConfig.y.opened) {
                        sidebarConfig.y.temp = sidebarConfig.y.opened;
                    } else if (sidebarConfig.y.diff < 0 && sidebarConfig.y.temp > sidebarConfig.y.closed) {
                        sidebarConfig.y.temp = sidebarConfig.y.closed;
                    }
                    sidebar.style.top = `${sidebarConfig.y.temp}px`;
                }
            }

            const onMouseUp = function (e) {
                if (sidebarConfig.dragging && sidebarConfig.moveable) {
                    if (!e) { e = window.event; }
                    e.preventDefault();
                    e.stopPropagation();
                    sidebarConfig.dragging = false;
                    sidebarConfig.y.diff = sidebarConfig.y.start - getMousePosition(e);
                    if (Math.abs(sidebarConfig.y.diff) >= 80) {
                        sidebarConfig.y.current = sidebarConfig.y.current - sidebarConfig.y.diff;
                        if (sidebarConfig.y.diff >= 80) {
                            sidebarConfig.y.current = sidebarConfig.y.opened;
                        } else if (sidebarConfig.y.diff <= -80) {
                            sidebarConfig.y.current = sidebarConfig.y.closed;
                        }
                    }
                    sidebar.style.top = `${sidebarConfig.y.current}px`;
                }
            }

            window.addEventListener('resize', onWindowResize);
            sidebar.addEventListener('mousedown', onMouseDown);
            sidebar.addEventListener('touchstart', onMouseDown);
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('touchmove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
            window.addEventListener('touchcancel', onMouseUp);
            window.addEventListener('touchend', onMouseUp);

            return () => {
                window.removeEventListener('resize', onWindowResize);
                sidebar.removeEventListener('mousedown', onMouseDown);
                sidebar.removeEventListener('touchstart', onMouseDown);
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('touchmove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
                window.removeEventListener('touchcancel', onMouseUp);
                window.removeEventListener('touchend', onMouseUp);
            }
        }
    }, []);

    const toggleTableSelector = (e) => {
        e.preventDefault();
        setTableSelectorVisibility(!isTableSelectorVisible);
    }

    const closeTableSelector = () => {
        setTableSelectorVisibility(false);
    }

    const selectTable = (tableKey) => {
        if (tables) {
            if (tableKey in tables) {
                setSelectedTable(tables[tableKey]);
            }
        }
    }

    const handleZoomLevelChange = (e) => {
        setZoomLevel(e.target.value);
    }

    const handleClose = (e) => {
        e.preventDefault();
        onClose();
    }

    return <div className="fixed z-20 w-full h-full bg-gray-50 right-0 top-0 bottom-0 overflow-hidden">
        <div className="flex flex-row w-full h-full relative">
            <div className="flex-1 overflow-auto relative">
                <DataTree data={source?.data} selectedNodeKey={selectedTable?.key} selectTable={selectTable} setSliderZoomLevel={setZoomLevel} sliderZoomLevel={zoomLevel} />
                <div className="inline-flex bg-white items-center py-1 absolute top-0 left-0 border-r border-b rounded-br-sm">
                    <ZoomOutIcon className="fill-gray-500 flex-none w-8 px-2" />
                    <div className="flex-1">
                        <input className="w-full block" type="range" min="0.1" max="6" step="0.05" value={zoomLevel} onChange={handleZoomLevelChange} />
                    </div>
                    <ZoomInIcon className="fill-gray-500 flex-none w-8 px-2" />
                </div>
            </div>
            <div ref={sidebarRef} className="transition-all md:flex-none bg-gray-50 md:border-l flex md:w-1/3 flex-col max-h-[80%] h-[80%] py-2 px-2 md:relative md:top-auto md:left-auto md:right-auto md:max-h-full md:h-full absolute top-[80%] left-0 right-0  md:rounded-none rounded-t-xl border-t">
                <header className="flex-none flex mb-3 flex-nowrap items-start content-start justify-start justify-items-start">
                    {source?.step ? <>
                        <div className={`flex-none border-2 ${source?.step?.marked_as_output ? 'border-primary-light bg-primary-lighter text-primary-dark ' : 'text-gray-500 bg-gray-100'} rounded-md w-[45px] h-[45px] lg:w-[60px] lg:h-[60px] relative`}>
                            {source?.step?.icon ? <img src={source?.step?.icon} alt="Step icon" className="block w-full rounded-md" /> : <StepIcon stepType={source?.step?.type} centered={true} />}
                        </div>
                        <div className="flex-1 pl-2 pt-1.5 md:pt-2">
                            <StepLabel stepType={source?.step?.type} stepFragmentType={source?.step?.fragment_type} className="text-xs lg:text-sm mb-0.5 leading-none text-gray-500 font-light" />
                            <h2 className="font-semibold w-full max-w-full text-gray-700 text-sm lg:text-lg">{source?.title}</h2>
                        </div>
                    </> : <>
                        <div className="flex-none border-2 text-gray-500 bg-gray-100 rounded-md w-[45px] h-[45px] lg:w-[60px] lg:h-[60px] relative">
                            <TableIcon className="absolute fill-current block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-50%" />
                        </div>
                        <div className="flex-1 pl-2 pt-1.5 md:pt-2">
                            <div className="text-xs lg:text-sm mb-0.5 leading-none text-gray-500 font-light">Data schema</div>
                            <h2 className="font-semibold w-full max-w-full text-gray-700 text-sm lg:text-lg">{source?.title}</h2>
                        </div>
                    </>}
                </header>
                {selectedTable && <div id="table-schema" className="flex flex-1 flex-col min-h-0">
                    <div className="flex-none w-full shadow-sm rounded-md relative">
                        <header className="flex py-1 px-1 flex-nowrap max-w-full rounded-t-md bg-gray-200 justify-between">
                            <div className="flex-1 table-selector relative">
                                <button onClick={toggleTableSelector} className="active:bg-gray-300 hover:bg-gray-100 pl-2 rounded-md text-sm selector-icon break-all appearance-none text-gray-700">{selectedTable.key || selectedTable.name}</button>
                                {isTableSelectorVisible ? <TableSelector value={selectedTable.key} tableTree={databaseToTableTree(source?.data)}  onClose={closeTableSelector} onChange={selectTable} /> : null}
                            </div>
                        </header>
                    </div>
                    <div className="flex-none flex justify-between text-gray-600 items-center text-xs px-2 py-1 bg-gray-100">
                        <span className="flex-none font-semibold">Columns</span>
                        <span className="flex-none">({selectedTable.columns?.length || 0})</span>
                    </div>
                    {selectedTable.columns && <div className="flex-1 overflow-auto min-h-0">
                        {selectedTable.columns.map((column) => {
                            const columnNameFields = keyHelpers.extractFieldsFromColumnName(column.name);
                            return <div key={column.name} className="flex min-h-[30px] hover:bg-gray-100 items-center text-xs justify-between px-2 py-1 bg-white border-b">
                                <span className="flex-1">{columnNameFields.map((s, j) => <span key={j.toString()} className={`line-clamp-2 break-all ${j + 1 === columnNameFields.length ? 'text-black' : 'text-gray-500'}`}>{j === 0 ? s : `.${s}`}</span>)}</span>
                                <span className="flex-none text-gray-600 font-semibold">{VALUE_TYPE_NAMES[column.value_type]}</span>
                            </div>
                        })}
                    </div>}
                </div>}
            </div>
            <button onClick={handleClose} className="fixed top-0 right-0 z-30 bg-white border-l border-b appearance-none rounded-bl-md px-2 py-1 hover:bg-gray-100 active:bg-gray-200 text-sm">Close</button>
        </div>
    </div>
}

function DataTree({ data, selectedNodeKey, selectTable, sliderZoomLevel, setSliderZoomLevel }) {
    const svgRef = useRef(null);
    const [hierarchyData, setHierarchyData] = useState(() => {
        return prepareHierarchyData(data);
    });
    const [config, setConfig] = useState(() => {
        return {
            width: 0,
            height: 0,
            treeHeight: 0,
            treeWidth: 0,
            minNodeWidth: 400,
            minNodeHeight: 60
        };
    })
    const currZoomLevel = useRef(sliderZoomLevel);
    const updateZoomLevel = (newZoomValue) => {
        if (newZoomValue !== currZoomLevel.current) {
            currZoomLevel.current = newZoomValue;
            setSliderZoomLevel(newZoomValue);
        }
    }

    useLayoutEffect(() => {
        function updateSize() {
            const newWidth = window.innerWidth > 768 ? window.innerWidth * (2 / 3) : window.innerWidth;
            const newHeight = window.innerWidth > 768 ? window.innerHeight : window.innerHeight * 0.8;
            if (config.width !== newWidth || config.height !== newHeight) {
                setConfig({ ...config, width: newWidth, height: newHeight });
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        setHierarchyData(prepareHierarchyData(data));
    }, [data]);

    useEffect(() => {
        if (config.width > 0 && config.height > 0) {
            const treeDimensions = computeSvgSizeFromData(prepareHierarchyData(data), config);
            if (treeDimensions.width !== config.treeWidth || treeDimensions.height !== config.treeHeight) {
                setConfig({ ...config, treeWidth: treeDimensions.width, treeHeight: treeDimensions.height });
            }
        }
    }, [data, config]);

    useLayoutEffect(() => {
        if (svgRef.current !== null && typeof selectedNodeKey === 'string') {
            focusOnSelectedNode(selectedNodeKey, select(svgRef.current), config, currZoomLevel.current, updateZoomLevel);
        }
    }, [selectedNodeKey, config])

    useEffect(() => {
        if (currZoomLevel.current !== sliderZoomLevel) {
            currZoomLevel.current = sliderZoomLevel;
            focusOnSelectedNode(selectedNodeKey, select(svgRef.current), config, currZoomLevel.current, updateZoomLevel);
        }
    }, [sliderZoomLevel])

    useLayoutEffect(() => {
        if (svgRef.current !== null && config.width > 0 && config.height > 0 && config.treeWidth > 0 && config.treeHeight > 0) {
            const svg = select(svgRef.current);
            const treeLayout = tree().size([config.treeHeight, config.treeWidth]);

            svg
                .attr('width', '100%')
                .attr('height', '100%')
                .style('cursor', 'grab');

            var zoomG = svg.select('#zoom');
            var g;
            if (zoomG.empty()) {
                zoomG = svg.append('g').attr('id', 'zoom');
                svg.call(zoomer(svg, updateZoomLevel))
                g = zoomG.append('g');
            } else {
                g = zoomG.select('g');
            }

            const links = treeLayout(hierarchyData).links();
            const linkPathGenerator = linkHorizontal()
                .x(d => d.y)
                .y(d => d.x);

            g.selectAll('path').data(links)
                .enter().append('path')
                .attr('d', linkPathGenerator)
                .attr('fill', 'none')
                .style("stroke", "#999")
                .style("stroke-width", "2px")
                .style("stroke-dasharray", (d) => d.source.data.key === ROOT_TABLE_KEY ? '8,8' : '');

            const textG = g.selectAll('g')
                .data(hierarchyData.descendants())
                .enter()
                .append('g')
                .attr('class', 'node')
                .style('cursor', 'pointer')
                .attr('id', (d) => d.parent === null ? 'root-table' : keyHelpers.keyToElementID(d.data.key))
                .on("click", (e, d) => {
                    e.preventDefault();
                    selectTable(d.data.key);
                    focusOnSelectedNode(d.data.key, svg, config, currZoomLevel.current, updateZoomLevel);
                }).on("mouseover", function () {
                    const currNode = select(this);
                    if (!currNode.classed("active")) {
                        currNode.select("rect").transition().style("fill", "#ddd");
                    }
                }).on("mouseout", function () {
                    const currNode = select(this);
                    if (!currNode.classed("active")) {
                        currNode.select("rect").transition().style("fill", "white");
                    }
                });

            textG
                .append('text')
                .attr('x', d => d.parent ? d.chldren ? d.y - 5 : d.y + 5 : d.y - 5)
                .attr('y', d => d.x)
                .attr('dy', '0.32em')
                .attr('text-anchor', 'start')
                .attr('font-size', d => 20 - (d.depth * 2) + 'px')
                .style('pointer-events', 'none')
                .style('user-select', 'none')
                .text(d => d.data.name)
                .call(getBB)

            textG.insert("rect", "text")
                .attr('x', d => (d.parent ? d.chldren ? d.y - 5 : d.y + 5 : d.y - 5) - 5)
                .attr('y', d => d.x - (d.bbox.height / 2) - 3)
                .attr('rx', 6)
                .attr('ry', 6)
                .attr("width", (d) => d.bbox.width + 10)
                .attr("height", (d) => d.bbox.height + 6)
                .style("fill", "white")
                .style("stroke", "steelblue")
                .style("stroke-width", "1px")
                .style('user-select', 'none');

            focusOnSelectedNode(selectedNodeKey, svg, config, currZoomLevel.current, updateZoomLevel);
        }
    }, [config, hierarchyData])

    return <svg ref={svgRef} />;
}

function computeSvgSizeFromData(hierarchyData, config) {

    // create a hierarchy from the root
    tree(hierarchyData)
    // nodes
    const nodes = hierarchyData.descendants();

    var maxTreeChildrenHeight = {},
        maxTreeHeight = 0,
        maxTreeDepth = 0,
        minSvgWidth,
        minSvgHeight;

    // Compute the max tree depth(node which is the lowest leaf) 
    nodes.forEach(function (d) {
        if (d.depth > maxTreeDepth) {
            maxTreeDepth = d.depth;
        }

        if (!maxTreeChildrenHeight[d.depth]) {
            maxTreeChildrenHeight[d.depth] = 0;
        }

        maxTreeChildrenHeight[d.depth] = maxTreeChildrenHeight[d.depth] + 1;
    });

    // Compute maximum number of vertical at a level
    for (var depth in maxTreeChildrenHeight) {
        if (maxTreeChildrenHeight[depth] > maxTreeHeight) {
            maxTreeHeight = maxTreeChildrenHeight[depth];
        }
    }

    // Since this is a horizontal tree, compute the width
    // based upon the depth and the height based upon 
    // the number of nodes at a depth level
    minSvgWidth = maxTreeDepth * config.minNodeWidth < config.width ? config.width : (maxTreeDepth + 1) * config.minNodeWidth;
    minSvgHeight = maxTreeHeight * config.minNodeHeight < config.height ? config.height : (maxTreeHeight + 1) * config.minNodeHeight;

    return {
        width: minSvgWidth,
        height: minSvgHeight
    };
}

function getBB(selection) {
    selection.each(function (d) { d.bbox = this.getBBox(); })
}

function focusOnSelectedNode(selectedNodeKey, svg, config, zoomLevel, updateZoomLevel) {
    if (!svg.empty() && typeof selectedNodeKey === 'string' && config.width > 0 && config.height > 0) {
        const activeNode = svg.select('.node.active');
        if (!activeNode.empty()) {
            activeNode.classed('active', false);
            activeNode.select('rect').transition().style('fill', 'white');
            activeNode.select('text').transition().style('fill', 'black');
        }

        const selectedNodeId = selectedNodeKey === '' ? 'root-table' : keyHelpers.keyToElementID(selectedNodeKey);
        const selectedNode = svg.select(`#${selectedNodeId}`);

        if (!selectedNode.empty()) {
            selectedNode.classed('active', true);
            selectedNode.select('rect').transition().style('fill', 'steelblue');
            selectedNode.select('text').transition().style('fill', 'white');

            var bbox = selectedNode.node().getBBox(),
                bounds = [[bbox.x, bbox.y], [bbox.x + bbox.width, bbox.y + bbox.height]]; //<-- the bounds from getBBox

            var dx = bounds[1][0] - bounds[0][0],
                dy = bounds[1][1] - bounds[0][1],
                x = (bounds[0][0] + bounds[1][0]) / 2,
                y = (bounds[0][1] + bounds[1][1]) / 2,
                scale = typeof zoomLevel === 'undefined' ? Math.max(1, Math.min(6, 0.1 / Math.max(dx / config.width, dy / config.height))) : zoomLevel,
                translate = { x: config.width / 2 - scale * x, y: config.height / 2 - scale * y };

            //get a transform to the selected node
            let transform = zoomIdentity.translate(translate.x, translate.y).scale(scale);
            svg.transition()
                .duration(750)
                .call(zoomer(svg, updateZoomLevel).transform, transform);
        }
    }
}

function prepareHierarchyData(data) {
    return hierarchy(databaseToTableTree(data));
}

function zoomer(svg, updateZoomLevel) {
    const zoomG = svg.select('#zoom');
    return zoom()
        .on('zoom', (e) => {
            zoomG.attr('transform', e.transform);
        })
        .on("start", function () {
            svg.style('cursor', 'grabbing');
        })
        .on("end", function (e) {
            svg.style('cursor', 'grab');
            updateZoomLevel(e.transform.k);
        });
}

function getMousePosition(e) {
    var touch = null;
    if (e.touches || e.changedTouches) {
        touch = e.touches[0] || e.changedTouches[0];
    }
    if (touch) {
        return touch.pageY;
    }
    return e.clientY;
}