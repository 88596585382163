import { React, useState, useEffect } from "react";
import { filterTree } from "../../helpers/database";

export default function TableSelector({ tableTree, value, onClose, onChange }) {

    const [selectedTable, setSelectedTable] = useState(value);
    const [filteredTableTree, setFilteredTableTree] = useState(tableTree);

    const search = (keyword) => {
        if ((keyword?.length || 0) === 0) {
            return tableTree;
        }
        keyword = keyword.trim().toLowerCase();
        if (tableTree) {
            return filterTree(tableTree, keyword);
        }
        return null;
    }

    const [searchKeyword, setSearchKeyword] = useState("");

    const handleSearch = (e) => {
        setSearchKeyword(e.target.value);
        setFilteredTableTree(search(e.target.value));
    }

    const selectTable = (table) => (e) => {
        e.preventDefault();
        setSelectedTable(table);
        onChange(table);
        onClose();
    }

    useEffect(() => {
        const cancel = (e) => {
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('table-selector')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('table-selector')) {
                disregard = false;
            }
            if (disregard) {
                onClose();
            }
        }
        document.addEventListener('click', cancel);
        return () => {
            document.removeEventListener('click', cancel);
        }
    }, [])

    return <div className="bg-white border w-80 md:w-96 z-30 absolute py-2 px-1 top-6 rounded-md left-0 shadow-lg inline-block">
        <div className="px-1"><input type="text" value={searchKeyword} onChange={handleSearch} className="w-full input input--sm" placeholder="Find table" /></div>
        <ul className="overflow-y-auto max-w-full px-1 mt-2 overflow-x-visible max-h-52">
            {filteredTableTree && <TableNode node={filteredTableTree} onClick={selectTable} selectedNode={selectedTable} />}
        </ul>
    </div>
}

function TableNode({ node, onClick, selectedNode }) {
    return <>
        <li className="flex flex-nowrap content-start relative items-start">
            <div className="flex flex-none tems-start flex-row-reverse">
            <ParentTableNode parentNode={node.parent} />
            </div>
            <a href="#" onClick={onClick(node.key)} className={`flex-1 min-h-[24px] py-1 px-1 break-all text-sm rounded-md ${selectedNode === node.key ? "font-semibold bg-primary-light" : "cursor-pointer hover:bg-gray-100"}`}>
                {node.name}
            </a>
        </li>
        {(node.children?.length || 0) > 0 ? node.children.map((childNode) => {
            return <TableNode key={childNode.key} node={childNode} onClick={onClick} selectedNode={selectedNode} />
        }) : null}
    </>
}

function ParentTableNode({ parentNode }) {
    return <>
        {parentNode && <div className="flex-none pt-2.5 pl-0.5">
            <div className="block float-left leading-none px-1 group cursor-pointer" title={parentNode.name}>
                <span className="absolute bg-gray-300 top-0 bottom-0 block w-px h-full ml-1"></span>
                <span style={{ minWidth: '10px', height: '10px' }} className="block leading-none relative rounded-full bg-gray-300 group-hover:bg-primary-dark"></span>
            </div>
        </div>}
        {parentNode?.parent && <ParentTableNode parentNode={parentNode.parent} />}
    </>
}