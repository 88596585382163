import { React } from "react";
import DataView from "../common/DataView";

export default function LineChartView({ data, parameters }) {
    return <>
        <label className="label !text-gray-500">X-Axis</label>
        <div className="border mb-2 p-2 rounded-md">
            <DataView parameters={parameters} className="mb-2" data={data.x_axis?.label} />
            <DataView parameters={parameters} data={data.x_axis?.values} />
        </div>
        <label className="label !text-gray-500">Y-Axis</label>
        <div className="border p-2 rounded-md">
            <DataView parameters={parameters} className="mb-2" data={data.y_axis?.label} />
            <DataView parameters={parameters} data={data.y_axis?.values} />
        </div>
    </>
}