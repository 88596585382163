import { React, useState } from 'react';
import Dropdown from '../common/Dropdown';
import FunctionStep from './FunctionStep';
import RequestStep from './RequestStep';
import SelectorStep from './SelectorStep';
import FilterStep from './FilterStep';
import StorageStep from './StorageStep';
import IfStep from './IfStep';
import RepeatIfStep from './RepeatIfStep';
import RepeatStep from './RepeatStep';
import TableDimensionsStep from './TableDimensionsStep';
import GoToStep from './GoToStep';
import BrowserExtensionStep from './BrowserExtensionStep';
import VariableStep from './VariableStep';
import SetValueStep from './SetValueStep';
import LastRowStep from './LastRowStep';
import FirstRowStep from './FirstRowStep';
import EmbedWorkflowStep from './EmbedWorkflowStep';
import TextStep from './TextStep';
import { FRAGMENT_STEP_TYPES, STEP_TYPES } from '../../constants/step';
import { isFragmentStep } from "../../helpers/step";
import RenameStep from './RenameStep';
import EachRowStep from './EachRowStep';
import GroupByStep from './GroupByStep';
import SortByStep from './SortByStep';
import JoinStep from './JoinStep';
import { StepOutput } from './StepOutput';
import JsonStep from './JsonStep';

function canStepBeDeleted(step) {
    if (isFragmentStep(step)) {
        return step.fragment_type !== FRAGMENT_STEP_TYPES.END;
    }
    return true;
}

export default function Step({ projectKey, index, step, fragmentOf, output, updateStep, deleteStep, markAsOutputStep, parameters, dispatchParameters, onRun, onStop, onReset, isRunning, lineColor, topBarColor, showDataSchema }) {

    const [editMode, setEditMode] = useState(step.editMode);

    const update = (index, updatedStep) => {
        if (updatedStep.hasOwnProperty('editMode')) {
            delete updatedStep['editMode'];
        }
        updateStep(index, updatedStep);
        setEditMode(false);
    }

    const content = () => {
        switch (step.type) {
            case STEP_TYPES.REQUEST:
                return <RequestStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.FUNCTION:
                return <FunctionStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.SELECTOR:
                return <SelectorStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.FILTER:
                return <FilterStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.STORAGE:
                return <StorageStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.IF:
                return <IfStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.TABLE_DIMENSIONS:
                return <TableDimensionsStep projectKey={projectKey} index={index} fragmentOf={fragmentOf} step={step} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onReset={onReset} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.REPEAT_IF:
                return <RepeatIfStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.REPEAT:
                return <RepeatStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.GO_TO:
                return <GoToStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.BROWSER_EXTENSION:
                return <BrowserExtensionStep projectKey={projectKey} index={index} fragmentOf={fragmentOf} step={step} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onReset={onReset} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.VARIABLE:
                return <VariableStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.SET_VALUE:
                return <SetValueStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.LAST_ROW:
                return <LastRowStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.FIRST_ROW:
                return <FirstRowStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.EMBED_WORKFLOW:
                return <EmbedWorkflowStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.TEXT:
                return <TextStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.RENAME:
                return <RenameStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.EACH_ROW:
                return <EachRowStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.GROUP_BY:
                return <GroupByStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.SORT_BY:
                return <SortByStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.JOIN:
                return <JoinStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            case STEP_TYPES.JSON:
                return <JsonStep projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} editMode={editMode} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
            default:
                return '';
        }
    }

    return (<div className="max-w-full md:flex md:items-start md:flex-col md:flex-nowrap relative">
        <div className="md:flex-none min-w-md md:max-w-lg md:w-lg relative shadow-sm rounded-md">
            {!editMode ? <Dropdown className="inline-block absolute right-1 top-1">
                <button onClick={(e) => { e.preventDefault(); onRun(step); }} className="py-1 text-left block w-full bg-gray-100 hover:bg-gray-50 active:bg-gray-200 px-2 text-sm">Run</button>
                <button onClick={(e) => { e.preventDefault(); setEditMode(true); }} className="py-1 text-left block w-full bg-gray-100 hover:bg-gray-50 active:bg-gray-200 px-2 text-sm">Edit</button>
                {!isFragmentStep(step) && <button onClick={(e) => { e.preventDefault(); markAsOutputStep(index, !step.marked_as_output); }} className="min-w-[140px] py-1 text-left block w-full px-2 text-sm bg-gray-100 hover:bg-gray-50 active:bg-gray-200">{step.marked_as_output ? 'Unmark' : 'Mark'} as output</button>}
                {canStepBeDeleted(step) && <button onClick={(e) => { e.preventDefault(); deleteStep(index); }} className="py-1 text-left block w-full px-2 text-red-500 bg-gray-100 hover:bg-gray-50 active:bg-gray-200 text-sm">Delete</button>}
            </Dropdown> : null}
            {content()}
        </div>
        {output && <StepOutput index={index} step={step} data={output} parameters={parameters} dispatchParameters={dispatchParameters} topBarColor={topBarColor} lineColor={lineColor} showDataSchema={showDataSchema} />}
    </div>)
}