import { React, useEffect, useState, useRef } from "react";
import Loader from "../components/brand/Loader";
import AddButton from "../components/team/AddButton";
import MemberActionDialog from "../components/team/MemberActionDialog";
import TeamMemberCard from "../components/team/TeamMemberCard";
import TeamMemberGroups from "../components/team/TeamMemberGroups";
import { useTeamMemberListFetcher } from "../hooks/team-member";
import useAccessToken from "../hooks/access-token";
import useLogout from "../hooks/logout";
import { useCancelToken } from "../hooks/cancel-token";
import requestHelpers from "../helpers/request";
import GroupActionDialog from "../components/team/GroupActionDialog";
import { useTeamMemberGroups } from "../hooks/team-member-group";
import {useUser} from "../hooks/user";

export default function TeamPage({ projectKey }) {

    const [teamMembers, setTeamMembers] = useState(null);
    const [cursor, setCursor] = useState("");
    const [groupKey, setGroupKey] = useState("");
    const {user} = useUser();

    useEffect(() => {
        setCursor("");
    }, [groupKey])

    const [isLoading, setIsLoading] = useState(true);
    const { responseData: teamMembersData } = useTeamMemberListFetcher({ projectKey, cursor, groupKey });

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    useEffect(() => {
        if (teamMembersData?.team_members instanceof Array) {
            if (teamMembers === null) {
                setTeamMembers(cleanTeamMembers(teamMembersData.team_members, groupKey));
            } else {
                setTeamMembers(cleanTeamMembers([...teamMembers, ...teamMembersData.team_members], groupKey));
            }
            setIsLoading(false);
        }
    }, [teamMembersData]);

    const loadMore = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        setCursor(teamMembersData?.next_cursor);
    }

    const [memberAction, setMemberAction] = useState(null);
    const [groupAction, setGroupAction] = useState(null);

    const showAddForm = (itemType) => {
        if (itemType === 'member') {
            setMemberAction({ name: "add" });
        } else if (itemType === 'group') {
            setGroupAction({ name: "add" });
        }
    }

    const showUpdateForm = (itemType, item) => {
        if (itemType === 'member') {
            setMemberAction({ name: "update", data: item });
        } else if (itemType === 'group') {
            setGroupAction({ name: "update", data: item });
        }
    }

    const showDeleteForm = (itemType, item) => {
        if (itemType === 'member') {
            setMemberAction({ name: "delete", data: item });
        } else if (itemType === 'group') {
            setGroupAction({ name: "delete", data: item });
        }
    }

    const onMemberDialogCancel = () => {
        setMemberAction(null);
    }

    const onGroupDialogCancel = () => {
        setGroupAction(null);
    }

    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const onMemberAdd = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/team`,
            data: data,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const newMember = response?.data?.data;
                    if (newMember) {
                        if (newMember.group === groupKey || groupKey === "") {
                            setTeamMembers(sortTeamMembers([...teamMembers, newMember], "recently_modified"));
                        }
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onMemberUpdate = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/team/${data?.key}`,
            data: data,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const updatedMember = response?.data?.data;
                    if (updatedMember) {
                        const updatedTeamMembers = teamMembers.map((tm) => {
                            if (updatedMember.key === tm.key) {
                                tm.group = updatedMember.group
                                tm.modified = updatedMember.modified;
                            }
                            return tm;
                        });
                        setTeamMembers(sortTeamMembers(updatedTeamMembers, "recently_modified"));
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onMemberDelete = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'delete',
            urlPath: `/projects/${projectKey}/team/${data?.key}`,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    setTeamMembers(teamMembers.filter((tm) => tm.key !== data?.key));
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const { teamMemberGroups, setTeamMemberGroups } = useTeamMemberGroups();

    const onGroupAdd = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/groups`,
            data: data,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const newGroup = response?.data?.data;
                    if (newGroup) {
                        setTeamMemberGroups(sortTeamMemberGroups([...teamMemberGroups, newGroup]));
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onGroupUpdate = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/groups/${data?.key}`,
            data: data,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const updatedGroup = response?.data?.data;
                    if (updatedGroup) {
                        const updatedTeamMemberGroups = teamMemberGroups.map((tmg) => {
                            if (updatedGroup.key === tmg.key) {
                                tmg.icon = updatedGroup.icon;
                                tmg.name = updatedGroup.name;
                                tmg.modified = updatedGroup.modified;
                            }
                            return tmg;
                        });
                        setTeamMemberGroups(sortTeamMemberGroups(updatedTeamMemberGroups));
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onGroupDelete = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'delete',
            urlPath: `/projects/${projectKey}/groups/${data?.key}`,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    setTeamMemberGroups(teamMemberGroups.filter((tmg) => tmg.key !== data?.key));
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onGroupSelect = (selectedGroupKey) =>{
        setTeamMembers(null);
        setGroupKey(selectedGroupKey);
    }

    return (
        <>
            <div className="min-h-0 flex w-full h-full flex-1 flex-col flex-nowrap overflow-hidden">
                <div className="flex-1 flex min-h-0 w-full justify-between items-stretch justify-items-stretch flex-col flex-nowrap overflow-hidden relative">
                    <div className="flex-none pb-3 flex items-center flex-row justify-between">
                        <h1 className="flex-1 mr-2 section-title">Team</h1>
                        {user?.is_admin && <AddButton onSelect={showAddForm} />}
                    </div>
                    <div className="flex-1 pb-3 flex flex-col items-stretch md:flex-row-reverse">
                        <div className="flex-none md:pl-2 md:border-l md:min-w-[280px] min-h-0 md:overflow-y-auto max-h-full">
                            <TeamMemberGroups projectKey={projectKey} editable={user?.is_admin} selectedGroupKey={groupKey} onSelect={onGroupSelect} onEdit={showUpdateForm} onDelete={showDeleteForm} />
                        </div>
                        <div className="flex-1 min-h-0 overflow-y-auto max-h-full md:pr-2">
                            {teamMembers ? teamMembers.length > 0 ?
                                <div className="auto-rows-min grid grid-cols-1 gap-2 sm:grid-cols-1 sm:gap-2 lg:grid-cols-2 lg:gap-3">
                                    {teamMembers.map((teamMember) => <TeamMemberCard key={teamMember?.key} editable={user?.is_admin} teamMember={teamMember} onEdit={showUpdateForm} onDelete={showDeleteForm} />)}
                                    {isLoading ? <>
                                        <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                        <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                        <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                        <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                    </> : (teamMembersData?.next_cursor !== '' ?
                                        <button className="block rounded-lg h-16 border text-sm text-gray-600 appearance-none bg-transparent hover:border-gray-300 active:bg-gray-200" onClick={loadMore}>Load more...</button> : null)
                                    }
                                </div> : <div className="pt-36 text-center">
                                    <div className="text-center text-lg">No team</div>
                                </div> : <div className="pt-36 text-center">
                                <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
                                <p>Scanning team...</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <MemberActionDialog action={memberAction} onAdd={onMemberAdd} onUpdate={onMemberUpdate} onDelete={onMemberDelete} onCancel={onMemberDialogCancel} />
            <GroupActionDialog action={groupAction} onAdd={onGroupAdd} onUpdate={onGroupUpdate} onDelete={onGroupDelete} onCancel={onGroupDialogCancel} />
        </>
    );
}

function cleanTeamMembers(teamMembers, groupKey) {
    const result = [];
    var i, j, exists;
    for (i = 0; i < teamMembers.length; i++) {
        if (teamMembers[i].group === groupKey || groupKey === "") {
            exists = false;
            for (j = 0; j < result.length; j++) {
                if (teamMembers[i].key === result[j].key) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                result.push(teamMembers[i]);
            }
        }
    }
    return result;
}

function sortTeamMembers(teamMembers, sortBy) {
    if (teamMembers.length > 0) {
        teamMembers.sort((a, b) => {
            var nameOrder;
            const aStartsWithUppercase = startsWithUppercase(a.full_name), bStartsWithUppercase = startsWithUppercase(b.full_name);
            if (aStartsWithUppercase === bStartsWithUppercase) {
                nameOrder = 0;
            } else if (aStartsWithUppercase & !bStartsWithUppercase) {
                nameOrder = -1;
            } else {
                nameOrder = 1;
            }
            if (nameOrder === 0) {
                if (a.name > b.name) {
                    nameOrder = 1;
                } else if (a.name < b.name) {
                    nameOrder = -1;
                }
            }
            if (sortBy === 'a_to_z') {
                return nameOrder || b.modified - a.modified;
            }
            return b.modified - a.modified || nameOrder;
        });
    }
    return teamMembers;
}

function sortTeamMemberGroups(teamMemberGroups) {
    if (teamMemberGroups.length > 0) {
        teamMemberGroups.sort((a, b) => {
            var nameOrder;
            const aStartsWithUppercase = startsWithUppercase(a.name), bStartsWithUppercase = startsWithUppercase(b.name);
            if (aStartsWithUppercase === bStartsWithUppercase) {
                nameOrder = 0;
            } else if (aStartsWithUppercase & !bStartsWithUppercase) {
                nameOrder = -1;
            } else {
                nameOrder = 1;
            }
            if (nameOrder === 0) {
                if (a.name > b.name) {
                    nameOrder = 1;
                } else if (a.name < b.name) {
                    nameOrder = -1;
                }
            }
            return nameOrder || b.modified - a.modified;
        });
    }
    return teamMemberGroups;
}

function startsWithUppercase(str) {
    return str.substr(0, 1) === str.substr(0, 1).toUpperCase();
}
