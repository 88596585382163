import { React, useState, useRef, useEffect } from 'react';
import { ReactComponent as EmbedWorkflowIcon } from '../../icons/step/embed_workflow.svg';
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import StepControlButton from "./StepControlButton";
import SaveButton from "../common/SaveButton";
import LiveTime from '../common/LiveTime';
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import valueHelpers from "../../helpers/value";
import { FORM_ERROR_KEY } from "../../config"
import valueTypes from "./valueTypes";
import Selector from "../common/Selector";
import selectorHelpers from "../../helpers/selector";
import requestHelpers from "../../helpers/request";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import FilePicker from "../explorer/FilePicker";
import { useFileFetcher } from "../../hooks/file";
import { ReactComponent as HomeIcon } from '../../icons/home.svg';
import FileIcon from "../explorer/FileIcon";
import WorkflowData from './WorkflowData';
import { DATA_SOURCE_KEYS, DATA_SOURCE_TYPES, DATA_SOURCE_TYPE_KEYS } from '../../constants/data-source';
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';

function WorkflowDetailsForm({ projectKey, details, update }) {
    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const { data, handleChange, handleSubmit, setItem, removeItem, errors } = useForm({
        initialValues: () => {
            let formData = {
                title: details?.title,
                icon: details?.icon,
                input_file_key: details?.input_file_key,
                run_scope: details?.run_scope,
                parameters: details?.parameters ? rfdc()(details.parameters) : []
            }
            return formData;
        },
        onSubmit: (submittedData) => {
            update(submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(details);
    }

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const [iconIsUploading, setIconIsUploading] = useState(false);
    const iconFileInput = useRef(null);

    const uploadIcon = (file) => {
        setIconIsUploading(true);
        let formData = new FormData();
        formData.append("icon", file);
        requestHelpers.sendApiRequest({
            urlPath: `/projects/${projectKey}/workspace/files/icons`,
            method: 'post',
            contentType: 'multipart/form-data',
            data: formData,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onSuccess: (response) => {
                if (mounted.current) {
                    const iconData = response?.data?.data;
                    if (iconData) {
                        handleChange('icon')(iconData.name);
                    }
                }
            },
            onError: () => {
                if (mounted.current) {
                    handleChange('icon')('');
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    setIconIsUploading(false);
                }
            }
        });
    };

    const handleIconChange = (e) => {
        const file = e.target.files[0];
        uploadIcon(file);
    }

    const handleIconClick = (e) => {
        var ignore = true;
        if (e.type === 'keydown') {
            if (e.key === 'Enter' || e.key === " " || e.key === "Spacebar") {
                ignore = false;
            }
        } else if (e.type === 'click') {
            ignore = false;
        }
        if (!ignore && iconFileInput.current) {
            iconFileInput.current.click();
        }
    }

    return <form onSubmit={handleSubmit}>
        <div className="rounded-b-md px-3 py-2 border-2 border-gray-200 bg-white">
            <div className="flex flex-nowrap items-start content-start justify-start justify-items-start mb-4">
                <div className="flex-none border-2 outline-none hover:ring-secondary hover:ring-2 focus:ring-secondary focus:ring-2 cursor-pointer bg-gray-100 rounded-md w-[45px] h-[45px] relative" tabIndex="0" onClick={handleIconClick} onKeyDown={handleIconClick}>
                    {iconIsUploading ? <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"><SpinnerIcon className="animate-spin text-gray-500 block w-5 h-5" /></div> : data?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${data?.icon}`} className="block w-full rounded-md" alt="Workflow icon" /> : <EmbedWorkflowIcon className="absolute fill-current text-gray-500 block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[50%]" />}
                    <input ref={iconFileInput} type="file" onChange={handleIconChange} className="hidden" accept="image/*" />
                </div>
                <div className="flex-1 pl-2 pt-1.5">
                    <div className="text-xs mb-0.5 leading-none text-gray-500 font-light">Workflow</div>
                    <input className="input input--sm input--full" type="text" value={valueHelpers.textValue(data?.title)} required placeholder="Enter workflow title" onChange={handleChange('title')} />
                </div>
            </div>
            <label className="text-xs text-gray-500 font-bold block mb-1">Input sample data</label>
            <FilePicker className="mb-2" projectKey={projectKey} fileType={3} placeholder="Select a JSON file..." value={valueHelpers.textValue(data?.input_file_key)} onChange={handleChange('input_file_key')} />
            <span className="text-xs text-gray-500 font-bold block mb-1">Parameters</span>
            {data.parameters.map((parameter, i) => {
                return <div key={`var_${i}/${data.parameters.length}`}>
                    <div className="text-gray-600 align-middle">
                        <div className="flex flex-nowrap">
                            <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-red-500 active:bg-red-700 leading-[0.1] text-white text-center flex-grow-0 bg-red-600 rounded-full mt-1 mr-2 block" onClick={(e) => { e.preventDefault(); removeItem(["parameters"], i); }}>–</button>
                            <div className={`border-l flex-1 border-r border-t border-gray-200 ${(i === 0) ? 'rounded-t-md' : ''} ${(i + 1 === data.parameters.length) ? 'border-b rounded-b-md' : ''}`}>
                                <div className="flex flex-nowrap">
                                    <div className="flex-1 align-middle py-1 px-2">
                                        <label className="label block mb-0.5">Name</label>
                                        <input type="text" className="input input--sm input--full mb-1" placeholder="Name" value={valueHelpers.textValue(parameter.name)} onChange={handleChange(["parameters", i, "name"])} />
                                    </div>
                                </div>
                                <div className="flex flex-nowrap">
                                    <div className="flex-1 align-middle py-1 px-2">
                                        <label className="label block mb-0.5">Type</label>
                                        <Selector value={parameter.type} onChange={handleChange(["parameters", i, "type"])} placeholder="Type" options={valueTypes.USER_INPUT} />
                                    </div>
                                </div>
                                <div className="flex flex-nowrap">
                                    <div className="flex-1 py-1 px-2 align-middle">
                                        <label className="label block mb-0.5">Value</label>
                                        <input type="text" className="input input--sm input--full mb-1" placeholder="Value" value={valueHelpers.textValue(parameter.value)} onChange={handleChange(["parameters", i, "value"])} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
            <div className="flex items-center mt-2 flex-nowrap">
                <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-green-400 active:bg-green-600 leading-[0.1] text-white text-center flex-grow-0 bg-green-500 rounded-full mr-2 block" onClick={(e) => { e.preventDefault(); setItem(["parameters"], { name: '', type: '1', value: '' }); }}>+</button>
                <label className="block text-xs font-semibold flex-1 text-gray-400">New parameter</label>
            </div>
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>
}

export default function WorkflowDetails({ projectKey, details, workflowRun, update, lastCheckpoint, onRun, onStop, isRunning, isBeingSaved, parameters, dispatchParameters, showDataSchema }) {

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    const [editMode, setEditMode] = useState(false);
    const updateDetails = (updatedDetails) => {
        update(updatedDetails);
        setEditMode(false);
    }
    const [paramsAndInputData, setParamsAndInputData] = useState(null);
    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();
    const [paramsAndInputError, setParamsAndInputError] = useState(null);

    const fetchParamsAndInputData = () => {
        setParamsAndInputError(null);
        requestHelpers.sendApiRequest({
            urlPath: `/projects/${workflowRun.project_key}/workflows/${workflowRun.workflow_key}/scopes/${workflowRun.run_scope_key}/runs/${workflowRun.key}/params-and-input`,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onSuccess: (response) => {
                if (mounted.current) {
                    var data = response?.data || null;
                    if (typeof data === 'string') {
                        try {
                            data = JSON.parse(data);
                        } catch {
                            data = null;
                        }
                    }
                    setParamsAndInputData(data);
                }
            },
            onError: (response) => {
                if (mounted.current) {
                    setParamsAndInputError(response);
                }
            }
        });
    }

    useEffect(() => {
        if (workflowRun?.status === 'running' && paramsAndInputData === null) {
            fetchParamsAndInputData();
        }
    }, [workflowRun?.status])

    const { responseData: inputSampleFileInfo } = useFileFetcher({ projectKey, fileKey: details?.input_file_key || '' }, [projectKey, details?.input_file_key]);

    const save = () => {
        updateDetails(details);
    }

    return (<div className="max-w-full md:flex md:items-start md:flex-col md:flex-nowrap relative">
        <div className="md:flex-none min-w-md md:max-w-lg md:w-lg relative rounded-md">

            <div className="flex bg-gray-200 p-1.5 items-center flex-nowrap justify-between rounded-t-md">
                <div className="flex-1 text-xs text-gray-700 px-1">
                    <span className="text-gray-500 sm:inline-block block">Last checkpoint:</span> <LiveTime unixtimestamp={lastCheckpoint} />
                </div>
                <div className="flex-none">
                    <SaveButton className="mr-1.5 bg-white hover:bg-gray-50 active:bg-gray-300" isBeingSaved={isBeingSaved} onSave={save} />
                    <StepControlButton className="bg-white hover:bg-gray-50 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} />
                </div>
            </div>
            {!editMode ? <div className="rounded-b-md px-3 py-2 border-2 border-gray-200 bg-white">
                <div className="flex flex-nowrap items-start content-start justify-start justify-items-start">
                    <div className="flex-none border-2 bg-gray-100 rounded-md w-[45px] h-[45px] relative">
                        {details?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${details?.icon}`} className="block w-full rounded-md" alt="Workflow icon" /> : <EmbedWorkflowIcon className="absolute fill-current text-gray-500 block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[50%]" />}
                    </div>
                    <div className="flex-1 pl-2 pt-1.5">
                        <div className="text-xs mb-0.5 leading-none text-gray-500 font-light">Workflow</div>
                        <h3 className="font-semibold w-full max-w-full text-gray-700 text-sm">{details?.title ? valueHelpers.textValue(details?.title) : '--'}</h3>
                    </div>
                    <button onClick={(e) => { e.preventDefault(); setEditMode(true); }} className="flex-none p-1">
                        <EditIcon className="fill-gray-500 hover:fill-gray-400 active:fill-gray-700 inline-block w-[20px]" />
                    </button>
                </div>
                {(details?.input_file_key && inputSampleFileInfo) && <>
                    <div className="text-xs text-gray-500 font-bold block mb-1 mt-4">Input sample data</div>
                    <div className="w-full file-picker rounded-md relative bg-gray-100 p-1">
                        <a href={`/app/projects/${projectKey}/workspace?key=${inputSampleFileInfo.key}`} target="_blank" className="flex hover:text-primary-dark">
                            {inputSampleFileInfo.key === "" ? <HomeIcon className="flex-none border bg-white rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" /> : <FileIcon type={inputSampleFileInfo.type} icon={inputSampleFileInfo.icon} className={`flex-none border bg-white rounded-sm mr-1.5 h-[25px] w-[25px] ${inputSampleFileInfo.icon ? '' : 'p-1'}`} />}
                            <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{inputSampleFileInfo.key === "" ? "Home" : inputSampleFileInfo.name}</span>
                        </a>
                    </div>
                </>}
                {(details?.parameters && details.parameters.length > 0) ? <div className='mt-2'>
                    <span className="text-xs text-gray-500 font-bold block">Parameters</span>
                    <div className="text-gray-600 rounded-md border border-gray-200 bg-gray-50 align-middle text-sm mt-1">
                        {details.parameters.map((parameter, i) => {
                            return (<div key={`var_${i}/${details.parameters.length}`}>
                                <div className={`${(i + 1 !== details.parameters.length) ? 'border-b border-gray-200' : ''}}`}>
                                    <div className="flex flex-wrap justify-between content-between">
                                        <div className="sm:inline-block flex-auto align-middle py-1 sm:py-2 px-2">
                                            <span className="text-xs font-bold block">Name</span>
                                            {parameter.name}
                                        </div>
                                        <div className="sm:inline-block flex-auto align-middle py-1 sm:py-2 px-2">
                                            <span className="text-xs font-bold block">Type</span>
                                            <p>{selectorHelpers.getLabelByValue(parameter.type, valueTypes.USER_INPUT)}</p>
                                        </div>
                                        <div className="sm:inline-block flex-auto py-1 sm:py-2 px-2 align-middle">
                                            <span className="text-xs font-bold block">Default value</span>
                                            {parameter.value !== '' ? parameter.value : '--'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div> : null}
            </div> : <WorkflowDetailsForm projectKey={projectKey} details={details} update={updateDetails} />}
        </div>
        {(paramsAndInputData && (paramsAndInputData[DATA_SOURCE_TYPE_KEYS.INPUT] !== null || paramsAndInputData[DATA_SOURCE_TYPE_KEYS.PARAMETER] !== null)) && <>
            <div className="w-full max-w-lg"><div className="relative h-5 w-0.5 mx-auto bg-gray-300"></div></div>
            {paramsAndInputData ? <WorkflowData dataSourceKey={paramsAndInputData[DATA_SOURCE_TYPE_KEYS.INPUT] ? DATA_SOURCE_KEYS.INPUT : DATA_SOURCE_KEYS.PARAMETER} dataSourceType={paramsAndInputData[DATA_SOURCE_TYPE_KEYS.INPUT] ? DATA_SOURCE_TYPES.INPUT : DATA_SOURCE_TYPES.PARAMETER} data={paramsAndInputData} parameters={parameters} dispatchParameters={dispatchParameters} topBarColor="bg-gray-200" showDataSchema={showDataSchema} /> :
                paramsAndInputError ? <div className="md:flex-none py-4 text-center md:min-w-lg bg-white max-w-full shadow-sm rounded-md relative">
                    <p className="text-sm">Oops, something went wrong.</p>
                    <button className="mt-2 bg-gray-200 hover:bg-gray-100 active:bg-gray-300 focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-blocktext-black shadow-sm text-sm rounded-md py-1 px-2" onClick={(e) => { e.preventDefault(); fetchParamsAndInputData(); }}>
                        <ResetIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Try again
                    </button>
                </div> : <div className="md:flex-none min-h-[120px] text-center md:min-w-lg max-w-full shadow-sm rounded-md relative block animate-pulse bg-gray-300"></div>}
        </>}
    </div>)
}