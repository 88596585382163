import { React, useState } from "react";

export default function Tabs({ tabs, onSelect, children, ...rest }) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const selectTab = (tabIndex) => (e) => {
        e.preventDefault();
        setSelectedTabIndex(tabIndex);

        if (typeof onSelect !== 'undefined'){
            onSelect(tabIndex);
        }
    }

    return <div {...rest}>
        { (tabs && tabs.length > 1) ? <div className="flex mb-2 bg-gray-100 flex-nowrap p-0.5 text-xs rounded-md">
            {tabs.map((tab, i) => {
                return <button key={`${tab}_${i.toString()}`} className={`nav-tab ${i === selectedTabIndex ? 'nav-tab--active' : ''}`} onClick={selectTab(i)}>{tab}</button>
            })}
        </div> : null}
        {(children && children.length > selectedTabIndex) ? children[selectedTabIndex] : null}
    </div>
}