function textValue(v) {
    if (v !== null && typeof v !== 'undefined') {
        return v.toString();
    }
    return '';
}

function convertValueToBoolean(value) {
    switch (textValue(value).toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
            return true;

        case "false":
        case "no":
        case "0":
        case null:
            return false;

        default:
            return Boolean(value);
    }
}

function convertValue(value, type) {
    value = textValue(value);
    switch (type) {
        case 1:
            return value;
        case 2:
            return Number.parseInt(value);
        case 3:
            return Number.parseFloat(value);
        case 4:
            return convertValueToBoolean(value);
        case 5:
            return null;
    }
    return value;
}

const valueHelpers = {
    textValue,
    convertValue
}

export default valueHelpers;