import { React } from "react";
import DataInput from "../common/DataInput";
import valueHelpers from "../../helpers/value";

export default function BarChartForm({ data, handleChange, parameters }) {
    return <>
        <label className="label !text-gray-500">X-Axis</label>
        <div className="border mb-2 p-2 rounded-md">
            <DataInput parameters={parameters} className="mb-2" placeholder="Label" value={valueHelpers.textValue(data.x_axis?.label)} onChange={handleChange(["x_axis", "label"])} />
            <DataInput parameters={parameters} placeholder="Values" value={valueHelpers.textValue(data.x_axis?.values)} onChange={handleChange(["x_axis", "values"])} />
        </div>
        <label className="label !text-gray-500">Y-Axis</label>
        <div className="border p-2 rounded-md">
            <DataInput parameters={parameters} className="mb-2" placeholder="Label" value={valueHelpers.textValue(data.y_axis?.label)} onChange={handleChange(["y_axis", "label"])} />
            <DataInput parameters={parameters} placeholder="Values" value={valueHelpers.textValue(data.y_axis?.values)} onChange={handleChange(["y_axis", "values"])} />
        </div>
    </>
}