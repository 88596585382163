import { React, useState, useEffect } from "react";
import { ReactComponent as InputIcon } from '../../icons/input.svg';
import Selector from "../common/Selector";
import { DATA_SOURCE_KEYS } from "../../constants/data-source";

function getInputKeyOptions(stepIndex, parameters) {
    const inputKeyOptions = { group: 'keys', list: [] };
    if (parameters) {
        for (var dataSourceKey in parameters) {
            if (dataSourceKey === DATA_SOURCE_KEYS.INPUT ||
                (dataSourceKey !== DATA_SOURCE_KEYS.PARAMETER && parameters[dataSourceKey].step?.index < stepIndex)) {
                inputKeyOptions.list.push({
                    index: dataSourceKey === DATA_SOURCE_KEYS.INPUT ? -1 : parameters[dataSourceKey].step.index,
                    label: dataSourceKey === DATA_SOURCE_KEYS.INPUT ? "Workflow Input" : `${parameters[dataSourceKey].step.index + 1}. ${parameters[dataSourceKey].step.title}`,
                    value: dataSourceKey
                });
            }
        }
        inputKeyOptions.list.sort((a, b) => a.index - b.index);
    }
    return [inputKeyOptions];
}

function useInputKeyOptions(stepIndex, parameters) {
    const [inputKeyOptions, setInputKeyOptions] = useState(() => {
        return getInputKeyOptions(stepIndex, parameters);
    });

    useEffect(() => {
        setInputKeyOptions(getInputKeyOptions(stepIndex, parameters));
    }, [parameters])

    return inputKeyOptions;
}

export default function StepInputKey({ value, stepIndex, parameters, editMode, onChange }) {

    const options = useInputKeyOptions(stepIndex, parameters);

    return (
        <div className="flex items-center flex-nowrap">
            <InputIcon className="flex-none inline-block text-gray-500 mr-1 fill-current h-4" />
            <div className="flex-1 pl-1">
                {
                    editMode ?
                        <Selector value={value} options={options} onChange={onChange} placeholder="Input key" />
                        : <span className="font-medium text-sm">{parameters.hasOwnProperty(value) ? (value === DATA_SOURCE_KEYS.INPUT ? "0. Workflow Input" : `${parameters[value].step.index + 1}. ${parameters[value].step.title}`) : ''}</span>
                }
            </div>
        </div>
    );
}