import { useState } from "react";
import rfdc from "rfdc";
import { FORM_ERROR_KEY } from "../config";

function isElement(element) {
    return element instanceof Element || element instanceof Document;
}

export default function useForm(options) {
    const [data, setData] = useState(() => {
        if (typeof options?.initialValues === 'function') {
            return options?.initialValues();
        }
        return options?.initialValues || {};
    });
    const [errors, setErrors] = useState({});

    // Add new item: key = ['headers']
    // Update item: key = ['headers', 1]
    const setItem = (key, item) => {
        let newData;
        if (key instanceof Array) {
            newData = rfdc()(data);
            let items = newData;
            for (let i = 0; i < key.length; i++) {
                if (i + 1 !== key.length ||
                    (i + 1 === key.length && typeof key[i] === 'string')) {

                    if (!items[key[i]]) {
                        if (typeof key[i] === 'string') {
                            items[key[i]] = {};
                        } else {
                            items[key[i]] = [];
                        }
                    }
                    items = items[key[i]];

                    if (i + 1 === key.length) {
                        items.push(item);
                    }
                } else {
                    if (items instanceof Array && items.length > key[i]) {
                        items[key[i]] = item;
                    }
                }
            }
        } else {
            newData = {
                ...data,
                [key]: [...(data[key] || []), item],
            };
        }
        setData(newData);
        if (errors[FORM_ERROR_KEY]) {
            const { [FORM_ERROR_KEY]: _, ...rest } = errors;
            setErrors(rest);
        }
    }

    const removeItem = (key, index) => {
        let newData;
        if (key instanceof Array) {
            newData = rfdc()(data);
            let items = newData;
            for (let i = 0; i < key.length; i++) {
                if (i + 1 !== key.length) {
                    if (typeof items[key[i]] === 'undefined') {
                        if (typeof key[i] === 'string') {
                            items[key[i]] = {};
                        } else {
                            items[key[i]] = [];
                        }
                    }
                    items = items[key[i]];
                }
            }
            items[key[key.length - 1]] = items[key[key.length - 1]].filter((_, i) => i !== index);
        } else {
            newData = {
                ...data,
                [key]: (data[key] || []).filter((_, i) => i !== index)
            };
        }
        setData(newData);
    }

    const handleChange = (key, sanitizeFn) => {
        return (o) => {
            var value = o ? (typeof o.target === 'undefined' ? o : isElement(o.target) ? o.target.value : o) : o;
            value = sanitizeFn ? sanitizeFn(value) : value;
            let newData;
            if (key instanceof Array) {
                newData = rfdc()(data);
                var values = newData;
                for (let i = 0; i < key.length; i++) {
                    if (i + 1 === key.length) {
                        if (typeof key[i] === 'string') {
                            values[key[i]] = value;
                        } else {
                            if (values.length <= key[i]) {
                                values = expandArray(values, key[i] + 1);
                            }
                            values[key[i]] = value;
                        }
                    } else if (!values[key[i]]) {
                        if (typeof key[i] === 'string') {
                            values[key[i]] = {};
                        } else {
                            values[key[i]] = [];
                        }
                    }
                    values = values[key[i]];
                }
            } else {
                newData = {
                    ...data,
                    [key]: value,
                };
            }

            setData(newData);

            const errKey = generateErrKeyFromKey(key);
            if (errors[errKey]) {
                const { [errKey]: _, ...rest } = errors;
                setErrors(rest);
            }
            if (errors[FORM_ERROR_KEY]) {
                const { [FORM_ERROR_KEY]: _, ...rest } = errors;
                setErrors(rest);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validations = options?.validations;
        if (validations) {
            let valid = true;
            const newErrors = {};

            for (let i = 0; i < validations.length; i++) {
                const validation = validations[i];
                const fieldKey = validations[i].field_key;
                let values;
                if (fieldKey instanceof Array) {
                    var fieldData = data;
                    for (let j = 0; j < fieldKey.length; j++) {
                        if (!fieldData[fieldKey[i]]) {
                            if (typeof fieldKey[i] === 'string') {
                                fieldData[fieldKey[i]] = {};
                            } else {
                                fieldData[fieldKey[i]] = [];
                            }
                        }
                        fieldData = fieldData[fieldKey[j]];
                    }
                    if (fieldData instanceof Array) {
                        for (let j = 0; j < fieldData.length; j++) {
                            values[generateErrKeyFromKey([...fieldKey, j])] = fieldData[j];
                        }
                    } else {
                        values = { [generateErrKeyFromKey(fieldKey)]: fieldData };
                    }
                } else {
                    values = { [fieldKey]: data[fieldKey] };
                }
                for (const key in values) {
                    const value = values[key];

                    if (validation?.required?.value && !value) {
                        valid = false;
                        newErrors[key] = validation.required.message;
                        continue;
                    }

                    const pattern = validation?.pattern;
                    if (pattern?.value && !RegExp(pattern.value).test(value)) {
                        valid = false;
                        newErrors[key] = pattern.message;
                        continue;
                    }

                    const custom = validation?.custom;
                    if (custom?.isValid && !custom.isValid(value)) {
                        valid = false;
                        newErrors[key] = custom.message;
                        continue;
                    }
                }
            }

            if (!valid) {
                setErrors(newErrors);
                return;
            }
        }
        setErrors({});

        if (options?.onSubmit) {
            options.onSubmit(data);
        }
    }

    const setErrorsFromResponse = (response) => {
        if (response?.data?.status === 'fail') {
            const newErrors = {};
            if (response?.data?.message) {
                newErrors[FORM_ERROR_KEY] = response.data.message;
            }
            const responseErrors = response?.data?.errors;
            if (responseErrors) {
                for (var i = 0; i < responseErrors.length; i++) {
                    newErrors[responseErrors[i].field] = responseErrors[i].message;
                }
            }
            setErrors(newErrors);
        }
    }

    const reset = () => {
        setData({});
    }

    return {
        data,
        setData,
        handleChange,
        handleSubmit,
        errors,
        setErrors,
        setErrorsFromResponse,
        setItem,
        removeItem,
        reset,
    }
}

function generateErrKeyFromKey(key) {
    return (key instanceof Array) ? key.join('_') : key;
}

function expandArray(arr, len) {
    const n = len - arr.length;
    for (var i = 0; i < n; i++) {
        arr.push(null);
    }
    return arr;
}