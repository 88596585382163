import { React } from "react";
import DataView from "../common/DataView";

export default function PieChartView({ data, parameters }) {
    return <>
        <label className="label !text-gray-500">Categories</label>
        <DataView parameters={parameters} className="mb-2" data={data.categories} />
        <label className="label !text-gray-500">Values</label>
        <DataView parameters={parameters} className="mb-2" data={data.values} />
    </>
}