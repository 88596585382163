import { INDEX_START_DELIMITER, INDEX_END_DELIMITER, INDEX_SYMBOL, INDEX_TERM, KEY_PARTITIONER, ROOT_TABLE_KEY } from "../constants/database";

function extractIndexParamsFromKey(key) {
    const indexParams = []
    if (typeof key === 'string') {
        var indexParam = null, c;
        for (var i = 0; i < key.length; i++) {
            c = key[i];
            if (i === 0 && c === INDEX_START_DELIMITER) {
                indexParam = { startPosition: i };
            } else if (i > 0) {
                if (c === INDEX_START_DELIMITER) {
                    indexParam = { startPosition: i };
                } else if (indexParam !== null) {
                    if ((c === INDEX_END_DELIMITER && i + 1 === key.length) ||
                        (c === KEY_PARTITIONER && key[i - 1] === INDEX_END_DELIMITER)) {
                        if (c === INDEX_END_DELIMITER && i + 1 === key.length) {
                            indexParam.endPosition = i;
                        } else {
                            indexParam.endPosition = i - 1;
                        }
                        indexParam.valueRange = parseIndexRange(key.substring(indexParam.startPosition + 1, indexParam.endPosition));
                        indexParams.push(indexParam);
                        indexParam.tableKey = generalizeKey(key.substring(0, indexParam.endPosition + 1), indexParams);
                        indexParam = null;
                    } else if (c === KEY_PARTITIONER) {
                        indexParam = null;
                    }
                }
            }
        }
    }
    return indexParams;
}

function parseIndexRange(s) {
    if (typeof s === 'string') {
        s = s.replaceAll(" ", "");
        if (s !== "" && s !== INDEX_SYMBOL) {
            const parts = s.split(":");
            var indexes = [-1, -1];
            if (parts[0] !== "") {
                indexes[0] = Number(parts[0]);
                if (isNaN(indexes[0])) {
                    indexes[0] = -1;
                }
            }
            if (parts.length === 2) {
                if (parts[1] !== "") {
                    indexes[1] = Number(parts[1]);
                    if (isNaN(indexes[1])) {
                        indexes[1] = -1;
                    }
                }
            } else {
                indexes = [indexes[0]];
            }
            return indexes
        }
    }
    return null
}

function generalizeKey(key, indexParams) {
    indexParams = (typeof indexParams === 'undefined') ? extractIndexParamsFromKey(key) : indexParams;

    if (indexParams.length > 0) {
        var generalKey, indexParam;
        for (var i = 0; i < indexParams.length; i++) {
            indexParam = indexParams[i];
            if (i === 0) {
                generalKey = key.substring(0, indexParam.startPosition) + INDEX_TERM;
            } else {
                generalKey += key.substring(indexParams[i - 1].endPosition + 1, indexParam.startPosition) + INDEX_TERM;
            }
            if (i + 1 === indexParams.length) {
                generalKey += key.substring(indexParam.endPosition + 1);
            }
        }
        return generalKey;
    }
    return key;
}

function specializeKey(key, indexes) {
    const indexParams = extractIndexParamsFromKey(key)
    if (indexParams.length > 0) {
        var specialKey, indexParam, indexParamValue;
        for (var i = 0; i < indexParams.length; i++) {
            indexParam = indexParams[i];
            if (indexes.length < i + 1 || indexes[i] === -1) {
                indexParamValue = INDEX_TERM;
            } else {
                indexParamValue = INDEX_START_DELIMITER + indexes[i].toString() + INDEX_END_DELIMITER;
            }
            if (i === 0) {
                specialKey = key.slice(0, indexParam.startPosition) + indexParamValue;
            } else {
                specialKey += key.slice(indexParams[i - 1].endPosition + 1, indexParam.startPosition) + indexParamValue;
            }
            if (i + 1 === indexParams.length) {
                specialKey += key.slice(indexParam.endPosition + 1);
                break
            }
        }
        return specialKey
    }
    return key
}

function splitKeyIntoTableKeyAndColumnName(key) {
    var tableKey = "", columnName = key;
    for (var i = key.length - 1; i >= 0; i--) {
        if (key[i] === INDEX_END_DELIMITER) {
            if (i + 1 === key.length) {
                columnName = ""
            } else if (i + 2 <= key.length) {
                columnName = key.substring(i + 2);
            }
        }
        if (key[i] === INDEX_START_DELIMITER) {
            tableKey = key.substring(0, i) + INDEX_TERM;
            break
        }
    }
    return { tableKey, columnName };
}

function detectKeyType(key) {
    if (typeof key === "string") {
        if (key === ROOT_TABLE_KEY) {
            return "table";
        }
        const indexParams = extractIndexParamsFromKey(key);
        if (indexParams.length > 0) {
            var numOfIndexParamsWithDefinedValue = 0;
            for (var i = 0; i < indexParams.length; i++) {
                if (indexParams[i].valueRange && indexParams[i].valueRange.length === 1 && indexParams[i].valueRange[0] > -1) {
                    numOfIndexParamsWithDefinedValue++;
                }
            }
            const columnName = extractColumnNameFromKey(key);
            if (indexParams.length === numOfIndexParamsWithDefinedValue && columnName !== null) {
                return "cell";
            }
            if (columnName !== null) {
                return "column";
            }
            if (numOfIndexParamsWithDefinedValue === 0) {
                return "table";
            }
            return "row";
        }
        return "field";
    }
    return null;
}

function extractTableKeyFromKey(key, indexParams) {
    indexParams = typeof indexParams === 'undefined' ? extractIndexParamsFromKey(key) : indexParams;
    if (indexParams.length > 0) {
        return indexParams[indexParams.length - 1].tableKey
    }
    return ""
}

function appendColumnNameToKey(key, columnName) {
    if (key === "") {
        return columnName;
    }
    return key + KEY_PARTITIONER + columnName;
}

function extractColumnNameFromKey(key) {
    for (var i = key.length - 1; i >= 0; i--) {
        if (key[i] == INDEX_END_DELIMITER) {
            if (i + 2 <= key.length) {
                return key.substring(i + 2);
            }
            return null;
        }
    }
    return key;
}

function keyToElementID(key) {
    return key.replaceAll(`_`, '-')
        .replaceAll(`${INDEX_TERM}${KEY_PARTITIONER}`, '_')
        .replaceAll(KEY_PARTITIONER, '-')
        .replaceAll(INDEX_TERM, '');
}

function extractFieldsFromColumnName(columnName) {
    return columnName.split(KEY_PARTITIONER);
}

function isIndexInRange(index, indexRange) {
    if ((indexRange?.length || 0) > 0) {
        if (indexRange.length === 1) {
            if (indexRange[0] !== -1 && index !== indexRange[0]) {
                return false;
            }
        } else if (indexRange.length === 2) {
            if (indexRange[0] !== -1 && indexRange[1] !== -1) {
                if (index < indexRange[0] || index >= indexRange[1]) {
                    return false;
                }
            } else if (indexRange[0] === -1) {
                if (index >= indexRange[1]) {
                    return false;
                }
            } else if (indexRange[1] === -1) {
                if (index < indexRange[0]) {
                    return false;
                }
            }
        }
    }
    return true;
}

const keyHelpers = {
    extractIndexParamsFromKey: extractIndexParamsFromKey,
    generalizeKey: generalizeKey,
    specializeKey: specializeKey,
    splitKeyIntoTableKeyAndColumnName: splitKeyIntoTableKeyAndColumnName,
    detectKeyType: detectKeyType,
    extractTableKeyFromKey: extractTableKeyFromKey,
    appendColumnNameToKey: appendColumnNameToKey,
    extractColumnNameFromKey: extractColumnNameFromKey,
    keyToElementID: keyToElementID,
    extractFieldsFromColumnName: extractFieldsFromColumnName,
    isIndexInRange: isIndexInRange,
}

export default keyHelpers;