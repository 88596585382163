import { React } from "react";
import { evaluateParams } from "../../helpers/param";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import WidgetIcon from './WidgetIcon';

export default function ScorecardOutput({ details, data }) {
    const value = evaluateParams(details?.value, data);

    return <div className="md:flex-none min-w-md md:max-w-lg md:w-lg relative text-center pb-3">
        <div className="inline-block bg-white rounded-md shadow-sm py-1 px-2 min-w-48 text-left min-h-[80px]">
            <div className="flex flex-nowrap items-start content-start justify-start justify-items-start">
                <div className="flex-none bg-gray-100 border rounded-md w-[24px] h-[24px] relative text-gray-700">
                    {details?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${details?.icon}`} className="block w-full rounded-md" alt="Widget icon" /> :  <WidgetIcon widgetType={details.type} />}
                </div>
                <h3 className="flex-1 pl-1.5 text-md text-gray-500">{details.title || 'Title'}</h3>
            </div>
            <p className="mt-1.5 text-3xl font-semibold">{value}</p>
        </div>
    </div>
}