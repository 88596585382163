import { findAllParams, paramText } from "./param";
import { DATA_SOURCE_KEYS } from "../constants/data-source";

export const NODE_TYPES = {
    ZERO_WIDTH_TEXT: 0,
    TEXT: 1,
    PARAMETER: 2,
}

const ZERO_WIDTH_TEXT_NODE_CONTENT = '\u200B';

function countUntilEndContainer(parent, endNode, offset, countingState = { offsetAtNodeStart: 0, offsetInNode: 0, offset: 0 }) {
    for (let node of parent.childNodes) {
        if (countingState.node) break;
        if (node === endNode) {
            countingState.node = node;
            countingState.offsetInNode = offset;
            countingState.offset = countingState.offsetAtNodeStart + countingState.offsetInNode;
            return countingState;
        }
        if (node.nodeType === Node.TEXT_NODE) {
            countingState.offsetAtNodeStart += node.length;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            countUntilEndContainer(node, endNode, offset, countingState);
        }
    }
    return countingState;
}

function countUntilOffset(parent, offset, countingState = { offsetAtNodeStart: 0, offsetInNode: 0, offset: 0 }) {
    for (let node of parent.childNodes) {
        if (countingState.node) break;
        if (node.nodeType === Node.TEXT_NODE) {
            if (countingState.offsetAtNodeStart <= offset && offset <= countingState.offsetAtNodeStart + node.length) {
                countingState.offsetInNode = offset - countingState.offsetAtNodeStart;
                countingState.node = node;
                countingState.offset = countingState.offsetAtNodeStart + countingState.offsetInNode;
                return countingState;
            } else {
                countingState.offsetAtNodeStart += node.length;
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            countUntilOffset(node, offset, countingState);
        }
    }
    return countingState;
}

function firstTextNode(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.children.length === 0 && node.childNodes.length === 0) {
            node.appendChild(document.createTextNode(ZERO_WIDTH_TEXT_NODE_CONTENT));
        }
    }
    while (node && node.nodeType !== Node.TEXT_NODE) {
        node = node.firstChild;
    }
    return node;
}

function lastTextNode(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.children.length === 0 && node.childNodes.length === 0) {
            node.appendChild(document.createTextNode(ZERO_WIDTH_TEXT_NODE_CONTENT));
        }
    }
    while (node && node.nodeType !== Node.TEXT_NODE) {
        node = node.lastChild;
    }
    return node;
}

// the max caret offset is just a hack to avoid the problem related to adding a zero-width character 
// when the text ends by a newline character
export function getCaretOffsets(container, maxCaretOffset) {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) { return undefined; }
    const range = selection.getRangeAt(0).cloneRange();
    const startContainer = range.startContainer.nodeType === Node.TEXT_NODE ? range.startContainer : firstTextNode(range.startOffset < range.startContainer.childNodes.length ? range.startContainer.childNodes[range.startOffset] : range.startContainer);
    const startOffset = range.startContainer.nodeType === Node.TEXT_NODE ? range.startOffset : 0;
    let startState = countUntilEndContainer(
        container,
        startContainer,
        startOffset,
    );
    const endContainer = range.endContainer.nodeType === Node.TEXT_NODE ? range.endContainer : lastTextNode(range.endOffset < range.endContainer.childNodes.length ? range.endContainer.childNodes[range.endOffset] : range.endContainer);
    const endOffset = range.endContainer.nodeType === Node.TEXT_NODE ? range.endOffset : range.endOffset >= range.endContainer.childNodes.length ? endContainer.length : 0;
    let endState = range.collapsed ? startState : countUntilEndContainer(
        container,
        endContainer,
        endOffset,
    );
    // if the current node is a zero-width node set the caret at its end
    if (startState.node.length === 1 && startState.node.textContent.charCodeAt(0) === ZERO_WIDTH_TEXT_NODE_CONTENT.charCodeAt(0) && startState.offsetInNode === 0) {
        startState.offsetInNode = 1;
        startState.offset += 1;
    }
    if (!range.collapsed && endState.node.length === 1 && endState.node.textContent.charCodeAt(0) === ZERO_WIDTH_TEXT_NODE_CONTENT.charCodeAt(0) && endState.offsetInNode === 1) {
        endState.offsetInNode = 0;
        endState.offset -= 1;
    }
    return {
        start: startState.offset > maxCaretOffset ? maxCaretOffset : startState.offset,
        end: endState.offset > maxCaretOffset ? maxCaretOffset : endState.offset
    };
}

export function setCaretOffsets(container, caretOffsets) {
    const selection = window.getSelection();
    if (!selection) { return undefined; }
    let startState = countUntilOffset(container, caretOffsets.start);
    if (!startState.node) {
        startState.node = lastTextNode(container);
        startState.offsetInNode = startState.node.length;
        startState.offsetAtNodeStart = caretOffsets.start - startState.node.length;
        startState.offset = caretOffsets.start;
    }
    let endState = caretOffsets.start === caretOffsets.end ? startState : countUntilOffset(container, caretOffsets.end);
    selection.removeAllRanges();
    let newRange = document.createRange();
    newRange.setStart(startState.node, startState.offsetInNode);
    newRange.setEnd(endState.node, endState.offsetInNode);
    selection.addRange(newRange);
    return { start: startState.offset, end: endState.offset };
}

export function createParameterNode(stepIndex, param) {
    return {
        type: NODE_TYPES.PARAMETER,
        value: `${stepIndex + 1}:${param.dataKey}`,
        param: {
            ...param,
            stepIndex: stepIndex,
        }
    }
}

export function createZeroWidthTextNode() {
    return {
        type: NODE_TYPES.ZERO_WIDTH_TEXT,
        value: ZERO_WIDTH_TEXT_NODE_CONTENT,
    };
}

export function textToNodes(text, params) {
    const textParams = findAllParams(text);
    var allNodes = [];
    if (text && text.length > 0) {
        if (textParams.length > 0) {
            for (var i = 0; i < textParams.length; i++) {
                if (i === 0 && textParams[i].startIndex > 0) {
                    allNodes.push({
                        type: NODE_TYPES.TEXT,
                        value: text.substring(0, textParams[i].startIndex),
                    });
                } else {
                    allNodes.push(createZeroWidthTextNode());
                }

                if (i > 0) {
                    if (textParams[i - 1].endIndex !== textParams[i].startIndex) {
                        allNodes.push({
                            type: NODE_TYPES.TEXT,
                            value: text.substring(textParams[i - 1].endIndex, textParams[i].startIndex),
                        });
                    } else {
                        allNodes.push(createZeroWidthTextNode());
                    }
                }

                const stepIndex = textParams[i].dataSourceKey !== DATA_SOURCE_KEYS.ANY &&
                    textParams[i].dataSourceKey !== DATA_SOURCE_KEYS.INPUT &&
                    textParams[i].dataSourceKey !== DATA_SOURCE_KEYS.PARAMETER &&
                    params.hasOwnProperty(textParams[i].dataSourceKey) ?
                    params[textParams[i].dataSourceKey].step.index :
                    -1;
                allNodes.push(createParameterNode(stepIndex, textParams[i]));

                if (i + 1 === textParams.length && textParams[i].endIndex < text.length) {
                    allNodes.push({
                        type: NODE_TYPES.TEXT,
                        value: text.substring(textParams[i].endIndex),
                    });
                } else {
                    allNodes.push(createZeroWidthTextNode());
                }
            }
        } else {
            allNodes.push({
                type: NODE_TYPES.TEXT,
                value: text,
            });
        }
    } else {
        allNodes.push(createZeroWidthTextNode());
    }
    return allNodes;
}

export function nodesToText(nodes, visibleValue) {
    visibleValue = typeof visibleValue === 'undefined' ? false : true;
    var text = '';
    if (nodes) {
        for (var i = 0; i < nodes.length; i++) {
            if (!visibleValue) {
                if (nodes[i].type === NODE_TYPES.PARAMETER) {
                    text += paramText(nodes[i].param);
                } else if (nodes[i].type === NODE_TYPES.TEXT) {
                    text += nodes[i].value;
                }
            } else {
                text += nodes[i].value;
            }
        }
    }
    return text;
}

//TODO: I should use names that make the difference between the two types of slection (DOM layer and data layer) more clear.
export function getSelectedNodeRange(nodes, caretOffsets) {
    const range = {
        start: {
            index: 0,
            offset: 0
        },
        end: {
            index: 0,
            offset: 0
        }
    };

    var currPos = 0;
    for (var i = 0; i < nodes.length; i++) {
        if (currPos <= caretOffsets.start && caretOffsets.start <= currPos + nodes[i].value.length) {
            range.start.index = i;
            range.start.offset = caretOffsets.start - currPos;
        }
        if (currPos <= caretOffsets.end && caretOffsets.end <= currPos + nodes[i].value.length) {
            range.end.index = i;
            range.end.offset = caretOffsets.end - currPos;
            break;
        }

        currPos += nodes[i].value.length;
    }
    return range;
}

export function modifyNodes(nodes, caretOffsets, key) {
    var newNodes = [];

    let char;
    switch (key) {
        case "Backspace":
        case "Delete":
            char = '';
            break;
        case "Enter":
            char = '\n';
            break;
        default:
            char = key;
    }

    if (nodes.length > 0) {

        const selectionRange = getSelectedNodeRange(nodes, caretOffsets);

        for (var j = 0; j < nodes.length; j++) {
            if (selectionRange.start.index <= j && j <= selectionRange.end.index) {
                if (selectionRange.start.index === j) {
                    // update j in order to skip the nodes that are already handled
                    j = selectionRange.end.index;

                    // if the range is collapsed and it's a delete operation
                    if (selectionRange.start.index === selectionRange.end.index &&
                        selectionRange.start.offset === selectionRange.end.offset &&
                        char === '') {
                        if (selectionRange.start.offset === 0) {
                            if (selectionRange.start.index > 0) {
                                if (newNodes[selectionRange.start.index - 1].type === NODE_TYPES.TEXT) {
                                    const newNode = {
                                        type: NODE_TYPES.TEXT,
                                        value: newNodes[selectionRange.start.index - 1].value.substring(0, newNodes[selectionRange.start.index - 1].value.length - 1),
                                    }
                                    if (newNode.value.length > 0) {
                                        newNodes[selectionRange.start.index - 1] = newNode;
                                    } else {
                                        newNodes[selectionRange.start.index - 1] = createZeroWidthTextNode();
                                    }
                                    newNodes.push(nodes[selectionRange.start.index]);
                                } else if (newNodes[selectionRange.start.index - 1].type === NODE_TYPES.ZERO_WIDTH_TEXT) {
                                    if (selectionRange.start.index > 1) {
                                        newNodes.splice(selectionRange.start.index - 2, 2);
                                    }
                                    newNodes.push(nodes[selectionRange.start.index]);
                                } else {
                                    if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT) {
                                        if (newNodes[selectionRange.start.index - 2].type === NODE_TYPES.TEXT) {
                                            newNodes[selectionRange.start.index - 2].value += nodes[selectionRange.start.index].value;
                                        } else {
                                            newNodes[selectionRange.start.index - 2] += nodes[selectionRange.start.index];
                                        }
                                    }
                                    newNodes.splice(selectionRange.start.index - 1, 1);
                                }
                            } else {
                                newNodes.push(nodes[selectionRange.start.index]);
                            }
                        } else if (selectionRange.start.offset > 0 && nodes[selectionRange.start.index].type === NODE_TYPES.TEXT) {
                            const newNode = {
                                type: NODE_TYPES.TEXT,
                                value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset - 1) + nodes[selectionRange.end.index].value.substring(selectionRange.end.offset),
                            };
                            if (newNode.value.length > 0) {
                                newNodes.push(newNode);
                            } else {
                                newNodes.push(createZeroWidthTextNode());
                            }
                        } else if (nodes[selectionRange.start.index].type === NODE_TYPES.ZERO_WIDTH_TEXT) {
                            if (selectionRange.start.index - 1 > 0) {
                                newNodes.splice(selectionRange.start.index - 1, 1);
                            } else {
                                newNodes.push(nodes[selectionRange.start.index]);
                            }
                        }
                    } else {
                        const newNode = { type: NODE_TYPES.TEXT };
                        // if the selection range is collapsed
                        if (selectionRange.start.index === selectionRange.end.index &&
                            selectionRange.start.offset === selectionRange.end.offset) {

                            const isTextNode = nodes[selectionRange.start.index].type === NODE_TYPES.TEXT;
                            const isZeroWidthTextNode = nodes[selectionRange.start.index].type === NODE_TYPES.ZERO_WIDTH_TEXT;
                            newNode.value = isTextNode ? nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset) + char + nodes[selectionRange.end.index].value.substring(selectionRange.end.offset) : char;

                            if (isTextNode || isZeroWidthTextNode) {
                                if (newNode.value.length > 0) {
                                    newNodes.push(newNode);
                                } else {
                                    newNodes.push(createZeroWidthTextNode());
                                }
                            } else {
                                // if the current node is a parameter node and the caret is at its end
                                if (selectionRange.end.offset === nodes[selectionRange.end.index].value.length) {
                                    newNodes.push(nodes[selectionRange.end.index]);
                                    if (newNode.value.length > 0) {
                                        newNodes.push(newNode);
                                    } else {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                } else {
                                    // check the previous node
                                    if (newNodes.length > 0 && newNodes[newNodes.length - 1].type !== NODE_TYPES.PARAMETER) {
                                        if (newNodes[newNodes.length - 1].type === NODE_TYPES.TEXT) {
                                            newNode.value = nodes[newNodes.length - 1].value + newNode.value;
                                        }
                                        if (newNode.value.length > 0) {
                                            newNodes[newNodes.length - 1] = newNode;
                                        } else {
                                            newNodes[newNodes.length - 1] = createZeroWidthTextNode();
                                        }
                                    } else {
                                        if (newNode.value.length > 0) {
                                            newNodes.push(newNode);
                                        } else {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    }
                                    if (selectionRange.start.offset === 0) {
                                        newNodes.push(nodes[selectionRange.end.index]);
                                    }
                                }
                            }
                        } else {
                            if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type === NODE_TYPES.TEXT) {
                                newNode.value = nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset) + char + nodes[selectionRange.end.index].value.substring(selectionRange.end.offset);
                                if (newNode.value.length > 0) {
                                    newNodes.push(newNode);
                                } else if (selectionRange.start.offset === 0 && selectionRange.end.offset === nodes[selectionRange.end.index].value.length) {
                                    newNodes.push(createZeroWidthTextNode());
                                }
                            } else if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type !== NODE_TYPES.TEXT) {
                                newNode.value = nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset) + char;
                                if (newNode.value.length > 0) {
                                    newNodes.push(newNode);
                                } else if (selectionRange.start.offset === 0) {
                                    newNodes.push(createZeroWidthTextNode());
                                }
                                if (selectionRange.end.offset === 0 && nodes[selectionRange.end.index].type === NODE_TYPES.PARAMETER) {
                                    newNodes.push(nodes[selectionRange.end.index]);
                                }
                            } else if (nodes[selectionRange.start.index].type !== NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type === NODE_TYPES.TEXT) {
                                if (selectionRange.start.offset === nodes[selectionRange.start.index].value.length && nodes[selectionRange.start.index].type === NODE_TYPES.PARAMETER) {
                                    newNodes.push(nodes[selectionRange.start.index]);
                                }
                                newNode.value = char + nodes[selectionRange.end.index].value.substring(selectionRange.end.offset);
                                if (newNode.value.length > 0) {
                                    newNodes.push(newNode);
                                } else if (selectionRange.end.offset === nodes[selectionRange.end.index].value.length) {
                                    newNodes.push(createZeroWidthTextNode());
                                }
                            } else if (nodes[selectionRange.start.index].type !== NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type !== NODE_TYPES.TEXT) {

                                newNode.value = char;

                                if (selectionRange.start.index !== selectionRange.end.index &&
                                    selectionRange.start.offset === nodes[selectionRange.start.index].value.length &&
                                    selectionRange.end.offset === 0) {
                                    if (nodes[selectionRange.start.index].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(nodes[selectionRange.start.index]);
                                    }
                                    if (newNode.value.length > 0) {
                                        newNodes.push(newNode);
                                    } else {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                    if (nodes[selectionRange.end.index].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(nodes[selectionRange.end.index]);
                                    }
                                } else if (selectionRange.start.index !== selectionRange.end.index &&
                                    selectionRange.start.offset === nodes[selectionRange.start.index].value.length) {
                                    if (nodes[selectionRange.start.index].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(nodes[selectionRange.start.index]);
                                    }
                                    if (newNode.value.length > 0) {
                                        newNodes.push(newNode);
                                    } else {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                } else if (selectionRange.start.index !== selectionRange.end.index &&
                                    selectionRange.end.offset === 0) {
                                    // if the previous node is not a parameter node
                                    if (newNodes.length > 0 && newNodes[newNodes.length - 1].type !== NODE_TYPES.PARAMETER) {
                                        if (newNodes[newNodes.length - 1].type === NODE_TYPES.TEXT) {
                                            newNode.value = nodes[newNodes.length - 1].value + newNode.value;
                                        }
                                        if (newNode.value.length > 0) {
                                            newNodes[newNodes.length - 1] = newNode;
                                        } else {
                                            newNodes[newNodes.length - 1] = createZeroWidthTextNode();
                                        }
                                    } else {
                                        if (newNode.value.length > 0) {
                                            newNodes.push(newNode);
                                        } else {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    }
                                    if (nodes[selectionRange.end.index].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(nodes[selectionRange.end.index]);
                                    }
                                } else {
                                    // if the previous node is not a parameter node
                                    if (newNodes.length > 0 && newNodes[newNodes.length - 1].type !== NODE_TYPES.PARAMETER) {
                                        if (newNodes[newNodes.length - 1].type === NODE_TYPES.TEXT) {
                                            newNode.value = nodes[newNodes.length - 1].value + newNode.value;
                                        }
                                        if (newNode.value.length > 0) {
                                            newNodes[newNodes.length - 1] = newNode;
                                        } else {
                                            newNodes[newNodes.length - 1] = createZeroWidthTextNode();
                                        }
                                    } else {
                                        if (newNode.value.length > 0) {
                                            newNodes.push(newNode);
                                        } else {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if (j === selectionRange.end.index + 1) {
                    if (newNodes[newNodes.length - 1].type === NODE_TYPES.TEXT) {
                        if (nodes[j].type === NODE_TYPES.TEXT) {
                            newNodes[newNodes.length - 1].value += nodes[j].value;
                        } else if (nodes[j].type === NODE_TYPES.PARAMETER) {
                            newNodes.push(nodes[j]);
                        }
                    } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.ZERO_WIDTH_TEXT) {
                        if (nodes[j].type === NODE_TYPES.TEXT) {
                            newNodes[newNodes.length - 1] = nodes[j];
                        } else if (nodes[j].type === NODE_TYPES.PARAMETER) {
                            newNodes.push(nodes[j]);
                        }
                    } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER) {
                        if (nodes[j].type === NODE_TYPES.PARAMETER) {
                            newNodes.push(createZeroWidthTextNode());
                        }
                        newNodes.push(nodes[j]);
                    }
                } else {
                    newNodes.push(nodes[j]);
                }
            }
        }
    } else {
        if (char.length > 0) {
            newNodes.push({
                type: NODE_TYPES.TEXT,
                value: char
            });
        } else {
            newNodes.push(createZeroWidthTextNode());
        }
    }
    if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER) {
        newNodes.push(createZeroWidthTextNode());
    }
    return newNodes;
}

export function insertNodes(nodesToInsert, nodes, caretOffsets) {
    if (nodesToInsert.length > 0) {
        var newNodes = [];
        if (nodes.length > 0) {
            const selectionRange = getSelectedNodeRange(nodes, caretOffsets);
            for (var i = 0; i < nodes.length; i++) {
                if (selectionRange.start.index <= i && i <= selectionRange.end.index) {
                    if (selectionRange.start.index === i) {
                        // update i in order to skip the nodes that are already handled
                        i = selectionRange.end.index;

                        // if the selection range is collapsed
                        if (selectionRange.start.index === selectionRange.end.index &&
                            selectionRange.start.offset === selectionRange.end.offset) {

                            const isTextNode = nodes[selectionRange.end.index].type === NODE_TYPES.TEXT;
                            const isZeroWidthTextNode = nodes[selectionRange.start.index].type === NODE_TYPES.ZERO_WIDTH_TEXT;

                            if (isZeroWidthTextNode) {
                                if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                    newNodes.push(createZeroWidthTextNode());
                                }
                                newNodes = newNodes.concat(nodesToInsert);
                            } else if (isTextNode) {
                                if (selectionRange.end.offset === nodes[selectionRange.end.index].value.length) {
                                    if (nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                        const firstInsertedNode = nodesToInsert.shift();
                                        if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                            firstInsertedNode.value = nodes[selectionRange.start.index].value + firstInsertedNode.value;
                                            newNodes.push(firstInsertedNode);
                                        } else {
                                            newNodes.push(nodes[selectionRange.start.index]);
                                        }
                                    } else {
                                        newNodes.push(nodes[selectionRange.start.index]);
                                    }
                                    newNodes = newNodes.concat(nodesToInsert);
                                } else if (selectionRange.start.offset === 0) {
                                    if (newNodes.length > 0) {
                                        if (newNodes[newNodes.length - 1].type !== NODE_TYPES.PARAMETER &&
                                            nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                            const lastNewNode = newNodes.pop();
                                            const firstInsertedNode = nodesToInsert.shift();
                                            if (lastNewNode.type === NODE_TYPES.TEXT && firstInsertedNode.type === NODE_TYPES.TEXT) {
                                                lastNewNode.value = lastNewNode.value + firstInsertedNode.value;
                                            } else if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                                lastNewNode = firstInsertedNode;
                                            }
                                            newNodes.push(lastNewNode);
                                        } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER &&
                                            nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    } else if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }

                                    if (nodesToInsert[nodesToInsert.length - 1].type !== NODE_TYPES.PARAMETER) {
                                        const lastInsertedNode = nodesToInsert.pop();
                                        if (lastInsertedNode.type === NODE_TYPES.TEXT) {
                                            lastInsertedNode.value = lastInsertedNode.value + nodes[selectionRange.end.index].value;
                                            nodesToInsert.push(lastInsertedNode);
                                        } else {
                                            newNodes.push(nodes[selectionRange.end.index]);
                                        }
                                    } else {
                                        newNodes.push(nodes[selectionRange.end.index]);
                                    }
                                    newNodes = newNodes.concat(nodesToInsert);
                                } else {
                                    if (nodesToInsert.length > 0 && nodesToInsert[nodesToInsert.length - 1].type !== NODE_TYPES.PARAMETER) {
                                        const lastInsertedNode = nodesToInsert.pop();
                                        if (lastInsertedNode.type === NODE_TYPES.TEXT) {
                                            lastInsertedNode.value = lastInsertedNode.value + nodes[selectionRange.end.index].value.substring(selectionRange.end.offset);
                                            nodesToInsert.push(lastInsertedNode);
                                        } else {
                                            nodesToInsert.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                            });
                                        }
                                    } else {
                                        nodesToInsert.push({
                                            type: NODE_TYPES.TEXT,
                                            value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                        });
                                    }
                                    if (nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                        const firstInsertedNode = nodesToInsert.shift();
                                        if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                            firstInsertedNode.value = nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset) + firstInsertedNode.value;
                                            newNodes.push(firstInsertedNode);
                                        } else {
                                            newNodes.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                            });
                                        }
                                    } else {
                                        newNodes.push({
                                            type: NODE_TYPES.TEXT,
                                            value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                        });
                                    }
                                    newNodes = newNodes.concat(nodesToInsert);
                                }
                            } else {
                                // if the current node is a parameter node and the caret is after it
                                if (selectionRange.end.offset === nodes[selectionRange.end.index].value.length) {
                                    newNodes.push(nodes[selectionRange.end.index]);
                                    if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                    newNodes = newNodes.concat(nodesToInsert);
                                } else {
                                    // check the previous node
                                    if (newNodes.length > 0) {
                                        if (newNodes[newNodes.length - 1].type !== NODE_TYPES.PARAMETER &&
                                            nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                            const lastNewNode = newNodes.pop();
                                            const firstInsertedNode = nodesToInsert.shift();
                                            if (lastNewNode.type === NODE_TYPES.TEXT && firstInsertedNode.type === NODE_TYPES.TEXT) {
                                                lastNewNode.value = lastNewNode.value + firstInsertedNode.value;
                                            } else if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                                lastNewNode = firstInsertedNode;
                                            }
                                            newNodes.push(lastNewNode);
                                        } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER &&
                                            nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    } else if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }

                                    newNodes = newNodes.concat(nodesToInsert);

                                    if (selectionRange.start.offset === 0) {
                                        if (nodesToInsert[nodesToInsert.length - 1].type === NODE_TYPES.PARAMETER) {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                        newNodes.push(nodes[selectionRange.end.index]);
                                    }
                                }
                            }
                        } else {
                            if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type === NODE_TYPES.TEXT) {

                                if (nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                    const firstInsertedNode = nodesToInsert.shift();
                                    if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                        firstInsertedNode.value = nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset) + firstInsertedNode.value;
                                        newNodes.push(firstInsertedNode);
                                    } else {
                                        if (selectionRange.start.offset > 0) {
                                            newNodes.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                            });
                                        } else {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    }
                                } else {
                                    if (selectionRange.start.offset > 0) {
                                        newNodes.push({
                                            type: NODE_TYPES.TEXT,
                                            value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                        });
                                    } else {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                }

                                if (nodesToInsert.length > 0 && nodesToInsert[nodesToInsert.length - 1].type !== NODE_TYPES.PARAMETER) {
                                    const lastInsertedNode = nodesToInsert.pop();
                                    if (lastInsertedNode.type === NODE_TYPES.TEXT) {
                                        lastInsertedNode.value = lastInsertedNode.value + nodes[selectionRange.end.index].value.substring(selectionRange.end.offset);
                                        nodesToInsert.push(lastInsertedNode);
                                    } else {
                                        if (selectionRange.end.offset < nodes[selectionRange.end.index].value.length) {
                                            nodesToInsert.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                            });
                                        } else {
                                            nodesToInsert.push(createZeroWidthTextNode());
                                        }
                                    }
                                } else {
                                    if (selectionRange.end.offset < nodes[selectionRange.end.index].value.length) {
                                        nodesToInsert.push({
                                            type: NODE_TYPES.TEXT,
                                            value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                        });
                                    } else {
                                        nodesToInsert.push(createZeroWidthTextNode());
                                    }
                                }
                                newNodes = newNodes.concat(nodesToInsert);
                            } else if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type !== NODE_TYPES.TEXT) {

                                if (nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                    const firstInsertedNode = nodesToInsert.shift();
                                    if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                        firstInsertedNode.value = nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset) + firstInsertedNode.value;
                                        newNodes.push(firstInsertedNode);
                                    } else {
                                        if (selectionRange.start.offset > 0) {
                                            newNodes.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                            });
                                        } else {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    }
                                } else {
                                    if (selectionRange.start.offset > 0) {
                                        newNodes.push({
                                            type: NODE_TYPES.TEXT,
                                            value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                        });
                                    } else {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                }

                                newNodes = newNodes.concat(nodesToInsert);

                                if (selectionRange.end.offset === 0 && nodes[selectionRange.end.index].type === NODE_TYPES.PARAMETER) {
                                    if (nodesToInsert[nodesToInsert.length - 1].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                    newNodes.push(nodes[selectionRange.end.index]);
                                }
                            } else if (nodes[selectionRange.start.index].type !== NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type === NODE_TYPES.TEXT) {

                                if (selectionRange.start.offset === nodes[selectionRange.start.index].value.length && nodes[selectionRange.start.index].type === NODE_TYPES.PARAMETER) {
                                    newNodes.push(nodes[selectionRange.start.index]);

                                    if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                } else if (newNodes.length > 0) {
                                    if (newNodes[newNodes.length - 1].type !== NODE_TYPES.PARAMETER &&
                                        nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                        const lastNewNode = newNodes.pop();
                                        const firstInsertedNode = nodesToInsert.shift();
                                        if (lastNewNode.type === NODE_TYPES.TEXT && firstInsertedNode.type === NODE_TYPES.TEXT) {
                                            lastNewNode.value = lastNewNode.value + firstInsertedNode.value;
                                        } else if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                            lastNewNode = firstInsertedNode;
                                        }
                                        newNodes.push(lastNewNode);
                                    } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER &&
                                        nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                } else if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                    newNodes.push(createZeroWidthTextNode());
                                }

                                if (nodesToInsert.length > 0 && nodesToInsert[nodesToInsert.length - 1].type !== NODE_TYPES.PARAMETER) {
                                    const lastInsertedNode = nodesToInsert.pop();
                                    if (lastInsertedNode.type === NODE_TYPES.TEXT) {
                                        lastInsertedNode.value = lastInsertedNode.value + nodes[selectionRange.end.index].value.substring(selectionRange.end.offset);
                                        nodesToInsert.push(lastInsertedNode);
                                    } else {
                                        if (selectionRange.end.offset < nodes[selectionRange.end.index].value.length) {
                                            nodesToInsert.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                            });
                                        } else {
                                            nodesToInsert.push(createZeroWidthTextNode());
                                        }
                                    }
                                } else {
                                    if (selectionRange.end.offset < nodes[selectionRange.end.index].value.length) {
                                        nodesToInsert.push({
                                            type: NODE_TYPES.TEXT,
                                            value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                        });
                                    } else {
                                        nodesToInsert.push(createZeroWidthTextNode());
                                    }
                                }

                                newNodes = newNodes.concat(nodesToInsert);
                            } else if (nodes[selectionRange.start.index].type !== NODE_TYPES.TEXT &&
                                nodes[selectionRange.end.index].type !== NODE_TYPES.TEXT) {

                                if (selectionRange.start.index !== selectionRange.end.index &&
                                    selectionRange.start.offset === nodes[selectionRange.start.index].value.length &&
                                    selectionRange.end.offset === 0) {
                                    newNodes.push(nodes[selectionRange.start.index]);
                                    if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                    newNodes = newNodes.concat(nodesToInsert);
                                    if (nodesToInsert[nodesToInsert.length - 1].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                    newNodes.push(nodes[selectionRange.end.index]);
                                } else if (selectionRange.start.index !== selectionRange.end.index &&
                                    selectionRange.start.offset === nodes[selectionRange.start.index].value.length) {
                                    newNodes.push(nodes[selectionRange.start.index]);
                                    if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }
                                    newNodes = newNodes.concat(nodesToInsert);
                                } else {

                                    if (newNodes.length > 0) {
                                        if (newNodes[newNodes.length - 1].type !== NODE_TYPES.PARAMETER &&
                                            nodesToInsert[0].type !== NODE_TYPES.PARAMETER) {
                                            const lastNewNode = newNodes.pop();
                                            const firstInsertedNode = nodesToInsert.shift();
                                            if (lastNewNode.type === NODE_TYPES.TEXT && firstInsertedNode.type === NODE_TYPES.TEXT) {
                                                lastNewNode.value = lastNewNode.value + firstInsertedNode.value;
                                            } else if (firstInsertedNode.type === NODE_TYPES.TEXT) {
                                                lastNewNode = firstInsertedNode;
                                            }
                                            newNodes.push(lastNewNode);
                                        } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER &&
                                            nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                    } else if (nodesToInsert[0].type === NODE_TYPES.PARAMETER) {
                                        newNodes.push(createZeroWidthTextNode());
                                    }

                                    newNodes = newNodes.concat(nodesToInsert);

                                    if (selectionRange.end.offset === 0) {
                                        if (nodesToInsert[nodesToInsert.length - 1].type === NODE_TYPES.PARAMETER) {
                                            newNodes.push(createZeroWidthTextNode());
                                        }
                                        newNodes.push(nodes[selectionRange.end.index]);
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (i === selectionRange.end.index + 1) {
                        if (newNodes[newNodes.length - 1].type === NODE_TYPES.TEXT) {
                            if (nodes[i].type === NODE_TYPES.TEXT) {
                                newNodes[newNodes.length - 1].value += nodes[i].value;
                            } else if (nodes[i].type === NODE_TYPES.PARAMETER) {
                                newNodes.push(nodes[i]);
                            }
                        } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.ZERO_WIDTH_TEXT) {
                            if (nodes[i].type === NODE_TYPES.TEXT) {
                                newNodes[newNodes.length - 1] = nodes[i];
                            } else if (nodes[i].type === NODE_TYPES.PARAMETER) {
                                newNodes.push(nodes[i]);
                            }
                        } else if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER) {
                            if (nodes[i].type === NODE_TYPES.PARAMETER) {
                                newNodes.push(createZeroWidthTextNode());
                            }
                            newNodes.push(nodes[i]);
                        }
                    } else {
                        newNodes.push(nodes[i]);
                    }
                }
            }
        } else {
            newNodes = [...nodesToInsert];
        }
        if (newNodes[newNodes.length - 1].type === NODE_TYPES.PARAMETER) {
            newNodes.push(createZeroWidthTextNode());
        }
        return newNodes;
    }
    return nodes;
}

export function sliceNodes(nodes, caretOffsets) {
    const result = {
        slice: [],
        rest: []
    };
    if (nodes.length > 0) {
        const selectionRange = getSelectedNodeRange(nodes, caretOffsets);
        // if the selection range is collapsed
        if (selectionRange.start.index === selectionRange.end.index &&
            selectionRange.start.offset === selectionRange.end.offset) {
            result.rest = nodes;
        } else {
            for (var i = 0; i < nodes.length; i++) {
                if (selectionRange.start.index <= i && i <= selectionRange.end.index) {
                    if (selectionRange.start.index === i) {
                        // update i in order to skip the nodes that are already handled
                        i = selectionRange.end.index;

                        if (selectionRange.start.index === selectionRange.end.index) {
                            if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT) {
                                if (selectionRange.start.offset > 0) {
                                    result.rest.push({
                                        type: NODE_TYPES.TEXT,
                                        value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                    });
                                }

                                result.slice.push({
                                    type: NODE_TYPES.TEXT,
                                    value: nodes[selectionRange.start.index].value.substring(selectionRange.start.offset, selectionRange.end.offset)
                                });

                                if (selectionRange.end.offset < nodes[selectionRange.end.index].value.length) {
                                    result.rest.push({
                                        type: NODE_TYPES.TEXT,
                                        value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                    });
                                }
                            } else {
                                if (selectionRange.end.offset < nodes[selectionRange.end.index].value.length) {
                                    result.slice.push(nodes[selectionRange.start.index]);
                                } else {
                                    result.rest.push(nodes[selectionRange.start.index]);
                                }
                            }
                        } else {
                            for (var j = selectionRange.start.index; j <= selectionRange.end.index; j++) {
                                if (selectionRange.start.index === j) {
                                    if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT) {

                                        if (selectionRange.start.offset > 0) {
                                            result.rest.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.start.index].value.substring(0, selectionRange.start.offset)
                                            });
                                        }

                                        if (selectionRange.start.offset < nodes[selectionRange.start.index].value.length) {
                                            result.slice.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.start.index].value.substring(selectionRange.start.offset)
                                            });
                                        }

                                    } else {
                                        if (selectionRange.start.offset < nodes[selectionRange.start.index].value.length) {
                                            result.slice.push(nodes[selectionRange.start.index]);
                                        } else {
                                            result.rest.push(nodes[selectionRange.start.index]);
                                        }
                                    }
                                } else if (selectionRange.end.index === j) {
                                    if (nodes[selectionRange.end.index].type === NODE_TYPES.TEXT) {

                                        if (selectionRange.end.offset > 0) {
                                            result.slice.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.end.index].value.substring(0, selectionRange.end.offset)
                                            });
                                        }

                                        if (selectionRange.end.offset < nodes[selectionRange.end.index].value.length) {
                                            result.rest.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.end.index].value.substring(selectionRange.end.offset)
                                            });
                                        }

                                    } else {
                                        if (selectionRange.end.offset > 0) {
                                            result.slice.push(nodes[selectionRange.end.index]);
                                        } else {
                                            result.rest.push(nodes[selectionRange.end.index]);
                                        }
                                    }
                                } else {
                                    result.slice.push(nodes[j]);
                                }
                            }
                        }
                    }
                } else {
                    if (nodes[i].value.length > 0) {
                        result.rest.push(nodes[i]);
                    }
                }
            }
        }
    }
    return result;
}

export function getSelectedNodes(nodes, caretOffsets) {
    const selectedNodes = [];
    if (nodes.length > 0) {
        const selectionRange = getSelectedNodeRange(nodes, caretOffsets);
        // if the selection range is not collapsed
        if (selectionRange.start.index !== selectionRange.end.index ||
            selectionRange.start.offset !== selectionRange.end.offset) {
            for (var i = 0; i < nodes.length; i++) {
                if (selectionRange.start.index <= i && i <= selectionRange.end.index) {
                    if (selectionRange.start.index === i) {
                        // update i in order to skip the nodes that are already handled
                        i = selectionRange.end.index;

                        if (selectionRange.start.index === selectionRange.end.index) {
                            if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT) {
                                selectedNodes.push({
                                    type: NODE_TYPES.TEXT,
                                    value: nodes[selectionRange.start.index].value.substring(selectionRange.start.offset, selectionRange.end.offset)
                                });
                            } else {
                                selectedNodes.push(nodes[selectionRange.start.index]);
                            }
                        } else {
                            for (var j = selectionRange.start.index; j <= selectionRange.end.index; j++) {
                                if (selectionRange.start.index === j) {
                                    if (nodes[selectionRange.start.index].type === NODE_TYPES.TEXT) {
                                        if (selectionRange.start.offset < nodes[selectionRange.start.index].value.length) {
                                            selectedNodes.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.start.index].value.substring(selectionRange.start.offset)
                                            });
                                        }

                                    } else {
                                        if (selectionRange.start.offset < nodes[selectionRange.start.index].value.length) {
                                            selectedNodes.push(nodes[selectionRange.start.index]);
                                        }
                                    }
                                } else if (selectionRange.end.index === j) {
                                    if (nodes[selectionRange.end.index].type === NODE_TYPES.TEXT) {

                                        if (selectionRange.end.offset > 0) {
                                            selectedNodes.push({
                                                type: NODE_TYPES.TEXT,
                                                value: nodes[selectionRange.end.index].value.substring(0, selectionRange.end.offset)
                                            });
                                        }
                                    } else {
                                        if (selectionRange.end.offset > 0) {
                                            selectedNodes.push(nodes[selectionRange.end.index]);
                                        }
                                    }
                                } else {
                                    selectedNodes.push(nodes[j]);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return selectedNodes;
}

export function getSelectedNodeIndexes(nodes, caretOffsets) {
    const selectedNodeIndexes = [];
    if (nodes.length > 0 && caretOffsets) {
        const selectionRange = getSelectedNodeRange(nodes, caretOffsets);
        // if the selection range is not collapsed
        if (selectionRange.start.index !== selectionRange.end.index ||
            selectionRange.start.offset !== selectionRange.end.offset) {
            for (var i = 0; i < nodes.length; i++) {
                if (selectionRange.start.index <= i && i <= selectionRange.end.index) {
                    if (selectionRange.start.index === i) {
                        // update i in order to skip the nodes that are already handled
                        i = selectionRange.end.index;

                        if (selectionRange.start.index === selectionRange.end.index) {
                            if (nodes[selectionRange.start.index].type !== NODE_TYPES.TEXT) {
                                selectedNodeIndexes.push(selectionRange.start.index);
                            }
                        } else {
                            for (var j = selectionRange.start.index; j <= selectionRange.end.index; j++) {
                                if (selectionRange.start.index === j) {
                                    if (nodes[selectionRange.start.index].type !== NODE_TYPES.TEXT) {
                                        if (selectionRange.start.offset < nodes[selectionRange.start.index].value.length) {
                                            selectedNodeIndexes.push(selectionRange.start.index);
                                        }
                                    }
                                } else if (selectionRange.end.index === j) {
                                    if (nodes[selectionRange.end.index].type !== NODE_TYPES.TEXT) {
                                        if (selectionRange.end.offset > 0) {
                                            selectedNodeIndexes.push(selectionRange.end.index);
                                        }
                                    }
                                } else {
                                    if (nodes[j].type !== NODE_TYPES.TEXT) {
                                        selectedNodeIndexes.push(j);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return selectedNodeIndexes;
}