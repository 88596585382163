import { React } from "react";

export default function AddStepButton({ stepIndex, addStep, lineColor, ...rest }) {

    const handleClick = (e) => {
        e.preventDefault();
        addStep(stepIndex);
    }

    return <div {...{ ...rest, className: "w-full max-w-lg " + (rest.className || '' )}}>
        <div className={`relative h-14 w-0.5 mx-auto ${lineColor}`}>
            <button onClick={handleClick} className="absolute text-primary-dark left-1/2 hover:text-primary top-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-white shadow-sm text-xl leading-none font-semibold rounded-full py-1 px-2">+</button>
        </div>
    </div>
}