import { React, useEffect } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepInputKey from "./StepInputKey";
import Accordion from "../common/Accordion";
import keyHelpers from "../../helpers/key";
import Checkbox from "../common/Checkbox";
import { paramText, parseParam } from "../../helpers/param";
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import StepTopBar from "./StepTopBar";
import valueHelpers from "../../helpers/value";
import MetaBox from "./MetaBox";
import { DATA_SOURCE_TYPES, DATA_SOURCE_KEYS } from "../../constants/data-source";

function addParametersToFormData(inputDataSourceKey, parameters, formData) {
    if (parameters && parameters.hasOwnProperty(inputDataSourceKey)) {
        const dataSourceType = inputDataSourceKey === DATA_SOURCE_KEYS.INPUT ? DATA_SOURCE_TYPES.INPUT : DATA_SOURCE_TYPES.STEP_OUTPUT;
        const dataSourceParameters = parameters[inputDataSourceKey];
        if (dataSourceParameters) {
            var i, j, tableKey, columnKey, fieldKey, foundTableIndex, foundColumnIndex, foundFieldIndex, table
            for (i = 0; i < dataSourceParameters.params.length; i++) {
                if (dataSourceParameters.params[i].dataSourceType !== DATA_SOURCE_TYPES.STEP_META &&
                    (dataSourceParameters.params[i].type === 'table' ||
                        dataSourceParameters.params[i].type === 'column' ||
                        dataSourceParameters.params[i].type === 'field')) {

                    tableKey = dataSourceParameters.params[i].type === 'table' ? dataSourceParameters.params[i].dataKey : keyHelpers.extractTableKeyFromKey(dataSourceParameters.params[i].dataKey);
                    foundTableIndex = -1;
                    for (j = 0; j < formData.tables.length; j++) {
                        if (formData.tables[j].key.dataKey === tableKey) {
                            foundTableIndex = j;
                            break;
                        }
                    }
                    if (foundTableIndex === -1) {
                        foundTableIndex = formData.tables.push({ key: { dataKey: tableKey, dataSourceKey: inputDataSourceKey, dynamic: false, dataSourceType: dataSourceType }, columns: [], selected: false }) - 1;
                    }
                    if (dataSourceParameters.params[i].type === 'column') {
                        table = formData.tables[foundTableIndex];
                        columnKey = dataSourceParameters.params[i].dataKey;
                        foundColumnIndex = -1;
                        for (j = 0; j < table.columns.length; j++) {
                            if (table.columns[j].key.dataKey === columnKey) {
                                foundColumnIndex = j;
                                break;
                            }
                        }
                        if (foundColumnIndex === -1) {
                            table.columns.push({ key: { dataKey: columnKey, dataSourceKey: inputDataSourceKey, dynamic: false, dataSourceType: dataSourceType }, selected: false });
                        }
                    }

                    if (dataSourceParameters.params[i].type === 'field') {
                        table = formData.tables[foundTableIndex];
                        fieldKey = dataSourceParameters.params[i].dataKey;
                        foundFieldIndex = -1;
                        for (j = 0; j < table.columns.length; j++) {
                            if (table.columns[j].key.dataKey === fieldKey) {
                                foundFieldIndex = j;
                                break;
                            }
                        }
                        if (foundFieldIndex === -1) {
                            table.columns.push({ key: { dataKey: fieldKey, dataSourceKey: inputDataSourceKey, dynamic: false, dataSourceType: dataSourceType }, selected: false });
                        }
                    }
                }
            }
        }
    }
    formData.tables = formData.tables.filter((table) => table.columns.length > 0);
    return formData;
}

function prepareDataForSubmit(data) {
    if (data) {
        data = { ...data };
        data.tables = data.tables.map((table) => {
            return {
                key: paramText({ dataSourceKey: table.key.dataSourceKey, dataKey: table.key.dataKey, dynamic: table.key.dynamic, dataSourceType: table.key.dataSourceType }),
                columns: table.columns.filter((column) => (column.selected)).map((column) => {
                    return paramText({ dataSourceKey: column.key.dataSourceKey, dataKey: column.key.dataKey, dynamic: column.key.dynamic, dataSourceType: column.key.dataSourceType })
                })
            }
        });
        data.tables = data.tables.filter((table) => table.columns.length > 0);
    }
    return data;
}

function areAllColumnsSelected(columns) {
    if (columns) {
        for (var i = 0; i < columns.length; i++) {
            if (!columns[i].selected) {
                return false;
            }
        }
    }
    return true;
}

function SelectorStepForm({ index, step, update, parameters, onRun, onStop, isRunning, topBarColor }) {

    const { data, setData, handleChange, handleSubmit, setItem, removeItem, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            formData.tables = formData?.tables || [];
            formData.tables = formData.tables.map((table) => {
                const tableParam = parseParam(table.key);
                return {
                    key: tableParam,
                    columns: table.columns.map((column) => {
                        const columnParam = parseParam(column);
                        return { key: columnParam, selected: true }
                    })
                }
            });
            formData = addParametersToFormData(step.input_key, parameters, formData);
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            update(index, prepareDataForSubmit(submittedData));
        }
    });

    useEffect(() => {
        setData({ ...addParametersToFormData(data?.input_key, parameters, data) });
    }, [parameters]);

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    const handleInputKeyChange = (newInputKey) => {
        setData({ ...addParametersToFormData(newInputKey, parameters, data) });
        handleChange('input_key')(newInputKey);
    }

    const handleSelectAllTableColumns = (index, table) => (checked) => {
        if ((checked && !areAllColumnsSelected(table.columns)) || (!checked && areAllColumnsSelected(table.columns))) {
            const newValue = { key: table.key, columns: [] };
            for (var i = 0; i < table.columns.length; i++) {
                newValue.columns.push({ key: table.columns[i].key, selected: checked });
            }
            setItem(['tables', index], newValue);
        }
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}>
            <StepInputKey value={data?.input_key} editMode={true} stepIndex={index} parameters={parameters} onChange={handleInputKeyChange} />
        </StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />
            {data.tables && data.tables.map((table, i) => {
                return <Accordion className={`${i + 1 !== data.tables.length ? 'mb-2' : ''}`} key={`accordion_${i}`}
                    title={<Checkbox className="text-sm px-2 py-1" value={areAllColumnsSelected(table.columns)} onChange={handleSelectAllTableColumns(i, table)}>
                        <span className="text-sm">{table.key.dataKey || 'Root'}</span>
                    </Checkbox>}>
                    {table.columns && table.columns.map((column, j) => {
                        return <Checkbox className="text-xs rounded-md px-2 py-1 border focus:border-secondary-dark hover:bg-gray-100 active:bg-gray-200" key={`column_${i}-${j}/${table.columns.length}`} value={table.columns[j].selected} onChange={handleChange(["tables", i, "columns", j, "selected"])}>
                            <div className="text-xs pt-[2px]">{keyHelpers.extractColumnNameFromKey(column.key.dataKey)}</div>
                        </Checkbox>
                    })}
                </Accordion>
            })}
            <MetaBox stepIndex={index} parameters={parameters} data={data} handleChange={handleChange} setItem={setItem} removeItem={removeItem} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} runToStep={prepareDataForSubmit(data)} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

export default function SelectorStep({ index, step, update, editMode, parameters, onRun, onStop, isRunning, topBarColor }) {

    return editMode ?
        <SelectorStepForm index={index} step={step} update={update} parameters={parameters} onRun={onRun} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} />
        : (<>
            <StepTopBar bgColor={topBarColor}>
                <StepInputKey value={step?.input_key} stepIndex={index} parameters={parameters} />
            </StepTopBar>
            <div className="bg-white rounded-b-md px-3 py-2">
                <StepHeader className="mb-3" stepType={step.type} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
                {step.tables && step.tables.map((table, i) => {
                    const tableParam = parseParam(table.key);
                    return <Accordion className={`${i + 1 !== step.tables.length ? 'mb-2' : ''}`} key={`accordion_${i}`} title={<div className="text-sm px-2 py-1">{tableParam.dataKey || 'Root'}</div>}>
                        {table.columns && table.columns.map((column, j) => {
                            const columnParam = parseParam(column);
                            return <div key={`column_${i}-${j}/${table.columns.length}`} className="text-xs border px-2 py-1 rounded-md">{keyHelpers.extractColumnNameFromKey(columnParam.dataKey)}</div>
                        })}
                    </Accordion>
                })}
            </div>
        </>);
}