import { React, useState, useEffect } from "react";

export default function Checkbox({ value, onChange, children, ...rest }) {

    const [checked, setChecked] = useState(value);

    const handleChange = () => {
        const newValue = !checked;
        if (typeof onChange === 'function') {
            onChange(newValue);
        }
        setChecked(newValue);
    }

    const handleClick = (e) => {
        e.preventDefault();
        handleChange();
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            handleChange();
        }
    }

    useEffect(() => {
        if (value !== checked) {
            setChecked(value);
        }
    }, [value])

    return <label {...{ ...rest, className: "flex items-start focus:outline-none cursor-pointer " + (rest.className || '' ) }} tabIndex="0" onKeyDown={handleKeyDown} onClick={handleClick} role="checkbox" aria-checked={checked}>
        <span className={`checkbox ${checked ? 'checkbox--checked' : ''}`}></span>
        <span className="flex-1 select-none">{children}</span>
    </label>
}