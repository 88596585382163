import { React } from "react";
import useQuery from "../hooks/query";
import Explorer from "../components/explorer/Explorer";
import NavBar from "../components/explorer/NavBar";
import Loader from "../components/brand/Loader";
import Workflow from "../components/workflow/Workflow";
import Json from "../components/json/Json";
import { useFileFetcher } from "../hooks/file";
import { FILE_TYPES } from "../constants/file";
import Widget from "../components/widget/Widget";
import Dashboard from "../components/dashboard/Dashboard";

export default function WorkspacePage({ projectKey }) {
    const query = useQuery();
    const fileKey = query.get('key') || '';
    const { responseData: fileInfo } = useFileFetcher({ projectKey, fileKey }, [projectKey, fileKey]);

    const content = () => {
        switch (fileInfo.type) {
            case FILE_TYPES.DIRECTORY.CODE:
                return <Explorer projectKey={projectKey} directoryKey={fileKey} />
            case FILE_TYPES.WORKFLOW.CODE:
                return <Workflow projectKey={projectKey} fileKey={fileKey} />
            case FILE_TYPES.WIDGET.CODE:
                return <Widget projectKey={projectKey} fileKey={fileKey} />
            case FILE_TYPES.JSON.CODE:
                return <Json projectKey={projectKey} fileKey={fileKey} />
            case FILE_TYPES.DASHBOARD.CODE:
                return <Dashboard projectKey={projectKey} fileKey={fileKey} />
            default:
                return fileKey;
        }
    }

    return (
        <>
            <h1 className="flex-none section-title">Workspace</h1>
            <NavBar className="flex-none" projectKey={projectKey} fileKey={fileKey} />
            {fileInfo ?
                content()
                : <div className="pt-36 text-center">
                    <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
                    <p>Please wait...</p>
                </div>
            }
        </>
    );
}