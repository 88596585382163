import { useEffect, useState } from "react";
import {useUser} from "./user";

export default function useWorkflowRunScopeKey(workflowRunScope) {
    const [workflowRunScopeKey, setWorkflowRunScopeKey] = useState(null);
    const { user } = useUser();

    useEffect(() => {
        if (workflowRunScope === 'global') {
            setWorkflowRunScopeKey('global');
        } else if (workflowRunScope === 'user') {
            setWorkflowRunScopeKey(user.key);
        } else {
            setWorkflowRunScopeKey("anonymous");
        }
    }, [user, workflowRunScope]);

    return [workflowRunScopeKey, setWorkflowRunScopeKey];
}