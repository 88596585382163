import { React, useRef, useState, useEffect } from 'react';
import ColumnSelector from '../common/ColumnSelector';
import TableSelector from '../common/TableSelector';
import SaveDataDialog from './SaveDataDialog';
import Dropdown from '../common/Dropdown';
import { DATA_SOURCE_TYPES, DATA_SOURCE_TYPE_KEYS } from "../../constants/data-source";
import { dataSourceTypeToDataSourceTypeKey, dataSourceTypeKeyToDataSourceType } from "../../helpers/data-source";
import { selectMainTable, selectColumnsFromTable, databaseToTableTree } from "../../helpers/database";
import keyHelpers from "../../helpers/key";
import { ROOT_TABLE_KEY } from '../../constants/database';

function useTable(data, dataSourceTypeKey) {
    const [table, setTable] = useState(() => {
        return selectMainTable(data ? data[dataSourceTypeKey] : null);
    });

    return [table, setTable];
}

export default function WorkflowData({ index = -1, step, projectKey, dataSourceKey, dataSourceType, data, parameters, dispatchParameters, topBarColor, showDataSchema }) {
    const tableRef = useRef(null);
    const currItemKeyRef = useRef(null);
    const [currDataSourceTypeKey, setCurrDataSourceTypeKey] = useState(() => dataSourceTypeToDataSourceTypeKey(dataSourceType));
    const currDataSourceType = dataSourceTypeKeyToDataSourceType(currDataSourceTypeKey);
    const [selectedTable, setSelectedTable] = useTable(data, currDataSourceTypeKey);
    const [selectedColumns, setSelectedColumns] = useState(selectColumnsFromTable({ table: selectedTable }));
    const [action, setAction] = useState(null);

    useEffect(() => {
        var newSelectedTable = null;
        if (selectedTable) {
            const tables = data && data[currDataSourceTypeKey] ? data[currDataSourceTypeKey].tables : null;
            if (tables) {
                if (selectedTable.key in tables) {
                    if (selectedTable.key !== ROOT_TABLE_KEY || (tables[selectedTable.key].columns?.length || 0) > 0) {
                        newSelectedTable = tables[selectedTable.key];
                    }
                }
            }
        }
        if (newSelectedTable === null) {
            newSelectedTable = selectMainTable(data ? data[currDataSourceTypeKey] : null);
        }
        if (selectedTable !== newSelectedTable) {
            setSelectedColumns(selectColumnsFromTable({ table: newSelectedTable }));
            setSelectedTable(newSelectedTable);
        }
    }, [data, currDataSourceTypeKey])

    const changeDataSourceType = (e) => {
        e.preventDefault();
        var newDataSourceTypeKey;
        if (currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT || currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.STEP_META) {
            newDataSourceTypeKey = currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT ? DATA_SOURCE_TYPE_KEYS.STEP_META : DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT;
        } else {
            newDataSourceTypeKey = currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.INPUT ? DATA_SOURCE_TYPE_KEYS.PARAMETER : DATA_SOURCE_TYPE_KEYS.INPUT;
        }
        setCurrDataSourceTypeKey(newDataSourceTypeKey);
    }

    useEffect(() => {
        const tableElem = tableRef.current;
        if (tableElem) {
            var idCell = tableElem.querySelector('tbody th');
            if (idCell) {
                tableElem.querySelector('thead th').style.width = `${idCell.offsetWidth}px`;
            }
        }
    });

    const [isTableSelectorVisible, setTableSelectorVisibility] = useState(false);

    const toggleTableSelector = (e) => {
        e.preventDefault();
        setTableSelectorVisibility(!isTableSelectorVisible);
    }

    const closeTableSelector = () => {
        setTableSelectorVisibility(false);
    }

    const selectTable = (tableKey) => {
        const tables = data && data[currDataSourceTypeKey] ? data[currDataSourceTypeKey].tables : null;
        if (tables) {
            if (tableKey in tables) {
                const newSelectedTable = tables[tableKey];
                setSelectedColumns(selectColumnsFromTable({ table: newSelectedTable }));
                setSelectedTable(newSelectedTable);
            }
        }
    }

    const [isColumnSelectorVisible, setColumnSelectorVisibility] = useState(false);

    const toggleColumnSelector = (e) => {
        e.preventDefault();
        setColumnSelectorVisibility(!isColumnSelectorVisible);
    }

    const closeColumnSelector = () => {
        setColumnSelectorVisibility(false);
    }

    const selectColumns = (newSelectedColumns) => {
        setSelectedColumns(newSelectedColumns);
    }

    const numberOfSelectedColumns = () => {
        var i = 0;
        for (var column in selectedColumns) {
            if (selectedColumns[column]) {
                i++;
            }
        }
        return i;
    }

    const [keysOfPickedItems, setKeysOfPickedItems] = useState(() => {
        return parameters && parameters[dataSourceKey] ? parameters[dataSourceKey].params.map((param) => `${param.dataSourceType}-${param.dataKey}`) : [];
    });

    const handleItemClick = (item) => (e) => {
        e.preventDefault();
        if (currDataSourceType !== DATA_SOURCE_TYPES.PARAMETER) {
            const isItemNotPicked = keysOfPickedItems.indexOf(`${currDataSourceType}-${item.key}`) === -1;
            const newParameterAction = {
                type: isItemNotPicked ? 'add_param' : 'remove_param',
                dataSourceKey: dataSourceKey,
                param: { dataKey: item.key, type: item.type, dataSourceType: currDataSourceType }
            };
            if (step && (currDataSourceType === DATA_SOURCE_TYPES.STEP_META || currDataSourceType === DATA_SOURCE_TYPES.STEP_OUTPUT)) {
                newParameterAction.step = { index: index, key: step.key, title: step.title };
            }
            dispatchParameters(newParameterAction);
            const currItemKeyElem = currItemKeyRef.current;
            if (currItemKeyElem) {
                currItemKeyElem.innerHTML = item.key;
            }
            setKeysOfPickedItems(isItemNotPicked ? [...keysOfPickedItems, `${currDataSourceType}-${item.key}`] : keysOfPickedItems.filter((key) => (key !== `${currDataSourceType}-${item.key}`)));
        }
    }

    const handleItemHighlight = (e, isItemHighlighted, item) => {
        const tableElem = tableRef.current;
        const currItemKeyElem = currItemKeyRef.current;
        if (tableElem) {
            const highlightedItemColor = 'bg-gray-100';
            if (item.type === 'table') {
                const tableRows = tableElem.getElementsByTagName('tr');
                for (var i = 0; i < tableRows.length; i++) {
                    if (isItemHighlighted) {
                        tableRows[i].classList.add(highlightedItemColor);
                    } else {
                        tableRows[i].classList.remove(highlightedItemColor);
                    }
                }
                const tableHeaders = tableElem.getElementsByTagName('tbody')[0].getElementsByTagName('th');
                for (var i = 0; i < tableHeaders.length; i++) {
                    tableHeaders[i].classList.remove(isItemHighlighted ? 'bg-gray-50' : highlightedItemColor);
                    tableHeaders[i].classList.add(isItemHighlighted ? highlightedItemColor : 'bg-gray-50');
                }
            } else if (item.type === 'row') {
                var rowIndexElem = e.target;
                while (rowIndexElem && rowIndexElem.tagName.toLowerCase() !== 'th') {
                    rowIndexElem = rowIndexElem.parentElement;
                }
                if (rowIndexElem && rowIndexElem.tagName.toLowerCase() === 'th') {
                    rowIndexElem.classList.remove(isItemHighlighted ? 'bg-gray-50' : highlightedItemColor);
                    rowIndexElem.classList.add(isItemHighlighted ? highlightedItemColor : 'bg-gray-50');
                    var rowElem = rowIndexElem;
                    while (rowElem && rowElem.tagName.toLowerCase() !== 'tr') {
                        rowElem = rowElem.parentElement;
                    }
                    if (rowElem && rowElem.tagName.toLowerCase() === 'tr') {
                        if (isItemHighlighted) {
                            rowElem.classList.add(highlightedItemColor);
                        } else {
                            rowElem.classList.remove(highlightedItemColor);
                        }
                    }
                }
            } else if (item.type === 'column') {
                var columnElem = e.target;
                while (columnElem && columnElem.tagName.toLowerCase() !== 'th') {
                    columnElem = columnElem.parentElement;
                }
                if (columnElem && columnElem.tagName.toLowerCase() === 'th') {
                    if (isItemHighlighted) {
                        columnElem.classList.add(highlightedItemColor);
                    } else {
                        columnElem.classList.remove(highlightedItemColor);
                    }

                    const columnElems = columnElem.parentElement.getElementsByTagName('th');
                    var columnIndex = -1;
                    for (var i = 0; i < columnElems.length; i++) {
                        if (columnElems[i] === columnElem) {
                            columnIndex = i - 1;
                            break;
                        }
                    }
                    if (columnIndex >= 0) {
                        const rowElems = tableElem.getElementsByTagName('tr');
                        var cells;
                        for (var i = 0; i < rowElems.length; i++) {
                            cells = rowElems[i].getElementsByTagName('td');
                            if (columnIndex < cells.length) {
                                if (isItemHighlighted) {
                                    cells[columnIndex].classList.add(highlightedItemColor);
                                } else {
                                    cells[columnIndex].classList.remove(highlightedItemColor);
                                }
                            }
                        }
                    }
                }
            } else if (item.type === 'cell') {
                var tdElem = e.target;
                while (tdElem && tdElem.tagName.toLowerCase() !== 'td') {
                    tdElem = tdElem.parentElement;
                }
                if (tdElem && tdElem.tagName.toLowerCase() === 'td') {
                    if (isItemHighlighted) {
                        tdElem.classList.add(highlightedItemColor);
                    } else {
                        tdElem.classList.remove(highlightedItemColor);
                    }
                }
            } else if (item.type === 'field') {
                var columnElem = e.target;
                while (columnElem && columnElem.tagName.toLowerCase() !== 'th' && columnElem.tagName.toLowerCase() !== 'td') {
                    columnElem = columnElem.parentElement;
                }
                if (columnElem && (columnElem.tagName.toLowerCase() === 'th' || columnElem.tagName.toLowerCase() === 'td')) {
                    if (isItemHighlighted) {
                        columnElem.classList.add(highlightedItemColor);
                    } else {
                        columnElem.classList.remove(highlightedItemColor);
                    }

                    const columnElems = columnElem.parentElement.getElementsByTagName(columnElem.tagName.toLowerCase());
                    var columnIndex = -1;
                    for (var i = 0; i < columnElems.length; i++) {
                        if (columnElems[i] === columnElem) {
                            columnIndex = i;
                            break;
                        }
                    }
                    if (columnIndex >= 0) {
                        const rowElems = tableElem.getElementsByTagName('tr');
                        var cells;
                        for (var i = 0; i < rowElems.length; i++) {
                            if (i === 0) {
                                cells = rowElems[i].getElementsByTagName('th');
                                if (columnIndex < cells.length) {
                                    if (isItemHighlighted) {
                                        cells[columnIndex].classList.add(highlightedItemColor);
                                    } else {
                                        cells[columnIndex].classList.remove(highlightedItemColor);
                                    }
                                }
                            }
                            cells = rowElems[i].getElementsByTagName('td');
                            if (columnIndex < cells.length) {
                                if (isItemHighlighted) {
                                    cells[columnIndex].classList.add(highlightedItemColor);
                                } else {
                                    cells[columnIndex].classList.remove(highlightedItemColor);
                                }
                            }
                        }
                    }
                }
            }
        }

        if (currItemKeyElem) {
            currItemKeyElem.innerHTML = (isItemHighlighted) ? item.key : '';
        }
    }

    const handleItemMouseEnter = (item) => (e) => {
        handleItemHighlight(e, true, item);
    }

    const handleItemMouseLeave = (item) => (e) => {
        handleItemHighlight(e, false, item);
    }

    return (
        data && <>
            <div className="md:flex-none md:min-w-lg max-w-full shadow-sm rounded-md relative">
                {selectedTable && <>
                    <header className={`flex py-1 px-1 flex-nowrap max-w-full rounded-t-md ${topBarColor} justify-between`}>
                        <div className="flex-1 table-selector relative">
                            <button onClick={toggleTableSelector} className="active:bg-gray-300 hover:bg-gray-100 pl-2 rounded-md text-sm selector-icon break-all appearance-none text-gray-700">{selectedTable.key || selectedTable.name}</button>
                            {isTableSelectorVisible ? <TableSelector value={selectedTable.key} tableTree={databaseToTableTree(data ? data[currDataSourceTypeKey] : null)} onClose={closeTableSelector} onChange={selectTable} /> : null}
                        </div>
                        <div className="flex-none relative">
                            <button onClick={toggleColumnSelector} className="active:bg-gray-300 hover:bg-gray-100 pl-2 rounded-md text-sm selector-icon appearance-none text-gray-700">{numberOfSelectedColumns()} of {selectedTable?.columns?.length || 0} columns</button>
                            {isColumnSelectorVisible ? <ColumnSelector value={selectedColumns} onClose={closeColumnSelector} onChange={selectColumns} /> : null}
                        </div>
                        <Dropdown className="flex-none relative">
                            <button onClick={(e) => { e.preventDefault(); showDataSchema(index, step, currDataSourceType, data, selectedTable.key); }} className="min-w-[140px] py-1 text-left block w-full bg-gray-100 hover:bg-gray-50 active:bg-gray-200 px-2 text-sm">Data schema</button>
                            <button onClick={(e) => { e.preventDefault(); setAction("save"); }} className="min-w-[140px] py-1 text-left block w-full bg-gray-100 hover:bg-gray-50 active:bg-gray-200 px-2 text-sm">Save data</button>
                        </Dropdown>
                    </header>
                    <div className="max-w-full overflow-x-auto bg-white">
                        <table ref={tableRef} className="bg-white border-collapse">
                            <thead className="block bg-gray-50">
                                <tr>
                                    {(() => {
                                        if (selectedTable.key !== ROOT_TABLE_KEY) {
                                            const item = {
                                                key: selectedTable.key,
                                                type: 'table'
                                            };
                                            const isPicked = currDataSourceType === DATA_SOURCE_TYPES.PARAMETER || keysOfPickedItems.indexOf(`${currDataSourceType}-${item.key}`) !== -1;
                                            return <th key={`${item.type}:${isPicked.toString()}`} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} onClick={handleItemClick(item)} className={`px-2 py-1 cursor-pointer border-b border-r text-left font-semibold text-xs ${`${item.type}-item`} ${isPicked ? 'item-picked' : ''}`}>$</th>
                                        }
                                        return null;
                                    })()}
                                    {selectedTable.columns && selectedTable.columns.filter((column) => selectedColumns[column.name]).map((column, i) => {
                                        const columnNameFields = keyHelpers.extractFieldsFromColumnName(column.name);
                                        const item = {
                                            key: keyHelpers.appendColumnNameToKey(selectedTable.key, column.name),
                                            type: selectedTable.key === ROOT_TABLE_KEY ? 'field' : 'column'
                                        };
                                        const isPicked = currDataSourceType === DATA_SOURCE_TYPES.PARAMETER || keysOfPickedItems.indexOf(`${currDataSourceType}-${item.key}`) !== -1;
                                        return <th key={`${item.type}_${i.toString()}:${isPicked.toString()}`} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} onClick={handleItemClick(item)} className={`border-b border-r cursor-pointer max-w-48 text-left py-1 font-semibold text-xs ${`${item.type}-item`} ${isPicked ? 'item-picked' : ''}`}>
                                            {columnNameFields.map((s, j) => <span key={j.toString()} className={`px-2 w-48 line-clamp-2 break-all ${j + 1 === columnNameFields.length ? 'text-black' : 'text-gray-500'}`}>{j === 0 ? s : `.${s}`}</span>)}
                                        </th>
                                    })}
                                </tr>
                            </thead>
                            <tbody className="block max-h-72 overflow-x-hidden overflow-y-auto">
                                {selectedTable.rows.slice(0, 10).map((row, i) => {
                                    return <tr key={i.toString()}>
                                        {(() => {
                                            if (selectedTable.key !== "") {
                                                const item = {
                                                    key: row.key,
                                                    type: 'row'
                                                };
                                                const isPicked = currDataSourceType === DATA_SOURCE_TYPES.PARAMETER || keysOfPickedItems.indexOf(`${currDataSourceType}-${item.key}`) !== -1;
                                                return <th key={`${item.type}_${i.toString()}:${isPicked.toString()}`} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} onClick={handleItemClick(item)} className={`bg-gray-50 cursor-pointer border-b border-r px-2 py-1 text-xs ${`${item.type}-item`} ${isPicked ? 'item-picked' : ''}`}>{row.index}</th>
                                            }
                                            return null;
                                        })()}
                                        {(selectedTable.columns && selectedTable.columns.length) > 0 && row.values.map((value, j) => {
                                            const item = {
                                                key: keyHelpers.appendColumnNameToKey(row.key, selectedTable.columns[j].name),
                                                type: selectedTable.key === ROOT_TABLE_KEY ? 'field' : 'cell'
                                            };
                                            const isPicked = currDataSourceType === DATA_SOURCE_TYPES.PARAMETER || keysOfPickedItems.indexOf(`${currDataSourceType}-${item.key}`) !== -1;
                                            return selectedColumns[selectedTable.columns[j].name] ?
                                                <td key={`${item.type}_${i.toString()}-${j.toString()}:${isPicked.toString()}`} title={(typeof value?.text === 'undefined' || value?.type === 5) ? 'null' : value?.text} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} onClick={handleItemClick(item)} className={`border-b border-r cursor-pointer max-w-48 text-xs ${`${item.type}-item`} ${isPicked ? 'item-picked' : ''}`}>
                                                    <span className="px-2 py-1 min-h-[20px] w-48 line-clamp-3 break-all">{(typeof value?.text === 'undefined' || value?.type === 5) ? 'null' : value?.text}</span>
                                                </td> : null;
                                        })}
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </>}
                <div className={`${topBarColor} pl-3 pr-2 py-1 rounded-b-md text-xs flex items-center`}>
                    <span className="flex-1 min-w-0 break-all" ref={currItemKeyRef}></span>
                    <div className='flex-none'>
                        <button className="flex bg-gray-100 flex-nowrap text-xs rounded-md" onClick={changeDataSourceType}>
                            {(currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT || currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.STEP_META) ? <>
                                <div className={`flex-1 py-0.5 px-2 block text-center font-semibold rounded-md ${currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT ? 'bg-white shadow-sm border text-black' : 'active:bg-gray-200 hover:text-gray-900 text-gray-500'}`}>Output</div>
                                <div className={`flex-1 py-0.5 px-2 block text-center font-semibold rounded-md ${currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.STEP_META ? 'bg-white shadow-sm border text-black' : 'active:bg-gray-200 hover:text-gray-900 text-gray-500'}`}>Meta</div>
                            </> : <>
                                <div className={`flex-1 py-0.5 px-2 min-w-[80px] block text-center font-semibold rounded-md ${currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.INPUT ? 'bg-white shadow-sm border text-black' : 'active:bg-gray-200 hover:text-gray-900 text-gray-500'}`}>Input</div>
                                <div className={`flex-1 py-0.5 px-2 min-w-[80px] block text-center font-semibold rounded-md ${currDataSourceTypeKey === DATA_SOURCE_TYPE_KEYS.PARAMETER ? 'bg-white shadow-sm border text-black' : 'active:bg-gray-200 hover:text-gray-900 text-gray-500'}`}>Parameter</div>
                            </>}
                        </button>
                    </div>
                </div>
            </div>
            {(selectedTable && action === 'save') ? <SaveDataDialog projectKey={projectKey} fileData={data ? data[currDataSourceTypeKey] : null} onClose={() => { setAction(null) }} /> : null}
        </>
    );
}