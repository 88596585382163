import { React, useState, useEffect } from "react";
import { LOGO_CHARACTER_BOX_SIZE } from "../../config";
import Highlighter from "./Highlighter";

export default function Logo({ words, colors }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div aria-label="Logo" className="inline-block relative" style={{ height: (LOGO_CHARACTER_BOX_SIZE * 2) + 'px', width: ((words[0].length > words[1].length ? words[0] : words[1]).length * LOGO_CHARACTER_BOX_SIZE) + 'px' }}>
            <Highlighter delay="1000ms" isVisible={isVisible} word={words[0].toUpperCase()} direction="horizontal" startRowIndex={0} startColumnIndex={0} color={colors[0]} />
            <Highlighter delay="1500ms" isVisible={isVisible} word={words[1].toUpperCase()} direction="horizontal" startRowIndex={1} startColumnIndex={0} color={colors[1]} />
        </div>
    );
}