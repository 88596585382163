import store from "store";
import useAccessToken from "./access-token";
import {useUser} from "./user";

export default function useLogout() {
    const { setToken } = useAccessToken();
    const { setUser } = useUser();

    return () => {
        // Clear user data
        store.remove('user');
        setUser(null);
        // Clear access token data
        store.remove('access_token');
        setToken(null);
    }
}