import { React } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepInputKey from "./StepInputKey";
import DataView from "../common/DataView";
import Selector from "../common/Selector";
import DataInput from "../common/DataInput";
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import StepTopBar from "./StepTopBar";
import valueHelpers from "../../helpers/value";
import MetaBox from "./MetaBox";

const FUNCTIONS = {
    "TextRemoveDiacritics": {
        friendly_name: "Remove diacritics",
        description: "",
        arguments: []
    },
    "TextToLowerCase": {
        friendly_name: "To lower case",
        description: "",
        arguments: []
    },
    "TextToUpperCase": {
        friendly_name: "To upper case",
        description: "",
        arguments: []
    },
    "NumberCalculate": {
        friendly_name: "Calculate",
        description: "Perform a number operation on the input.",
        arguments: [
            {
                name: "Operation",
                hint: "",
                options: [
                    {
                        group: "all",
                        list: [
                            {
                                value: "+",
                                label: "+ Addition"
                            },
                            {
                                value: "-",
                                label: "- Subtraction"
                            },
                            {
                                value: "x",
                                label: "x Multiplication"
                            },
                            {
                                value: "/",
                                label: "/ Division"
                            },
                            {
                                value: "%",
                                label: "% Remainder"
                            }
                        ]
                    }
                ]
            },
            {
                name: "Number",
                hint: ""
            }
        ]
    },
};

function functionOptions() {
    const textFuncs = {
        group: 'Text',
        list: []
    };
    const numberFuncs = {
        group: 'Number',
        list: []
    };
    for (var funcName in FUNCTIONS) {
        if (FUNCTIONS.hasOwnProperty(funcName)) {
            if (funcName.toLocaleLowerCase().startsWith('text')) {
                textFuncs.list.push({ value: funcName, label: FUNCTIONS[funcName].friendly_name });
            } else {
                numberFuncs.list.push({ value: funcName, label: FUNCTIONS[funcName].friendly_name });
            }
        }
    }
    return [textFuncs, numberFuncs];
}

const FUNCTION_OPTIONS = functionOptions();

function FunctionStepForm({ index, step, update, parameters, onRun, onStop, isRunning, topBarColor }) {

    const { data, handleChange, handleSubmit, setItem, removeItem, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            formData.columns = formData?.columns || [];
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            update(index, submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    const handleInputKeyChange = (newInputKey) => {
        handleChange('input_key')(newInputKey);
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}>
            <StepInputKey value={data?.input_key} editMode={true} stepIndex={index} parameters={parameters} onChange={handleInputKeyChange} />
        </StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />
            {data.columns && data.columns.map((column, i) => {
                return <div key={`function_${i.toString()}/${data.columns.length}`} className="flex flex-nowrap mt-2">
                    <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-red-500 active:bg-red-700 leading-[0.1] text-white text-center flex-grow-0 bg-red-600 rounded-full mt-1 mr-2 block" onClick={(e) => { e.preventDefault(); removeItem("columns", i); }}>–</button>
                    <div className="flex-1 py-1 px-2 rounded-md border border-gray-100">
                        <label className="label block mb-0.5">Input</label>
                        <DataInput stepIndex={index} parameters={parameters} className="w-full mb-1" placeholder="Input column" value={valueHelpers.textValue(column.input)} onChange={handleChange(["columns", i, "input"])} />
                        <label className="label block">Functions</label>
                        {column.functions && column.functions.map((func, j) => {
                            return <div key={`function_${j}/${column.functions.length}`} className={`flex flex-nowrap ${j + 1 !== column.functions.length ? 'mb-1' : ''}`}>
                                <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-red-500 active:bg-red-700 leading-[0.1] text-white text-center flex-grow-0 bg-red-600 rounded-full mt-2 mr-2 block" onClick={(e) => { e.preventDefault(); removeItem(["columns", i, "functions"], j); }}>–</button>
                                <div className="flex-1 border border-gray-100 mb-1 px-2 py-1 rounded-md">
                                    <Selector className="flex-1" value={func.name} onChange={handleChange(["columns", i, "functions", j, "name"])} placeholder="Function" options={FUNCTION_OPTIONS} />
                                    {FUNCTIONS[func.name] ?
                                        <>
                                            {FUNCTIONS[func.name].description && <p className="text-xs mt-0.5 text-gray-600">{FUNCTIONS[func.name].description}</p>}
                                            {FUNCTIONS[func.name].arguments ?
                                                FUNCTIONS[func.name].arguments.map((arg, g) => {
                                                    return <div key={`function_${j}(arg_${g})`} className="mt-1">
                                                        <label className="label block !text-gray-600">{arg.name}</label>
                                                        {arg.options ?
                                                            <Selector className="w-full mb-1" value={func.arguments[g]} onChange={handleChange(["columns", i, "functions", j, "arguments", g])} placeholder={arg.name} options={arg.options} />
                                                            : <DataInput stepIndex={index} parameters={parameters} className="w-full" placeholder={arg.name} value={valueHelpers.textValue(func.arguments[g])} onChange={handleChange(["columns", i, "functions", j, "arguments", g])} />}
                                                        {arg.hint && <p className="text-xs mt-0.5 text-gray-600">{arg.hint}</p>}
                                                    </div>
                                                })
                                                : null}
                                        </>
                                        : null}
                                </div>
                            </div>
                        })}
                        <div className="flex items-center my-2 flex-nowrap">
                            <button className="w-5 h-5 appearance-none font-semibold hover:bg-green-400 active:bg-green-600 leading-[0.1] text-white text-center flex-grow-0 bg-green-500 rounded-full mr-2 block" onClick={(e) => { e.preventDefault(); setItem(["columns", i, "functions"], { name: "", arguments: [] }); }}>+</button>
                            <label className="block text-xs font-semibold flex-1 text-gray-400">Add new function method</label>
                        </div>
                        <label className="label block mb-0.5">Output</label>
                        <DataInput stepIndex={index} parameters={parameters} className="w-full" placeholder="Output column" value={valueHelpers.textValue(column.output)} onChange={handleChange(["columns", i, "output"])} />
                    </div>
                </div>
            })}
            <div className="flex items-center mt-2 flex-nowrap">
                <button className="w-5 h-5 appearance-none font-semibold hover:bg-green-400 active:bg-green-600 leading-[0.1] text-white text-center flex-grow-0 bg-green-500 rounded-full mr-2 block" onClick={(e) => { e.preventDefault(); setItem("columns", { input: '', output: '', functions: [{ name: "", arguments: [] }] }); }}>+</button>
                <label className="block text-xs font-semibold flex-1 text-gray-400">Add new column to format</label>
            </div>
            <MetaBox stepIndex={index} parameters={parameters} data={data} handleChange={handleChange} setItem={setItem} removeItem={removeItem} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} runToStep={data} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

export default function FunctionStep({ index, step, update, editMode, parameters, onRun, onStop, isRunning, topBarColor }) {
    return editMode ?
        <FunctionStepForm index={index} step={step} update={update} parameters={parameters} onRun={onRun} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} />
        : (<>
            <StepTopBar bgColor={topBarColor}>
                <StepInputKey value={step?.input_key} stepIndex={index} parameters={parameters} />
            </StepTopBar>
            <div className="bg-white rounded-b-md px-3 py-2">
                <StepHeader className="mb-3" stepType={step.type} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
                {step.columns.map((column, i) => {
                    return (<ul key={i.toString()} className={`text-gray-600 align-middle text-sm ${(i + 1 !== step.columns.length) ? 'mb-6' : ''}`}>
                        <li className="rounded-md mb-2 border border-gray-200 align-middle bg-gray-50 py-1 px-2 "><span className="text-xs font-bold mb-1 block">Input</span><DataView data={column.input} parameters={parameters} /></li>
                        <li className="rounded-md mb-2 border border-gray-200 align-middle bg-gray-50 py-1 px-2 "><span className="text-xs font-bold block">Functions</span>{
                            column.functions.map((func, j) => {
                                return <div key={`${func.name}_${j}`}>{FUNCTIONS[func.name].friendly_name} ({(func.arguments && func.arguments.length > 0) ? func.arguments.map((arg, g) => {
                                    return <span key={`${func.name}_${j}(arg_${g})`}>{FUNCTIONS[func.name].arguments[g] ? <span className="text-xs text-gray-400">{FUNCTIONS[func.name].arguments[g].name}: </span> : null}{valueHelpers.textValue(arg)}{g + 1 !== func.arguments.length ? <span className="text-gray-400">, </span> : null}</span>
                                }) : " "})</div>
                            })
                        }</li>
                        <li className="rounded-md border border-gray-200 align-middle bg-gray-50 py-1 px-2 "><span className="text-xs font-bold mb-1 block">Output</span><DataView data={column.output} parameters={parameters} /></li>
                    </ul>)
                })}
            </div>
        </>);
}