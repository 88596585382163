import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as contentEditableHelpers from "../../helpers/contentEditable";
import { DATA_SOURCE_KEYS } from "../../constants/data-source";

const MODIFIER_KEY_CODES = [
    "Meta",
    "Alt",
    "Shift",
    "Control"
];
const REPEAT_KEY_CODES = ["Enter", " ", "Spacebar", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Left", "Right", "ArrowUp", "ArrowDown", "Up", "Down"];
const CARET_NAV_KEY_CODES = ["ArrowLeft", "ArrowRight", "Left", "Right", "ArrowUp", "ArrowDown", "Up", "Down"];

function stepSuggestions(stepIndex, parameters, isDynamicParameter) {
    const suggestions = [];
    for (var dataSourceKey in parameters) {
        if (dataSourceKey === DATA_SOURCE_KEYS.INPUT || dataSourceKey === DATA_SOURCE_KEYS.PARAMETER || dataSourceKey === DATA_SOURCE_KEYS.ANY) {
            suggestions.push({
                dataSourceKey: dataSourceKey,
                params: parameters[dataSourceKey].params.filter((param) => {
                    return !isDynamicParameter || (isDynamicParameter && (param.type === 'cell' || param.type === 'field'));
                })
            });
        } else if (parameters[dataSourceKey].step.index < stepIndex) {
            suggestions.push({
                dataSourceKey: dataSourceKey,
                step: parameters[dataSourceKey].step,
                params: parameters[dataSourceKey].params.filter((param) => {
                    return !isDynamicParameter || (isDynamicParameter && (param.type === 'cell' || param.type === 'field'));
                })
            });
        }
    }

    suggestions.sort(function (a, b) {
        if (a.step === null || typeof a.step === 'undefined') {
            return -1;
        }
        if (b.step === null || typeof b.step === 'undefined') {
            return 1;
        }
        if (a.step.index < b.step.index) { return -1; }
        if (a.step.index > b.step.index) { return 1; }
        return 0;
    })
    return suggestions;
}

export default function DataInput({ stepIndex, placeholder, parameters, onChange, value, multipleLines, ...rest }) {
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const caretOffsets = useRef(null);

    const [nodeList, setNodeList] = useState(() => {
        return contentEditableHelpers.textToNodes(value, parameters);
    });

    const [isActive, setIsActive] = useState(false);

    const [isDynamicParameter, setIsDynamicParameter] = useState(false);
    const suggestions = useMemo(() => { return stepSuggestions(stepIndex, parameters, isDynamicParameter) }, [stepIndex, isDynamicParameter, parameters]);
    const [suggestionStep, setSuggestionStep] = useState(-1);

    const updateInputValueAndCaretPosition = useCallback((currTextValue, newNodeList, currCaretOffsets) => {
        const newTextValue = contentEditableHelpers.nodesToText(newNodeList, true);

        if (newTextValue === currTextValue) {
            return;
        }

        var newCaretOffset = currCaretOffsets.end - (currTextValue.length - newTextValue.length);
        // the max caret offset is just a hack to avoid the problem related to adding a zero-width character 
        // when the text ends by a newline character
        const maxCaretOffset = newTextValue.length;
        newCaretOffset = newCaretOffset > maxCaretOffset ? maxCaretOffset : newCaretOffset;
        caretOffsets.current = {
            start: newCaretOffset,
            end: newCaretOffset
        }
        setNodeList(newNodeList);
        if (typeof onChange === 'function') {
            onChange(contentEditableHelpers.nodesToText(newNodeList));
        }
    }, [onChange, setNodeList]);

    const insertSuggestionItem = (stepIndex, dataSourceKey, param) => {
        const inputElem = inputRef.current;
        const currCaretOffsets = caretOffsets.current;
        if (inputElem && currCaretOffsets) {
            const newNodeList = contentEditableHelpers.insertNodes(
                [contentEditableHelpers.createParameterNode(stepIndex, { ...param, dataSourceKey: dataSourceKey, dynamic: isDynamicParameter })],
                nodeList,
                currCaretOffsets,
            );
            updateInputValueAndCaretPosition(nodeList, newNodeList, currCaretOffsets);
        }
    }

    const handleSuggestionItemClick = (suggestionStepIndex, suggestionParamIndex) => {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (suggestionStep !== suggestionStepIndex) {
                setSuggestionStep(suggestionStepIndex);
            } else {
                insertSuggestionItem(
                    suggestions[suggestionStepIndex].step ? suggestions[suggestionStepIndex].step.index : -1,
                    suggestions[suggestionStepIndex].dataSourceKey,
                    suggestions[suggestionStepIndex].params[suggestionParamIndex],
                );
            }
        }
    }

    useEffect(() => {
        const wrapperElem = wrapperRef.current;
        const inputElem = inputRef.current;

        if (isActive && caretOffsets.current) {
            contentEditableHelpers.setCaretOffsets(inputElem, caretOffsets.current);
        }

        const handleKeyDown = (e) => {
            if (!e.ctrlKey && !e.metaKey) {
                if ((e.repeat && REPEAT_KEY_CODES.indexOf(e.key) === -1) ||
                    CARET_NAV_KEY_CODES.indexOf(e.key) !== -1) {
                    return
                }

                e.preventDefault();

                if (e.key.length === 1 || ["Enter", " ", "Spacebar", "Backspace", "Delete"].indexOf(e.key) !== -1) {
                    const currTextValue = contentEditableHelpers.nodesToText(nodeList, true);
                    const currCaretOffsets = contentEditableHelpers.getCaretOffsets(inputElem, currTextValue.length);
                    const newNodeList = contentEditableHelpers.modifyNodes(nodeList, currCaretOffsets, e.key);
                    updateInputValueAndCaretPosition(currTextValue, newNodeList, currCaretOffsets);
                }
            }
        }

        const handleKeyUp = (e) => {
            if (!e.ctrlKey && !e.metaKey && MODIFIER_KEY_CODES.indexOf(e.key) === -1) {
                e.preventDefault();
            }
        }

        const handleFocusIn = (e) => {
            if (!isActive) {
                setIsActive(true);
            }
        }

        const handlePaste = (e) => {
            e.stopPropagation();
            e.preventDefault();

            // Get pasted data via clipboard API
            const clipboardData = e.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');

            const currTextValue = contentEditableHelpers.nodesToText(nodeList, true);
            const currCaretOffsets = contentEditableHelpers.getCaretOffsets(inputElem, currTextValue.length);
            const newNodeList = contentEditableHelpers.insertNodes(
                contentEditableHelpers.textToNodes(pastedData, parameters),
                nodeList,
                currCaretOffsets
            );
            updateInputValueAndCaretPosition(currTextValue, newNodeList, currCaretOffsets);
        }

        const handleCut = (e) => {
            e.stopPropagation();
            e.preventDefault();
            const currTextValue = contentEditableHelpers.nodesToText(nodeList, true);
            const currCaretOffsets = contentEditableHelpers.getCaretOffsets(inputElem, currTextValue.length);
            if (currCaretOffsets.start !== currCaretOffsets.end) {

                const { slice: nodesToCut, rest: newNodeList } = contentEditableHelpers.sliceNodes(nodeList, currCaretOffsets);

                const clipboardData = e.clipboardData || window.clipboardData;
                clipboardData.setData('text/plain', contentEditableHelpers.nodesToText(nodesToCut));

                updateInputValueAndCaretPosition(currTextValue, newNodeList, currCaretOffsets);
            }
        }

        const handleCopy = (e) => {
            e.stopPropagation();
            e.preventDefault();
            const currTextValue = contentEditableHelpers.nodesToText(nodeList, true);
            const currCaretOffsets = contentEditableHelpers.getCaretOffsets(inputElem, currTextValue.length);
            if (currCaretOffsets.start !== currCaretOffsets.end) {

                const nodesToCopy = contentEditableHelpers.getSelectedNodes(nodeList, currCaretOffsets);

                const clipboardData = e.clipboardData || window.clipboardData;
                clipboardData.setData('text/plain', contentEditableHelpers.nodesToText(nodesToCopy));
            }
        }

        const handleDisregard = (e) => {
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('data-input')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('data-input')) {
                disregard = false;
            }
            if (disregard || wrapperElem?.parentElement !== el) {
                if (isActive) {
                    setIsActive(false);
                }
                if (inputElem) {
                    inputElem.blur();
                }
            } else if (!disregard && wrapperElem?.parentElement === el) {
                if (!isActive) {
                    setIsActive(true);
                }
            }
        }

        const handleDrop = function (e) {
            e.preventDefault();
        }

        const handleFocusOut = function (e) {
            const currTextValue = contentEditableHelpers.nodesToText(nodeList, true);
            caretOffsets.current = contentEditableHelpers.getCaretOffsets(inputElem, currTextValue.length);
        }

        document.addEventListener('click', handleDisregard);
        document.addEventListener('focusin', handleDisregard);

        if (inputElem) {
            inputElem.addEventListener('keydown', handleKeyDown);
            inputElem.addEventListener('keyup', handleKeyUp);
            inputElem.addEventListener("focusin", handleFocusIn);
            inputElem.addEventListener("focusout", handleFocusOut);
            inputElem.addEventListener("paste", handlePaste);
            inputElem.addEventListener("cut", handleCut);
            inputElem.addEventListener("copy", handleCopy);
            inputElem.addEventListener("drop", handleDrop);
        }
        return () => {
            document.removeEventListener('click', handleDisregard);
            document.removeEventListener('focusin', handleDisregard);
            if (inputElem) {
                inputElem.removeEventListener('keydown', handleKeyDown);
                inputElem.removeEventListener('keyup', handleKeyUp)
                inputElem.removeEventListener("focusin", handleFocusIn);
                inputElem.removeEventListener("focusout", handleFocusOut);
                inputElem.removeEventListener("paste", handlePaste);
                inputElem.removeEventListener("cut", handleCut);
                inputElem.removeEventListener("copy", handleCopy);
                inputElem.removeEventListener("drop", handleDrop);
            }
        }
    }, [inputRef.current, wrapperRef.current, suggestions, parameters, nodeList, isActive]);

    return (
        <div {...{ ...rest, className: "data-input relative " + (rest.className || '') }}>
            <div ref={wrapperRef} className="relative">
                {nodeList.length === 1 && nodeList[0].type === contentEditableHelpers.NODE_TYPES.ZERO_WIDTH_TEXT ? <div className="overflow-ellipsis sm:text-sm pointer-events-none text-gray-500 absolute py-1 px-2 max-w-full top-0 left-0">{placeholder}</div> : null}
                <div ref={inputRef} suppressContentEditableWarning={true} autoCapitalize="false" spellCheck="false" autoCorrect="false" contentEditable="true" className={`${isActive ? 'ring-2 ring-secondary-dark' : ''} h-full focus:outline-none bg-gray-100 rounded-md cursor-text sm:text-sm leading-normal py-1 px-2 w-full break-all tracking-normal whitespace-pre-wrap ${multipleLines ? `min-h-[160px]` : 'md:min-h-[1.75rem] min-h-[2rem]'}`}>{
                    nodeList.map((node, i) => {
                        if (node.type === contentEditableHelpers.NODE_TYPES.PARAMETER) {
                            return <span suppressContentEditableWarning={true} contentEditable="false" className="before:inline-block after:inline-block break-all pointer-events-none whitespace-pre-wrap tracking-normal" key={`${i}-${node.value.length}`}>
                                <span className={`${node.param.dynamic ? 'text-primary-dark' : 'text-secondary-dark'} ${node.param.meta ? 'border-dashed' : 'border-solid'} bg-gray-100 border sm:text-sm font-[500] border-gray-300 rounded-l-lg px-[8px] !leading-none`}>{node.param.stepIndex + 1}</span>
                                &#x200B;
                                <span className={`${node.param.meta ? 'border-dashed' : 'border-solid'} text-black bg-white border-t border-r rounded-r-lg border-b border-gray-300 pl-[5px] pr-[8px] break-all whitespace-pre-wrap tracking-normal !leading-none sm:text-sm font-[500]`}>{node.param.dataKey}</span>
                            </span>
                        }
                        return <span key={`${i}-${node.value.length}`} className="sm:text-sm break-all whitespace-pre-wrap tracking-normal inline">{node.value}</span>;
                    })}{nodeList.length > 0 && nodeList[nodeList.length - 1].value.endsWith('\n') ? <>&#xfeff;</> : null}</div>
            </div>
            {isActive && suggestions.length > 0 ?
                <div className="w-full absolute min-w-[200px] max-h-[300px] overflow-y-auto overflow-x-hidden bg-white z-40 rounded-md border-2 left-0 mt-1 top-[100%]">
                    <div className="px-2 py-1">
                        <div className="flex bg-gray-100 flex-nowrap p-0.5 text-xs rounded-md">
                            <button className={`nav-tab ${!isDynamicParameter ? '!text-secondary-dark nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsDynamicParameter(false); setSuggestionStep(-1); }}>Static</button>
                            <button className={`nav-tab ${isDynamicParameter ? '!text-primary-dark nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsDynamicParameter(true); setSuggestionStep(-1); }}>Dynamic</button>
                        </div>
                    </div>
                    {suggestionStep > -1 ? <button className={`text-sm font-semibold px-2 py-1 w-full block text-left`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSuggestionStep(-1); }}><strong className={`${isDynamicParameter ? 'text-primary-dark' : 'text-secondary-dark'}`}>{suggestions[suggestionStep].step ? suggestions[suggestionStep].step.index + 1 : '0'}.</strong> {getSuggestionItemTitle(suggestions[suggestionStep])}</button> : null}
                    <ul className="max-h-[300px] block overflow-y-auto overflow-x-hidden">
                        {suggestionStep > -1 ? suggestions[suggestionStep].params.filter((param) => {
                            return !isDynamicParameter || (isDynamicParameter && (param.type === 'cell' || param.type === 'field'))
                        }).map((param, i) => {
                            return <li className="block w-full" key={`${param.dataSourceType}-${param.dataKey}`}>
                                <a href="#" onClick={handleSuggestionItemClick(suggestionStep, i)} className="hover:bg-gray-100 active:bg-gray-200 py-1 px-2 w-full flex justify-between items-start">
                                    <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{param.dataKey}</span>
                                    <span className="flex-none leading-5 text-xs">{param.type}</span>
                                </a>
                            </li>;
                        }) :
                            suggestions.map((item, i) => {
                                return item.params.length > 0 ? <li className="block w-full" key={item.dataSourceKey}>
                                    <a href="#" onClick={handleSuggestionItemClick(i)} className="hover:bg-gray-50 active:bg-gray-100 py-1 px-2 w-full flex justify-between items-start">
                                        <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal"><strong className={`${isDynamicParameter ? 'text-primary-dark' : 'text-secondary-dark'}`}>{item.step ? item.step.index + 1 : '0'}.</strong> {getSuggestionItemTitle(item)}</span>
                                        <span className="flex-none leading-5 text-xs">({item.params.filter((param) => { return !isDynamicParameter || (isDynamicParameter && (param.type === 'cell' || param.type === 'field')) }).length})</span>
                                    </a>
                                </li> : null
                            })}
                    </ul>
                </div>
                : null}
        </div>
    );
}   

function getSuggestionItemTitle(suggestion){
    if (suggestion.dataSourceKey === DATA_SOURCE_KEYS.INPUT){
        return 'Workflow input';
    }
    if (suggestion.dataSourceKey === DATA_SOURCE_KEYS.PARAMETER){
        return 'Workflow parameters';
    }
    if (suggestion.dataSourceKey === DATA_SOURCE_KEYS.ANY){
        return 'Data';
    }
    if (suggestion.step){
        return suggestion.step.title;
    }
    return '';
}