import valueHelpers from "./value";
import { ROOT_TABLE_KEY, MASTER_LIST_TABLE_KEY, LIST_ITEM_COLUMN_NAME } from "../constants/database";
import { VALUE_TYPES } from "../constants/value-types";
import keyHelpers from "./key";

export function databaseToDocument(database) {
    const tables = database?.tables;
    if (tables) {
        const table = (
            (tables[ROOT_TABLE_KEY].columns?.length || 0) === 0 &&
            (tables[ROOT_TABLE_KEY].children?.length || 0) === 1 &&
            tables[ROOT_TABLE_KEY].children[0] === MASTER_LIST_TABLE_KEY
        ) ? tables[MASTER_LIST_TABLE_KEY] : tables[ROOT_TABLE_KEY];

        if (table) {
            const allItems = [];
            var item, colIndex, childIndex;
            for (var rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {
                item = {};

                if (table.columns) {
                    for (colIndex = 0; colIndex < table.columns.length; colIndex++) {
                        if (table.rows[rowIndex].values[colIndex].type !== VALUE_TYPES.NULL) {
                            item[table.columns[colIndex].name] = valueHelpers.convertValue(table.rows[rowIndex].values[colIndex].text, table.rows[rowIndex].values[colIndex].type);
                        }
                    }
                }

                if (table.children) {
                    for (childIndex = 0; childIndex < table.children.length; childIndex++) {
                        if (tables[table.children[childIndex]]) {
                            item[tables[table.children[childIndex]].name] = getRelatedItems(tables, tables[table.children[childIndex]], table.rows[rowIndex]);
                        }
                    }
                }

                allItems.push(item);
            }

            // is root table?
            if (table.key === ROOT_TABLE_KEY) {
                return allItems[0];
            }
            return allItems
        }
    }
    return null;
}

function getRelatedItems(tables, table, row) {
    if (table) {
        const allItems = [],
            tableHasColumns = table.columns && table.columns.length > 0,
            tableHasChildren = table.children && table.children.length > 0,
            tableHasSingleColumn = table.columns && table.columns.length === 1;

        var item, colIndex, childIndex;
        for (var rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {

            if (validatePaternity(row, table.rows[rowIndex])) {

                // check if the table is a list of values
                if ((tableHasChildren && !tableHasColumns) ||
                    (!tableHasChildren && tableHasSingleColumn && table.columns[0].name === LIST_ITEM_COLUMN_NAME)) {

                    if (tableHasSingleColumn) {
                        allItems.push(valueHelpers.convertValue(table.rows[rowIndex].values[0].text, table.rows[rowIndex].values[0].type));
                    } else if (tableHasChildren) {
                        for (childIndex = 0; childIndex < table.children.length; childIndex++) {
                            allItems.push(getRelatedItems(tables, tables[table.children[childIndex]], table.rows[rowIndex]));
                        }
                    }

                } else {
                    item = {};

                    if (tableHasColumns) {
                        for (colIndex = 0; colIndex < table.columns.length; colIndex++) {
                            if (table.rows[rowIndex].values[colIndex].type !== VALUE_TYPES.NULL) {
                                item[table.columns[colIndex].name] = valueHelpers.convertValue(table.rows[rowIndex].values[colIndex].text, table.rows[rowIndex].values[colIndex].type);
                            }
                        }
                    }

                    if (tableHasChildren) {
                        for (childIndex = 0; childIndex < table.children.length; childIndex++) {
                            if (tables[table.children[childIndex]]) {
                                item[tables[table.children[childIndex]].name] = getRelatedItems(tables, tables[table.children[childIndex]], table.rows[rowIndex]);
                            }
                        }
                    }

                    allItems.push(item);
                }
            }
        }
        return allItems
    }
    return null;
}

function validatePaternity(parent, child) {
    if (child.parent_indexes && child.parent_indexes.length > 0) {
        if (parent.parent_indexes && parent.parent_indexes.length > 0) {
            if (child.parent_indexes.length > parent.parent_indexes.length) {
                for (var i = 0; i < parent.parent_indexes.length; i++) {
                    if (parent.parent_indexes[i] !== child.parent_indexes[i]) {
                        return false;
                    }
                }
                return parent.index === child.parent_indexes[child.parent_indexes.length - 1];
            }
        } else if (child.parent_indexes.length === 1) {
            return parent.index === child.parent_indexes[0];
        }
    }
    return parent.parent_indexes === null || parent.parent_indexes.length === 0;
}

export function databaseToTableTree(database) {
    const table = database?.tables ? database.tables[ROOT_TABLE_KEY] : null;
    if (table) {
        const rootNode = { name: table.name, key: table.key, parent: null, depth: 0 };
        rootNode.children = getChildrenNodes(database.tables, table, rootNode, 1);
        return rootNode;
    }
    return null;
}

function getChildrenNodes(tables, table, parentNode, depth) {
    if (table.children && table.children.length > 0) {
        const childrenNodes = [];
        for (var i = 0; i < table.children.length; i++) {
            const node = { key: table.children[i], parent: parentNode, depth: depth };
            if (tables[table.children[i]]) {
                node.name = tables[table.children[i]].name;
                node.children = getChildrenNodes(tables, tables[table.children[i]], node, depth + 1);
            }
            childrenNodes.push(node);
        }
        return childrenNodes;
    }
    return null
}

export function selectMainTable(database) {
    const tables = database?.tables;
    if (tables) {
        if ((tables[ROOT_TABLE_KEY].columns?.length || 0) === 0 &&
            (tables[ROOT_TABLE_KEY].children?.length || 0) === 1 &&
            tables[ROOT_TABLE_KEY].children[0] === MASTER_LIST_TABLE_KEY) {
            return tables[MASTER_LIST_TABLE_KEY];
        }
        if (tables.hasOwnProperty(ROOT_TABLE_KEY)) {
            if ((tables[ROOT_TABLE_KEY].columns?.length || 0) > 0) {
                return tables[ROOT_TABLE_KEY];
            }
        }
        var tableKey = null;
        for (var tk in tables) {
            if (tk !== ROOT_TABLE_KEY) {
                if (tableKey === null) {
                    tableKey = tk;
                } else if (tk.length < tableKey.length) {
                    tableKey = tk;
                }
            }
        }
        if (tableKey !== null) {
            return tables[tableKey];
        }
        return tables[ROOT_TABLE_KEY];
    }
    return null;
}

export function selectColumnsFromTable({ table, maxColumnNum = 10 }) {
    const columns = {};
    if (table?.columns) {
        for (var i = 0; i < table.columns.length; i++) {
            if (i < maxColumnNum || maxColumnNum === -1) {
                columns[table.columns[i].name] = true;
            } else {
                columns[table.columns[i].name] = false;
            }
        }
    }
    return columns;
}

export function filterTree(tree, keyword) {
    let filteredTree = { ...tree, children: [] };
    filterChildrenNodes(tree, filteredTree, keyword);
    return filteredTree;
}

function filterChildrenNodes(node, filteredNode, keyword) {
    if ((node?.children?.length || 0) > 0) {
        var newFilteredNode;
        for (var i = 0; i < node.children.length; i++) {
            if (isNodeRelatedToKeyword(node.children[i], keyword)) {
                newFilteredNode = { ...node.children[i], children: [] };
                filteredNode.children.push(newFilteredNode);
                filterChildrenNodes(node.children[i], newFilteredNode, keyword)
            }
        }
    }
}

function isNodeRelatedToKeyword(node, keyword) {
    if (node.name.toLowerCase().indexOf(keyword) !== -1) {
        return true;
    }
    if ((node.children?.length || 0) > 0) {
        for (var i = 0; i < node.children.length; i++) {
            if (isNodeRelatedToKeyword(node.children[i], keyword)) {
                return true;
            }
        }
    }
    return false;
}

export function findRowsByKey(db, key) {
    if (db?.tables) {
        const indexParams = keyHelpers.extractIndexParamsFromKey(key);
        // if key has no index params then it belongs to the root table
        if (indexParams.length === 0) {
            const table = db.tables[ROOT_TABLE_KEY];
            for (var i = 0; i < table.rows.length; i++) {
                table.rows[i].table = table;
            }
            return table.rows;
        } else {
            const table = db.tables[indexParams[indexParams.length - 1].tableKey];
            if (table?.rows) {
                const rows = [];
                for (var i = 0; i < table.rows.length; i++) {
                    if (doRowIndexesFitInto(table.rows[i], indexParams, false)) {
                        table.rows[i].table = table;
                        rows.push(table.rows[i]);
                    }
                }
                return rows;
            }
        }
    }
    return [];
}

function doRowIndexesFitInto(row, indexParams, compareParentIndexesOnly) {
    var numOfIndexesToCompare = row?.parent_indexes?.length || 0;
    if (!compareParentIndexesOnly) {
        numOfIndexesToCompare++;
    }
    if (numOfIndexesToCompare <= (indexParams?.length || 0)) {
        for (var i = 0; i < numOfIndexesToCompare; i++) {
            if (i + 1 == numOfIndexesToCompare && !compareParentIndexesOnly) {
                //the last index is the row index
                if (!keyHelpers.isIndexInRange(row.index, indexParams[i].valueRange)) {
                    return false;
                }
            } else if (!keyHelpers.isIndexInRange(row.parent_indexes[i], indexParams[i].valueRange)) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export function findColumnIndex(columnName, table) {
    if (table?.columns) {
        for (var i = 0; i < table.columns.length; i++) {
            if (table.columns[i].name == columnName) {
                return i;
            }
        }
    }
    return -1;
}

export function getColumnValues(database, columnKey) {
    if (database?.tables && columnKey) {
        if (keyHelpers.detectKeyType(columnKey) === "column") {
            const { tableKey, columnName } = keyHelpers.splitKeyIntoTableKeyAndColumnName(columnKey);
            const table = database.tables[tableKey];
            if (table?.rows) {
                const columIndex = findColumnIndex(columnName, table);
                if (columIndex !== -1) {
                    const result = [];
                    for (var i = 0; i < table.rows.length; i++) {
                        result.push(table.rows[i].values[columIndex].text);
                    }
                    return result;
                }
            }
        }
    }
    return [];
}