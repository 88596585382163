import { useEffect, useState, useRef } from "react";
import { useJsonConverter } from "../../hooks/json-converter";
import keyHelpers from "../../helpers/key";
import { selectMainTable, selectColumnsFromTable, databaseToTableTree } from "../../helpers/database";
import Loader from "../brand/Loader";
import ColumnSelector from '../common/ColumnSelector';
import TableSelector from '../common/TableSelector';
import Dropdown from '../common/Dropdown';
import DataSchema from "../workflow/DataSchema";
import { ROOT_TABLE_KEY } from "../../constants/database";

function useTable(data) {
    const [table, setTable] = useState(() => {
        return selectMainTable(data);
    });

    return [table, setTable];
}

export default function JsonToTables({ title, json, onError }) {
    const tableRef = useRef(null);
    const currItemKeyRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const { responseData: data, errorResponse } = useJsonConverter({ json });

    const [selectedTable, setSelectedTable] = useTable(data);
    const [selectedColumns, setSelectedColumns] = useState(selectColumnsFromTable({ table: selectedTable }));

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    useEffect(() => {
        if (mounted.current && data !== null) {
            const firstTable = selectMainTable(data);
            setSelectedTable(firstTable);
            setSelectedColumns(selectColumnsFromTable({ table: firstTable }));
            setIsLoading(false);
        }
    }, [data])

    useEffect(() => {
        if (typeof onError === 'function' && errorResponse !== null) {
            onError(errorResponse);
            setIsLoading(false);
        }
    }, [errorResponse])

    useEffect(() => {
        const tableElem = tableRef.current;
        if (tableElem) {
            var idCell = tableElem.querySelector('tbody th');
            if (idCell) {
                tableElem.querySelector('thead th').style.width = `${idCell.offsetWidth}px`;
            }
        }
    });

    const [isTableSelectorVisible, setTableSelectorVisibility] = useState(false);

    const toggleTableSelector = (e) => {
        e.preventDefault();
        setTableSelectorVisibility(!isTableSelectorVisible);
    }

    const closeTableSelector = () => {
        setTableSelectorVisibility(false);
    }

    const selectTable = (tableKey) => {
        const tables = data?.tables;
        if (tables) {
            if (tableKey in tables) {
                const newSelectedTable = tables[tableKey];
                setSelectedColumns(selectColumnsFromTable({ table: newSelectedTable }));
                setSelectedTable(newSelectedTable);
            }
        }
    }

    const [isColumnSelectorVisible, setColumnSelectorVisibility] = useState(false);

    const toggleColumnSelector = (e) => {
        e.preventDefault();
        setColumnSelectorVisibility(!isColumnSelectorVisible);
    }

    const closeColumnSelector = () => {
        setColumnSelectorVisibility(false);
    }

    const selectColumns = (newSelectedColumns) => {
        setSelectedColumns(newSelectedColumns);
    }

    const numberOfSelectedColumns = () => {
        var i = 0;
        for (var column in selectedColumns) {
            if (selectedColumns[column]) {
                i++;
            }
        }
        return i;
    }

    const handleItemHighlight = (e, isItemHighlighted, item) => {
        const tableElem = tableRef.current;
        const currItemKeyElem = currItemKeyRef.current;
        if (tableElem) {
            const highlightedItemColor = 'bg-gray-100';
            if (item.type === 'table') {
                const tableRows = tableElem.getElementsByTagName('tr');
                for (var i = 0; i < tableRows.length; i++) {
                    if (isItemHighlighted) {
                        tableRows[i].classList.add(highlightedItemColor);
                    } else {
                        tableRows[i].classList.remove(highlightedItemColor);
                    }
                }
                const tableHeaders = tableElem.getElementsByTagName('tbody')[0].getElementsByTagName('th');
                for (var i = 0; i < tableHeaders.length; i++) {
                    tableHeaders[i].classList.remove(isItemHighlighted ? 'bg-gray-50' : highlightedItemColor);
                    tableHeaders[i].classList.add(isItemHighlighted ? highlightedItemColor : 'bg-gray-50');
                }
            } else if (item.type === 'row') {
                var rowIndexElem = e.target;
                while (rowIndexElem && rowIndexElem.tagName.toLowerCase() !== 'th') {
                    rowIndexElem = rowIndexElem.parentElement;
                }
                if (rowIndexElem && rowIndexElem.tagName.toLowerCase() === 'th') {
                    rowIndexElem.classList.remove(isItemHighlighted ? 'bg-gray-50' : highlightedItemColor);
                    rowIndexElem.classList.add(isItemHighlighted ? highlightedItemColor : 'bg-gray-50');
                    var rowElem = rowIndexElem;
                    while (rowElem && rowElem.tagName.toLowerCase() !== 'tr') {
                        rowElem = rowElem.parentElement;
                    }
                    if (rowElem && rowElem.tagName.toLowerCase() === 'tr') {
                        if (isItemHighlighted) {
                            rowElem.classList.add(highlightedItemColor);
                        } else {
                            rowElem.classList.remove(highlightedItemColor);
                        }
                    }
                }
            } else if (item.type === 'column') {
                var columnElem = e.target;
                while (columnElem && columnElem.tagName.toLowerCase() !== 'th') {
                    columnElem = columnElem.parentElement;
                }
                if (columnElem && columnElem.tagName.toLowerCase() === 'th') {
                    if (isItemHighlighted) {
                        columnElem.classList.add(highlightedItemColor);
                    } else {
                        columnElem.classList.remove(highlightedItemColor);
                    }

                    const columnElems = columnElem.parentElement.getElementsByTagName('th');
                    var columnIndex = -1;
                    for (var i = 0; i < columnElems.length; i++) {
                        if (columnElems[i] === columnElem) {
                            columnIndex = i - 1;
                            break;
                        }
                    }
                    if (columnIndex >= 0) {
                        const rowElems = tableElem.getElementsByTagName('tr');
                        var cells;
                        for (var i = 0; i < rowElems.length; i++) {
                            cells = rowElems[i].getElementsByTagName('td');
                            if (columnIndex < cells.length) {
                                if (isItemHighlighted) {
                                    cells[columnIndex].classList.add(highlightedItemColor);
                                } else {
                                    cells[columnIndex].classList.remove(highlightedItemColor);
                                }
                            }
                        }
                    }
                }
            } else if (item.type === 'cell') {
                var tdElem = e.target;
                while (tdElem && tdElem.tagName.toLowerCase() !== 'td') {
                    tdElem = tdElem.parentElement;
                }
                if (tdElem && tdElem.tagName.toLowerCase() === 'td') {
                    if (isItemHighlighted) {
                        tdElem.classList.add(highlightedItemColor);
                    } else {
                        tdElem.classList.remove(highlightedItemColor);
                    }
                }
            } else if (item.type === 'field') {
                var columnElem = e.target;
                while (columnElem && columnElem.tagName.toLowerCase() !== 'th' && columnElem.tagName.toLowerCase() !== 'td') {
                    columnElem = columnElem.parentElement;
                }
                if (columnElem && (columnElem.tagName.toLowerCase() === 'th' || columnElem.tagName.toLowerCase() === 'td')) {
                    if (isItemHighlighted) {
                        columnElem.classList.add(highlightedItemColor);
                    } else {
                        columnElem.classList.remove(highlightedItemColor);
                    }

                    const columnElems = columnElem.parentElement.getElementsByTagName(columnElem.tagName.toLowerCase());
                    var columnIndex = -1;
                    for (var i = 0; i < columnElems.length; i++) {
                        if (columnElems[i] === columnElem) {
                            columnIndex = i;
                            break;
                        }
                    }
                    if (columnIndex >= 0) {
                        const rowElems = tableElem.getElementsByTagName('tr');
                        var cells;
                        for (var i = 0; i < rowElems.length; i++) {
                            if (i === 0) {
                                cells = rowElems[i].getElementsByTagName('th');
                                if (columnIndex < cells.length) {
                                    if (isItemHighlighted) {
                                        cells[columnIndex].classList.add(highlightedItemColor);
                                    } else {
                                        cells[columnIndex].classList.remove(highlightedItemColor);
                                    }
                                }
                            }
                            cells = rowElems[i].getElementsByTagName('td');
                            if (columnIndex < cells.length) {
                                if (isItemHighlighted) {
                                    cells[columnIndex].classList.add(highlightedItemColor);
                                } else {
                                    cells[columnIndex].classList.remove(highlightedItemColor);
                                }
                            }
                        }
                    }
                }
            }
        }

        if (currItemKeyElem) {
            currItemKeyElem.innerHTML = (isItemHighlighted) ? item.key : '';
        }
    }

    const handleItemMouseEnter = (item) => (e) => {
        handleItemHighlight(e, true, item);
    }

    const handleItemMouseLeave = (item) => (e) => {
        handleItemHighlight(e, false, item);
    }

    const [isDataSchemaVisible, setIsDataSchemaVisible] = useState(false);
    const showDataSchema = (e) => {
        e.preventDefault();
        setIsDataSchemaVisible(true);
    }
    const hideDataSchema = () => {
        setIsDataSchemaVisible(false);
    }

    return !isLoading ? <div className="flex-1 w-full shadow-sm rounded-md relative">
        {selectedTable && <>
            <header className='flex py-1 px-1 flex-nowrap max-w-full rounded-t-md bg-gray-200 justify-between'>
                <div className="flex-1 table-selector relative">
                    <button onClick={toggleTableSelector} className="active:bg-gray-300 hover:bg-gray-100 pl-2 rounded-md text-sm selector-icon break-all appearance-none text-gray-700">{selectedTable.key || selectedTable.name}</button>
                    {isTableSelectorVisible ? <TableSelector value={selectedTable.key} tableTree={databaseToTableTree(data)} onClose={closeTableSelector} onChange={selectTable} /> : null}
                </div>
                <div className="flex-none relative">
                    <button onClick={toggleColumnSelector} className="active:bg-gray-300 hover:bg-gray-100 pl-2 rounded-md text-sm selector-icon appearance-none text-gray-700">{numberOfSelectedColumns()} of {selectedTable?.columns?.length || 0} columns</button>
                    {isColumnSelectorVisible ? <ColumnSelector value={selectedColumns} onClose={closeColumnSelector} onChange={selectColumns} /> : null}
                </div>
                <Dropdown className="flex-none relative">
                    <button onClick={showDataSchema} className="min-w-[140px] py-1 text-left block w-full bg-gray-100 hover:bg-gray-50 active:bg-gray-200 px-2 text-sm">Data schema</button>
                </Dropdown>
            </header>
            <div className="max-w-full overflow-x-auto bg-white">
                <table ref={tableRef} className="bg-white border-collapse">
                    <thead className="block bg-gray-50">
                        <tr className="border-b">
                            {(() => {
                                if (selectedTable.key !== ROOT_TABLE_KEY) {
                                    const item = {
                                        key: selectedTable.key,
                                        type: 'table'
                                    }
                                    return <th key={`${item.key}`} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} className="px-2 py-1 cursor-pointer border-r text-left font-semibold text-xs">$</th>
                                }
                                return null;
                            })()}
                            {selectedTable.columns && selectedTable.columns.filter((column) => selectedColumns[column.name]).map((column, i) => {
                                const columnNameFields = keyHelpers.extractFieldsFromColumnName(column.name);
                                const item = {
                                    key: keyHelpers.appendColumnNameToKey(selectedTable.key, column.name),
                                    type: selectedTable.key === ROOT_TABLE_KEY ? 'field' : 'column'
                                }
                                return <th key={`${item.type}_${i.toString()}`} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} className="border-r cursor-pointer max-w-48 text-left py-1 font-semibold text-xs">
                                    {columnNameFields.map((s, j) => <span key={j.toString()} className={`px-2 w-48 line-clamp-2 break-all ${j + 1 === columnNameFields.length ? 'text-black' : 'text-gray-500'}`}>{j === 0 ? s : `.${s}`}</span>)}
                                </th>
                            })}
                        </tr>
                    </thead>
                    <tbody className="block max-h-72 overflow-x-hidden overflow-y-auto">
                        {selectedTable.rows.map((row, i) => {
                            return <tr key={i.toString()} className="border-b">
                                {(() => {
                                    if (selectedTable.key !== "") {
                                        const item = {
                                            key: row.key,
                                            type: 'row'
                                        }
                                        return <th key={`${item.type}_${i.toString()}`} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} className="bg-gray-50 cursor-pointer border-r px-2 py-1 text-xs">{row.index}</th>
                                    }
                                    return null;
                                })()}
                                {(selectedTable.columns && selectedTable.columns.length) > 0 && row.values.map((value, j) => {
                                    const item = {
                                        key: keyHelpers.appendColumnNameToKey(row.key, selectedTable.columns[j].name),
                                        type: selectedTable.key === ROOT_TABLE_KEY ? 'field' : 'cell'
                                    };
                                    return selectedColumns[selectedTable.columns[j].name] ?
                                        <td key={`${item.type}_${i.toString()}-${j.toString()}`} title={(typeof value?.text === 'undefined' || value?.type === 5) ? 'null' : value?.text} onMouseEnter={handleItemMouseEnter(item)} onMouseLeave={handleItemMouseLeave(item)} className="border-r cursor-pointer max-w-48 text-xs">
                                            <span className="px-2 py-1 min-h-[20px] w-48 line-clamp-3 break-all">{(typeof value?.text === 'undefined' || value?.type === 5) ? 'null' : value?.text}</span>
                                        </td> : null;
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>}
        <div className="bg-gray-200 min-h-[24px] pl-3 pr-2 py-1 rounded-b-md text-xs flex items-center">
            <span className="flex-1 min-w-0 break-all" ref={currItemKeyRef}></span>
        </div>
        {isDataSchemaVisible && <DataSchema source={{ data: data, tableKey: selectedTable?.key, title: title }} onClose={hideDataSchema} />}
    </div> : <div className="pt-36 text-center">
        <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
        <p>Please wait...</p>
    </div>
}