import { useEffect, useRef, useState } from "react"
import { SCREEN_SIZES } from "../../constants/screen-sizes";
import { ReactComponent as SmallScreenIcon } from '../../icons/screen/small.svg';
import { ReactComponent as MediumScreenIcon } from '../../icons/screen/medium.svg';
import { ReactComponent as LargeScreenIcon } from '../../icons/screen/large.svg';

const MAX_NUM_OF_CELLS_HORIZONTALLY = SCREEN_SIZES.LARGE.CELLS;
const MAX_NUM_OF_CELLS_VERTICALLY = MAX_NUM_OF_CELLS_HORIZONTALLY / 2;

export default function ItemSizePicker({ value, onChange }) {
    const pickerElemRef = useRef(null);
    const getCellSize = () => {
        const pickerElem = pickerElemRef.current;
        if (pickerElem) {
            return (pickerElem.offsetWidth - 2) / MAX_NUM_OF_CELLS_HORIZONTALLY;
        }
        return 0;
    }
    const [cellSize, setCellSize] = useState(() => getCellSize());

    useEffect(() => {
        setCellSize(getCellSize());
        const onWindowResize = (e) => {
            setCellSize(getCellSize());
        }
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        }
    }, [pickerElemRef.current]);

    const [selectedCell, setSelectedCell] = useState(() => {
        const sc = { rowIndex: -1, colIndex: -1 };
        if (value?.height) {
            sc.rowIndex = value.height - 1;
        }
        if (value?.width) {
            sc.colIndex = value.width - 1;
        }
        return sc;
    });

    const onCellClick = (rowIndex, colIndex) => {
        return (e) => {
            e.preventDefault();
            if (selectedCell.rowIndex !== rowIndex || selectedCell.colIndex !== colIndex) {
                setSelectedCell({ rowIndex, colIndex });
                onChange({ width: colIndex + 1, height: rowIndex + 1 });
            }
        }
    }
    const [hoveredCell, setHoveredCell] = useState(() => {
        return { rowIndex: -1, colIndex: -1 };
    });
    const onMouseEnterCell = (rowIndex, colIndex) => {
        return (e) => {
            e.preventDefault();
            if (hoveredCell.rowIndex !== rowIndex || hoveredCell.colIndex !== colIndex) {
                setHoveredCell({ rowIndex, colIndex });
            }
        }
    }

    const onMouseLeaveCell = (e) => {
        if (e.relatedTarget) {
            if (!e.relatedTarget.classList.contains("cell")) {
                setHoveredCell({ rowIndex: -1, colIndex: -1 });
            }
        }
    }

    return <div ref={pickerElemRef} className="overflow-hidden w-full border-l border-t border-gray-400">
        <div className="flex items-stretch justify-items-stretch flex-row border-b border-gray-400">
            <div className="flex-none text-center border-r border-gray-400" style={{ width: `${cellSize * SCREEN_SIZES.SMALL.CELLS}px` }}><SmallScreenIcon className="inline-block h-4" /></div>
            <div className="flex-none text-center border-r border-gray-400" style={{ width: `${cellSize * (SCREEN_SIZES.MEDIUM.CELLS - SCREEN_SIZES.SMALL.CELLS)}px` }}><MediumScreenIcon className="inline-block h-4" /></div>
            <div className="flex-none text-center border-r border-gray-400" style={{ width: `${cellSize * (SCREEN_SIZES.LARGE.CELLS - SCREEN_SIZES.MEDIUM.CELLS)}px` }}><LargeScreenIcon className="inline-block h-4" /></div>
        </div>
        {cellSize > 0 &&
            [...Array(MAX_NUM_OF_CELLS_VERTICALLY)].map((_, rowIndex) => {
                return <div key={`row-${rowIndex}`} className="w-full overflow-hidden">
                    {[...Array(MAX_NUM_OF_CELLS_HORIZONTALLY)].map((_, colIndex) => {
                        const notAllSelectedCellsAreHovered = selectedCell.colIndex > hoveredCell.colIndex || selectedCell.rowIndex > hoveredCell.rowIndex;
                        const isCellSelected = rowIndex <= selectedCell.rowIndex && colIndex <= selectedCell.colIndex;
                        const isCellHovered = rowIndex <= hoveredCell.rowIndex && colIndex <= hoveredCell.colIndex;
                        return <span key={`cell-${colIndex}`} onClick={onCellClick(rowIndex, colIndex)} onMouseEnter={onMouseEnterCell(rowIndex, colIndex)} onMouseLeave={onMouseLeaveCell} className={`cell ${isCellSelected && !(isCellHovered && notAllSelectedCellsAreHovered) ? "bg-primary border-white" : isCellHovered ? "bg-primary-lighter border-gray-400" : "border-gray-400"} block m-0 p-0 float-left border-r border-b`} style={{ width: `${cellSize}px`, height: `${cellSize}px` }} ></span>
                    })}
                </div>
            })}
    </div>
}