import { React, useState, useEffect, useRef, useCallback } from "react";
import useForm from "../../hooks/form";
import valueHelpers from "../../helpers/value";
import { FORM_ERROR_KEY } from "../../config";
import { getFileTypeName } from './FileTypeNames';
import LocationPicker from "./LocationPicker";
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';
import { FILE_PERMISSIONS, FILE_TYPES } from "../../constants/file";
import { WIDGET_TYPES } from "../../constants/widget";
import { copyArray } from "../../helpers/array";
import UserPicker from "../common/UserPicker";
import { useTeamMemberGroups } from "../../hooks/team-member-group";

export default function FileActionDialog({ projectKey, action, selectedFileKeys, directoryKey, onRename, onCopy, onChangePermissions, onMove, onDelete, onCreate, onCancel }) {
    const dialogRef = useRef(null);
    const [isVisible, setIsVisible] = useState(action !== null);

    useEffect(() => {
        setIsVisible(action !== null);
    }, [action]);

    const cancel = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
        setIsVisible(false);
    }, [onCancel]);

    useEffect(() => {

        const handleDisregard = (e) => {
            const dialogElem = dialogRef.current;
            if (isVisible) {
                var el = e.target, disregard = true;
                while (el && !el.classList.contains('file-action-dialog')) {
                    el = el.parentElement;
                }
                if (el && el.classList.contains('file-action-dialog')) {
                    disregard = false;
                }
                if (disregard || el !== dialogElem) {
                    cancel();
                }
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [isVisible, cancel])

    const dialogContent = () => {
        if (action) {
            switch (action.name) {
                case 'create':
                    return <CreateActionDialog action={action} onCreate={onCreate} onCancel={cancel} />
                case 'rename':
                    return <RenameActionDialog action={action} onRename={onRename} onCancel={cancel} selectedFileKeys={selectedFileKeys} />
                case 'delete':
                    return <DeleteActionDialog onDelete={onDelete} onCancel={cancel} selectedFileKeys={selectedFileKeys} />
                case 'move':
                    return <MoveActionDialog projectKey={projectKey} directoryKey={directoryKey} selectedFileKeys={selectedFileKeys} onMove={onMove} onCancel={cancel} />
                case 'copy':
                    return <CopyActionDialog projectKey={projectKey} directoryKey={directoryKey} selectedFileKeys={selectedFileKeys} onCopy={onCopy} onCancel={cancel} />
                case 'change_permissions':
                    return <ChangePermissionsActionDialog projectKey={projectKey} action={action} onChangePermissions={onChangePermissions} onCancel={cancel} selectedFileKeys={selectedFileKeys} />
                default:
                    return null;
            }
        }
        return null
    }

    return <div className={`fixed px-2 top-0 left-0 bottom-0 right-0 bg-gray-500/50 ${isVisible ? '' : 'hidden'}`}>
        <div ref={dialogRef} className="file-action-dialog w-full max-w-[280px] md:max-w-[400px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg border">
            {dialogContent()}
        </div>
    </div>
}

function CreateActionDialog({ action, onCreate, onCancel }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return { name: '', type: action?.data?.fileType };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            if (action?.data?.fileType === FILE_TYPES.WORKFLOW.CODE) {
                submittedData.content = JSON.stringify({
                    title: submittedData.name,
                    steps: [],
                    parameters: [],
                    run_scope: "global",
                });
            } else if (action?.data?.fileType === FILE_TYPES.WIDGET.CODE) {
                submittedData.content = JSON.stringify({
                    title: submittedData.name,
                    type: WIDGET_TYPES.UNSPECIFIED,
                    arguments: [],
                });
            } else if (action?.data?.fileType === FILE_TYPES.DASHBOARD.CODE) {
                submittedData.content = JSON.stringify({
                    title: submittedData.name,
                    containers: [],
                });
            }
            onCreate({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    return <form className="p-2" onSubmit={submit}>
        <h2 className="mb-1">Create a new {getFileTypeName(data?.type)}</h2>
        <input className="input input--sm input--full" type="text" value={valueHelpers.textValue(data?.name)} required placeholder={`Enter new ${getFileTypeName(data?.type)}'s name`} onChange={handleChange('name')} />
        {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        <div className="mt-2 text-right">
            <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
            <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Create"}</button>
        </div>
    </form>
}

function RenameActionDialog({ action, onRename, onCancel, selectedFileKeys }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return { new_name: action?.data?.name };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onRename({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    return <form onSubmit={submit}>
        <div className="p-2">
            <h2 className="mb-1">Rename the selected file{selectedFileKeys.length !== 1 ? 's' : ''}</h2>
            <input className="input input--sm input--full" type="text" value={valueHelpers.textValue(data?.new_name)} required placeholder={`Enter new name`} onChange={handleChange('new_name')} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        </div>
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-1 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2">{`(${selectedFileKeys.length}) file${selectedFileKeys.length !== 1 ? 's' : ''} selected`}</p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Rename"}</button>
            </div>
        </div>
    </form>
}

function DeleteActionDialog({ onDelete, onCancel, selectedFileKeys }) {
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState(null);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        e.preventDefault();
        setIsBusy(true);
        onDelete({
            onSuccess: () => {
                if (mounted.current) {
                    onCancel();
                }
            },
            onError: (response) => {
                if (mounted.current) {
                    if (response?.data?.status === 'fail') {
                        if (response?.data?.message) {
                            setError(response.data.message);
                        }
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    setIsBusy(false);
                }
            }
        });
    }

    return <form onSubmit={submit}>
        <div className="p-2">
            <h2 className="max-w-[360px]">Are you sure you want to delete the selected file{selectedFileKeys.length !== 1 ? 's' : ''}?</h2>
            {error && <div className="form-error">{error}</div>}
        </div>
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-1 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2">{`(${selectedFileKeys.length}) file${selectedFileKeys.length !== 1 ? 's' : ''} selected`}</p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button onClick={submit} className="btn btn--sm btn--danger" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Delete"}</button>
            </div>
        </div>
    </form>
}

function MoveActionDialog({ projectKey, directoryKey, selectedFileKeys, onMove, onCancel }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return { directory: directoryKey };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onMove({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    return <form onSubmit={submit}>
        <LocationPicker projectKey={projectKey} value={data?.directory} onChange={handleChange('directory')} ignoreFiles={selectedFileKeys} />
        {errors[FORM_ERROR_KEY] && <div className="form-error px-2">{errors[FORM_ERROR_KEY]}</div>}
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-3 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2">{`(${selectedFileKeys.length}) file${selectedFileKeys.length !== 1 ? 's' : ''} selected`}</p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button onClick={submit} className="btn btn--sm btn--primary" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Move here"}</button>
            </div>
        </div>
    </form>
}

function CopyActionDialog({ projectKey, directoryKey, selectedFileKeys, onCopy, onCancel }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return { directory: directoryKey };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onCopy({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    return <form onSubmit={submit}>
        <LocationPicker projectKey={projectKey} value={data?.directory} onChange={handleChange('directory')} ignoreFiles={selectedFileKeys} />
        {errors[FORM_ERROR_KEY] && <div className="form-error px-2">{errors[FORM_ERROR_KEY]}</div>}
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-3 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2">{`(${selectedFileKeys.length}) file${selectedFileKeys.length !== 1 ? 's' : ''} selected`}</p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button onClick={submit} className="btn btn--sm btn--primary" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Paste here"}</button>
            </div>
        </div>
    </form>
}

function ChangePermissionsActionDialog({ projectKey, action, onChangePermissions, onCancel, selectedFileKeys }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return {
                owner_key: action?.data?.owner_key,
                read: copyArray(action?.data?.read, null),
                write: copyArray(action?.data?.write, null),
                execute: copyArray(action?.data?.execute, null),
            };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onChangePermissions({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    return <form onSubmit={submit}>
        <div className="p-2">
            <h2 className="mb-1">Change the permissions of the selected file{selectedFileKeys.length !== 1 ? 's' : ''}</h2>
            <label className="font-semibold text-gray-500 text-sm">Owner</label>
            <UserPicker projectKey={projectKey} value={data?.owner_key} onChange={handleChange('owner_key')} placeholder="Select a user" />
            <FilePermissionField label={`Who can view the file${selectedFileKeys.length !== 1 ? 's' : ''}?`} value={data?.read} onChange={handleChange('read')} />
            <FilePermissionField label={`Who can edit the file${selectedFileKeys.length !== 1 ? 's' : ''}?`} value={data?.write} onChange={handleChange('write')} />
            <FilePermissionField label={`Who can execute the file${selectedFileKeys.length !== 1 ? 's' : ''}?`} value={data?.execute} onChange={handleChange('execute')} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        </div>
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-1 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2">{`(${selectedFileKeys.length}) file${selectedFileKeys.length !== 1 ? 's' : ''} selected`}</p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Change"}</button>
            </div>
        </div>
    </form>
}

function FilePermissionField({ label, value, onChange }) {
    const [selectedPermission, setSelectedPermission] = useState(() => getFilePermission(value));
    const changePermission = (e) => {
        e.preventDefault();
        const newPermission = e.target.value;
        setSelectedPermission(newPermission);
        if (e.target.value !== "_groups_") {
            onChange([newPermission]);
        } else {
            onChange(["admins"]);
        }
    }

    const { teamMemberGroups } = useTeamMemberGroups();
    const [selectedGroups, setSelectedGroups] = useState(() => selectedPermission === "_groups_" ? value : []);
    const handleGroupClick = (groupKey) => {
        return (e) => {
            e.preventDefault();
            if (groupKey !== "admins") {
                var newSelectedGroups;
                if (selectedGroups.indexOf(groupKey) !== -1) {
                    newSelectedGroups = selectedGroups.filter((gk) => gk !== groupKey);
                } else {
                    newSelectedGroups = [...selectedGroups, groupKey];
                }
                setSelectedGroups(newSelectedGroups);
                onChange(newSelectedGroups);
            }
        }
    }

    return <><div className="mt-3 flex items-center flex-row justify-between">
        <h3 className="pr-1 font-semibold text-gray-500 text-sm">{label}</h3>
        <select className="select select--sm" value={selectedPermission} onChange={changePermission} required>
            <option value="">--</option>
            <option value={FILE_PERMISSIONS.EVERYONE.KEY}>{FILE_PERMISSIONS.EVERYONE.NAME}</option>
            <option value={FILE_PERMISSIONS.TEAM_ONLY.KEY}>{FILE_PERMISSIONS.TEAM_ONLY.NAME}</option>
            <option value="_groups_">Selected groups</option>
        </select>
    </div>
        {(selectedPermission === "_groups_" && teamMemberGroups) &&
            <ul className="mt-1 block whitespace-nowrap">
                {teamMemberGroups.map((group) => {
                    return <li className="inline-block" key={group.key}>
                        <a href="#" onClick={handleGroupClick(group.key)} className={`inline-block mr-1.5 rounded-md border mb-1.5 py-0.5 px-1 ${group?.key === "admins" ? "cursor-not-allowed bg-primary-lighter border-primary opacity-70" : (selectedGroups.indexOf(group?.key) !== -1 ? "bg-primary-lighter border-primary hover:bg-primary-light active:bg-primary" : "hover:bg-gray-200 active:bg-gray-300")}`}>
                            <span className="text-sm truncate"><span className="mr-1">{group?.icon ? group?.icon : "❔"}</span>{group?.name}</span>
                        </a>
                    </li>
                })}
            </ul>
        }
    </>
}

function getFilePermission(value) {
    if (value && value instanceof Array) {
        if (value.length === 1) {
            if (value[0] === FILE_PERMISSIONS.TEAM_ONLY.KEY) {
                return FILE_PERMISSIONS.TEAM_ONLY.KEY;
            }
            if (value[0] === FILE_PERMISSIONS.EVERYONE.KEY) {
                return FILE_PERMISSIONS.EVERYONE.KEY;
            }
        }
        return "_groups_";
    }
    return "";
}