export const STEP_TYPES = {
    UNSPECIFIED: "unspecified",
    REQUEST: "request",
    FILTER: "filter",
    FUNCTION: "function",
    STORAGE: "storage",
    SELECTOR: "selector",
    IF: "if",
    TABLE_DIMENSIONS: "table_dimensions",
    REPEAT_IF: "repeat_if",
    REPEAT: "repeat",
    GO_TO: "go_to",
    BROWSER_EXTENSION: "browser_extension",
    VARIABLE: "variable",
    SET_VALUE: "set_value",
    FIRST_ROW: "first_row",
    LAST_ROW: "last_row",
    EMBED_WORKFLOW: "embed_workflow",
    TEXT: "text",
    RENAME: "rename",
    EACH_ROW: "each_row",
    GROUP_BY: "group_by",
    SORT_BY: "sort_by",
    JOIN: "join",
    JSON: "json",
}

export const FRAGMENT_STEP_TYPES = {
    OTHERWISE: "otherwise",
    END: "end",
    BREAK: "break",
    CONTINUE: "continue"
}