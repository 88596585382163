import React from "react";
import {LOGO_CHARACTER_BOX_SIZE} from "../../config";

const style = {
    width: LOGO_CHARACTER_BOX_SIZE + 'px',
    height: LOGO_CHARACTER_BOX_SIZE + 'px',
    lineHeight: LOGO_CHARACTER_BOX_SIZE + 'px'
};

function Row({ cells }) {
    return (
        <div className="overflow-hidden whitespace-nowrap leading-none">
            {cells.map((cell, colIndex) => {
                return <span key={colIndex.toString()} aria-hidden="true" style={style} className="inline-block text-center font-logo text-xs">{cell}</span>;
            })}
        </div>
    );
}

export default Row;