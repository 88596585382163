import { React, useState, useRef, useEffect } from "react";
import { ReactComponent as DotsIcon } from '../../icons/dots.svg';
import { ReactComponent as XIcon } from '../../icons/x.svg';
import { ReactComponent as RenameIcon } from '../../icons/action/rename.svg';
import { ReactComponent as CopyIcon } from '../../icons/action/copy.svg';
import { ReactComponent as MoveIcon } from '../../icons/action/move.svg';
import { ReactComponent as ChangePermissionsIcon } from '../../icons/action/change-permissions.svg';
import { ReactComponent as DeleteIcon } from '../../icons/action/delete.svg';

export default function FileActions({ onSelect }) {
    const popupRef = useRef(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPopupVisible(!isPopupVisible);
    }

    useEffect(() => {

        const handleDisregard = (e) => {
            const popupElem = popupRef.current;
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('file-actions')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('file-actions')) {
                disregard = false;
            }
            if (disregard || el !== popupElem) {
                setIsPopupVisible(false);
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [])

    const handleActionClick = (actionName) => {
        return (e) => {
            e.preventDefault();
            if (typeof onSelect === 'function') {
                onSelect(actionName);
            }
            setIsPopupVisible(false);
        }
    }

    return <div className="flex-none relative">
        <ul ref={popupRef} className={`absolute file-actions bottom-[100%] overflow-hidden rounded-lg bg-white shadow-md right-1 w-[200px] mb-7 ${!isPopupVisible ? 'hidden' : ''}`}>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleActionClick('rename')}><RenameIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Rename</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleActionClick('copy')}><CopyIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Copy</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleActionClick('move')}><MoveIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Move</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleActionClick('change_permissions')}><ChangePermissionsIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Change permissions</span></a></li>
            <li className="px-3"><a href="#" className="flex items-center overflow-hidden active:bg-gray-50 text-red-600 hover:text-red-500 py-2" onClick={handleActionClick('delete')}><DeleteIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Delete</span></a></li>
        </ul>
        <button className="block -mt-6 rounded-full shadow-md p-4 bg-primary hover:bg-primary-light active:bg-primary-dark transition-all" onClick={togglePopup}>{isPopupVisible ? <XIcon className="block w-[20px] h-[20px]" /> : <DotsIcon className="block w-[20px] h-[20px]" />}</button>
    </div>
}