import { React, useState, useEffect } from 'react';
import WorkflowData from '../workflow/WorkflowData';
import { DATA_SOURCE_KEYS, DATA_SOURCE_TYPES, DATA_SOURCE_TYPE_KEYS } from '../../constants/data-source';
import useApiRequest from '../../hooks/api-request';
import StepError from '../workflow/StepError';
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';
import ScorecardOutput from './ScorecardOutput';
import { WIDGET_TYPES } from '../../constants/widget';
import BarChartOutput from './BarChartOutput';
import LineChartOutput from './LineChartOutput';
import PieChartOutput from './PieChartOutput';

export default function WidgetOutput({ details, widgetOutput, parameters, dispatchParameters, showDataSchema, outputType }) {
    const { responseData, errorResponse, retry } = useWorkflowDataFetcher(widgetOutput);
    const [workflowDataResponse, setWorkflowDataResponse] = useState(null);

    useEffect(() => {
        if (responseData !== null || errorResponse !== null) {
            setWorkflowDataResponse(responseData);
        }
    }, [responseData, errorResponse])

    const viewOutput = () => {
        switch (details?.type) {
            case WIDGET_TYPES.SCORECARD:
                return <ScorecardOutput details={details} data={workflowDataResponse} />
            case WIDGET_TYPES.BAR_CHART:
                return <BarChartOutput details={details} data={workflowDataResponse} />
            case WIDGET_TYPES.LINE_CHART:
                return <LineChartOutput details={details} data={workflowDataResponse} />
            case WIDGET_TYPES.PIE_CHART:
                return <PieChartOutput details={details} data={workflowDataResponse} />
            default:
                return null;
        }
    }

    return <>
        <div className="w-full max-w-lg"><div className="relative h-5 w-0.5 mx-auto bg-gray-200"></div></div>
        {workflowDataResponse ?
            workflowDataResponse.status === 'success' ?
                outputType === 'data' ?
                    <WorkflowData projectKey={widgetOutput.project_key} dataSourceKey={DATA_SOURCE_KEYS.ANY} dataSourceType={workflowDataResponse[DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT] ? DATA_SOURCE_TYPES.STEP_OUTPUT : DATA_SOURCE_TYPES.STEP_META} data={workflowDataResponse} parameters={parameters} dispatchParameters={dispatchParameters} topBarColor="bg-gray-200" showDataSchema={showDataSchema} />
                    : viewOutput()
                : <StepError data={workflowDataResponse} />
            : errorResponse ? <div className="md:flex-none py-4 text-center md:min-w-lg bg-white max-w-full shadow-sm rounded-md relative">
                <p className="text-sm">Oops, something went wrong.</p>
                <button className="mt-2 bg-gray-200 hover:bg-gray-100 active:bg-gray-300 focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-blocktext-black shadow-sm text-sm rounded-md py-1 px-2" onClick={(e) => { e.preventDefault(); retry(); }}>
                    <ResetIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Try again
                </button>
            </div> : <div className="md:flex-none min-h-[120px] text-center md:min-w-lg max-w-full shadow-sm rounded-md relative block animate-pulse bg-gray-300"></div>}
    </>
}

function useWorkflowDataFetcher(widgetOutput) {
    return useApiRequest({
        urlPath: `/projects/${widgetOutput.project_key}/workflows/${widgetOutput.workflow_key}/scopes/${widgetOutput.run_scope_key}/runs/${widgetOutput.run_key}/outputs/${widgetOutput.key}`,
    }, [widgetOutput?.key, widgetOutput?.run_key, widgetOutput?.started]);
}