import { React } from 'react';
import * as contentEditableHelpers from "../../helpers/contentEditable";
import valueHelpers from "../../helpers/value";

export default function DataView({ data, parameters, ...rest }) {
    const nodeList = contentEditableHelpers.textToNodes(valueHelpers.textValue(data), parameters);

    return <div  {...{ ...rest, className: "sm:text-sm leading-normal break-all tracking-normal whitespace-pre-wrap " + (rest.className || '' ) }}>{
        nodeList.map((node, i) => {
            if (node.type === contentEditableHelpers.NODE_TYPES.PARAMETER) {
                return <span className="break-all pointer-events-none whitespace-pre-wrap tracking-normal" key={`${i}-${node.value.length}`}>
                    <span className={`${node.param.dynamic ? 'text-primary-dark' : 'text-secondary-dark'} ${node.param.meta ? 'border-dashed' : 'border-solid'} bg-gray-100 border sm:text-sm font-[500] border-gray-300 rounded-l-lg px-[8px] !leading-none`}>{node.param.stepIndex + 1}</span>
                    &#x200B;
                    <span className={`${node.param.meta ? 'border-dashed' : 'border-solid'} text-black bg-white border-t border-r rounded-r-lg border-b border-gray-300 pl-[5px] pr-[8px] break-all whitespace-pre-wrap tracking-normal !leading-none sm:text-sm font-[500]`}>{node.param.dataKey}</span>
                </span>
            }
            return <span key={`${i}-${node.value.length}`} className="sm:text-sm break-all whitespace-pre-wrap tracking-normal inline">{node.value}</span>;
        })} </div>;
}