import { React, useState, useEffect, useRef } from "react";
import useForm from "../../hooks/form";
import valueHelpers from "../../helpers/value";
import { FORM_ERROR_KEY } from "../../config";
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';
import { ReactComponent as ProjectIcon } from '../../icons/project.svg';
import requestHelpers from "../../helpers/request";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';

export default function ProjectCreationDialog({ onCreate, onCancel, isVisible }) {
    const dialogRef = useRef(null);

    useEffect(() => {

        const handleDisregard = (e) => {
            if (isVisible) {
                const dialogElem = dialogRef.current;
                var el = e.target, disregard = true;
                while (el && !el.classList.contains('project-creation-form')) {
                    el = el.parentElement;
                }
                if (el && el.classList.contains('project-creation-form')) {
                    disregard = false;
                }
                if (disregard || el !== dialogElem) {
                    onCancel();
                }
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [isVisible, onCancel])


    return <div className={`fixed px-2 top-0 left-0 bottom-0 right-0 bg-gray-500/50 ${isVisible ? '' : 'hidden'}`}>
        <div ref={dialogRef} className="project-creation-form w-full max-w-[280px] md:max-w-[400px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg border">
            <ProjectCreationForm onCreate={onCreate} onCancel={onCancel} />
        </div>
    </div>
}

function ProjectCreationForm({ onCreate, onCancel }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return { name: '', description: '', icon: null };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onCreate({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const [iconIsUploading, setIconIsUploading] = useState(false);
    const iconFileInput = useRef(null);

    const uploadIcon = (file) => {
        setIconIsUploading(true);
        let formData = new FormData();
        formData.append("icon", file);
        requestHelpers.sendApiRequest({
            urlPath: '/projects/icons',
            method: 'post',
            contentType: 'multipart/form-data',
            data: formData,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onSuccess: (response) => {
                if (mounted.current) {
                    const iconData = response?.data?.data;
                    if (iconData) {
                        handleChange('icon')(iconData.name);
                    }
                }
            },
            onError: () => {
                if (mounted.current) {
                    handleChange('icon')('');
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    setIconIsUploading(false);
                }
            }
        });
    };

    const handleIconChange = (e) => {
        const file = e.target.files[0];
        uploadIcon(file);
    }

    const handleIconClick = (e) => {
        var ignore = true;
        if (e.type === 'keydown') {
            if (e.key === 'Enter' || e.key === " " || e.key === "Spacebar") {
                ignore = false;
            }
        } else if (e.type === 'click') {
            ignore = false;
        }
        if (!ignore && iconFileInput.current) {
            iconFileInput.current.click();
        }
    }

    return <form className="p-2" onSubmit={submit}>
        <h2 className="mb-1">Create a new project</h2>
        <label className="label">Icon</label>
        <div className="flex-none border-2 outline-none hover:ring-secondary hover:ring-2 focus:ring-secondary focus:ring-2 cursor-pointer bg-gray-100 rounded-md w-[45px] h-[45px] relative" tabIndex="0" onClick={handleIconClick} onKeyDown={handleIconClick}>
            {iconIsUploading ? <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"><SpinnerIcon className="animate-spin text-gray-500 block w-5 h-5" /></div> : data?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/projects/medium/${data.icon}`} className="block w-full rounded-md" alt="Workflow icon" /> : <ProjectIcon className="absolute fill-current text-gray-500 block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[50%]" />}
            <input ref={iconFileInput} type="file" onChange={handleIconChange} className="hidden" accept="image/*" />
        </div>
        <label className="label">Name</label>
        <input className="input input--sm input--full" type="text" value={valueHelpers.textValue(data?.name)} required placeholder="Name" onChange={handleChange('name')} />
        <label className="label">Description</label>
        <textarea className="textarea textarea--sm textarea--full" placeholder="Description" onChange={handleChange('description')} defaultValue={valueHelpers.textValue(data?.description)}></textarea>
        {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        <div className="mt-2 text-right">
            <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
            <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Create"}</button>
        </div>
    </form>
}