import { React } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import StepTopBar from "./StepTopBar";
import valueHelpers from "../../helpers/value";
import MetaBox from "./MetaBox";
import FilePicker from "../explorer/FilePicker";
import { useFileFetcher } from "../../hooks/file";
import { ReactComponent as HomeIcon } from '../../icons/home.svg';
import FileIcon from "../explorer/FileIcon";

function JsonStepForm({ index, step, update, parameters, onRun, onStop, isRunning, topBarColor, projectKey }) {

    const { data, handleChange, handleSubmit, setItem, removeItem, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            update(index, submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}></StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />
            <label className="label mb-0.5 block">Json File</label>
            <FilePicker className="mb-2" projectKey={projectKey} fileType={3} placeholder="Select a Json file..." value={valueHelpers.textValue(data?.json_file_key)} onChange={handleChange('json_file_key')} />
            <MetaBox stepIndex={index} parameters={parameters} data={data} handleChange={handleChange} setItem={setItem} removeItem={removeItem} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} runToStep={data} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

export default function JsonStep({ index, step, update, editMode, parameters, onRun, onStop, isRunning, topBarColor, projectKey }) {
    const { responseData: jsonFileInfo } = useFileFetcher({ projectKey, fileKey: step?.json_file_key || '' }, [projectKey, step?.json_file_key]);

    return editMode ?
        <JsonStepForm index={index} step={step} update={update} parameters={parameters} onRun={onRun} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} projectKey={projectKey} />
        : (<>
            <StepTopBar bgColor={topBarColor}></StepTopBar>
            <div className="bg-white rounded-b-md px-3 py-2">
                <StepHeader className="mb-3" stepType={step.type} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
                {(step?.json_file_key && jsonFileInfo) && <>
                    <label className="label mb-0.5 block">Json File</label>
                    <div className="w-full file-picker rounded-md relative bg-gray-100 p-1">
                        <a href={`/app/projects/${projectKey}/workspace?key=${jsonFileInfo.key}`} target="_blank" className="flex hover:text-primary-dark">
                            {jsonFileInfo.key === "" ? <HomeIcon className="flex-none border bg-white rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" /> : <FileIcon type={jsonFileInfo.type} icon={jsonFileInfo.icon} className={`flex-none border bg-white rounded-sm mr-1.5 h-[25px] w-[25px] ${jsonFileInfo.icon ? '' : 'p-1'}`} />}
                            <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{jsonFileInfo.key === "" ? "Home" : jsonFileInfo.name}</span>
                        </a>
                    </div>
                </>}
            </div>
        </>);
}