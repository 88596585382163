import { React, useState, useEffect, useRef, useCallback} from "react";
import useForm from "../../hooks/form";
import valueHelpers from "../../helpers/value";
import { FORM_ERROR_KEY } from "../../config";
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';
import { useTeamMemberGroups } from "../../hooks/team-member-group";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';

export default function MemberActionDialog({ action, onDelete, onAdd, onUpdate, onCancel }) {
    const dialogRef = useRef(null);
    const [isVisible, setIsVisible] = useState(action !== null);

    useEffect(() => {
        setIsVisible(action !== null);
    }, [action]);

    const cancel = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
        setIsVisible(false);
    }, [onCancel]);

    useEffect(() => {
        const handleDisregard = (e) => {
            const dialogElem = dialogRef.current;
            if (isVisible) {
                var el = e.target, disregard = true;
                while (el && !el.classList.contains('member-action-dialog')) {
                    el = el.parentElement;
                }
                if (el && el.classList.contains('member-action-dialog')) {
                    disregard = false;
                }
                if (disregard || el !== dialogElem) {
                    cancel();
                }
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [isVisible, cancel])

    const dialogContent = () => {
        if (action) {
            switch (action.name) {
                case 'add':
                    return <AddActionDialog onAdd={onAdd} onCancel={cancel} />
                case 'update':
                    return <UpdateActionDialog data={action.data} onUpdate={onUpdate} onCancel={cancel} />
                case 'delete':
                    return <DeleteActionDialog data={action.data} onDelete={onDelete} onCancel={cancel} />
                default:
                    return null;
            }
        }
        return null
    }

    return <div className={`fixed px-2 top-0 left-0 bottom-0 right-0 bg-gray-500/50 ${isVisible ? '' : 'hidden'}`}>
        <div ref={dialogRef} className="member-action-dialog w-full max-w-[280px] md:max-w-[400px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg border">
            {dialogContent()}
        </div>
    </div>
}

function AddActionDialog({ onAdd, onCancel }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return {
                email: '',
                group: '',
            };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onAdd({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    const { teamMemberGroups } = useTeamMemberGroups();

    return <form className="p-2" onSubmit={submit}>
        <h2 className="mb-1">Add a new member</h2>
        <label className="label">Email</label>
        <input className="input input--sm input--full" type="email" value={valueHelpers.textValue(data?.email)} required placeholder="Enter user's email" onChange={handleChange('email')} />
        {errors?.email && <div className="input-error">{errors.email}</div>}
        <label className="label">Group</label>
        <select className="select select--sm block w-full" value={valueHelpers.textValue(data?.group)} onChange={handleChange("group")} required>
            <option value="">Select member's group</option>
            {teamMemberGroups && teamMemberGroups.map((teamMemberGroup) => {
                return <option key={teamMemberGroup.key} value={teamMemberGroup.key}>{`${teamMemberGroup.icon ? teamMemberGroup.icon : "❔"} ${teamMemberGroup.name}`}</option>
            })}
        </select>
        {errors?.group && <div className="input-error">{errors.group}</div>}
        {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        <div className="mt-2 text-right">
            <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
            <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Add"}</button>
        </div>
    </form>
}

function UpdateActionDialog({ onUpdate, onCancel, data }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data: updatedData, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return { 
                key: data?.key,
                group: data?.group,
             };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onUpdate({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    const { teamMemberGroups } = useTeamMemberGroups();

    return <form onSubmit={submit}>
        <div className="p-2">
            <div className="flex flex-row items-center">
                <div className="flex-none rounded-full w-7 h-7 leading-6 bg-gray-100 overflow-hidden border text-black text-center text-md uppercase font-bold">
                    {data?.picture ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/users/medium/${data.picture}`} className="block w-full rounded-md" alt="Team member picture" /> : (data?.full_name ? data.full_name[0] : '')}
                </div>
                <div className="flex-1">
                    <h4 className="px-2 font-medium text-sm truncate">{data?.full_name}</h4>
                </div>
            </div>
            <label className="label">Group</label>
            <select className="select select--sm block w-full" value={valueHelpers.textValue(updatedData?.group)} onChange={handleChange("group")} required>
                <option value="">Select member's group</option>
                {teamMemberGroups && teamMemberGroups.map((teamMemberGroup) => {
                    return <option key={teamMemberGroup.key} value={teamMemberGroup.key}>{`${teamMemberGroup.icon ? teamMemberGroup.icon : "❔"} ${teamMemberGroup.name}`}</option>
                })}
            </select>
            {errors?.group && <div className="input-error">{errors.group}</div>}
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        </div>
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-1 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2"></p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Update"}</button>
            </div>
        </div>
    </form>
}

function DeleteActionDialog({ onDelete, onCancel, data }) {
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState(null);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        e.preventDefault();
        setIsBusy(true);
        onDelete({
            data,
            onSuccess: () => {
                if (mounted.current) {
                    onCancel();
                }
            },
            onError: (response) => {
                if (mounted.current) {
                    if (response?.data?.status === 'fail') {
                        if (response?.data?.message) {
                            setError(response.data.message);
                        }
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    setIsBusy(false);
                }
            }
        });
    }

    return <form onSubmit={submit}>
        <div className="p-2">
            <h2 className="max-w-[360px]">Are you sure you want to remove "<strong>{data?.full_name}</strong>" from the team?</h2>
            {error && <div className="form-error">{error}</div>}
        </div>
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-1 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2"></p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button onClick={submit} className="btn btn--sm btn--danger" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Remove"}</button>
            </div>
        </div>
    </form>
}