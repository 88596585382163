import { React } from "react";
import Step from "./Step";
import WorkflowNodeGroup from "./WorkflowNodeGroup";
import AddStepButton from "./AddStepButton";
import NewStep from "./NewStep";
import { isFragmentStep } from "../../helpers/step";
import { STEP_TYPES } from "../../constants/step";

export default function WorkflowNode({projectKey, index, node, steps, workflowOutputs, addStep, updateStep, deleteStep, markAsOutputStep, onRun, onStop, onReset, isRunning, parameters, dispatchParameters, showDataSchema }) {

    const lineColor = node.depth === 0 ? 'bg-gray-300' : node.depth % 2 === 0 ? 'bg-gray-400' : 'bg-gray-300';
    const topBarColor = node.depth === 0 ? 'bg-gray-200' : node.depth % 2 === 0 ? 'bg-gray-300' : 'bg-gray-200';
    const lastNodeInGroup = node.children.length > 0 || index + 1 === node.parent?.children?.length;

    return (node.parent === null && node.children.length === 0) ?
        <AddStepButton addStep={addStep} stepIndex={0} lineColor={lineColor} />
        : <>
            {steps[node.stepIndex] ?
                <>
                    {steps[node.stepIndex].type === STEP_TYPES.UNSPECIFIED ?
                        <NewStep index={node.stepIndex} addStep={addStep} deleteStep={deleteStep} topBarColor={topBarColor} />
                        : <Step projectKey={projectKey} key={`step-${steps[node.stepIndex].key}`} fragmentOf={fragmentOf(steps[node.stepIndex], steps)} index={node.stepIndex} step={steps[node.stepIndex]} output={workflowOutputs[steps[node.stepIndex].key] || null} updateStep={updateStep} deleteStep={deleteStep} markAsOutputStep={markAsOutputStep} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} parameters={parameters} dispatchParameters={dispatchParameters} lineColor={lineColor} topBarColor={topBarColor} showDataSchema={showDataSchema} />}
                    {!lastNodeInGroup && <AddStepButton addStep={addStep} stepIndex={node.stepIndex + 1} lineColor={lineColor} />}
                </>
                : null}
            {node.children.length > 0 && <>
                <AddStepButton addStep={addStep} stepIndex={node.parent === null ? 0 : node.stepIndex + 1} lineColor={lineColor} />
                <WorkflowNodeGroup projectKey={projectKey} node={node} steps={steps} workflowOutputs={workflowOutputs} addStep={addStep} updateStep={updateStep} deleteStep={deleteStep} markAsOutputStep={markAsOutputStep} onRun={onRun} onReset={onReset} onStop={onStop} isRunning={isRunning} parameters={parameters} dispatchParameters={dispatchParameters} showDataSchema={showDataSchema} />
                <AddStepButton addStep={addStep} stepIndex={node.children.length > 0 ? node.children[node.children.length - 1].stepIndex + 1 : node.stepIndex + 1} lineColor={lineColor} />
            </>}
        </>
}

function fragmentOf(step, steps) {
    if (isFragmentStep(step)) {
        for (var i = 0; i < steps.length; i++) {
            if (step.fragment_of === steps[i].key && step.type === steps[i].type) {
                return steps[i];
            }
        }
    }
    return null;
}