import { FILE_ACTIONS, FILE_PERMISSIONS } from "../constants/file";
import { arrayContainsAny } from "./array"

export function isUserAllowedToViewTheFile(user, file) {
    return isUserAllowedTo(FILE_ACTIONS.VIEW.CODE, user, file);
}

export function isUserAllowedToEditTheFile(user, file) {
    return isUserAllowedTo(FILE_ACTIONS.EDIT.CODE, user, file);
}

export function isUserAllowedToExecuteTheFile(user, file) {
    return isUserAllowedTo(FILE_ACTIONS.EXECUTE.CODE, user, file);
}

export function isUserAllowedTo(fileAction, user, file) {
    if (isUserAnAdminOrOwnerOfTheFile(user, file)) {
        return true;
    }

    var permissionsToCheck;
    switch (fileAction) {
        case FILE_ACTIONS.VIEW.CODE:
            permissionsToCheck = file.read;
            break;
        case FILE_ACTIONS.EDIT.CODE:
            permissionsToCheck = file.write;
            break;
        case FILE_ACTIONS.EXECUTE.CODE:
            permissionsToCheck = file.execute;
            break;
    }

    var permissions = [FILE_PERMISSIONS.EVERYONE.KEY];
    if (user?.team) {
        if (user.team.project_key === file.project_key) {
            permissions.push(user.team.group_key);
            permissions.push(FILE_PERMISSIONS.TEAM_ONLY.KEY);
        }
    }

    // check if the file is public
    // or if the file permission is set to team only
    // or if the user's group has the permission to access the file
    return arrayContainsAny(permissions, permissionsToCheck);
}

export function doesUserHaveFullFilePermission(user, file) {
    if (isUserAnAdminOrOwnerOfTheFile(user, file)) {
        return true;
    }

    var permissions = [FILE_PERMISSIONS.EVERYONE.KEY];
    if (user?.team) {
        if (user.team.project_key === file.project_key) {
            permissions.push(user.team.group_key);
            permissions.push(FILE_PERMISSIONS.TEAM_ONLY.KEY);
        }
    }
    // check if the file is public
    // or if the file permission is set to team only
    // or if the user's group has the permission to access the file
    return arrayContainsAny(permissions, file.read) &&
        arrayContainsAny(permissions, file.write) &&
        arrayContainsAny(permissions, file.execute)
}

export function isUserAnAdminOrOwnerOfTheFile(user, file) {
    if (user) {
        // check if the user is the owner of the file
        if (user.key === file.owner_key) {
            return true;
        }
        if (user.team) {
            // check if the user is an admin (admins have full permissions)
            return user.team.project_key === file.project_key &&
                user.team.group_key === "admins";
        }
    }
    return false
}
