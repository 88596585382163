import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useFileAncestorsFetcher } from "../../hooks/file";
import { ReactComponent as HomeIcon } from '../../icons/home.svg';

export default function NavBar({ projectKey, fileKey, ...rest }) {
    const [files, setFiles] = useState(null);
    const { responseData: filesData } = useFileAncestorsFetcher({ projectKey, fileKey: fileKey || "" }, [projectKey, fileKey || ""]);

    useEffect(() => {
        if (filesData instanceof Array) {
            setFiles(filesData);
        }
    }, [filesData]);

    return (
        <nav {...rest}>
            <ul className="block pb-3">
                <li className="inline-block">
                    <Link className="rounded inline-block text-gray-300 hover:text-gray-600 active:bg-gray-300 px-1 py-0 m-0" to={`/app/projects/${projectKey}/workspace`}><HomeIcon className="align-middle inline-block max-h-[18px] w-[18px] fill-current" /></Link>
                    <span className="inline-block text-gray-400">/</span>
                </li>
                {files && files.map((file, i) => {
                    if (i == 0) {
                        return null;
                    }
                    return <li key={i.toString()} className="inline-block">
                        <Link className="rounded inline-block text-gray-600 transition hover:text-black active:bg-gray-300 bg-gray-200 px-1 mx-1" to={`/app/projects/${projectKey}/workspace?key=${file.key}`}>{file.name}</Link>
                        {(i < files.length - 1) ? <span className="inline-block text-gray-400">/</span> : null}
                    </li>
                })}
            </ul>
        </nav>
    );
}