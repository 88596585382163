import { React, useState, useEffect, useRef, useCallback } from "react";
import ProjectCard from "../components/projects/ProjectCard";
import Loader from "../components/brand/Loader";
import { useProjectListFetcher } from "../hooks/project";
import requestHelpers from "../helpers/request";
import useAccessToken from "../hooks/access-token";
import useLogout from "../hooks/logout";
import { useCancelToken } from "../hooks/cancel-token";
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import ProjectCreationDialog from "../components/projects/ProjectCreationDialog";

export default function ProjectsPage() {
    const [projects, setProjects] = useState(null);
    const [cursor, setCursor] = useState("");
    const [sortBy, setSortBy] = useState("recently_modified");

    useEffect(() => {
        setCursor("");
    }, [sortBy])

    const [isLoading, setIsLoading] = useState(true);
    const { responseData: projectsData } = useProjectListFetcher({ cursor, sortBy });

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    useEffect(() => {
        if (projectsData?.projects instanceof Array) {
            if (projects === null) {
                setProjects(cleanProjects(projectsData.projects));
            } else {
                setProjects(cleanProjects([...projects, ...projectsData.projects]));
            }
            setIsLoading(false);
        }
    }, [projectsData]);

    const loadMore = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        setCursor(projectsData?.next_cursor);
    }

    const [isCreationFormVisible, setIsCreationFormVisible] = useState(false);
    const hideCreationForm = useCallback(() => {
        setIsCreationFormVisible(false);
    });
    const showCreationForm = (e) => {
        e.preventDefault();
        setIsCreationFormVisible(true);
    }

    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const onCreate = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: '/projects',
            data: data,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const newProject = response?.data?.data;
                    if (newProject) {
                        setProjects(sortProjects([...projects, newProject], sortBy));
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    return (
        <>
            <div className="min-h-0 flex w-full h-full flex-1 flex-col flex-nowrap overflow-hidden">
                <div className="flex-1 flex min-h-0 w-full justify-between items-stretch justify-items-stretch flex-col flex-nowrap overflow-hidden relative">
                    <div className="flex-none pb-3 flex items-center flex-row justify-between">
                        <h1 className="flex-1 mr-2 section-title">My Projects</h1>
                        <button className="m-0 rounded-md bg-primary py-1 px-2 text-sm hover:bg-primary-light active:bg-primary-dark" onClick={showCreationForm}><PlusIcon className="inline-block w-[12px] h-[12px] mr-1" />Create</button>
                    </div>

                    <div className="flex-1 min-h-0 overflow-y-auto max-h-full pb-3">
                        {projects ? projects.length > 0 ?
                            <div className="auto-rows-min grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-3 lg:grid-cols-3 lg:gap-4">
                                {projects.map((project) => <ProjectCard key={project?.key} project={project} />)}
                                {isLoading ? <>
                                    <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                    <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                    <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                    <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                </> : (projectsData?.next_cursor !== '' ?
                                    <button className="block rounded-lg h-16 border text-sm text-gray-600 appearance-none bg-transparent hover:border-gray-300 active:bg-gray-200" onClick={loadMore}>Load more...</button> : null)
                                }
                            </div> : <div className="pt-36 text-center">
                                <div className="text-center text-lg">No projects</div>
                            </div> : <div className="pt-36 text-center">
                            <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
                            <p>Scanning projects...</p>
                        </div>}
                    </div>
                </div>
            </div>
            <ProjectCreationDialog onCreate={onCreate} onCancel={hideCreationForm} isVisible={isCreationFormVisible} />
        </>
    );
}

function cleanProjects(projects) {
    const cleanProjects = [];
    var i, j, exists;
    for (i = 0; i < projects.length; i++) {
        exists = false;
        for (j = 0; j < cleanProjects.length; j++) {
            if (projects[i].key === cleanProjects[j].key) {
                exists = true;
                break;
            }
        }
        if (!exists) {
            cleanProjects.push(projects[i]);
        }
    }
    return cleanProjects;
}

function sortProjects(projects, sortBy) {
    if (projects.length > 0) {
        if (sortBy === 'a_to_z') {
            projects.sort((a, b) => {
                var nameOrder;
                const aStartsWithUppercase = startsWithUppercase(a.name), bStartsWithUppercase = startsWithUppercase(b.name);
                if (aStartsWithUppercase === bStartsWithUppercase) {
                    nameOrder = 0;
                } else if (aStartsWithUppercase & !bStartsWithUppercase) {
                    nameOrder = -1;
                } else {
                    nameOrder = 1;
                }
                if (nameOrder === 0) {
                    if (a.name > b.name) {
                        nameOrder = 1;
                    } else if (a.name < b.name) {
                        nameOrder = -1;
                    }
                }
                return a.type - b.type || nameOrder;
            });
        } else {
            projects.sort((a, b) => a.type - b.type || b.modified - a.modified);
        }
    }
    return projects;
}

function startsWithUppercase(str) {
    return str.substr(0, 1) === str.substr(0, 1).toUpperCase();
}
