import { useContext } from "react";
import TeamMemberGroupContext from "../contexts/TeamMemberGroupContext";
import useApiRequest from "./api-request";

export function useTeamMemberGroupFetcher({ projectKey, teamMemberGroupKey }, dependencies = []) {
    return useApiRequest({
        urlPath: '/projects/' + projectKey + '/groups' + teamMemberGroupKey
    }, dependencies);
}

export function useTeamMemberGroupListFetcher({ projectKey }) {
    return useApiRequest({
        urlPath: '/projects/' + projectKey + '/groups'
    });
}

export function useTeamMemberGroups() {
    return useContext(TeamMemberGroupContext);
}