import { React } from 'react';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';

export default function SaveButton({ onSave, isBeingSaved = false, ...rest }) {

    const handleClick = (e) => {
        e.preventDefault();
        onSave()
    }

    return <button disabled={isBeingSaved} {...{ ...rest, className: "disabled:cursor-not-allowed focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-block text-black shadow-sm text-sm rounded-md py-1 px-2 " + (rest.className || '' ) }} onClick={handleClick}>
        {isBeingSaved ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : <><SaveIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Save</>}
    </button>
}