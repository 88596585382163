import useApiRequest from "./api-request";

export function useFileFetcher({ projectKey, fileKey, withContent = false }, dependencies = []) {
    return useApiRequest({
        urlPath: `/projects/${projectKey}/workspace/files/${fileKey}`,
        queryParams: {
            with_content: withContent
        }
    }, dependencies);
}

export function useFileAncestorsFetcher({ projectKey, fileKey }, dependencies = []) {
    return useApiRequest({ urlPath: `/projects/${projectKey}/workspace/files/${fileKey}/ancestors` }, dependencies);
}

export function useFileListFetcher({ projectKey, directoryKey, fileType, cursor, count = 12, sortBy = 'recently_modified' }) {
    return useApiRequest({
        urlPath: `/projects/${projectKey}/workspace/files`,
        queryParams: {
            directory: directoryKey,
            type: fileType,
            cursor: cursor,
            count: count,
            sort_by: sortBy
        }
    }, [projectKey, directoryKey, fileType, cursor, count, sortBy]);
}