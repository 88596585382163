import { React, useState } from 'react';
import rfdc from 'rfdc';
import { v4 as uuidv4 } from 'uuid';
import StepTopBar from "./StepTopBar";
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import StepIcon from './StepIcon';
import StepLabel from './StepLabel';
import { FRAGMENT_STEP_TYPES, STEP_TYPES } from '../../constants/step';

const STEPS = {
    [STEP_TYPES.VARIABLE]: {
        title: "Set a new variable",
        type: STEP_TYPES.VARIABLE,
        variables: []
    },
    [STEP_TYPES.BROWSER_EXTENSION]: {
        title: "Get data from browser...",
        type: STEP_TYPES.BROWSER_EXTENSION,
        extension_key: ""
    },
    [STEP_TYPES.REQUEST]: {
        title: "Send an HTTP request",
        type: STEP_TYPES.REQUEST,
        url: "",
        method: "GET",
        body: "",
        params: [],
        headers: [],
        proxy: {
            host: '',
            port: '',
            username: '',
            password: '',
            schema: 'http'
        }
    },
    [STEP_TYPES.TABLE_DIMENSIONS]: {
        title: "Get dimensions of a table",
        input_key: "",
        type: STEP_TYPES.TABLE_DIMENSIONS,
        table: ""
    },
    [STEP_TYPES.IF]: {
        title: "Continue only if...",
        type: STEP_TYPES.IF,
        rules: []
    },
    [STEP_TYPES.GO_TO]: {
        title: "Go to...",
        type: STEP_TYPES.GO_TO,
        target: 0
    },
    [STEP_TYPES.REPEAT]: {
        title: "Repeat",
        type: STEP_TYPES.REPEAT,
        number_of_runs: 0
    },
    [STEP_TYPES.REPEAT_IF]: {
        title: "Repeat only if...",
        type: STEP_TYPES.REPEAT_IF,
        rules: []
    },
    [STEP_TYPES.SELECTOR]: {
        title: "Select columns...",
        type: STEP_TYPES.SELECTOR,
        input_key: "",
        tables: []
    },
    [STEP_TYPES.FUNCTION]: {
        title: "Apply functions on...",
        type: STEP_TYPES.FUNCTION,
        input_key: "",
        columns: []
    },
    [STEP_TYPES.FILTER]: {
        title: "Filter rows by...",
        type: STEP_TYPES.FILTER,
        input_key: "",
        filter_scope: "",
        filters: []
    },
    [STEP_TYPES.STORAGE]: {
        title: "Save data",
        type: STEP_TYPES.STORAGE,
        input_key: "",
        storage: "",
        key_column: "",
        columns: []
    },
    [STEP_TYPES.SET_VALUE]: {
        title: "Set value of...",
        type: STEP_TYPES.SET_VALUE,
        instructions: []
    },
    [STEP_TYPES.FIRST_ROW]: {
        title: "First row of...",
        type: STEP_TYPES.FIRST_ROW,
        table: ""
    },
    [STEP_TYPES.LAST_ROW]: {
        title: "Last row of...",
        type: STEP_TYPES.LAST_ROW,
        table: ""
    },
    [STEP_TYPES.EMBED_WORKFLOW]: {
        title: "Use a predefined workflow",
        type: STEP_TYPES.EMBED_WORKFLOW,
        workflow_path: "",
        input_key: ""
    },
    [STEP_TYPES.TEXT]: {
        title: "Show text...",
        type: STEP_TYPES.TEXT,
        text: ""
    },
    [STEP_TYPES.RENAME]: {
        title: "Rename tables / columns",
        type: STEP_TYPES.RENAME,
        items: []
    },
    [STEP_TYPES.EACH_ROW]: {
        title: "For each row in the table...",
        input_key: "",
        type: STEP_TYPES.EACH_ROW,
        table: ""
    },
    [STEP_TYPES.GROUP_BY]: {
        title: "Group the table by columns...",
        input_key: "",
        type: STEP_TYPES.GROUP_BY,
        table: "",
        columns: [],
        aggregations: []
    },
    [STEP_TYPES.SORT_BY]: {
        title: "Sort the table by columns...",
        input_key: "",
        type: STEP_TYPES.SORT_BY,
        table: "",
        columns: []
    },
    [STEP_TYPES.JOIN]: {
        title: "Join two tables...",
        type: STEP_TYPES.JOIN,
        left_table: "",
        left_data_source: "",
        left_join_column: "",
        right_table: "",
        right_data_source: "",
        right_join_column: "",
        join_type: "inner",
        output_table_name: ""
    },
    [STEP_TYPES.JSON]: {
        title: "Read Json file...",
        type: STEP_TYPES.JSON,
        json_file_type: ""
    }
};

const FRAGMENTS = {
    [STEP_TYPES.REPEAT]: [
        {
            title: "End",
            type: STEP_TYPES.REPEAT,
            fragment_type: FRAGMENT_STEP_TYPES.END
        }
    ],
    [STEP_TYPES.REPEAT_IF]: [
        {
            title: "End",
            type: STEP_TYPES.REPEAT_IF,
            fragment_type: FRAGMENT_STEP_TYPES.END
        }
    ],
    [STEP_TYPES.IF]: [
        {
            title: "Otherwise...",
            type: STEP_TYPES.IF,
            fragment_type: FRAGMENT_STEP_TYPES.OTHERWISE
        },
        {
            title: "End",
            type: STEP_TYPES.IF,
            fragment_type: FRAGMENT_STEP_TYPES.END
        }
    ],
    [STEP_TYPES.EMBED_WORKFLOW]: [
        {
            title: "End",
            type: STEP_TYPES.EMBED_WORKFLOW,
            fragment_type: FRAGMENT_STEP_TYPES.END
        }
    ],
    [STEP_TYPES.EACH_ROW]: [
        {
            title: "End",
            type: STEP_TYPES.EACH_ROW,
            fragment_type: FRAGMENT_STEP_TYPES.END
        }
    ]
};

function allSteps() {
    const steps = [];
    for (var key in STEPS) {
        steps.push(STEPS[key]);
    }
    steps.sort(function (a, b) {
        if (a.type < b.type) { return -1; }
        if (a.type > b.type) { return 1; }
        return 0;
    })
    return steps;
}

export default function NewStep({ index, addStep, deleteStep, topBarColor }) {

    const [searchText, setSearchText] = useState('');
    const [steps, setSteps] = useState(allSteps);

    const filterSteps = (searchBy) => {
        searchBy = searchBy.trim().toLocaleLowerCase();
        var filteredSteps = allSteps();
        filteredSteps = filteredSteps.filter((step) => {
            if (searchBy !== '') {
                return step.title.toLocaleLowerCase().indexOf(searchBy) > -1 ||
                    step.type.replace(/_/, ' ').toLocaleLowerCase().indexOf(searchBy) > -1;
            }
            return true;
        });
        filteredSteps.sort(function (a, b) {
            if (a.type < b.type) { return -1; }
            if (a.type > b.type) { return 1; }
            return 0;
        })
        return filteredSteps;
    }

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value);
        setSteps(filterSteps(e.target.value));
    }

    const add = (stepType) => {
        if (STEPS[stepType]) {
            const step = rfdc()(STEPS[stepType]);
            step.key = uuidv4();
            step.editMode = true;

            const fragments = [];
            if (FRAGMENTS[stepType]) {
                var fragment;
                for (var i = 0; i < FRAGMENTS[stepType].length; i++) {
                    fragment = rfdc()(FRAGMENTS[stepType][i]);
                    fragment.key = uuidv4();
                    fragment.fragment_of = step.key;
                    fragments.push(fragment);
                }
            }
            addStep(index, step, fragments);
        }
    }

    const cancel = (e) => {
        e.preventDefault();
        deleteStep(index);
    }

    return (<div className="max-w-full md:flex md:items-start md:flex-col md:flex-nowrap relative">
        <div className="md:flex-none min-w-md md:max-w-lg md:w-lg relative shadow-sm rounded-md">
            <StepTopBar bgColor={topBarColor}>
                <div className="flex items-center flex-nowrap">
                    <SearchIcon className="flex-none inline-block text-gray-500 mr-1 fill-current h-4" />
                    <div className="flex-1 pl-1">
                        <input className="input input--sm input--full" type="text" value={searchText} onChange={onSearchTextChange} placeholder="Search..." />
                    </div>
                </div>
            </StepTopBar>
            <div className="bg-white rounded-b-md px-2 py-2">
                <div className="overflow-y-auto max-h-[200px]">
                    {steps.map((step, i) => {
                        return <button key={`new_step_${step.key}_${i}`} className="border hover:bg-gray-50 active:bg-gray-100 w-full px-1 py-1 rounded-md flex mb-2 flex-nowrap items-end content-start justify-start justify-items-start" onClick={(e) => { e.preventDefault(); add(step.type); }}>
                            <div className="flex-none border-2 bg-gray-100 text-gray-500 rounded-md w-[36px] h-[36px] relative">
                                <StepIcon stepType={step.type} centered={true} />
                            </div>
                            <div className="flex-1 pl-2 text-left">
                                <StepLabel stepType={step.type} className="font-semibold text-gray-700 text-sm" />
                                <p className="text-xs mb-0.5 leading-none text-gray-500 font-light truncate">{step.title}</p>
                            </div>
                        </button>
                    })}
                </div>
                <div className="pt-2 text-right border-t">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                </div>
            </div>
        </div>
    </div>)
}