import { React } from "react";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';

export default function Avatar({ user }) {
    return (
        <div className="avatar">
            {user?.picture ?
                <img src={`${UPLOADS_STORAGE_BASE_URL}/images/users/medium/${user.picture}`} alt="Profile" className="block w-full h-full" />
                :
                (user?.full_name ? user.full_name[0] : '')}
        </div>
    );
}