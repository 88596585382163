import { React } from 'react';
import { FRAGMENT_STEP_TYPES, STEP_TYPES } from '../../constants/step';

function stepTypeLabel(stepType) {
    switch (stepType) {
        case STEP_TYPES.REQUEST:
            return 'Request';
        case STEP_TYPES.FUNCTION:
            return 'Function';
        case STEP_TYPES.SELECTOR:
            return 'Selector';
        case STEP_TYPES.FILTER:
            return 'Filter';
        case STEP_TYPES.STORAGE:
            return 'Storage';
        case STEP_TYPES.IF:
            return 'If';
        case STEP_TYPES.TABLE_DIMENSIONS:
            return 'Table Dimensions';
        case STEP_TYPES.REPEAT_IF:
            return 'Repdat If';
        case STEP_TYPES.REPEAT:
            return 'Repeat';
        case STEP_TYPES.GO_TO:
            return 'Go To';
        case STEP_TYPES.BROWSER_EXTENSION:
            return 'Browser Extension';
        case STEP_TYPES.VARIABLE:
            return 'Variable';
        case STEP_TYPES.SET_VALUE:
            return 'Set Value';
        case STEP_TYPES.FIRST_ROW:
            return 'First Row';
        case STEP_TYPES.LAST_ROW:
            return 'Last Row';
        case STEP_TYPES.EMBED_WORKFLOW:
            return 'Embed workflow';
        case STEP_TYPES.TEXT:
            return 'Text';
        case STEP_TYPES.RENAME:
            return 'Rename';
        case STEP_TYPES.EACH_ROW:
            return 'Each row';
        case STEP_TYPES.GROUP_BY:
            return 'Group by'
        case STEP_TYPES.SORT_BY:
            return 'Sort by';
        case STEP_TYPES.JOIN:
            return 'Join';
        case STEP_TYPES.JSON:
            return 'Json';
        default:
            return '';
    }
}

function stepFragmentTypeLabel(stepType, stepFragmentType) {
    switch (stepType) {
        case STEP_TYPES.IF:
            switch (stepFragmentType) {
                case FRAGMENT_STEP_TYPES.OTHERWISE:
                    return 'Otherwise';
                case FRAGMENT_STEP_TYPES.END:
                    return 'End';
                default:
                    return '';
            }
        case STEP_TYPES.REPEAT_IF:
            switch (stepFragmentType) {
                case FRAGMENT_STEP_TYPES.BREAK:
                    return 'Break';
                case FRAGMENT_STEP_TYPES.CONTINUE:
                    return 'Continue';
                case FRAGMENT_STEP_TYPES.END:
                    return 'End';
                default:
                    return '';
            }
        case STEP_TYPES.REPEAT:
            switch (stepFragmentType) {
                case FRAGMENT_STEP_TYPES.BREAK:
                    return 'Break';
                case FRAGMENT_STEP_TYPES.CONTINUE:
                    return 'Continue';
                case FRAGMENT_STEP_TYPES.END:
                    return 'End';
                default:
                    return '';
            }
        case STEP_TYPES.EMBED_WORKFLOW:
            switch (stepFragmentType) {
                case FRAGMENT_STEP_TYPES.END:
                    return 'End';
                default:
                    return '';
            }
        case STEP_TYPES.EACH_ROW:
            switch (stepFragmentType) {
                case FRAGMENT_STEP_TYPES.BREAK:
                    return 'Break';
                case FRAGMENT_STEP_TYPES.CONTINUE:
                    return 'Continue';
                case FRAGMENT_STEP_TYPES.END:
                    return 'End';
                default:
                    return '';
            }
        default:
            return '';
    }
}

export default function StepLabel({ stepType, stepFragmentType, ...rest }) {
    return <div {...rest}>{stepTypeLabel(stepType)}{stepFragmentType ? ` : ${stepFragmentTypeLabel(stepType, stepFragmentType)}` : null}</div>
}