import { useEffect, useState } from "react";
import WidgetContainer from "./WidgetContainer";

export default function Container({projectKey, cellSize, details }) {

    const content = () => {
        switch (details?.content_type) {
            case 'widget':
                return <WidgetContainer widgetKey={details.content} projectKey={projectKey} />
            case 'group':
                return <div className={`flex-1 min-h-0 w-full h-full overflow-y-auto rounded-md bg-gray-300/40 flex flex-row ${details.wrap ? 'flex-wrap' : 'flex-nowrap overflow-x-auto'} ${details.alignment === 'center' ? 'items-center' : (details.alignment === 'right' ? 'items-end' : 'items-start')} justify-start justify-items-start max-w-full justify-self-start content-start`}>
                    {details.children && details.children.map((child, i) => <Container key={`child_${i}`} cellSize={cellSize} details={child} projectKey={projectKey} />)}
                </div>
            default:
                return <div className="bg-gray-300 w-full h-full"></div>;
        }
    }

    return <div className={`flex-none max-w-full ${details.content_type !== 'group' ? 'p-2' : ''}`} style={{ width: `${cellSize * details.size.width}px`, height: `${cellSize * details.size.height}px` }}>
        {content()}
    </div>
}