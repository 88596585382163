import {DATA_SOURCE_TYPES, DATA_SOURCE_TYPE_KEYS } from "../constants/data-source";

export function dataSourceTypeToDataSourceTypeKey(type) {
    for (var t in DATA_SOURCE_TYPES){
        if (DATA_SOURCE_TYPES[t] === type && DATA_SOURCE_TYPE_KEYS.hasOwnProperty(t)){
            return DATA_SOURCE_TYPE_KEYS[t];
        }
    }
    return null
}

export function dataSourceTypeKeyToDataSourceType(typeKey) {
    for (var t in DATA_SOURCE_TYPE_KEYS){
        if (DATA_SOURCE_TYPE_KEYS[t] === typeKey && DATA_SOURCE_TYPES.hasOwnProperty(t)){
            return DATA_SOURCE_TYPES[t];
        }
    }
    return null
}