import { React } from 'react';
import { ReactComponent as ScorecardIcon } from '../../icons/widget/scorecard.svg';
import { ReactComponent as LineChartIcon } from '../../icons/widget/line-chart.svg';
import { ReactComponent as BarChartIcon } from '../../icons/widget/bar-chart.svg';
import { ReactComponent as PieChartIcon } from '../../icons/widget/pie-chart.svg';
import { ReactComponent as DefaultWidgetIcon } from '../../icons/file/widget.svg';
import { WIDGET_TYPES } from '../../constants/widget';

function widgetTypeIcon({ widgetType, ...rest }) {
    rest = { ...rest, className: generateClassNamesForCenteredIcon(widgetType) };
    switch (widgetType) {
        case WIDGET_TYPES.SCORECARD:
            return <ScorecardIcon {...rest} />;
        case WIDGET_TYPES.BAR_CHART:
            return <BarChartIcon {...rest} />;
        case WIDGET_TYPES.PIE_CHART:
            return <PieChartIcon {...rest} />;
        case WIDGET_TYPES.LINE_CHART:
            return <LineChartIcon {...rest} />;
        default:
            return <DefaultWidgetIcon {...rest} />;
    }
}


function generateClassNamesForCenteredIcon(widgetType) {
    var className = 'absolute fill-current block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] ';
    switch (widgetType) {
        case WIDGET_TYPES.BAR_CHART:
        case WIDGET_TYPES.PIE_CHART:
            className += 'w-[60%]';
            break;
        case WIDGET_TYPES.LINE_CHART:
            className += 'h-[45%]';
            break;
        case WIDGET_TYPES.SCORECARD:
            className += 'h-[30%]';
            break;
        default:
            className += 'w-[60%]';
    }
    return className;
}


export default function WidgetIcon(props) {
    return widgetTypeIcon(props);
}