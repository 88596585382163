import { FRAGMENT_STEP_TYPES, STEP_TYPES } from "../constants/step";

export function isFragmentStep(step) {
    return typeof step.fragment_of === 'string' && step.fragment_of !== '';
}

export function isBlockStartStep(step) {
    if (isFragmentStep(step)) {
        return false;
    }
    return step.type === STEP_TYPES.IF ||
        step.type === STEP_TYPES.REPEAT_IF ||
        step.type === STEP_TYPES.REPEAT ||
        step.type === STEP_TYPES.EMBED_WORKFLOW || 
        step.type === STEP_TYPES.EACH_ROW;
}

export function isBlockEndStep(step) {
    return isFragmentStep(step) && step.fragment_type === FRAGMENT_STEP_TYPES.END;
}

export function isBlockIntersectionStep(step) {
    return isFragmentStep(step) && step.fragment_type === FRAGMENT_STEP_TYPES.OTHERWISE;
}
