import { React, useState, useRef, useEffect } from "react";
import Tabs from "./Tabs";
import selectorHelpers from "../../helpers/selector";

function prepareOptions(options) {
    return options.map((optionGoup, i) => {
        return { ...optionGoup, active: i === 0, list: optionGoup.list.map((option, j) => ({ ...option, active: j === 0 })) };
    })
}

export default function Selector({ value, onChange, placeholder, options = [], ...rest }) {

    const selectorRef = useRef(null);
    const [filteredOptions, setFilteredOptions] = useState(() => {
        return prepareOptions(options);
    });
    const [isVisible, setIsVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);

    const handleSearch = (e) => {
        if (e.target.value !== "") {
            const searchKeyword = e.target.value.toLowerCase();
            var activeGroup = null;
            for (var j = 0; j < filteredOptions.length; j++) {
                if (filteredOptions[j].active) {
                    activeGroup = filteredOptions[j].group;
                    break;
                }
            }
            const newFilteredOptions = [];
            for (var i = 0; i < options.length; i++) {
                newFilteredOptions.push({
                    active: options[i].group === activeGroup,
                    group: options[i].group,
                    list: options[i].list.filter((option) => option.label.toLowerCase().indexOf(searchKeyword) !== -1)
                        .sort((a, b) => { return a.label.toLowerCase().indexOf(searchKeyword) - b.label.toLowerCase().indexOf(searchKeyword) })
                        .map((option, j) => ({ ...option, active: j === 0 }))
                });
            }
            setFilteredOptions(newFilteredOptions);
        } else {
            setFilteredOptions(prepareOptions(options));
        }
    }

    const setActiveOption = (optionGroupIndex, optionIndex) => {
        if (filteredOptions.length > optionGroupIndex && optionGroupIndex !== -1) {
            if (filteredOptions[optionGroupIndex].list.length > optionIndex && optionIndex !== -1) {
                const newFilteredOptions = [...filteredOptions];
                for (var i = 0; i < newFilteredOptions.length; i++) {
                    newFilteredOptions[i] = {
                        active: optionGroupIndex === i,
                        group: newFilteredOptions[i].group,
                        list: newFilteredOptions[i].list.map((option, j) => ({ ...option, active: optionIndex === j }))
                    };
                }
                setFilteredOptions(newFilteredOptions);
            }
        }
    }

    const selectOption = (optionGroupIndex, optionIndex) => {
        if (filteredOptions.length > optionGroupIndex && optionGroupIndex !== -1) {
            if (filteredOptions[optionGroupIndex].list.length > optionIndex && optionIndex !== -1) {
                const newSelectedOption = filteredOptions[optionGroupIndex].list[optionIndex].value;
                setSelectedOption(newSelectedOption);
                setIsVisible(false);
                if (typeof onChange === 'function') {
                    onChange(newSelectedOption);
                }
            }
        }
    }

    const findActiveOptionIndexes = () => {
        for (var optionGroupIndex = 0; optionGroupIndex < filteredOptions.length; optionGroupIndex++) {
            if (filteredOptions[optionGroupIndex].active) {
                for (var optionIndex = 0; optionIndex < filteredOptions[optionGroupIndex].list.length; optionIndex++) {
                    if (filteredOptions[optionGroupIndex].list[optionIndex].active) {
                        return [optionGroupIndex, optionIndex];
                    }
                }
            }
        }
        return [-1, -1];
    }

    const handleSearchKeyDown = (e) => {
        if (["ArrowUp", "ArrowDown", "Up", "Down", "Enter"].indexOf(e.key) !== -1) {
            e.preventDefault();
            const [optionGroupIndex, optionIndex] = findActiveOptionIndexes();
            switch (e.key) {
                case "Enter":
                    selectOption(optionGroupIndex, optionIndex);
                    break;
                case "ArrowUp":
                case "Up":
                    setActiveOption(optionGroupIndex, optionIndex - 1);
                    break;
                case "ArrowDown":
                case "Down":
                    setActiveOption(optionGroupIndex, optionIndex + 1);
                    break;
            }
        }
    }

    const handleSearchKeyUp = (e) => {
        if (["ArrowUp", "ArrowDown", "Up", "Down", "Enter"].indexOf(e.key) !== -1) {
            e.preventDefault();
            return;
        }
        handleSearch(e);
    }

    const handleFocus = () => {
        setIsVisible(true);
    }

    const handleOptionHover = (optionGroupIndex, optionIndex) => (e) => {
        setActiveOption(optionGroupIndex, optionIndex);
    }

    const focusOn = () => {
        const selectorElem = selectorRef.current;
        if (isVisible && selectorElem) {
            const inputs = selectorElem.getElementsByTagName("input");
            if (inputs.length > 0) {
                inputs[0].focus();
            }
        }
    }

    const handleSelectTab = (tabIndex) => {
        setActiveOption(tabIndex, 0);
        focusOn();
    }

    const reset = () => {
        setSelectedOption('');
        setFilteredOptions(prepareOptions(options));
        setIsVisible(true);
        if (typeof onChange === 'function') {
            onChange('');
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        reset();
    }

    const handleKeyDown = (e) => {
        if (["Enter", "Spacebar", " "].indexOf(e.key) !== -1) {
            e.preventDefault();
            reset();
        }
    }

    const handleOptionClick = (optionGroupIndex, optionIndex) => (e) => {
        selectOption(optionGroupIndex, optionIndex);
    }

    useEffect(() => {
        focusOn();
    }, [isVisible, selectorRef.current]);

    useEffect(() => {
        const handleDisregard = (e) => {
        const selectorElem = selectorRef.current;
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('selector')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('selector')) {
                disregard = false;
            }
            if (disregard || el !== selectorElem) {
                setIsVisible(false);
            }
        }
        document.addEventListener('click', handleDisregard);
        document.addEventListener('focusin', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
            document.removeEventListener('focusin', handleDisregard);
        }
    }, [])

    return <div ref={selectorRef} {...{ ...rest, className: "relative selector " + (rest.className || '') }} >
        {selectedOption ?
            <div tabIndex="0" onClick={handleClick} onKeyDown={handleKeyDown} className="bg-gray-100 rounded-md sm:text-sm focus:ring-2 focus:ring-secondary-dark outline-none cursor-pointer hover:bg-gray-50 active:bg-gray-200 px-2 py-1">{selectorHelpers.getLabelByValue(selectedOption, options)}</div>
            : <input className="input input--sm input--full" type="text" onFocus={handleFocus} onKeyDown={handleSearchKeyDown} onKeyUp={handleSearchKeyUp} placeholder={placeholder} />}
        {filteredOptions.length > 0 && <div style={{ maxHeight: (isVisible) ? 500 : 0, overflowY: (isVisible) ? 'auto' : 'hidden' }} className="absolute transition-all mt-1 top-[100%] left-0 right-0 z-30">
            <Tabs className="rounded-md border-2 px-2 py-2 bg-white border-gray-200" onSelect={handleSelectTab} tabs={filteredOptions.map((optionGroup) => optionGroup.group)}>
                {filteredOptions.map((optionGroup, i) => {
                    return <ul key={`${optionGroup.group}_${i.toString()}`}>
                        {optionGroup.list && optionGroup.list.map((option, j) => {
                            return <li key={`${option.value}_${j.toString()}`} onClick={handleOptionClick(i, j)} onMouseEnter={handleOptionHover(i, j)} className={`text-sm py-1 px-2 cursor-pointer rounded-md ${option.active ? 'bg-gray-100' : ''}`}>{option.label}</li>
                        })}
                    </ul>
                })}
            </Tabs>
        </div>}
    </div>
}