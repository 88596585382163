import React from "react";
import { Link } from "react-router-dom";
import Logo from "../brand/Logo";
import {useUser} from "../../hooks/user";
import Avatar from "./Avatar";

export default function Header() {
    const { user } = useUser();

    return (
        <header className="container flex-none border-b border-gray-300 justify-between items-center content-center flex mx-auto pt-4 md:pt-10 pb-3 md:px-0 px-3">
            <Link to="/">
                <Logo words={["Data", "Assembler"]} colors={["bg-secondary-light", "bg-primary-light"]} />
            </Link>
            {user ?
                <Avatar user={user} />
                :
                <Link to="/app/login" className="font-bold align-middle inline-block hover:text-primary-dark">Login</Link>
            }
        </header>
    )
}