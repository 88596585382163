import useApiRequest from "./api-request";

export function useTeamMemberFetcher({ projectKey, teamMemberKey }, dependencies = []) {
    return useApiRequest({
        urlPath: '/projects/' + projectKey + '/team/' + teamMemberKey
    }, dependencies);
}

export function useTeamMemberListFetcher({ projectKey, groupKey, cursor, count = 12, sortBy = 'recently_modified' }) {
    return useApiRequest({
        urlPath: '/projects/' + projectKey + '/team',
        queryParams: {
            cursor: cursor,
            count: count,
            sort_by: sortBy,
            group_key: groupKey
        }
    }, [projectKey, groupKey, cursor, count, sortBy]);
}
