import {React} from "react";
import Loader from "../components/brand/Loader";

export default function PageNotFound(){
    return (
        <div className="container md:px-0 px-3 pt-40 max-w-xs mx-auto text-center">
            <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
            <h2 className="font-bold mt-1 text-lg">Page not found</h2>
            <p>
                Sorry, the page you requested could not be found.
            </p>
        </div>
    );
}