export const VALUE_TYPES = {
    UNDEFINED: 0,
    TEXT: 1,
    INTEGER: 2,
    DECIMAL: 3,
    BOOLEAN: 4,
    NULL: 5,
    ANY: 6
}

export const VALUE_TYPE_NAMES = {
    [VALUE_TYPES.UNDEFINED]: "Undefined",
    [VALUE_TYPES.TEXT]: "Text",
    [VALUE_TYPES.INTEGER]: "Integer",
    [VALUE_TYPES.DECIMAL]: "Decimal",
    [VALUE_TYPES.BOOLEAN]: "Boolean",
    [VALUE_TYPES.NULL]: "Null",
    [VALUE_TYPES.ANY]: "Any"
}
