import { React, useState, useEffect } from 'react';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import ControlButton from "./ControlButton";
import SaveButton from "../common/SaveButton";
import LiveTime from '../common/LiveTime';
import valueHelpers from "../../helpers/value";
import { useFileFetcher } from "../../hooks/file";
import { ReactComponent as HomeIcon } from '../../icons/home.svg';
import FileIcon from "../explorer/FileIcon";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import useWorkflowRunScopeKey from '../../hooks/workflowRunScopeKey';
import { WIDGET_TYPES } from '../../constants/widget';
import useWorkflowRun from './WorkflowRun';
import ScorecardView from './ScorecardView';
import WidgetOutput from './WidgetOutput';
import WidgetLabel from './WidgetLabel';
import WidgetIcon from './WidgetIcon';
import BarChartView from './BarChartView';
import LineChartView from './LineChartView';
import PieChartView from './PieChartView';

export default function WidgetView({ projectKey, details, update, enableEditMode, lastCheckpoint, workflowRunKey, onRun, onStop, setIsRunning, isRunning, setError, isBeingSaved, currentTab, setCurrentTab, parameters, dispatchParameters, widgetOutput, setWidgetOutput, showDataSchema }) {
    const { responseData: workflowFileResponseData } = useFileFetcher({ projectKey, fileKey: details?.workflow_file_key || '', withContent: true }, [projectKey, details?.workflow_file_key]);
    const [workflowFileInfo, setWorkflowFileInfo] = useState(workflowFileResponseData);
    const [workflowArguments, setWorkflowArguments] = useState([]);
    const [workflowRunScopeKey, setWorkflowRunScopeKey] = useWorkflowRunScopeKey(workflowFileInfo?.content?.run_scope);
    useWorkflowRun(projectKey, details?.workflow_file_key, workflowRunScopeKey, workflowRunKey, setIsRunning, setError, setWidgetOutput);

    useEffect(() => {
        if (workflowFileResponseData) {
            var fileInfo = { ...workflowFileResponseData }
            try {
                fileInfo.content = JSON.parse(fileInfo.content);
            } catch {
                fileInfo.content = { title: "", steps: [], parameters: [] };
            }
            setWorkflowFileInfo(fileInfo);
        }
    }, [workflowFileResponseData])

    useEffect(() => {
        if (workflowFileInfo?.content?.parameters) {
            const args = workflowFileInfo.content?.parameters.map((parameter) => ({ ...parameter }));
            if (details?.arguments) {
                var i, j;
                for (i = 0; i < args.length; i++) {
                    for (j = 0; j < details.arguments.length; j++) {
                        if (args[i].name === details.arguments[j].name) {
                            args[i].value = details.arguments[j].value;
                            break;
                        }
                    }
                }
            }
            setWorkflowArguments(args);
        } else {
            setWorkflowArguments([]);
        }
    }, [details, workflowFileInfo]);

    const stop = () => {
        onStop(details?.workflow_file_key);
    }

    const run = () => {
        setWidgetOutput(null);
        onRun(details?.workflow_file_key, workflowArguments);
    }

    const save = () => {
        update(details);
    }

    const detailsView = () => {
        switch (details?.type) {
            case WIDGET_TYPES.SCORECARD:
                return <ScorecardView data={details} parameters={parameters} />
            case WIDGET_TYPES.BAR_CHART:
                return <BarChartView data={details} parameters={parameters} />
            case WIDGET_TYPES.LINE_CHART:
                return <LineChartView data={details} parameters={parameters} />
            case WIDGET_TYPES.PIE_CHART:
                return <PieChartView data={details} parameters={parameters} />
            default:
                return null;
        }
    }

    return <>
        <div className="md:flex-none min-w-md md:max-w-lg md:w-lg relative rounded-md">
            <div className="flex bg-gray-200 p-1.5 items-center flex-nowrap justify-between rounded-t-md">
                <div className="flex-1 text-xs text-gray-700 px-1">
                    <span className="text-gray-500 sm:inline-block block">Last checkpoint:</span> <LiveTime unixtimestamp={lastCheckpoint} />
                </div>
                <div className="flex-none">
                    <SaveButton className="mr-1.5 bg-white hover:bg-gray-50 active:bg-gray-300" isBeingSaved={isBeingSaved} onSave={save} />
                    <ControlButton className="bg-white hover:bg-gray-50 active:bg-gray-300" onRun={run} onStop={stop} isRunning={isRunning} />
                </div>
            </div>
            <div className="rounded-b-md px-3 py-2 border-2 border-gray-200 bg-white">
                <div className="flex flex-nowrap items-start content-start justify-start justify-items-start">
                    <div className="flex-none border-2 bg-gray-100 rounded-md w-[45px] h-[45px] relative">
                        {details?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${details?.icon}`} className="block w-full rounded-md" alt="Widget icon" /> : <WidgetIcon widgetType={details?.type} />}
                    </div>
                    <div className="flex-1 pl-2 pt-1.5">
                        <WidgetLabel widgetType={details?.type} className="text-xs mb-0.5 leading-none text-gray-500 font-light" />
                        <h3 className="font-semibold w-full max-w-full text-gray-700 text-sm">{details?.title ? valueHelpers.textValue(details?.title) : '--'}</h3>
                    </div>
                    <button onClick={enableEditMode} className="flex-none p-1">
                        <EditIcon className="fill-gray-500 hover:fill-gray-400 active:fill-gray-700 inline-block w-[20px]" />
                    </button>
                </div>
                <div className="flex mt-4 bg-gray-100 flex-nowrap p-0.5 text-xs rounded-md">
                    <button className={`nav-tab ${currentTab === 'data' ? 'nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); setCurrentTab('data') }}>Data</button>
                    <button className={`nav-tab ${currentTab === 'view' ? 'nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); setCurrentTab('view') }}>View</button>
                </div>
                {currentTab === 'data' ? <>
                    {(details?.workflow_file_key && workflowFileInfo) ? <>
                        <div className="text-xs text-gray-500 font-bold block mb-1 mt-2">Workflow</div>
                        <div className="w-full file-picker rounded-md relative bg-gray-100 p-1">
                            <a href={`/app/projects/${projectKey}/workspace?key=${workflowFileInfo.key}`} target="_blank" className="flex hover:text-primary-dark">
                                {workflowFileInfo.key === "" ? <HomeIcon className="flex-none border bg-white rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" /> : <FileIcon type={workflowFileInfo.type} icon={workflowFileInfo.icon} className={`flex-none border bg-white rounded-sm mr-1.5 h-[25px] w-[25px] ${workflowFileInfo.icon ? '' : 'p-1'}`} />}
                                <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{workflowFileInfo.key === "" ? "Home" : workflowFileInfo.name}</span>
                            </a>
                        </div>
                    </> : !details?.workflow_file_key && <p className="text-sm text-gray-600 py-6 text-center">Widget's data is not inititialized yet</p>}
                    {(workflowArguments && workflowArguments.length > 0) ? <div className='mt-2'>
                        <span className="text-xs text-gray-500 font-bold block">Arguments</span>
                        <div className="text-gray-600 rounded-md border border-gray-200 bg-gray-50 align-middle text-sm mt-1">
                            {workflowArguments.map((arg, i) => {
                                return (<div key={`var_${i}/${workflowArguments.length}`}>
                                    <div className={`${(i + 1 !== workflowArguments.length) ? 'border-b border-gray-200' : ''}}`}>
                                        <div className="flex flex-wrap justify-between content-between">
                                            <div className="sm:inline-block flex-auto align-middle py-1 sm:py-2 px-2">
                                                <span className="text-xs font-bold block">Name</span>
                                                {arg.name}
                                            </div>
                                            <div className="sm:inline-block flex-auto py-1 sm:py-2 px-2 align-middle">
                                                <span className="text-xs font-bold block">Value</span>
                                                {arg.value !== '' ? arg.value : '--'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div> : null}
                </> : <>
                    {details?.type !== WIDGET_TYPES.UNSPECIFIED ? detailsView() : <p className="text-sm text-gray-600 py-6 text-center">Widget is not inititialized yet</p>}
                </>}
            </div>
        </div>
        {widgetOutput && <WidgetOutput details={details} outputType={currentTab} widgetOutput={widgetOutput} parameters={parameters} dispatchParameters={dispatchParameters} showDataSchema={showDataSchema} />}
    </>
}