import { React } from "react";
import { Redirect } from "react-router-dom";
import LoginForm from "../components/login/LoginForm";
import useAccessToken from "../hooks/access-token";

export default function LoginPage() {
    const accessToken = useAccessToken();

    return (!accessToken?.token ?
        <div className="container md:px-0 px-3 pt-24 max-w-sm mx-auto">
            <h1 className="text-3xl mb-3 text-center font-bold text-black">Welcome Back</h1>
            <LoginForm />
        </div>
        :
        <Redirect
            to={{
                pathname: "/app/projects",
                state: { from: "/login" }
            }}
        />);
}