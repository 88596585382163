import { React, useState, useRef, useEffect } from 'react';
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';
import ControlButton from "./ControlButton";
import SaveButton from "../common/SaveButton";
import LiveTime from '../common/LiveTime';
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import valueHelpers from "../../helpers/value";
import { FORM_ERROR_KEY } from "../../config"
import requestHelpers from "../../helpers/request";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import FilePicker from "../explorer/FilePicker";
import { useFileFetcher } from "../../hooks/file";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import useWorkflowRunScopeKey from '../../hooks/workflowRunScopeKey';
import { WIDGET_TYPES } from '../../constants/widget';
import WidgetTypeSelector from './WidgetTypeSelector';
import useWorkflowRun from './WorkflowRun';
import ScorecardForm from './ScorecardForm';
import WidgetOutput from './WidgetOutput';
import WidgetLabel from './WidgetLabel';
import WidgetIcon from './WidgetIcon';
import BarChartForm from './BarChartForm';
import LineChartForm from './LineChartForm';
import PieChartForm from './PieChartForm';


export default function WidgetForm({ projectKey, details, update, lastCheckpoint, workflowRunKey, onRun, onStop, setIsRunning, isRunning, setError, isBeingSaved, currentTab, setCurrentTab, parameters, dispatchParameters, widgetOutput, setWidgetOutput, showDataSchema }) {
    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const { data, setData, handleChange, handleSubmit, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(details);
            if (!formData.arguments) {
                formData.arguments = [];
            }
            return formData;
        },
        onSubmit: (submittedData) => {
            update(submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(details);
    }

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    const { responseData: workflowFileResponseData } = useFileFetcher({ projectKey, fileKey: data?.workflow_file_key || '', withContent: true }, [projectKey, data?.workflow_file_key]);
    const [workflowFileInfo, setWorkflowFileInfo] = useState(workflowFileResponseData);
    const [workflowRunScopeKey, setWorkflowRunScopeKey] = useWorkflowRunScopeKey(workflowFileInfo?.content?.run_scope);
    useWorkflowRun(projectKey, data?.workflow_file_key, workflowRunScopeKey, workflowRunKey, setIsRunning, setError, setWidgetOutput);

    useEffect(() => {
        if (workflowFileResponseData) {
            var fileInfo = { ...workflowFileResponseData }
            try {
                fileInfo.content = JSON.parse(fileInfo.content);
            } catch {
                fileInfo.content = { title: "", steps: [], parameters: [] };
            }
            setWorkflowFileInfo(fileInfo);
        }
    }, [workflowFileResponseData])

    useEffect(() => {
        if (workflowFileInfo?.content?.parameters) {
            const args = workflowFileInfo.content?.parameters.map((parameter) => ({ name: parameter.name, value: parameter.value }));
            if (details?.arguments) {
                var i, j;
                for (i = 0; i < args.length; i++) {
                    for (j = 0; j < details.arguments.length; j++) {
                        if (args[i].name === details.arguments[j].name) {
                            args[i].value = details.arguments[j].value;
                            break;
                        }
                    }
                }
            }
            setData({ ...data, workflow_run_scope: workflowFileInfo?.content?.run_scope, arguments: args });
        } else {
            setData({ ...data, workflow_run_scope: workflowFileInfo?.content?.run_scope, arguments: [] });
        }
    }, [workflowFileInfo]);

    const [iconIsUploading, setIconIsUploading] = useState(false);
    const iconFileInput = useRef(null);

    const uploadIcon = (file) => {
        setIconIsUploading(true);
        let formData = new FormData();
        formData.append("icon", file);
        requestHelpers.sendApiRequest({
            urlPath: `/projects/${projectKey}/workspace/files/icons`,
            method: 'post',
            contentType: 'multipart/form-data',
            data: formData,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onSuccess: (response) => {
                if (mounted.current) {
                    const iconData = response?.data?.data;
                    if (iconData) {
                        handleChange('icon')(iconData.name);
                    }
                }
            },
            onError: () => {
                if (mounted.current) {
                    handleChange('icon')('');
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    setIconIsUploading(false);
                }
            }
        });
    };

    const handleIconChange = (e) => {
        const file = e.target.files[0];
        uploadIcon(file);
    }

    const handleIconClick = (e) => {
        var ignore = true;
        if (e.type === 'keydown') {
            if (e.key === 'Enter' || e.key === " " || e.key === "Spacebar") {
                ignore = false;
            }
        } else if (e.type === 'click') {
            ignore = false;
        }
        if (!ignore && iconFileInput.current) {
            iconFileInput.current.click();
        }
    }

    const stop = () => {
        onStop(data?.workflow_file_key);
    }

    const run = () => {
        setWidgetOutput(null);
        onRun(data?.workflow_file_key, data?.arguments);
    }

    const save = () => {
        update(data);
    }

    const detailsForm = () => {
        switch (data?.type) {
            case WIDGET_TYPES.SCORECARD:
                return <ScorecardForm data={data} handleChange={handleChange} parameters={parameters} />
            case WIDGET_TYPES.BAR_CHART:
                return <BarChartForm data={data} handleChange={handleChange} parameters={parameters} />
            case WIDGET_TYPES.LINE_CHART:
                return <LineChartForm data={data} handleChange={handleChange} parameters={parameters} />
            case WIDGET_TYPES.PIE_CHART:
                return <PieChartForm data={data} handleChange={handleChange} parameters={parameters} />
            default:
                return null;
        }
    }

    return <>
        <div className="md:flex-none min-w-md md:max-w-lg md:w-lg relative rounded-md">
            <div className="flex bg-gray-200 p-1.5 items-center flex-nowrap justify-between rounded-t-md">
                <div className="flex-1 text-xs text-gray-700 px-1">
                    <span className="text-gray-500 sm:inline-block block">Last checkpoint:</span> <LiveTime unixtimestamp={lastCheckpoint} />
                </div>
                <div className="flex-none">
                    <SaveButton className="mr-1.5 bg-white hover:bg-gray-50 active:bg-gray-300" isBeingSaved={isBeingSaved} onSave={save} />
                    <ControlButton className="bg-white hover:bg-gray-50 active:bg-gray-300" onRun={run} onStop={stop} isRunning={isRunning} />
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="rounded-b-md px-3 py-2 border-2 border-gray-200 bg-white">
                    <div className="flex flex-nowrap items-start content-start justify-start justify-items-start mb-4">
                        <div className="flex-none border-2 outline-none hover:ring-secondary hover:ring-2 focus:ring-secondary focus:ring-2 cursor-pointer bg-gray-100 rounded-md w-[45px] h-[45px] relative" tabIndex="0" onClick={handleIconClick} onKeyDown={handleIconClick}>
                            {iconIsUploading ? <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"><SpinnerIcon className="animate-spin text-gray-500 block w-5 h-5" /></div> : data?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${data?.icon}`} className="block w-full rounded-md" alt="Widget icon" /> : <WidgetIcon widgetType={data?.type} />}
                            <input ref={iconFileInput} type="file" onChange={handleIconChange} className="hidden" accept="image/*" />
                        </div>
                        <div className="flex-1 pl-2 pt-1.5">
                            <WidgetLabel widgetType={data?.type} className="text-xs mb-0.5 leading-none text-gray-500 font-light" />
                            <input className="input input--sm input--full" type="text" value={valueHelpers.textValue(data?.title)} required placeholder="Enter widget title" onChange={handleChange('title')} />
                        </div>
                    </div>
                    <div className="flex mt-2 bg-gray-100 flex-nowrap p-0.5 text-xs rounded-md">
                        <button className={`nav-tab ${currentTab === 'data' ? 'nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); setCurrentTab('data') }}>Data</button>
                        <button className={`nav-tab ${currentTab === 'view' ? 'nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); setCurrentTab('view') }}>View</button>
                    </div>
                    {currentTab === 'data' ? <>
                        <label className="text-xs text-gray-500 font-bold block mt-2 mb-1">Workflow</label>
                        <FilePicker className="mb-2" projectKey={projectKey} fileType={2} placeholder="Select a Workflow file..." value={valueHelpers.textValue(data?.workflow_file_key)} onChange={handleChange('workflow_file_key')} />
                        {data.arguments.length > 0 && <div className="mt-2">
                            <span className="text-xs text-gray-400 font-bold block mb-1">Arguments</span>
                            <div className="py-1 px-2 border rounded-md">
                                {data.arguments.map((argument, i) => {
                                    return <div key={`var_${i}/${data.arguments.length}`}>
                                        <label className="label block !text-gray-500 mb-0.5">{argument.name}</label>
                                        <input className="input input--sm input--full" type="text" placeholder={argument.name.toString()} value={valueHelpers.textValue(argument.value)} onChange={handleChange(["arguments", i, "value"])} />
                                    </div>
                                })}
                            </div>
                        </div>}
                    </> : <>
                        {data?.type !== WIDGET_TYPES.UNSPECIFIED ? detailsForm() : <WidgetTypeSelector data={data} setData={setData} />}
                    </>}
                    {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
                    <div className="flex mt-3">
                        <div className="flex-1 text-right">
                            <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                            <button className="btn btn--primary btn--sm" type="submit">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        {widgetOutput && <WidgetOutput details={data} outputType={currentTab} widgetOutput={widgetOutput} parameters={parameters} dispatchParameters={dispatchParameters} showDataSchema={showDataSchema} />}
    </>
}