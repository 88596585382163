import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import useApiRequest from "./api-request";

export function useUser() {
    return useContext(UserContext);
}

export function useUserFetcher({ userKey }, dependencies = []) {
    return useApiRequest({
        urlPath: `/users/${userKey}`
    }, dependencies);
}