import { React } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepInputKey from "./StepInputKey";
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import StepTopBar from "./StepTopBar";
import valueHelpers from "../../helpers/value";

function StorageStepForm({ index, step, update, parameters, onRun, stop, isRunning, topBarColor }) {

    const { data, handleChange, handleSubmit, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            update(index, submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    const handleInputKeyChange = (newInputKey) => {
        handleChange('input_key')(newInputKey);
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}>
            <StepInputKey value={data?.input_key} editMode={true} stepIndex={index} parameters={parameters} onChange={handleInputKeyChange} />
        </StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />

            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={stop} isRunning={isRunning} runToStep={data} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

export default function StorageStep({ index, step, update, editMode, parameters, onRun, stop, isRunning, topBarColor }) {
    return editMode ?
        <StorageStepForm index={index} step={step} update={update} parameters={parameters} onRun={onRun} onStop={stop} isRunning={isRunning} topBarColor={topBarColor} />
        : (<>
            <StepTopBar bgColor={topBarColor}>
                <StepInputKey value={step?.input_key} stepIndex={index} parameters={parameters} />
            </StepTopBar>
            <div className="bg-white rounded-b-md px-3 py-2">
                <StepHeader className="mb-3" stepType={step.type} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
            </div>
        </>);
}