import { DATA_SOURCE_TYPES, DATA_SOURCE_KEYS, DATA_SOURCE_TYPE_KEYS } from "../constants/data-source";
import keyHelpers from "./key";
import { findColumnIndex, findRowsByKey } from "./database";

const OPEN_BRACKET = '[';
const CLOSED_BRACKET = ']';
const EQUAL = '=';
const MINUS = '-';
const COLON = ':';

export function findAllParams(s) {
    const allParams = [];
    var currParam = null, dataKeyStartIndex = -1;
    if (s && s.length > 0) {
        var c;
        for (var i = 0; i < s.length; i++) {
            c = s[i]
            if (i > 0) {
                if (currParam === null) {
                    if (c === OPEN_BRACKET && (s[i - 1] === MINUS || s[i - 1] === EQUAL)) {
                        currParam = { startIndex: i - 1, dynamic: s[i - 1] === EQUAL, dataSourceKey: null };
                    }
                } else {
                    if ((!currParam.dynamic && c === MINUS && s[i - 1] === CLOSED_BRACKET) ||
                        (currParam.dynamic && c === EQUAL && s[i - 1] === CLOSED_BRACKET)) {
                        currParam.endIndex = i + 1;

                        if (currParam.dataSourceType === DATA_SOURCE_TYPES.STEP_META) {
                            dataKeyStartIndex = currParam.startIndex + 2 + (currParam?.dataSourceKey?.length || 0) + 2;
                        } else {
                            dataKeyStartIndex = currParam.startIndex + 2 + (currParam?.dataSourceKey?.length || 0) + 1;
                        }

                        currParam.dataKey = s.substring(dataKeyStartIndex, currParam.endIndex - 2)

                        allParams.push(currParam);
                        i++;
                        currParam = null;
                    } else if (currParam.dataSourceKey === null) {
                        if (c !== COLON) {
                            // only dashes, letters, and numbers are valid.
                            if (c !== '-' && !(c >= '0' && c <= '9') && !(c >= 'A' && c <= 'Z') && !(c >= 'a' && c <= 'z')) {
                                currParam = null;
                            }
                        } else {
                            currParam.dataSourceKey = s.substring(currParam.startIndex + 2, i);

                            if (currParam.dataSourceKey.length === 0) {
                                currParam = null;
                            } else {
                                // detect data source type
                                if (s.length > i + 1 && s[i + 1] === COLON) {
                                    currParam.dataSourceType = DATA_SOURCE_TYPES.STEP_META;
                                } else {
                                    if (currParam.dataSourceKey === DATA_SOURCE_KEYS.INPUT) {
                                        currParam.dataSourceType = DATA_SOURCE_TYPES.INPUT;
                                    } else if (currParam.dataSourceKey === DATA_SOURCE_KEYS.PARAMETER) {
                                        currParam.dataSourceType = DATA_SOURCE_TYPES.PARAMETER;
                                    } else {
                                        currParam.dataSourceType = DATA_SOURCE_TYPES.STEP_OUTPUT;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return allParams;
}

export function parseParam(s) {
    var param = null, dataKeyStartIndex = -1;
    if (s && s.length > 0) {
        var c;
        for (var i = 0; i < s.length; i++) {
            c = s[i]
            if (i > 0) {
                if (param === null) {
                    if (c === OPEN_BRACKET && (s[i - 1] === MINUS || s[i - 1] === EQUAL)) {
                        param = { startIndex: i - 1, dynamic: s[i - 1] === EQUAL, dataSourceKey: null };
                    }
                } else {
                    if ((!param.dynamic && c === MINUS && s[i - 1] === CLOSED_BRACKET) ||
                        (param.dynamic && c === EQUAL && s[i - 1] === CLOSED_BRACKET)) {
                        param.endIndex = i + 1;
                        if (param.dataSourceType === DATA_SOURCE_TYPES.STEP_META) {
                            dataKeyStartIndex = param.startIndex + 2 + (param?.dataSourceKey?.length || 0) + 2;
                        } else {
                            dataKeyStartIndex = param.startIndex + 2 + (param?.dataSourceKey?.length || 0) + 1;
                        }
                        param.dataKey = s.substring(dataKeyStartIndex, param.endIndex - 2);
                        return param;
                    } else if (param.dataSourceKey === null) {
                        if (c !== COLON) {
                            // only dashes, letters, and numbers are valid.
                            if (c !== '-' && !(c >= '0' && c <= '9') && !(c >= 'A' && c <= 'Z') && !(c >= 'a' && c <= 'z')) {
                                param = null;
                            }
                        } else {
                            param.dataSourceKey = s.substring(param.startIndex + 2, i);
                            if (param.dataSourceKey.length === 0) {
                                param = null;
                            } else {
                                // detect data source type
                                if (s.length > i + 1 && s[i + 1] === COLON) {
                                    param.dataSourceType = DATA_SOURCE_TYPES.STEP_META;
                                } else {
                                    if (param.dataSourceKey === DATA_SOURCE_KEYS.INPUT) {
                                        param.dataSourceType = DATA_SOURCE_TYPES.INPUT;
                                    } else if (param.dataSourceKey === DATA_SOURCE_KEYS.PARAMETER) {
                                        param.dataSourceType = DATA_SOURCE_TYPES.PARAMETER;
                                    } else {
                                        param.dataSourceType = DATA_SOURCE_TYPES.STEP_OUTPUT;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return param;
}

export function paramText(param) {
    if (param?.dynamic) {
        return `=[${param?.dataSourceKey}${param?.dataSourceType === DATA_SOURCE_TYPES.STEP_META ? '::' : ':'}${param?.dataKey}]=`;
    }
    return `-[${param?.dataSourceKey}${param?.dataSourceType === DATA_SOURCE_TYPES.STEP_META ? '::' : ':'}${param?.dataKey}]-`;
}

export function encodeURIComonentInOuterText(s) {
    var params = findAllParams(s)
    if (params.length > 0) {
        var out = '';
        for (var i = 0; i < params.length; i++) {
            if (i === 0) {
                out += encodeURIComponent(s.substring(0, params[i].startIndex));
            } else {
                out += encodeURIComponent(s.substring(params[i - 1].endIndex, params[i].startIndex));
            }
            out += paramText(params[i]);
            if (i + 1 === params.length) {
                if (params[i].endIndex < s.length) {
                    out += encodeURIComponent(s.substring(params[i].endIndex));
                }
            }
        }
        return out;
    }
    return encodeURIComponent(s);
}

export function evaluateParams(s, data) {
    var params = findAllParams(s)
    if (params.length > 0) {
        var out = '', param = null;
        for (var i = 0; i < params.length; i++) {
            param = params[i];
            if (i === 0) {
                out += s.substring(0, param.startIndex);
            } else {
                out += s.substring(params[i - 1].endIndex, param.startIndex);
            }
            if (param.dataSourceType === DATA_SOURCE_TYPES.INPUT) {
                out += evaluateParam(param, data[DATA_SOURCE_TYPE_KEYS.INPUT]);
            } else if (param.dataSourceType === DATA_SOURCE_TYPES.PARAMETER) {
                out += evaluateParam(param, data[DATA_SOURCE_TYPE_KEYS.PARAMETER]);
            } else if (param.dataSourceType === DATA_SOURCE_TYPES.STEP_META) {
                out += evaluateParam(param, data[DATA_SOURCE_TYPE_KEYS.STEP_META])
            } else {
                out += evaluateParam(param, data[DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT])
            }
            if (i + 1 === params.length) {
                if (param.endIndex < s.length) {
                    out += s.substring(param.endIndex);
                }
            }
        }
        return out;
    }
    return s;
}

export function evaluateParam(param, db) {
    if (param.dynamic && db) {
        const rows = findRowsByKey(db, param.dataKey);
        if (rows.length > 0) {
            const columnIndex = findColumnIndex(keyHelpers.extractColumnNameFromKey(param.dataKey), rows[0].table);
            if (columnIndex !== -1 && rows[0].values) {
                const value = rows[0].values[columnIndex];
                if (value) {
                    return value.text;
                }
            }
        }
        return "";
    }
    return param.dataKey;
}

