export function removeDuplicatesFromListOfObjects(originalArray, uniqueField) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
        if (typeof uniqueField === 'function') {
            lookupObject[uniqueField(originalArray[i])] = originalArray[i];
        } else {
            lookupObject[originalArray[i][uniqueField]] = originalArray[i];
        }
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
}

export function arrayContainsAny(array, any) {
    if (array && any && array.length > 0 && any.length > 0) {
        var j;
        for (var i = 0; i < array.length; i++) {
            for (j = 0; j < any.length; j++) {
                if (array[i] === any[j]) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function doArraysHaveSameItems(array1, array2) {
    if (array1 && array2 && array1.length === array2.length) {
        var exists, j;
        for (var i = 0; i < array1.length; i++) {
            exists = false;
            for (j = 0; j < array2.length; j++) {
                if (array1[i] === array2[j]) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                return false;
            }
        }
        return true;
    }
    return array1 === array2;
}

export function copyArray(array, empty = []) {
    if (array && array instanceof Array) {
        return [...array];
    }
    return empty;
}