import { React, useEffect, useState } from "react";
import { evaluateParams } from "../../helpers/param";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import WidgetIcon from './WidgetIcon';
import { LineChart as LineChartConstructor, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getColumnValues } from "../../helpers/database";
import { DATA_SOURCE_TYPE_KEYS } from "../../constants/data-source";
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';

export default function LineChart({ details, data, onRefresh }) {
    const xLabel = evaluateParams(details?.x_axis?.label, data);
    const yLabel = evaluateParams(details?.y_axis?.label, data);
    const xDataColumn = evaluateParams(details?.x_axis?.values, data);
    const yDataColumn = evaluateParams(details?.y_axis?.values, data);

    const [chartData, setChartData] = useState(() => { return prepareChartData(data, xDataColumn, yDataColumn); });

    useEffect(() => {
        setChartData(prepareChartData(data, xDataColumn, yDataColumn));
    }, [xDataColumn, yDataColumn, data])

    return <div className="flex flex-col bg-white rounded-md shadow-sm py-1 px-2 text-left h-full w-full">
        <div className="flex-none flex flex-nowrap items-start content-start justify-start justify-items-start">
            <div className="flex-none bg-gray-100 border rounded-md w-[24px] h-[24px] relative text-gray-700">
                {details?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${details?.icon}`} className="block w-full rounded-md" alt="Widget icon" /> : <WidgetIcon widgetType={details.type} />}
            </div>
            <h3 className="flex-1 pl-1.5 text-md text-gray-500">{details.title || 'Title'}</h3>
            <button className="text-gray-500 flex-none bg-transparent hover:bg-gray-50 active:bg-gray-100 rounded-md w-[24px] h-[24px] p-1" onClick={onRefresh}><ResetIcon className="block h-full fill-current" /></button>
        </div>
        <div className="flex-1 max-h-full overflow-hidden">
            <ResponsiveContainer width="100%" height="100%">
                <LineChartConstructor
                    width="100%"
                    height="100%"
                    data={chartData}
                    margin={{
                        top: 20,
                        bottom: 25,
                        left: 15,
                        right: 15
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" label={{ value: xLabel, position: 'bottom' }} />
                    <YAxis label={{ value: yLabel, angle: -90, position: 'left' }} />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" fill="#88cffb" dot={{ stroke: '#ff9933', strokeWidth: 3 }} />
                </LineChartConstructor>
            </ResponsiveContainer>
        </div>
    </div>
}

function prepareChartData(data, xDataColumn, yDataColumn) {
    if (data && xDataColumn && yDataColumn) {
        const xData = getColumnValues(data[DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT], xDataColumn);
        const yData = getColumnValues(data[DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT], yDataColumn);

        if (xData?.length === yData?.length) {
            const chartData = [];
            for (var i = 0; i < xData.length; i++) {
                chartData.push({
                    label: xData[i],
                    value: Number(yData[i])
                })
            }
            return chartData;
        }
    }
    return [];
}