import md5 from "md5";
import { MerkleJson } from "merkle-json";
import { useEffect, useMemo, useRef, useState } from "react";
import { WIDGET_TYPES } from "../../constants/widget";
import useApiRequest from "../../hooks/api-request";
import { useFileFetcher } from "../../hooks/file";
import useWorkflowRunScopeKey from "../../hooks/workflowRunScopeKey";
import { streamWorkflowRunStepOutputs, watchWorkflowRecentRunUpdates } from "../../services/firestore";
import BarChart from "../widget/BarChart";
import LineChart from "../widget/LineChart";
import PieChart from "../widget/PieChart";
import Scorecard from "../widget/Scorecard";
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';
import { useCancelToken } from "../../hooks/cancel-token";
import useLogout from "../../hooks/logout";
import useAccessToken from "../../hooks/access-token";
import requestHelpers from "../../helpers/request";

export default function WidgetContainer({ projectKey, widgetKey }) {
    const { responseData: widgetFileResponseData } = useFileFetcher({ projectKey, fileKey: widgetKey, withContent: true }, [projectKey, widgetKey]);
    const [widgetFileInfo, setWidgetFileInfo] = useState(widgetFileResponseData);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const handleWidgetFileResponseData = (responseDataToHandle) => {
        if (mounted.current) {
            if (responseDataToHandle) {
                var fileInfo = { ...responseDataToHandle }
                try {
                    fileInfo.content = JSON.parse(fileInfo.content);
                } catch {
                    fileInfo.content = { title: "", type: WIDGET_TYPES.UNSPECIFIED };
                }
                setWidgetFileInfo(fileInfo);
            } else {
                setWidgetFileInfo(null);
            }
        }
    }

    useEffect(() => {
        handleWidgetFileResponseData(widgetFileResponseData);
    }, [widgetFileResponseData])

    const [workflowRunScopeKey, setWorkflowRunScopeKey] = useWorkflowRunScopeKey(widgetFileInfo?.content?.workflow_run_scope);
    const workflowRecentRunKey = useMemo(() => {
        var hashData = "";
        if (widgetFileInfo?.content?.arguments instanceof Array &&
            widgetFileInfo?.content?.arguments?.length > 0) {
            hashData = {}
            for (var i = 0; i < widgetFileInfo.content.arguments.length; i++) {
                hashData[widgetFileInfo.content.arguments[i].name] = widgetFileInfo.content.arguments[i].value;
            }
            const mj = new MerkleJson();
            hashData = mj.stringify(hashData)
        }
        return md5(hashData);
    }, [widgetFileInfo]);

    const [widgetOutput, setWidgetOutput] = useState(null);
    const [error, setError] = useState(null);
    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const refresh = (e) => {
        e.preventDefault();

        setWidgetOutput(null);
        if (error) {
            setError(null);
        }

        var queryParams;
        if (widgetFileInfo?.content?.arguments instanceof Array &&
            widgetFileInfo?.content?.arguments?.length > 0) {
            queryParams = {};
            for (var i = 0; i < widgetFileInfo.content.arguments.length; i++) {
                queryParams[widgetFileInfo.content.arguments[i].name] = widgetFileInfo.content.arguments[i].value;
            }
        }

        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workflows/${widgetFileInfo?.content?.workflow_file_key}/runs/async`,
            queryParams: queryParams,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    setError(response);
                }
            }
        });
    }

    useWorkflowRecentRun(projectKey, widgetFileInfo?.content?.workflow_file_key, workflowRunScopeKey, workflowRecentRunKey, setError, setWidgetOutput);

    return widgetOutput ? <Chart details={widgetFileInfo?.content} output={widgetOutput} onRefresh={refresh} /> : (error ? <div className="bg-white h-full w-full shadow-sm rounded-md relative">
        <div className="text-center top-[50%] absolute -translate-y-[50%] w-full left-0">
            <p className="text-sm">Oops, something went wrong.</p>
            <button className="mt-2 bg-gray-200 hover:bg-gray-100 active:bg-gray-300 focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-blocktext-black shadow-sm text-sm rounded-md py-1 px-2" onClick={refresh}>
                <ResetIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Try again
            </button>
        </div>
    </div> : <div className="animate-pulse w-full h-full bg-slate-200 rounded-md" />)
}

function Chart({ details, output, onRefresh }) {
    const { responseData, errorResponse, retry } = useWorkflowDataFetcher(output);
    const [widgetDataResponse, setWidgetDataResponse] = useState(null);

    useEffect(() => {
        if (responseData !== null || errorResponse !== null) {
            setWidgetDataResponse(responseData);
        }
    }, [responseData, errorResponse])

    const content = () => {
        switch (details?.type) {
            case WIDGET_TYPES.SCORECARD:
                return <Scorecard details={details} data={widgetDataResponse} onRefresh={onRefresh} />
            case WIDGET_TYPES.BAR_CHART:
                return <BarChart details={details} data={widgetDataResponse} onRefresh={onRefresh} />
            case WIDGET_TYPES.LINE_CHART:
                return <LineChart details={details} data={widgetDataResponse} onRefresh={onRefresh} />
            case WIDGET_TYPES.PIE_CHART:
                return <PieChart details={details} data={widgetDataResponse} onRefresh={onRefresh} />
            default:
                return null;
        }
    }

    return widgetDataResponse ? content() : (errorResponse ? <div className="bg-white h-full w-full shadow-sm rounded-md relative">
        <div className="text-center top-[50%] absolute -translate-y-[50%] w-full left-0">
            <p className="text-sm">Oops, something went wrong.</p>
            <button className="mt-2 bg-gray-200 hover:bg-gray-100 active:bg-gray-300 focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-blocktext-black shadow-sm text-sm rounded-md py-1 px-2" onClick={(e) => { e.preventDefault(); retry(); }}>
                <ResetIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Try again
            </button>
        </div>
    </div> : <div className="animate-pulse w-full h-full bg-slate-200 rounded-md" />)
}

function useWorkflowRecentRun(projectKey, workflowKey, runScopeKey, recentRunKey, setError, setWidgetOutput) {

    const [workflowRecentRun, setWorkflowRecentRun] = useState(null);

    useEffect(() => {
        if (projectKey && workflowKey && runScopeKey && recentRunKey) {
            const unsub = watchWorkflowRecentRunUpdates(projectKey, workflowKey, runScopeKey, recentRunKey, (doc) => {
                setWorkflowRecentRun({
                    key: doc.id,
                    project_key: projectKey,
                    workflow_key: workflowKey,
                    run_scope_key: runScopeKey,
                    ...doc.data()
                });
            }, (err) => {
                setError(err);
            });
            return unsub;
        }
    }, [projectKey, workflowKey, runScopeKey, recentRunKey])

    useEffect(() => {
        if (projectKey && workflowKey && runScopeKey && workflowRecentRun?.run_key) {
            const unsub = streamWorkflowRunStepOutputs(projectKey, workflowKey, runScopeKey, workflowRecentRun.run_key, (snapshot) => {
                if (snapshot.size > 0) {
                    setWidgetOutput({
                        key: snapshot.docs[snapshot.size - 1].id,
                        project_key: projectKey,
                        workflow_key: workflowKey,
                        run_scope_key: runScopeKey,
                        run_key: workflowRecentRun.run_key,
                        ...snapshot.docs[snapshot.size - 1].data()
                    });
                } else {
                    setWidgetOutput(null);
                }
            }, (err) => {
                setError(err);
            });
            return unsub;
        }
    }, [projectKey, workflowKey, runScopeKey, workflowRecentRun])

    return {
        workflowRecentRun,
        setWorkflowRecentRun
    };
}

function useWorkflowDataFetcher(widgetOutput) {
    return useApiRequest({
        urlPath: `/projects/${widgetOutput?.project_key}/workflows/${widgetOutput?.workflow_key}/scopes/${widgetOutput?.run_scope_key}/runs/${widgetOutput?.run_key}/outputs/${widgetOutput?.key}`,
    }, [widgetOutput?.key, widgetOutput?.run_key, widgetOutput?.started]);
}