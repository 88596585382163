import {useState, useEffect } from 'react';
import timeAndDateHelpers from "../../helpers/timeAndDate.js";

export default function LiveTime({ unixtimestamp }) {
    const [liveTime, setLiveTime] = useState(() => formatTime(unixtimestamp));

    useEffect(() => {
        setLiveTime(formatTime(unixtimestamp));
        const interval = setInterval(() => {
            setLiveTime(formatTime(unixtimestamp));
        }, 10000);
        return () => clearInterval(interval)
    }, [unixtimestamp]);

    return liveTime;
}

function formatTime(unixtimestamp) {
    return unixtimestamp ? timeAndDateHelpers.timeSince(unixtimestamp) : '';
}