import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ToggleButton = ({ value, onChange, disabled, className }) => {

    const [checked, setChecked] = useState(value);

    const handleChange = () => {
        if (disabled) {
            return;
        }
        const newValue = !checked;
        if (typeof onChange === 'function') {
            onChange(newValue);
        }
        setChecked(newValue);
    }

    const handleClick = (e) => {
        e.preventDefault();
        handleChange();
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            handleChange();
        }
    }

    useEffect(() => {
        if (value !== checked) {
            setChecked(value);
        }
    }, [value])

    const toggleClasses = classNames('transition-all duration-300 ease-in-out cursor-pointer inline-block rounded-lg relative w-[36px] h-[16px] bg-gray-200', {
        'bg-primary': checked,
        'opacity-50 cursor-none': disabled
    }, className);

    return (
        <div onClick={handleClick} className={toggleClasses} onKeyDown={handleKeyDown} tabIndex="0" role="checkbox" aria-checked={checked}>
            <div className={`transition-all duration-300 ease-in-out rounded-full block w-[12px] h-[12px] shadow-md bg-white absolute top-[2px] ${checked ? 'right-[2px]' : 'left-[2px]'}`}></div>
        </div>
    );
}

ToggleButton.propTypes = {
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

export default ToggleButton;