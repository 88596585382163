import { React, useState, useEffect } from "react";
import Selector from "../common/Selector";
import { DATA_SOURCE_KEYS } from "../../constants/data-source";

function getOptions(stepIndex, parameters) {
    const options = { group: 'keys', list: [] };
    if (parameters) {
        for (var dataSourceKey in parameters) {
            if (dataSourceKey === DATA_SOURCE_KEYS.INPUT ||
                (dataSourceKey !== DATA_SOURCE_KEYS.PARAMETER && parameters[dataSourceKey].step?.index < stepIndex)) {
                options.list.push({
                    index: dataSourceKey === DATA_SOURCE_KEYS.INPUT ? -1 : parameters[dataSourceKey].step.index,
                    label: dataSourceKey === DATA_SOURCE_KEYS.INPUT ? "Workflow Input" : `${parameters[dataSourceKey].step.index + 1}. ${parameters[dataSourceKey].step.title}`,
                    value: dataSourceKey
                });
            }
        }
        options.list.sort((a, b) => a.index - b.index);
    }
    return [options];
}

function useOptions(stepIndex, parameters) {
    const [options, setOptions] = useState(() => {
        return getOptions(stepIndex, parameters);
    });

    useEffect(() => {
        setOptions(getOptions(stepIndex, parameters));
    }, [parameters])

    return options;
}

export default function DataSourceSelector({ value, stepIndex, parameters, editMode, onChange, ...rest }) {

    const options = useOptions(stepIndex, parameters);

    return <div {...rest}>
        {
            editMode ?
                <Selector value={value} options={options} onChange={onChange} placeholder="Data source" />
                : <div className="bg-gray-100 rounded-md sm:text-sm px-2 py-1">{parameters.hasOwnProperty(value) ? `${parameters[value].step.index + 1}. ${parameters[value].step.title}` : '--'}</div>
        }
    </div>
}