import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useFileFetcher } from "../../hooks/file";
import { JSONEditor } from 'svelte-jsoneditor/dist/jsoneditor.js'
import Loader from "../brand/Loader";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import requestHelpers from "../../helpers/request";
import SaveButton from "../common/SaveButton";
import LiveTime from '../common/LiveTime';
import JsonToTables from "./JsonToTables";
import { ReactComponent as TableIcon } from '../../icons/table.svg';
import { ReactComponent as JsonIcon } from '../../icons/json.svg';
import AlertBox from "../common/AlertBox";

export default function Json({ projectKey, fileKey }) {
    const [isLoading, setIsLoading] = useState(true);
    const { responseData: jsonFileResponse, errorResponse: jsonFileErrResponse } = useFileFetcher({ projectKey, fileKey, withContent: true }, [projectKey, fileKey]);
    const [jsonFile, setJsonFile] = useState(null);
    const [jsonContent, setJsonContent] = useState({
        text: "",
        json: undefined,
    });
    const [isTableViewMode, setIsTableViewMode] = useState(false);
    const [error, setError] = useState(null);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    useEffect(() => {
        if (jsonFileResponse?.key === fileKey && mounted.current) {
            setJsonFile(jsonFileResponse);
            setJsonContent({ ...jsonContent, text: jsonFileResponse?.content || '' });
            setIsLoading(false);
        }
    }, [jsonFileResponse])

    useEffect(() => {
        if (mounted.current) {
            setError(jsonFileErrResponse);
        }
    }, [jsonFileErrResponse])

    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();
    const [isBeingSaved, setIsBeingSaved] = useState(null);

    const save = () => {
        if (!isBeingSaved) {
            setIsBeingSaved(true);
            if (error) {
                setError(null);
            }

            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workspace/files/${fileKey}`,
                data: { ...jsonFile, content: jsonContent?.json ? JSON.stringify(jsonContent.json) : (jsonContent?.text || "") },
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onError: (response) => {
                    if (mounted.current) {
                        setError(response);
                    }
                },
                onSuccess: (response) => {
                    if (mounted.current && response?.data?.data) {
                        setJsonFile(response?.data?.data);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBeingSaved(false);
                    }
                }
            });
        }
    }

    const closeAlertBox = (e) => {
        e.preventDefault();
        if (error) {
            setError(null);
        }
    }

    return !isLoading ? <div className="flex flex-1 flex-col">
        <div className="flex-none flex pb-2 items-center flex-nowrap justify-between">
            <div className="flex-none text-xs text-gray-700">
                <span className="text-gray-500 sm:inline-block block">Last checkpoint:</span> <LiveTime unixtimestamp={jsonFile?.modified} />
            </div>
            <div className="flex-non">
                <button disabled={isBeingSaved} className="bg-gray-200 disabled:cursor-not-allowed focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary text-gray-400 hover:text-gray-700 text-sm rounded-md inline-flex items-center px-0.5 py-0.5" onClick={(e) => { e.preventDefault(); setIsTableViewMode(!isTableViewMode); }}>
                    <div className={`flex-none py-0.5 px-2 rounded-md ${!isTableViewMode ? 'bg-white text-black shadow-sm' : ''}`}>
                        <JsonIcon className="fill-current inline-block h-[14px]" />
                    </div>
                    <div className={`flex-none py-0.5 px-2 rounded-md ${isTableViewMode ? 'bg-white text-black shadow-sm' : ''}`}>
                        <TableIcon className="fill-current inline-block h-[16px]" />
                    </div>
                </button>
                <SaveButton isBeingSaved={isBeingSaved} onSave={save} className="ml-2 bg-white" />
            </div>
        </div>
        <div className="flex-1 pb-3">
            {isTableViewMode ? <JsonToTables title={jsonFile?.name} json={jsonContent?.json ? JSON.stringify(jsonContent.json) : (jsonContent?.text || {})} onError={setError} /> : <SvelteJSONEditor content={jsonContent} onChange={setJsonContent} />}
        </div>
        {error ? <AlertBox title="Error" error={error} onClose={closeAlertBox} /> : null}
    </div> : <div className="pt-36 text-center">
        <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
        <p>Please wait...</p>
    </div>
}

function SvelteJSONEditor(props) {
    const refContainer = useRef(null);
    const refEditor = useRef(null);

    useLayoutEffect(() => {
        // create editor
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props
        });

        return () => {
            // destroy editor
            const editorElem = refEditor.current;
            if (editorElem) {
                editorElem.destroy();
                refEditor.current = null;
            }
        };
    }, []);

    // update props
    useLayoutEffect(() => {
        const editorElem = refEditor.current;
        if (editorElem) {
            editorElem.updateProps(props);
        }
    }, [props]);

    return <div className="w-full h-full" ref={refContainer}></div>;
}
