import { React } from 'react';
import { WIDGET_TYPES } from '../../constants/widget';

function widgetTypeLabel(widgetType) {
    switch (widgetType) {
        case WIDGET_TYPES.SCORECARD:
            return 'Scorecard';
        case WIDGET_TYPES.BAR_CHART:
            return 'Bar Chart';
        case WIDGET_TYPES.PIE_CHART:
            return 'Pie Chart';
        case WIDGET_TYPES.LINE_CHART:
            return 'Line Chart';
        default:
            return 'Widget';
    }
}

export default function WidgetLabel({ widgetType, ...rest }) {
    return <div {...rest}>{widgetTypeLabel(widgetType)}</div>
}