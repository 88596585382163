import { useEffect, useState } from "react";
import { streamWorkflowRunStepOutputs, watchWorkflowRunUpdates } from "../../services/firestore";

export default function useWorkflowRun(projectKey, workflowKey, runScopeKey, runKey, setIsRunning, setError, setWidgetOutput) {

    const [workflowRun, setWorkflowRun] = useState(null);

    useEffect(() => {
        if (projectKey && workflowKey && runScopeKey && runKey) {
            const unsub = watchWorkflowRunUpdates(projectKey, workflowKey, runScopeKey, runKey, (doc) => {
                const updatedWorkflowRun = {
                    key: doc.id,
                    project_key: projectKey,
                    workflow_key: workflowKey,
                    run_scope_key: runScopeKey,
                    ...doc.data()
                };
                if (updatedWorkflowRun.status === 'completed' ||
                    updatedWorkflowRun.status === 'stopped' ||
                    updatedWorkflowRun.status === 'error') {
                    setIsRunning(false);
                }
                setWorkflowRun(updatedWorkflowRun);
            }, (err) => {
                setIsRunning(false);
                setError(err);
            });
            return unsub;
        }
    }, [projectKey, workflowKey, runScopeKey, runKey])

    useEffect(() => {
        if (projectKey && workflowKey && runScopeKey && runKey) {
            const unsub = streamWorkflowRunStepOutputs(projectKey, workflowKey, runScopeKey, runKey, (snapshot) => {
                if (snapshot.size > 0) {
                    setWidgetOutput({
                        key: snapshot.docs[snapshot.size - 1].id,
                        project_key: projectKey,
                        workflow_key: workflowKey,
                        run_scope_key: runScopeKey,
                        run_key: runKey,
                        ...snapshot.docs[snapshot.size - 1].data()
                    });
                } else {
                    setWidgetOutput(null);
                }
            }, (err) => {
                console.log(err);
                setIsRunning(false);
                setError(err);
            });
            return unsub;
        }
    }, [projectKey, workflowKey, runScopeKey, runKey])

    return {
        workflowRun,
        setWorkflowRun
    };
}
