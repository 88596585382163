import { React, useEffect, useState } from "react";
import { evaluateParams } from "../../helpers/param";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import WidgetIcon from './WidgetIcon';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import { getColumnValues } from "../../helpers/database";
import { DATA_SOURCE_TYPE_KEYS } from "../../constants/data-source";

export default function PieChartOutput({ details, data }) {
    const categoriesColumn = evaluateParams(details?.categories, data);
    const valuesColumn = evaluateParams(details?.values, data);

    const [chartData, setChartData] = useState(() => { return prepareChartData(data, categoriesColumn, valuesColumn); });

    useEffect(() => {
        setChartData(prepareChartData(data, categoriesColumn, valuesColumn));
    }, [categoriesColumn, valuesColumn, data])

    return <div className="md:flex-none min-w-md md:max-w-lg md:w-lg relative text-center pb-3">
        <div className="inline-block bg-white rounded-md shadow-sm py-1 px-2 min-w-[360px] text-left min-h-[240px]">
            <div className="flex flex-nowrap items-start content-start justify-start justify-items-start">
                <div className="flex-none bg-gray-100 border rounded-md w-[24px] h-[24px] relative text-gray-700">
                    {details?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${details?.icon}`} className="block w-full rounded-md" alt="Widget icon" /> : <WidgetIcon widgetType={details.type} />}
                </div>
                <h3 className="flex-1 pl-1.5 text-md text-gray-500">{details.title || 'Title'}</h3>
            </div>
            <div className="h-[240px]">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart
                        width="100%"
                        height="100%"
                        margin={{
                            top: 20,
                            bottom: 25
                        }}>
                        <Tooltip />
                        <Pie data={chartData} dataKey="value" nameKey="category" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#88cffb" label />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
}

function prepareChartData(data, categoriesColumn, valuesColumn) {
    if (data && categoriesColumn && valuesColumn) {
        const categories = getColumnValues(data[DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT], categoriesColumn);
        const values = getColumnValues(data[DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT], valuesColumn);

        if (categories?.length === values?.length) {
            const chartData = [];
            for (var i = 0; i < categories.length; i++) {
                chartData.push({
                    category: categories[i],
                    value: Number(values[i])
                })
            }
            return chartData;
        }
    }
    return [];
}