import { React, useState, useEffect, useRef } from 'react';
import { useUserFetcher } from '../../hooks/user';
import { useTeamMemberListFetcher } from '../../hooks/team-member';
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import { useTeamMemberGroups } from '../../hooks/team-member-group';
import valueHelpers from "../../helpers/value";

export default function UserPicker({ projectKey, value, onChange, placeholder = "", groupKey = "", ...rest }) {

    const oldValue = useRef(value);
    const userPickerElemRef = useRef(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const { responseData: userData } = useUserFetcher({ userKey: value || "" });
    const [isSelectionMode, setIsSelectionMode] = useState(false);

    useEffect(() => {
        if (userData !== null) {
            setSelectedUser(userData);
        }
    }, [userData]);

    const handleSelectBtnClick = (e) => {
        e.preventDefault();
        const _isSelectionMode = !isSelectionMode
        if (_isSelectionMode) {
            oldValue.current = value;
            setSelectedUser(null);
            if (typeof onChange === 'function') {
                onChange("");
            }
        }
        setIsSelectionMode(_isSelectionMode);
    }

    const onSelect = (user) => {
        setSelectedUser(user);
        setIsSelectionMode(false);
        if (typeof onChange === 'function') {
            onChange(user?.key || "");
        }
    }

    useEffect(() => {

        const cancel = (e) => {
            const userPickerElem = userPickerElemRef.current;
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('user-picker')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('user-picker')) {
                disregard = false;
            }
            if (disregard || el !== userPickerElem) {
                setIsSelectionMode(false);
            }
        }
        document.addEventListener('click', cancel);
        return () => {
            document.removeEventListener('click', cancel);
        }
    }, []);

    return <div ref={userPickerElemRef} {...{ ...rest, className: "w-full user-picker rounded-md relative bg-gray-100 p-1 " + (rest.className || '') }}>
        <div className="flex">
            <div className="flex-1 text-sm">
                {selectedUser ? <div className="flex flex-row items-center">
                    <div className="flex-none rounded-full w-7 h-7 leading-6 bg-gray-100 overflow-hidden border text-black text-center text-md uppercase font-bold">
                        {selectedUser?.picture ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/users/medium/${selectedUser.picture}`} className="block w-full rounded-md" alt="User picture" /> : (selectedUser?.full_name ? selectedUser.full_name[0] : '')}
                    </div>
                    <div className="flex-1">
                        <h4 className="px-2 text-sm truncate">{selectedUser?.full_name}</h4>
                    </div>
                </div> : <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">No user is selected</span>}
            </div>
            <button onClick={handleSelectBtnClick} className="flex-none leading-none border bg-white hover:bg-gray-50 focus:bg-gray-200 focus:outline-none appearance-none transition rounded-md focus:ring-2 focus:ring-secondary hover:shadow-none inline-block shadow-sm text-black text-center min-w-[80px] py-1 px-2 text-sm">Select</button>
        </div>
        {isSelectionMode && <UserPickerPopup projectKey={projectKey} user={selectedUser} onSelect={onSelect} groupKey={groupKey} />}
    </div>
}

function UserPickerPopup({ projectKey, user, onSelect, groupKey }) {

    const [users, setUsers] = useState(null);
    const [selectedGroupKey, setSelectedGroupKey] = useState(groupKey);
    const [cursor, setCursor] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const { responseData: usersData } = useTeamMemberListFetcher({ projectKey, groupKey: selectedGroupKey, cursor, sortBy: "a_to_z" });

    useEffect(() => {
        if (usersData?.team_members instanceof Array) {
            if (users === null) {
                setUsers(cleanUsers(usersData.team_members, selectedGroupKey));
            } else {
                setUsers(cleanUsers([...users, ...usersData.team_members], selectedGroupKey));
            }
            setIsLoading(false);
        }
    }, [usersData]);

    useEffect(() => {
        setUsers(null);
        setIsLoading(true);
        setCursor("");
    }, [selectedGroupKey]);

    const onUserSelected = (user) => {
        return (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (typeof onSelect === 'function') {
                onSelect(user);
            }
        }
    }

    const loadMore = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        setCursor(usersData?.next_cursor);
    }

    const changeSelectedGroupKey = (e) =>{
        e.preventDefault();
        setSelectedGroupKey(e.target.value);
    }

    const { teamMemberGroups } = useTeamMemberGroups();

    return <div className="w-full absolute min-w-[200px] max-h-[300px] overflow-y-auto overflow-x-hidden bg-white z-40 rounded-md border-2 mt-0.5 left-0 top-[100%]">
        <div className="bg-gray-100 px-1 py-1 w-full">
            <select className="select select--sm block w-full" value={valueHelpers.textValue(selectedGroupKey)} onChange={changeSelectedGroupKey}>
                <option value="">Any group</option>
                {teamMemberGroups && teamMemberGroups.map((teamMemberGroup) => {
                    return <option key={teamMemberGroup.key} value={teamMemberGroup.key}>{`${teamMemberGroup.icon ? teamMemberGroup.icon : "❔"} ${teamMemberGroup.name}`}</option>
                })}
            </select>
        </div>
        <ul className="max-h-[300px] block overflow-y-auto overflow-x-hidden">
            {users != null && users.map((u, i) => {
                return <li className="block w-full" key={u.key}>
                    <a href="#" onClick={onUserSelected(u)} className="hover:bg-gray-100 active:bg-gray-200 py-1 px-2 w-full flex flex-row items-center">
                        <div className="flex-none rounded-full w-7 h-7 leading-6 bg-gray-100 overflow-hidden border text-black text-center text-md uppercase font-bold">
                            {u?.picture ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/users/medium/${u.picture}`} className="block w-full rounded-md" alt="User picture" /> : (u?.full_name ? u.full_name[0] : '')}
                        </div>
                        <div className="flex-1">
                            <h4 className="px-2 text-sm truncate">{u?.full_name}</h4>
                        </div>
                    </a>
                </li>;
            })}
            {isLoading && <>
                <li className="py-1 px-2 w-full flex items-center">
                    <div className="flex-none animate-pulse bg-slate-200 rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" />
                    <div className="flex-none animate-pulse w-[100px] h-3 bg-slate-200 rounded" />
                </li>
                <li className="py-1 px-2 w-full flex items-center">
                    <div className="flex-none animate-pulse bg-slate-200 rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" />
                    <div className="flex-none animate-pulse w-[30px] h-3 bg-slate-200 rounded mr-1" />
                    <div className="flex-none animate-pulse w-[90px] h-3 bg-slate-200 rounded" />
                </li>
            </>}
            {(!isLoading && usersData?.next_cursor !== "") && <li>
                <a href="#" onClick={loadMore} className="bg-gray-50 block text-gray-600 text-xs px-2 py-1 w-full">
                    Load more...
                </a>
            </li>}
        </ul>
    </div>
}

function cleanUsers(users, groupKey) {
    const cleanUsers = [];
    var i, j, exists;
    for (i = 0; i < users.length; i++) {
        if (groupKey === '' || users[i].group === groupKey) {
            exists = false;
            for (j = 0; j < cleanUsers.length; j++) {
                if (users[i].key === cleanUsers[j].key) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                cleanUsers.push(users[i]);
            }
        }
    }
    return cleanUsers;
}