import { React } from 'react';
import Dropdown from '../common/Dropdown';

export default function TeamMemberGroupCard({ teamMemberGroup, editable, onSelect, selected, onEdit, onDelete }) {

    const handleClick = (e) => {
        e.preventDefault();
        if (selected) {
            onSelect("");
        } else {
            onSelect(teamMemberGroup?.key);
        }
    }

    return (
        <a href="#" onClick={handleClick} className={`block relative rounded-md border mb-1.5 py-1 px-2 ${selected ? "bg-primary-lighter border-primary hover:bg-primary-light active:bg-primary" : "hover:bg-gray-200 active:bg-gray-300"}`}>
            <span className="text-md truncate"><span className="mr-2">{teamMemberGroup?.icon ? teamMemberGroup?.icon : "❔"}</span>{teamMemberGroup?.name}</span>
            {editable && <Dropdown className="inline-block absolute right-1 top-1" stopPropagation={true}>
                <button onClick={(e) => { e.preventDefault(); onEdit("group", teamMemberGroup); }} className="py-1 text-left block w-full bg-gray-100 hover:bg-gray-50 active:bg-gray-200 px-2 text-sm">Edit</button>
                <button onClick={(e) => { e.preventDefault(); onDelete("group", teamMemberGroup); }} className="py-1 text-left block w-full px-2 text-red-500 bg-gray-100 hover:bg-gray-50 active:bg-gray-200 text-sm">Delete</button>
            </Dropdown>}
        </a>
    );
}