import { React, useEffect, useRef, useState, useReducer } from "react";
import WidgetView from "./WidgetView";
import WidgetForm from "./WidgetForm";
import AlertBox from "../common/AlertBox";
import { useFileFetcher } from "../../hooks/file";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import { DATA_SOURCE_KEYS } from "../../constants/data-source";
import { appendParametersFromText, parametersReducer } from "../../reducers/parameters";
import { WIDGET_TYPES } from "../../constants/widget";
import { removeDuplicatesFromListOfObjects } from "../../helpers/array";
import requestHelpers from "../../helpers/request";
import DataSchema from "../workflow/DataSchema";
import { dataSourceTypeToDataSourceTypeKey } from "../../helpers/data-source";

export default function Widget({ projectKey, fileKey }) {
    const [parameters, dispatchParameters] = useReducer(parametersReducer, () => { return findUsedParameters(widgetFileInfo?.content); });
    const { responseData: widgetFileResponseData } = useFileFetcher({ projectKey, fileKey, withContent: true }, [projectKey, fileKey]);
    const [widgetFileInfo, setWidgetFileInfo] = useState(widgetFileResponseData);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const handleWidgetFileResponseData = (responseDataToHandle) => {
        if (responseDataToHandle) {
            var fileInfo = { ...responseDataToHandle }
            try {
                fileInfo.content = JSON.parse(fileInfo.content);
            } catch {
                fileInfo.content = { title: "", type: WIDGET_TYPES.UNSPECIFIED };
            }
            setWidgetFileInfo(fileInfo);
            dispatchParameters({ type: 'update_all', scopes: findUsedParameters(fileInfo.content) });
        }
    }

    useEffect(() => {
        handleWidgetFileResponseData(widgetFileResponseData);
    }, [widgetFileResponseData])

    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();
    const [error, setError] = useState(null);

    const closeAlertBox = (e) => {
        e.preventDefault();
        if (error) {
            setError(null);
        }
    }

    const [isBeingSaved, setIsBeingSaved] = useState(false);
    const save = (updatedWidgetFileInfo) => {
        if (!isBeingSaved) {
            setIsBeingSaved(true);
            if (error) {
                setError(null);
            }
            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workspace/files/${widgetFileInfo?.key}`,
                data: { ...updatedWidgetFileInfo, content: JSON.stringify(updatedWidgetFileInfo.content) },
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onError: (response) => {
                    if (mounted.current) {
                        setError(response);
                    }
                },
                onSuccess: (response) => {
                    if (mounted.current) {
                        handleWidgetFileResponseData(response?.data?.data);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBeingSaved(false);
                    }
                }
            });
        }
    }

    const [isRunning, setIsRunning] = useState(false);
    const [workflowRunKey, setWorkflowRunKey] = useState(null);

    const run = (workflowKey, workflowArgs) => {
        if (!isRunning) {
            setIsRunning(true);
            if (error) {
                setError(null);
            }

            var queryParams = null;
            if (workflowArgs && workflowArgs.length > 0) {
                queryParams = {}
                for (var i = 0; i < workflowArgs.length; i++) {
                    queryParams[workflowArgs[i].name] = workflowArgs[i].value;
                }
            }

            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workflows/${workflowKey}/runs/async`,
                queryParams: queryParams,
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onSuccess: (response) => {
                    if (mounted.current) {
                        setWorkflowRunKey(response?.data?.data?.key);
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setIsRunning(false);
                        setWorkflowRunKey(null);
                        setError(response);
                    }
                }
            });
        }
    }

    const stop = (workflowKey) => {
        if (isRunning) {
            setIsRunning(false);
            if (error) {
                setError(null);
            }
            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workflows/${workflowKey}/runs/${workflowRunKey}/stop`,
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onError: (response) => {
                    if (mounted.current) {
                        setError(response);
                    }
                }
            });
        }
    }

    const [editMode, setEditMode] = useState(false);
    const [currentTab, setCurrentTab] = useState('data');

    const update = (updatedDetails) => {
        const updatedWidgetFileInfo = {
            ...widgetFileInfo,
            icon: updatedDetails?.icon || '',
            content: updatedDetails,
        };
        setWidgetFileInfo(updatedWidgetFileInfo);
        save(updatedWidgetFileInfo);
        setEditMode(false);
    }

    const enableEditMode = (e) => {
        e.preventDefault();
        setEditMode(true);
    }

    const [widgetOutput, setWidgetOutput] = useState(null);

    const [dataSchemaSource, setDataSchemaSource] = useState(null);
    const showDataSchema = (steIndex, step, dataSourceType, data, tableKey) => {
        setDataSchemaSource({
            title: `0. Data`,
            data: data[dataSourceTypeToDataSourceTypeKey(dataSourceType)],
            tableKey: tableKey,
        });
    }

    const hideDataSchema = () => {
        setDataSchemaSource(null);
    }

    return <>
        <div className="max-w-full md:flex md:items-start md:flex-col md:flex-nowrap relative">
            {!editMode ? <WidgetView projectKey={projectKey} widgetOutput={widgetOutput} setWidgetOutput={setWidgetOutput} enableEditMode={enableEditMode} details={widgetFileInfo?.content} update={update} lastCheckpoint={widgetFileInfo?.modified} workflowRunKey={workflowRunKey} onRun={run} onStop={stop} setIsRunning={setIsRunning} isRunning={isRunning} setError={setError} isBeingSaved={isBeingSaved} parameters={parameters} dispatchParameters={dispatchParameters} currentTab={currentTab} setCurrentTab={setCurrentTab} showDataSchema={showDataSchema} />
                : <WidgetForm projectKey={projectKey} widgetOutput={widgetOutput} setWidgetOutput={setWidgetOutput} details={widgetFileInfo?.content} lastCheckpoint={widgetFileInfo?.modified} update={update} workflowRunKey={workflowRunKey} onRun={run} onStop={stop} setIsRunning={setIsRunning} isRunning={isRunning} setError={setError} isBeingSaved={isBeingSaved} parameters={parameters} dispatchParameters={dispatchParameters} currentTab={currentTab} setCurrentTab={setCurrentTab} showDataSchema={showDataSchema} />}
        </div>
        {error ? <AlertBox title="Error" error={error} onClose={closeAlertBox} /> : null}
        {dataSchemaSource && <DataSchema source={dataSchemaSource} onClose={hideDataSchema} />}
    </>
}

function findUsedParameters(widget) {
    var scopes = {
        [DATA_SOURCE_KEYS.ANY]: { params: [] },
    };
    if (widget) {
        switch (widget.type) {
            case WIDGET_TYPES.SCORECARD:
                scopes = appendParametersFromText(widget.value, scopes);
                break;
            case WIDGET_TYPES.BAR_CHART:
                if (widget.x_axis) {
                    scopes = appendParametersFromText(widget.x_axis.label, scopes);
                    scopes = appendParametersFromText(widget.x_axis.values, scopes);
                }
                if (widget.y_axis) {
                    scopes = appendParametersFromText(widget.y_axis.label, scopes);
                    scopes = appendParametersFromText(widget.y_axis.values, scopes);
                }
                break;
            case WIDGET_TYPES.LINE_CHART:
                if (widget.x_axis) {
                    scopes = appendParametersFromText(widget.x_axis.label, scopes);
                    scopes = appendParametersFromText(widget.x_axis.values, scopes);
                }
                if (widget.y_axis) {
                    scopes = appendParametersFromText(widget.y_axis.label, scopes);
                    scopes = appendParametersFromText(widget.y_axis.values, scopes);
                }
                break;
            case WIDGET_TYPES.PIE_CHART:
                scopes = appendParametersFromText(widget.categories, scopes);
                scopes = appendParametersFromText(widget.values, scopes);
                break;
        }
    }

    // remove duplicates
    for (var dataSourceKey in scopes) {
        scopes[dataSourceKey].params = removeDuplicatesFromListOfObjects(scopes[dataSourceKey].params, (param) => `${param.dataSourceType}-${param.dataKey}`);
    }
    return scopes;
}