import { React } from 'react';

export default function StepError({ data }) {

    return (<div className="md:flex-none md:min-w-lg bg-red-100 max-w-full shadow-sm rounded-md relative">
        <p className="px-3 py-1 text-gray-700 inline-block text-sm">An error occured while running the step number <strong>{(data?.index || 0) + 1}</strong>.</p>
        <div className="max-w-full max-h-72 overflow-auto">
            <table className="border-collapse w-full">
                <tbody>
                    <tr className={`border-b ${!data?.error?.data_type ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">Data type</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.data_type === 'undefined') ? '' : data?.error?.data_type}</td>
                    </tr>
                    <tr className={`border-b ${!data?.error?.database_key ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">Input key</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.database_key === 'undefined') ? '' : data?.error?.database_key}</td>
                    </tr>
                    <tr className={`border-b ${!data?.error?.table_key ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">Table key</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.table_key === 'undefined') ? '' : data?.error?.table_key}</td>
                    </tr>
                    <tr className={`border-b ${!data?.error?.row_key ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">Row key</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.row_key === 'undefined') ? '' : data?.error?.row_key}</td>
                    </tr>
                    <tr className={`border-b ${!data?.error?.column_name ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">Column name</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.column_name === 'undefined') ? '' : data?.error?.column_name}</td>
                    </tr>
                    <tr className={`border-b ${!data?.error?.details ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">Details</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.details === 'undefined') ? '' : data?.error?.details}</td>
                    </tr>
                    <tr className={`border-b ${!data?.error?.value ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">Wrong value</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.value === 'undefined') ? '' : data?.error?.value}</td>
                    </tr>
                    <tr className={`border-b ${!data?.error?.more_details ? 'hidden' : ''}`}>
                        <th className="bg-gray-50 px-2 py-1 border-r text-left font-semibold text-xs">More details</th>
                        <td className="border-r bg-white px-2 py-1 text-xs">{(typeof data?.error?.more_details === 'undefined') ? '' : data?.error?.more_details}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>);
}