
export const DATA_SOURCE_KEYS = {
    ANY: "any",
    INPUT: "input",
    PARAMETER: "parameter",
};


export const DATA_SOURCE_TYPES = {
    ANY: 0,
    INPUT: 1,
    PARAMETER: 2,
    STEP_OUTPUT: 3,
    STEP_META: 4
};

export const DATA_SOURCE_TYPE_KEYS = {
    ANY: "any",
    INPUT: "input",
    PARAMETER: "parameters",
    STEP_OUTPUT: "output",
    STEP_META: "meta"
};