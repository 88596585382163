import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from '../config';
import useAccessToken from "./access-token";
import useLogout from "./logout";
import { useCancelToken } from "./cancel-token";

export default function useApiRequest({ urlPath, method = 'get', data, queryParams, authenticationIsRequired = true, jsonResponseData = true }, dependencies = []) {
    const { newCancelToken, isCancel } = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const [response, setResponse] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [errorResponse, setErrorResponse] = useState(null);

    const sendRequest = () => {
        if ((authenticationIsRequired && accessToken) || !authenticationIsRequired) {
            setResponse(null);
            setErrorResponse(null);
            axios({
                method: method,
                url: API_BASE_URL + urlPath,
                cancelToken: newCancelToken(),
                headers: {
                    'Authorization': `Bearer ${accessToken?.token}`
                },
                data: data,
                params: queryParams
            }).then((response) => {
                setResponse(response);
            }).catch((error) => {
                if (!isCancel(error)) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if (error.response.status === 401) {
                            logout();
                        } else {
                            setErrorResponse(error.response);
                        }
                    } else {
                        setErrorResponse("No internet connection");
                    }
                }
            });
        }
    }

    useEffect(() => {
        sendRequest();
    }, [accessToken, ...dependencies]);

    useEffect(() => {
        let newResponseData = response?.data?.data || (response?.data || null);
        if (typeof newResponseData === 'string' && jsonResponseData) {
            try {
                newResponseData = JSON.parse(newResponseData);
            } catch (_) { }
        }
        setResponseData(newResponseData);
    }, [response])

    return { responseData, response, errorResponse, retry: sendRequest };
}
