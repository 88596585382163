import React, { useEffect, useState } from "react";
import Row from "./Row";
import Highlighter from "./Highlighter";
import { LOGO_CHARACTERS } from "../../config";

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getPositionOfWords(words) {
    if (words.length === 2) {
        var verticalWord, horizontalWord;
        if (words[0].length < words[1].length) {
            verticalWord = words[0].toUpperCase();
            horizontalWord = words[1].toUpperCase();
        } else {
            verticalWord = words[1].toUpperCase();
            horizontalWord = words[0].toUpperCase();
        }
        var commonCharPosition = { column: 0, row: 0 };
        for (var i = 0; i < verticalWord.length; i++) {
            commonCharPosition.column = horizontalWord.indexOf(verticalWord[i]);
            if (commonCharPosition.column > -1) {
                commonCharPosition.row = i;
                break;
            }
            commonCharPosition.column = 0;
        }
        return {
            vertical: {
                word: verticalWord,
                column_index: commonCharPosition.column
            },
            horizontal: {
                word: horizontalWord,
                row_index: commonCharPosition.row
            }
        };
    }
    return null
}

function usePositionOfWords(words) {
    const [positionOfWords, setPositionOfWords] = useState(() => getPositionOfWords(words));
    useEffect(() => {
        setPositionOfWords(getPositionOfWords(words));
    }, [words]);
    return positionOfWords;
}

function getCellCharacter(rowIndex, colIndex, positionOfWords) {
    if (positionOfWords != null) {
        if (colIndex === positionOfWords.vertical.column_index) {
            return positionOfWords.vertical.word[rowIndex];
        }
        if (rowIndex === positionOfWords.horizontal.row_index) {
            return positionOfWords.horizontal.word[colIndex];
        }
    }
    return LOGO_CHARACTERS[getRandomInt(0, LOGO_CHARACTERS.length - 1)];
}

function getRows(positionOfWords, random) {
    var rows = [];
    if (positionOfWords != null) {
        var rowIndex, columnIndex;
        for (rowIndex = 0; rowIndex < positionOfWords.vertical.word.length; rowIndex++) {
            rows.push([]);
            for (columnIndex = 0; columnIndex < positionOfWords.horizontal.word.length; columnIndex++) {
                rows[rowIndex].push(getCellCharacter(rowIndex, columnIndex, random ? null : positionOfWords))
            }
        }
    }
    return rows;
}

function useRowGenerator(positionOfWords, random) {
    const [rows, setRows] = useState(() => getRows(positionOfWords, random));
    useEffect(() => {
        setRows(getRows(positionOfWords, random));
    }, [positionOfWords]);
    return [rows, setRows];
}

function isRandom(numberOfCycles, maxRandomCycles) {
    return maxRandomCycles === -1 || numberOfCycles < maxRandomCycles
}

export default function Loader({ words, colors, maxRandomCycles}) {

    const [numberOfCycles, setNumberOfCycles] = useState(0);
    const positionOfWords = usePositionOfWords(words);
    const [rows, setRows] = useRowGenerator(positionOfWords, isRandom(numberOfCycles, maxRandomCycles));

    const shouldStop = () => {
        return numberOfCycles > maxRandomCycles + 8;
    }

    const isInfiniteLoop = () => {
        return maxRandomCycles === -1;
    }

    useEffect(() => {
        let timeoutID = setTimeout(() => {
            if (isInfiniteLoop() || !shouldStop()) {
                setRows(getRows(positionOfWords, isRandom(numberOfCycles, maxRandomCycles)));
                setNumberOfCycles(numberOfCycles + 1);
            }
        }, 250);
        return () => { clearTimeout(timeoutID) };
    }, [numberOfCycles]);

    const visibleHighlights = numberOfCycles >= maxRandomCycles && maxRandomCycles !== -1;

    return (
        <div aria-label="Loader" className="inline-block relative">
            {rows && rows.map((cells, rowIndex) => <Row key={rowIndex.toString()} cells={cells} />)}
            {positionOfWords != null && (positionOfWords.horizontal.row_index === 0 ?
                <>
                    <Highlighter delay="1000ms" isVisible={visibleHighlights} word={positionOfWords.horizontal.word} direction="horizontal" startRowIndex={positionOfWords.horizontal.row_index} startColumnIndex={0} color={colors ? colors[1] : ''} />
                    <Highlighter delay="1500ms" isVisible={visibleHighlights} word={positionOfWords.vertical.word} direction="vertical" startRowIndex={0} startColumnIndex={positionOfWords.vertical.column_index} color={colors ? colors[0] : ''} />
                </>
                :
                <>
                    <Highlighter delay="1000ms" isVisible={visibleHighlights} word={positionOfWords.vertical.word} direction="vertical" startRowIndex={0} startColumnIndex={positionOfWords.vertical.column_index} color={colors ? colors[0] : ''} />
                    <Highlighter delay="1500ms" isVisible={visibleHighlights} word={positionOfWords.horizontal.word} direction="horizontal" startRowIndex={positionOfWords.horizontal.row_index} startColumnIndex={0} color={colors ? colors[1] : ''} />
                </>
            )}
        </div>
    );
}