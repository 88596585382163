import { encodeURIComonentInOuterText } from "./param";

const optionsRegex = /(--[a-zA-Z\-]+ '.*?')|(--[a-zA-Z\-]+)|(-[a-zA-Z\-]+? '.+?')|('?[a-z]+:\/\/.*?'+?)|("?[a-z]+:\/\/.*?"+?)/g; // eslint-disable-line
const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=<>]*)$/; // eslint-disable-line

const contentTypeHeader = 'content-type';
const jsonMimeType = 'application/json';

const isMatchingOption = (headers, str) => {
    for (let i = 0; i < headers.length; i += 1) {
        if (str.startsWith(headers[i])) {
            return true;
        }
    }
    return false;
};

const isMethodOption = str => isMatchingOption(['-X ', '--request '], str);
const isAHeaderOption = str => isMatchingOption(['-H ', '--headers ', '--header '], str);
const isDataOption = str => isMatchingOption(['--data ', '--data-ascii ', '-d ', '--data-raw ', '--dta-urlencode ', '--data-binary '], str);

const removeLeadingTrailingQuotes = (str) => {
    const quotes = ['\'', '"'];
    const newStr = str.trim();
    return quotes.includes(newStr[0]) ?
        newStr.substr(1, (newStr[0] === newStr[newStr.length - 1]) ? newStr.length - 2 : newStr.length - 1)
        : newStr;
};

const subStrFrom = (val, startFromVal) => {
    const dataPosition = val.indexOf(startFromVal);
    return val.substr(dataPosition);
};

const splitN = (s, separator, n) => {
    if (n === 0) {
        return [];
    }
    if (n === 1 || separator === '' || separator === null) {
        return [s];
    }
    const out = [];
    var currentVal = '';
    for (var i = 0; i < s.length; i++) {
        if (s[i] === separator) {
            out.push(currentVal);
            currentVal = '';
            if (out.length + 1 === n) {
                currentVal = s.substr(i + 1);
                out.push(currentVal);
                break;
            }
        } else {
            currentVal += s[i];
        }
    }
    return out;
}

const isJsonRequest = headers => {
    for (var header in headers) {
        if (header.toLowerCase() === contentTypeHeader) {
            return headers[header].toLowerCase().indexOf(jsonMimeType) !== -1
        }
    }
    return false;
};

const parseMethodOption = (option) => {
    return removeLeadingTrailingQuotes(subStrFrom(option, ' ')).toUpperCase();
};

const parseBodyByContentType = ({ body, headers }) => {
    if (body && isJsonRequest(headers)) {
        try {
            const cleanedBodyData = body.replace('\\"', '"').replace("\\'", "'");
            return JSON.stringify(JSON.parse(cleanedBodyData), 0, 2);
        } catch (ex) {
            // ignore json conversion error..
            console.log('Cannot parse JSON Data ' + ex.message); // eslint-disable-line
        }
    }
    return body;
};

const parseHeaderOption = (option) => {
    const headerSplit = splitN(removeLeadingTrailingQuotes(subStrFrom(option, ' ')), ':', 2);
    return {
        key: headerSplit[0],
        value: headerSplit[1].trim(),
    };
};

const parseQueryParams = (url) => {
    const paramPosition = url.indexOf('?');
    const queryParams = [];
    if (paramPosition !== -1) {
        const paramsString = url.substr(paramPosition + 1);
        const params = paramsString.split('&') || [];

        params.forEach((param) => {
            const splitParam = splitN(param, '=', 2); // eslint-disable-line
            if (splitParam.length === 2) {
                queryParams.push({ key: decodeURIComponent(splitParam[0]), value: decodeURIComponent(splitParam[1]) }); // eslint-disable-line
            }
        });
    }
    return queryParams;
};

const parseUrlOption = (val) => {
    const urlMatches = val.match(urlRegex) || [];
    if (urlMatches.length) {
        const url = urlMatches[0]; // eslint-disable-line
        return {
            url,
            params: parseQueryParams(url),
        };
    }
    return { url: '', params: [] };
};

const parseBody = val => removeLeadingTrailingQuotes(subStrFrom(val, ' '));

const isACurlCommand = val => val.trim().startsWith('curl ');
const isAUrlOption = (val) => {
    const matches = val.match(urlRegex) || [];
    return !!matches.length;
};

/*
 * Parse cUrl command to a JSON structure
 * params:
 * command - cUrl command as a string.
 * return JSON object
*/
function toJSON(command) {
    if (!command) { return ''; }

    const parsedCommand = {
        url: '',
        method: 'GET',
        headers: [],
        params: [],
        body: '',
    };

    // quit if the command does not starts with curl
    if (isACurlCommand(command)) {
        const matches = command.match(optionsRegex);
        matches.forEach((val) => {
            const option = removeLeadingTrailingQuotes(val);
            if (isMethodOption(option)) {
                parsedCommand.method = parseMethodOption(option);
            } else if (isAUrlOption(option)) {
                const { url, params } = parseUrlOption(option);
                parsedCommand.url = url;
                parsedCommand.params = params;
            } else if (isAHeaderOption(option)) {
                parsedCommand.headers.push(parseHeaderOption(option));
            } else if (isDataOption(option)) {
                parsedCommand.body = parseBody(option);
            } else {
                console.log(`Skipped Header ${val}`); // eslint-disable-line
            }
        }); // parse over matches ends

        // should be checked after all the options are analyzed
        // so that we guarentee that we have content-type header
        parsedCommand.body = parseBodyByContentType(parsedCommand);
    }

    return parsedCommand;
}

const getCurlUrl = (url, params) => {
    var urlQueryParams = parseQueryParams(url);
    const paramPosition = url.indexOf('?');
    url = paramPosition !== -1 ? url.substr(0, paramPosition) : url;
    if (urlQueryParams.length > 0) {
        var i, j, paramFound;
        for (i = 0; i < params.length; i++) {
            paramFound = false;
            for (j = 0; j < urlQueryParams.length; j++) {
                if (urlQueryParams[j].key === params[i].key) {
                    paramFound = true;
                    urlQueryParams[j].value = params[i].value;
                    break;
                }
            }
            if (!paramFound) {
                urlQueryParams.push(params[i]);
            }
        }
    } else {
        urlQueryParams = params || [];
    }

    return url = urlQueryParams.length > 0 ? (url + '?' + urlQueryParams.map((p) => `${encodeURIComonentInOuterText(p.key)}=${encodeURIComonentInOuterText(p.value)}`).join('&')) : url;
}

const requestCurlMethod = {
    GET: '-X GET',
    POST: '-X POST',
    PUT: '-X PUT',
    PATCH: '-X PATCH',
    DELETE: '-X DELETE',
}

const getCurlMethod = (method) => {
    return typeof method === 'string' ? requestCurlMethod[method.trim().toUpperCase()] || '' : '';
}

const getCurlHeaders = (headers) => {
    let result = ""
    if (headers) {
        for (var i = 0; i < headers.length; i++) {
            result += `\\\n -H '${headers[i].key}: ${headers[i].value.replace(/(\\|")/g, '\\$1')}' `;
        }
    }
    return result
}

const getCurlBody = (body, headers) => {
    let result = "";
    if (typeof body !== 'undefined') {
        if (isJsonRequest(headers)) {
            try {
                return `\\\n -d "${(JSON.stringify(body, 0, 2)).replace(/(\\|")/g, '\\$1')}" `
            } catch (ex) {
                // ignore json conversion error..
                console.log('Cannot stringify JSON Data ' + ex.message); // eslint-disable-line
            }
        }
        result = body.toString();
    }
    return result
}

/*
 * Generate cUrl command from a JSON structure
 * params:
 * request - JSON object.
 * return cUrl command as a string.
*/
function fromJSON(request) {
    let curlSnippet = "curl "
    curlSnippet += `'${getCurlUrl(request.url, request.params)}' `
    curlSnippet += getCurlMethod(request.method)
    curlSnippet += getCurlHeaders(request.headers)
    curlSnippet += getCurlBody(request.body, request.headers)
    return curlSnippet.trim();
}

const curl = {
    toJSON: toJSON,
    fromJSON: fromJSON,
}

export default curl;
