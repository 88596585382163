import { React, useState, useRef, useEffect } from "react";
import Checkbox from "./Checkbox";
import keyHelpers from "../../helpers/key";

function areAllColumnsSelected(columns) {
    if (columns) {
        for (var column in columns) {
            if (!columns[column]) {
                return false;
            }
        }
    }
    return true;
}

export default function ColumnSelector({ value, onClose, onChange }) {

    const columnSelectorRef = useRef(null);
    const [columns, setColumns] = useState(value);
    const areAllSelected = areAllColumnsSelected(columns);

    const handleSelectAll = (checked) => {
        if ((checked && !areAllSelected) || (!checked && areAllSelected)) {
            const newValue = {};
            for (var column in columns) {
                newValue[column] = checked;
            }
            setColumns(newValue);
        }
    }

    const handleSelectColumn = (column) => {
        return (checked) => {
            if (column in columns) {
                setColumns({ ...columns, [column]: checked });
            }
        }
    }

    const [searchResults, setSearchResults] = useState(() => Object.keys(columns));

    const search = (keyword) => {
        if ((keyword?.length || 0) === 0) {
            return Object.keys(columns);
        }
        keyword = keyword.trim().toLowerCase();
        let results = [];
        let columnNames = Object.keys(columns);
        for (var i = 0; i < columnNames.length; i++) {
            if (columnNames[i].trim().toLowerCase().indexOf(keyword) !== -1) {
                results.push(columnNames[i]);
            }
        }
        return results;
    }

    const [searchKeyword, setSearchKeyword] = useState("");

    const handleSearch = (e) => {
        setSearchKeyword(e.target.value);
        setSearchResults(search(e.target.value));
    }

    const handleCancel = (e) => {
        e.preventDefault();
        onClose();
    }

    const handleApply = (e) => {
        e.preventDefault();
        onChange(columns);
        onClose();
    }

    useEffect(() => {

        const cancel = (e) => {
            const columnSelectorElem = columnSelectorRef.current;

            var el = e.target, disregard = true;
            while (el && !el.classList.contains('column-selector')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('column-selector')) {
                disregard = false;
            }
            if (disregard || el !== columnSelectorElem) {
                onClose();
            }
        }
        document.addEventListener('click', cancel);
        return () => {
            document.removeEventListener('click', cancel);
        }
    }, [])

    return <div ref={columnSelectorRef} className="column-selector bg-white border min-w-48 z-30 absolute py-2 px-1 top-[100%] mg-1 rounded-md right-0 shadow-lg inline-block">
        <div className="px-1"><input type="text" value={searchKeyword} onChange={handleSearch} className="w-full input input--sm" placeholder="Find column" /></div>
        <ul className="overflow-y-auto overflow-x-visible max-h-52">
            <li className="py-1">
                <Checkbox className="px-1 focus:border-secondary-dark text-sm py-1" value={areAllSelected} onChange={handleSelectAll}><strong>Select all</strong></Checkbox>
            </li>
            {searchResults.map((column, i) => {
                const columnNameFields = keyHelpers.extractFieldsFromColumnName(column);
                return <li key={i.toString()}>
                    <Checkbox className="px-1 focus:rounded-md focus:border focus:border-secondary-dark py-1" value={columns[column]} onChange={handleSelectColumn(column)}>
                        <span className="max-w-48 text-left text-xs">{columnNameFields.map((s, j) => <span key={j.toString()} className={`line-clamp-2 break-all ${j + 1 === columnNameFields.length ? 'text-black' : 'text-gray-500'}`}>{j === 0 ? s : `.${s}`}</span>)}</span>
                    </Checkbox>
                </li>
            })}
        </ul>
        <div className="px-1 text-right">
            <button className="btn btn--secondary mr-2 btn--sm" onClick={handleCancel}>Cancel</button>
            <button className="btn btn--primary btn--sm" onClick={handleApply}>Apply</button>
        </div>
    </div>
}