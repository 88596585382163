import { React, useRef, useEffect } from "react";
import Row from "./Row";
import { LOGO_CHARACTER_BOX_SIZE } from "../../config";


function useEntranceAnimation(highlighter, isVisible, word, direction) {
    useEffect(() => {
        const highlighterElem = highlighter.current;
        if (highlighterElem) {
            if (direction === "vertical") {
                highlighterElem.style.maxHeight = isVisible ? (word.length * LOGO_CHARACTER_BOX_SIZE) + "px" : 0;
            } else {
                highlighterElem.style.maxWidth = isVisible ? (word.length * LOGO_CHARACTER_BOX_SIZE) + "px" : 0;
            }
        }
    }, [isVisible, highlighter.current])
}

export default function Highlighter({ isVisible, word, direction, startRowIndex, startColumnIndex, color, delay }) {

    const highlighter = useRef(null);
    const style = ({
        maxHeight: (!isVisible && direction === "vertical") ? 0 : 'auto',
        maxWidth: (!isVisible && direction === "horizontal") ? 0 : 'auto',
        top: (startRowIndex * LOGO_CHARACTER_BOX_SIZE) + "px",
        left: (startColumnIndex * LOGO_CHARACTER_BOX_SIZE) + "px",
        transitionDelay: delay
    });

    useEntranceAnimation(highlighter, isVisible, word, direction);

    return (
        <div className={`overflow-hidden transition-all ease-linear absolute rounded-lg ${color}`} ref={highlighter} style={style}>
            {direction === "horizontal" ?
                <Row cells={Array.from(word).map((char) => char.toUpperCase())} />
                :
                Array.from(word).map((char, index) => <Row key={index} cells={[char.toUpperCase()]} />)
            }
        </div>
    );
}
