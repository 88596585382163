const CONDITIONS = [
    {
        group: 'Text',
        list: [
            {
                value: "TextContains",
                label: "Contains"
            }
        ],
    },
    {
        group: 'Number',
        list: [
            {
                value: "NumberLessThan",
                label: "Less than"
            },
            {
                value: "NumberEqualTo",
                label: "Equal to"
            },
            {
                value: "NumberGreaterThan",
                label: "Greater than"
            }
        ]
    }
];

export default CONDITIONS;