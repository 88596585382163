import { React, useRef, useState, useEffect } from 'react';
import { ReactComponent as Dots } from '../../icons/dots.svg';

export default function Dropdown({ children, stopPropagation = false, ...rest }) {
    const dropdownBtnRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);


    const toggle = (e) => {
        e.preventDefault();
        if (stopPropagation){
            e.stopPropagation();
        }
        setIsVisible(!isVisible);
    }

    useEffect(() => {
        const cancel = (e) => {
            const dropdownBtnElem = dropdownBtnRef.current;

            var el = e.target, disregard = true;
            while (el && !el.classList.contains('dropdown-btn')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('dropdown-btn')) {
                disregard = false;
            }
            if (disregard || el !== dropdownBtnElem) {
                setIsVisible(false);
            }
        }
        document.addEventListener('click', cancel);
        return () => {
            document.removeEventListener('click', cancel);
        }
    }, []);

    const items = children ? children instanceof Array ? children : [children] : [];

    return (
        <div { ...rest}>
            <button ref={dropdownBtnRef} onClick={toggle} className="dropdown-btn inline-block text-gray-800 hover:text-gray-600 rounded-md hover:bg-gray-100 active:bg-gray-300 px-2 py-1">
                <Dots className="h-4 fill-current" />
            </button>
            {isVisible && <ul className="overflow-hidden min-w-[80px] absolute rounded-md top-6 right-0 z-40 border-gray-200 shadow-md">
                {items && items.map((item, i) => <li key={i.toString()} className={`${i + 1 !== items.length ? 'border-b border-gray-200' : ''}`}>{item}</li>)}
            </ul>}
        </div>
    )
}