import { React, useState, useRef, useEffect } from "react";
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as XIcon } from '../../icons/x.svg';
import { ReactComponent as DirectoryIcon } from '../../icons/file/directory.svg';
import { ReactComponent as WorkflowIcon } from '../../icons/file/workflow.svg';
import { ReactComponent as JsonIcon } from '../../icons/file/json.svg';
import { ReactComponent as WidgetIcon } from '../../icons/file/widget.svg';
import { ReactComponent as DashboardIcon } from '../../icons/file/dashboard.svg';
import { FILE_TYPES } from "../../constants/file";

export default function FileCreationAction({ onSelect }) {
    const popupRef = useRef(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPopupVisible(!isPopupVisible);
    }

    useEffect(() => {

        const handleDisregard = (e) => {
            const popupElem = popupRef.current;
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('file-types')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('file-types')) {
                disregard = false;
            }
            if (disregard || el !== popupElem) {
                setIsPopupVisible(false);
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [])

    const handleTypeClick = (fileType) => {
        return (e) => {
            e.preventDefault();
            if (typeof onSelect === 'function') {
                onSelect(fileType);
            }
            setIsPopupVisible(false);
        }
    }

    return <div className="flex-none relative">
        <ul ref={popupRef} className={`absolute z-30 file-types bottom-[100%] overflow-hidden rounded-lg bg-white shadow-md right-1 w-[200px] mb-7 ${!isPopupVisible ? 'hidden' : ''}`}>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleTypeClick(FILE_TYPES.DIRECTORY.CODE)}><DirectoryIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">{FILE_TYPES.DIRECTORY.NAME}</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleTypeClick(FILE_TYPES.WORKFLOW.CODE)}><WorkflowIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">{FILE_TYPES.WORKFLOW.NAME}</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleTypeClick(FILE_TYPES.JSON.CODE)}><JsonIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">{FILE_TYPES.JSON.NAME}</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleTypeClick(FILE_TYPES.WIDGET.CODE)}><WidgetIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">{FILE_TYPES.WIDGET.NAME}</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleTypeClick(FILE_TYPES.DASHBOARD.CODE)}><DashboardIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">{FILE_TYPES.DASHBOARD.NAME}</span></a></li>
        </ul>
        <button className="block -mt-6 rounded-full shadow-md p-4 bg-primary hover:bg-primary-light active:bg-primary-dark transition-all" onClick={togglePopup}>{isPopupVisible ? <XIcon className="block w-[20px] h-[20px]" /> : <PlusIcon className="block w-[20px] h-[20px]" />}</button>
    </div>
}