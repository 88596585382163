import process from "process";
const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

//API
export const API_BASE_URL = development ? "http://192.168.31.10:8080" : "https://api.dataassembler.com";
export const WEBSOCKET_URL = development ? "ws://192.168.31.10:8080/realtime" : "wss://api.dataassembler.com/realtime";
export const FORM_ERROR_KEY = "*";
export const UPLOADS_STORAGE_BASE_URL = 'https://storage.googleapis.com/dataassembler-public/uploads';

//Errors
export const NO_INTERNET_CONNECTION_ERROR = "No internet connection. Please check your internet connection and try again.";

//Logo
export const LOGO_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const LOGO_CHARACTER_BOX_SIZE = 20;

// Firebase config
export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyCiGc6XkK03Gk8oCs6kyyvVRux436wrTC4",
    authDomain: "dataassembler.firebaseapp.com",
    projectId: "dataassembler",
    messagingSenderId: "63933978736",
    appId: "1:63933978736:web:482a30b0514fd4dee7fcb5"
};
