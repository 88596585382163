import { React } from "react";
import DataInput from "../common/DataInput";
import valueHelpers from "../../helpers/value";

export default function PieChartForm({ data, handleChange, parameters }) {
    return <>
        <label className="label !text-gray-500">Categories</label>
        <DataInput parameters={parameters} className="mb-2" placeholder="Categories" value={valueHelpers.textValue(data.categories)} onChange={handleChange("categories")} />
        <label className="label !text-gray-500">Values</label>
        <DataInput parameters={parameters} className="mb-2" placeholder="Values" value={valueHelpers.textValue(data.values)} onChange={handleChange("values")} />
    </>
}