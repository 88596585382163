import { React, useState, useEffect, useRef } from "react";
import store from "store";
import axios from "axios";
import useForm from "../../hooks/form";
import { API_BASE_URL, FORM_ERROR_KEY, NO_INTERNET_CONNECTION_ERROR } from "../../config"
import { useCancelToken } from "../../hooks/cancel-token";
import useAccessToken from "../../hooks/access-token";
import valueHelpers from "../../helpers/value";

export default function LoginForm() {

    const [isBusy, setIsBusy] = useState(false);
    const { newCancelToken, isCancel } = useCancelToken();
    const accessToken = useAccessToken();

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors, setErrors, setErrorsFromResponse } = useForm({
        validations: [
            // {
            //     field_key: "password",
            //     custom: {
            //         isValid: (value) => {
            //             return value ? (value.length >= 6 && value.length <= 16) : false;
            //         },
            //         message: 'Password length must be between 6 and 16 characters'
            //     }
            // }
        ],
        onSubmit: (submittedData) => {
            if (!isBusy) {
                setIsBusy(true);
                axios({
                    method: 'post',
                    data: submittedData,
                    url: `${API_BASE_URL}/auth/user`,
                    cancelToken: newCancelToken(),
                }).then((response) => {
                    const tokenData = response?.data?.data;
                    if (tokenData) {
                        store.set('access_token', tokenData);
                        accessToken.setToken(tokenData);
                    }
                }).catch((error) => {
                    if (mounted.current) {
                        setIsBusy(false);
                        if (!isCancel(error)) {
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                setErrorsFromResponse(error.response);
                            } else {
                                setErrors({ [FORM_ERROR_KEY]: NO_INTERNET_CONNECTION_ERROR });
                            }
                        }
                    }
                });
            }
        }
    });


    return (
        <form onSubmit={handleSubmit} className="bg-white px-4 py-2 rounded-lg shadow-sm">
            <label className="block" htmlFor="email">
                <div className="label">Email</div>
                <input className="input input--full" type="email" value={valueHelpers.textValue(data.email)} onChange={handleChange('email')} required name="email" placeholder="name@example.com" />
            </label>
            <label className="block mt-1" htmlFor="password">
                <div className="label">Password</div>
                <input className={`input input--full ${errors?.password && 'input--error'}`} type="password" value={valueHelpers.textValue(data.password)} onChange={handleChange('password')} required name="password" placeholder="Password" />
                {errors?.password && <div className="input-error">{errors.password}</div>}
            </label>
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <button disabled={isBusy} className={`btn btn--primary btn--full mt-3 ${isBusy ? "btn--disabled" : ""}`} type="submit">Login</button>
        </form>
    );
}