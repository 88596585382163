import axios from "axios";
import { API_BASE_URL } from '../config';

function sendApiRequest({ urlPath, method = 'get', contentType = 'application/json', data, queryParams, onSuccess, onError, onComplete, onAccessDenied, accessToken, cancelToken }) {
    axios({
        method: method,
        url: API_BASE_URL + urlPath,
        cancelToken: cancelToken.newCancelToken(),
        headers: {
            'Authorization': `Bearer ${accessToken?.token}`,
            'Content-Type': data ? contentType : undefined,
        },
        data: data,
        params: queryParams
    }).then((response) => {
        if (typeof onSuccess === 'function') {
            onSuccess(response);
        }
    }).catch((error) => {
        if (!cancelToken.isCancel(error)) {
            if (error.response) {
                if (error.response.status === 401) {
                    if (typeof onAccessDenied === 'function') {
                        onAccessDenied();
                    }
                }
            }
            if (typeof onError === 'function') {
                onError(error.response);
            }
        }
    }).finally(() => {
        if (typeof onComplete === 'function') {
            onComplete();
        }
    });
}

const requestHelpers = {
    sendApiRequest: sendApiRequest
}

export default requestHelpers;