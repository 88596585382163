import { React, useState } from "react";

export default function Accordion({ title, children, ...rest }) {
    const [isActive, setIsActive] = useState(false);

    return <div {...rest}>
        <div className="bg-gray-100 flex content-center items-center rounded-md">
            <h3 className="flex-1">{title}</h3>
            <button className="flex-none text-gray-500 hover:text-gray-900 px-3 py-[4px] leading-none font-bold text-xl appearance-none" onClick={(e) => { e.preventDefault(); setIsActive(!isActive)}}>{isActive ? '–' : '+'}</button>
        </div>
        <ul className={`overflow-y-auto transition-all pl-4 ${isActive ? 'mt-2' : ''}`} style={{ maxHeight: isActive ? '500px' : 0 }}>
            {children && children.map((child, i) => {
                return <li key={`item_${i}/${children.length}`} className={`${i + 1 !== children.length ? 'mb-2' : ''}`}>{child}</li>
            })}
        </ul>
    </div>
}