import { React, useState, useEffect, useRef} from 'react';
import { useFileFetcher, useFileAncestorsFetcher, useFileListFetcher } from "../../hooks/file";
import FileIcon from './FileIcon';
import { ReactComponent as RightIcon } from '../../icons/right.svg';
import { ReactComponent as LeftIcon } from '../../icons/left.svg';
import { ReactComponent as HomeIcon } from '../../icons/home.svg';

export default function FilePicker({ projectKey, value, onChange, placeholder = "", fileType = 0, ...rest }) {

    const oldValue = useRef(value);
    const filePickerElemRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const { responseData: fileData } = useFileFetcher({ projectKey, fileKey: value || "" });
    const [isSelectionMode, setIsSelectionMode] = useState(false);

    useEffect(() => {
        if (fileData !== null && fileData.type === fileType) {
            setSelectedFile(fileData);
        }
    }, [fileData]);

    const handleSelectBtnClick = (e) => {
        e.preventDefault();
        const _isSelectionMode = !isSelectionMode
        if (_isSelectionMode) {
            oldValue.current = value;
            setSelectedFile(null);
            if (typeof onChange === 'function') {
                onChange("");
            }
        }
        setIsSelectionMode(_isSelectionMode);
    }

    const onSelect = (file) => {
        setSelectedFile(file);
        setIsSelectionMode(false);
        if (typeof onChange === 'function') {
            onChange(file?.key || "");
        }
    }

    useEffect(() => {

        const cancel = (e) => {
            const filePickerElem = filePickerElemRef.current;
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('file-picker')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('file-picker')) {
                disregard = false;
            }
            if (disregard || el !== filePickerElem) {
                setIsSelectionMode(false);
            }
        }
        document.addEventListener('click', cancel);
        return () => {
            document.removeEventListener('click', cancel);
        }
    }, []);

    return <div ref={filePickerElemRef} {...{ ...rest, className: "w-full file-picker rounded-md relative bg-gray-100 p-1 " + (rest.className || '') }}>
        <div className="flex">
            <div className="flex-1 text-sm">
                {selectedFile === null ?
                    <div className="text-gray-600 py-0.5 break-all px-1 tracking-normal whitespace-pre-wrap leading-normal">{placeholder}</div> :
                    <a href={`/app/projects/${projectKey}/workspace?key=${selectedFile.key}`} target="_blank" className="flex hover:text-primary-dark">
                        {selectedFile.key !== "" && <FileIcon type={selectedFile.type} icon={selectedFile.icon} className={`flex-none border bg-white rounded-sm mr-1.5 h-[25px] w-[25px] ${selectedFile.icon ? '' : 'p-1'}`} />}
                        <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{selectedFile.key === "" ? "No file is selected" : selectedFile.name}</span>
                    </a>}
            </div>
            <button onClick={handleSelectBtnClick} className="flex-none leading-none border bg-white hover:bg-gray-50 focus:bg-gray-200 focus:outline-none appearance-none transition rounded-md focus:ring-2 focus:ring-secondary hover:shadow-none inline-block shadow-sm text-black text-center min-w-[80px] py-1 px-2 text-sm">Select</button>
        </div>
        {isSelectionMode && <FilePickerPopup projectKey={projectKey} fileKey={oldValue.current} onSelect={onSelect} fileType={fileType} />}
    </div>
}

function FilePickerPopup({ projectKey, fileKey, onSelect, fileType = 0 }) {

    const [fileAncestors, setFileAncestors] = useState(null);
    const [state, setState] = useState({ name: 'not_ready', directoryKey: null });
    const [files, setFiles] = useState(null);
    const [cursor, setCursor] = useState("");
    const currDirectory = getCurrentDirectory(fileAncestors);
    const [isLoading, setIsLoading] = useState(true);
    const { responseData: fileAncestorsData } = useFileAncestorsFetcher({ projectKey, fileKey: fileKey || "" });
    const { responseData: filesData } = useFileListFetcher({ projectKey, directoryKey: currDirectory?.key || "", fileType, cursor });

    useEffect(() => {
        if (fileAncestorsData instanceof Array) {
            setFileAncestors(fileAncestorsData);
        }
    }, [fileAncestorsData]);

    useEffect(() => {
        if (state.name !== 'not_ready' &&
            filesData?.files instanceof Array &&
            state.filesData !== filesData) {
            if (state.name === 'first_load') {
                setFiles(cleanFiles(filesData.files, state.directoryKey));
            } else if (state.name === 'load_more' && files !== null) {
                setFiles(cleanFiles([...files, ...filesData.files], state.directoryKey));
            }
            setIsLoading(false);
        }
    }, [filesData, state]);

    useEffect(() => {
        setFiles(null);
        setIsLoading(true);
        setCursor("");
        const currDirectoryKey = getCurrentDirectoryKey(fileAncestors);
        if (currDirectoryKey === null && state.name !== 'not_ready') {
            setState({ name: 'not_ready', directoryKey: null });
        } else if (state.name !== 'first_load' || state.directoryKey !== currDirectoryKey || state.filesData !== filesData) {
            setState({ name: 'first_load', directoryKey: currDirectoryKey, filesData });
        }
    }, [fileAncestors, fileType]);

    const onFileSelected = (file) => {
        return (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (file.type === 1) {
                setFileAncestors([...fileAncestors, file]);
            } else {
                if (typeof onSelect === 'function') {
                    onSelect(file);
                }
            }
        }
    }

    const goBack = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const currDirectoryIndex = getCurrentDirectoryIndex(fileAncestors);
        if (fileAncestors && fileAncestors.length > 0) {
            setFileAncestors(fileAncestors.slice(0, currDirectoryIndex));
        }
    }

    const loadMore = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        setCursor(filesData?.next_cursor);
        setState({ name: 'load_more', directoryKey: currDirectory?.key || null, filesData });
    }

    return <div className="w-full absolute min-w-[200px] max-h-[300px] overflow-y-auto overflow-x-hidden bg-white z-40 rounded-md border-2 mt-0.5 left-0 top-[100%]">
        <div className="bg-gray-50 px-2 py-1 w-full">
            {currDirectory != null && <div className="flex flex-nowrap items-center">
                {currDirectory.key === "" && <HomeIcon className="flex-none max-h-[18px] w-[18px] fill-gray-300 p-0 mr-2" />}
                {currDirectory.key !== "" && <>
                    <a href="#" onClick={goBack} className="rounded flex-none text-gray-300 hover:text-gray-600 active:bg-gray-300 px-1 py-0 mr-1">
                        <LeftIcon className="block max-h-[18px] w-[18px] fill-current" />
                    </a>
                    <FileIcon type={currDirectory.type} className="flex-none mr-2 max-h-[18px] w-[18px]" />
                </>}
                <h3 className="flex-1 text-sm font-semibold break-all tracking-normal whitespace-pre-wrap leading-normal">{currDirectory.key === "" ? "Home" : currDirectory.name}</h3>
            </div>}
        </div>
        <ul className="max-h-[300px] block overflow-y-auto overflow-x-hidden">
            {files != null && files.map((file, i) => {
                return <li className="block w-full" key={file.key}>
                    <a href="#" onClick={onFileSelected(file)} className="hover:bg-gray-100 active:bg-gray-200 py-1 px-2 w-full flex items-center">
                        <div className="flex-1">
                            <FileIcon type={file.type} icon={file.icon} className={`float-left block border rounded-sm mr-1.5 h-[25px] w-[25px] ${file.icon ? '' : 'p-1'}`} />
                            <span className="text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{file.name}</span>
                        </div>
                        {file.type === 1 && <RightIcon className="flex-none fill-gray-500 max-h-[15px] w-[15px]" />}
                    </a>
                </li>;
            })}
            {isLoading && <>
                <li className="py-1 px-2 w-full flex items-center">
                    <div className="flex-none animate-pulse bg-slate-200 rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" />
                    <div className="flex-none animate-pulse w-[100px] h-3 bg-slate-200 rounded" />
                </li>
                <li className="py-1 px-2 w-full flex items-center">
                    <div className="flex-none animate-pulse bg-slate-200 rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" />
                    <div className="flex-none animate-pulse w-[30px] h-3 bg-slate-200 rounded mr-1" />
                    <div className="flex-none animate-pulse w-[90px] h-3 bg-slate-200 rounded" />
                </li>
            </>}
            {(!isLoading && filesData?.next_cursor !== "") && <li>
                <a href="#" onClick={loadMore} className="bg-gray-50 block text-gray-600 text-xs px-2 py-1 w-full">
                    Load more...
                </a>
            </li>}
        </ul>
    </div>
}

function getCurrentDirectory(fileAncestors) {
    if (fileAncestors instanceof Array) {
        for (var i = fileAncestors.length - 1; i >= 0; i--) {
            if (fileAncestors[i].type === 1) {
                return fileAncestors[i];
            }
        }
    }
    return null;
}

function getCurrentDirectoryIndex(fileAncestors) {
    if (fileAncestors instanceof Array) {
        for (var i = fileAncestors.length - 1; i >= 0; i--) {
            if (fileAncestors[i].type === 1) {
                return i;
            }
        }
    }
    return -1;
}

function getCurrentDirectoryKey(fileAncestors) {
    if (fileAncestors instanceof Array) {
        for (var i = fileAncestors.length - 1; i >= 0; i--) {
            if (fileAncestors[i].type === 1) {
                return fileAncestors[i].key;
            }
        }
    }
    return null;
}

function cleanFiles(files, directoryKey) {
    const cleanFiles = [];
    var i, j, exists;
    for (i = 0; i < files.length; i++) {
        if (files[i].directory === directoryKey) {
            exists = false;
            for (j = 0; j < cleanFiles.length; j++) {
                if (files[i].key === cleanFiles[j].key) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                cleanFiles.push(files[i]);
            }
        }
    }
    return cleanFiles;
}