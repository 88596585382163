import { useRef, useEffect } from 'react';
import { CancelToken, isCancel } from 'axios';

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 *
 * @returns {newCancelToken: function, isCancel: function}
 * newCancelToken - used to generate the cancel token sent in the Axios request.
 * isCancel - used to check if error returned in response is a cancel token error.
 */
export const useCancelToken = () => {
  const axiosSourceRef = useRef(null);
  const newCancelToken = () => {
    const axiosSource = CancelToken.source();
    axiosSourceRef.current = axiosSource;
    return axiosSource.token;
  };

  useEffect(() => {
    return () => {
      const axiosSource = axiosSourceRef.current;
      if (axiosSource) axiosSource.cancel();
    }
  }, []);

  return { newCancelToken, isCancel };
};