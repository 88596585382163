import { React, useState, useEffect, useCallback, useRef } from "react";
import FileInfo from "./FileInfo";
import FileActions from "./FileActions";
import Loader from "../brand/Loader";
import { useFileListFetcher } from "../../hooks/file";
import FileCreationAction from "./FileCreationAction";
import FileActionDialog from "./FileActionDialog";
import requestHelpers from "../../helpers/request";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import { useHistory } from "react-router-dom";
import { doArraysHaveSameItems } from "../../helpers/array";

export default function Explorer({ projectKey, directoryKey }) {
    const [files, setFiles] = useState(null);
    const [cursor, setCursor] = useState("");
    const [sortBy, setSortBy] = useState("recently_modified");
    const history = useHistory();

    useEffect(() => {
        setCursor("");
    }, [sortBy])

    const [isLoading, setIsLoading] = useState(true);
    const { responseData: filesData } = useFileListFetcher({ projectKey, directoryKey, cursor, sortBy });
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [selectedFileKeys, setSelectedFileKeys] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    useEffect(() => {
        if (filesData?.files instanceof Array) {
            if (files === null) {
                setFiles(cleanFiles(filesData.files, directoryKey));
            } else {
                setFiles(cleanFiles([...files, ...filesData.files], directoryKey));
            }
            setIsLoading(false);
        }
    }, [filesData]);

    const loadMore = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        setCursor(filesData?.next_cursor);
    }

    const enableSelectionMode = (e) => {
        e.preventDefault();
        setIsSelectionMode(true);
    }

    const disableSelectionMode = (e) => {
        e.preventDefault();
        setIsSelectionMode(false);
        if (selectedFileKeys.length > 0) {
            setSelectedFileKeys([]);
        }
    }

    const selectFile = (fileKey) => {
        if (selectedFileKeys.indexOf(fileKey) === -1) {
            setSelectedFileKeys([...selectedFileKeys, fileKey]);
        }
    }

    const unselectFile = (fileKey) => {
        if (selectedFileKeys.indexOf(fileKey) !== -1) {
            setSelectedFileKeys(selectedFileKeys.filter((v, i) => v !== fileKey));
        }
    }

    const handleActionSelection = (actionName) => {
        const action = { name: actionName, data: {} };
        var selectedFiles;
        switch (action.name) {
            case "rename":
                action.data.name = '';
                selectedFiles = files.filter((f) => selectedFileKeys.indexOf(f.key) !== -1);
                if (selectedFiles.length > 0) {
                    var sameName = true;
                    for (var i = 1; i < selectedFiles.length; i++) {
                        if (selectedFiles[i - 1].name !== selectedFiles[i].name) {
                            sameName = false;
                            break;
                        }
                    }
                    if (sameName) {
                        action.data.name = selectedFiles[0].name;
                    }
                }
                break;
            case 'change_permissions':
                selectedFiles = files.filter((f) => selectedFileKeys.indexOf(f.key) !== -1);
                if (selectedFiles.length > 0) {
                    var i, haveSameOwner = true;
                    for (i = 1; i < selectedFiles.length; i++) {
                        if (selectedFiles[i - 1].owner_key !== selectedFiles[i].owner_key) {
                            haveSameOwner = false;
                            break;
                        }
                    }
                    if (haveSameOwner) {
                        action.data.owner_key = selectedFiles[0].owner_key;
                    }
                    var haveSameReadPermission = true;
                    for (i = 1; i < selectedFiles.length; i++) {
                        if (!doArraysHaveSameItems(selectedFiles[i - 1].read, selectedFiles[i].read)) {
                            haveSameReadPermission = false;
                            break;
                        }
                    }
                    if (haveSameReadPermission) {
                        action.data.read = selectedFiles[0].read;
                    }
                    var haveSameWritePermission = true;
                    for (i = 1; i < selectedFiles.length; i++) {
                        if (!doArraysHaveSameItems(selectedFiles[i - 1].write, selectedFiles[i].write)) {
                            haveSameWritePermission = false;
                            break;
                        }
                    }
                    if (haveSameWritePermission) {
                        action.data.write = selectedFiles[0].write;
                    }
                    var haveSameExecutePermission = true;
                    for (i = 1; i < selectedFiles.length; i++) {
                        if (!doArraysHaveSameItems(selectedFiles[i - 1].execute, selectedFiles[i].execute)) {
                            haveSameExecutePermission = false;
                            break;
                        }
                    }
                    if (haveSameExecutePermission) {
                        action.data.execute = selectedFiles[0].execute;
                    }
                }
                break;
        }
        setSelectedAction(action);
    }

    const handleFileTypeSelection = (fileType) => {
        setSelectedAction({ name: 'create', data: { fileType } });
    }

    const onCancel = useCallback(() => {
        setSelectedAction(null);
    }, [setSelectedAction]);


    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const onCreate = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workspace/files`,
            data: { ...data, directory: directoryKey },
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const newFile = response?.data?.data;
                    if (newFile) {
                        setFiles(sortFiles([...files, newFile], sortBy));
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onRename = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workspace/files/rename`,
            data: { ...data, keys: selectedFileKeys },
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const updatedData = response?.data?.data;
                    if (updatedData?.file_keys) {
                        const updatedFiles = files.map((f) => {
                            const indexOfRenamedFile = updatedData.file_keys.indexOf(f.key);
                            if (indexOfRenamedFile !== -1) {
                                if (updatedData.file_keys.length === 1) {
                                    f.name = updatedData.name;
                                } else {
                                    f.name = `${updatedData.name} (${indexOfRenamedFile + 1})`;
                                }
                                f.modified = updatedData.modified;
                            }
                            return f;
                        });
                        setFiles(sortFiles(updatedFiles, sortBy));
                    }
                    setSelectedFileKeys([]);
                    setIsSelectionMode(false);
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onDelete = ({ onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workspace/files/delete`,
            data: { keys: selectedFileKeys },
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const deletedFileKeys = response?.data?.data?.file_keys;
                    if (deletedFileKeys) {
                        setFiles(files.filter((f) => deletedFileKeys.indexOf(f.key) === -1));
                    }
                    setSelectedFileKeys([]);
                    setIsSelectionMode(false);
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onMove = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workspace/files/move`,
            data: { ...data, keys: selectedFileKeys },
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    setSelectedFileKeys([]);
                    setIsSelectionMode(false);
                    history.push(`/app/projects/${projectKey}/workspace?key=${data?.directory}`);
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onCopy = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workspace/files/copy`,
            data: { ...data, keys: selectedFileKeys },
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    setSelectedFileKeys([]);
                    setIsSelectionMode(false);
                    if (data?.directory === directoryKey && response?.data?.data?.files) {
                        setFiles(sortFiles(cleanFiles([...files, ...response?.data?.data?.files], directoryKey), sortBy));
                    } else {
                        history.push(`/app/projects/${projectKey}/workspace?key=${data?.directory}`);
                    }
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    const onChangePermissions = ({ data, onError, onSuccess, onComplete }) => {
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workspace/files/owner-and-permissions`,
            data: { ...data, keys: selectedFileKeys },
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    if (typeof onError === 'function') {
                        onError(response);
                    }
                }
            },
            onSuccess: (response) => {
                if (mounted.current) {
                    const updatedData = response?.data?.data;
                    if (updatedData?.file_keys) {
                        const updatedFiles = files.map((f) => {
                            if (updatedData.file_keys.indexOf(f.key) !== -1) {
                                f.owner_key = updatedData.owner_key;
                                f.read = updatedData.read;
                                f.write = updatedData.write;
                                f.execute = updatedData.execute;
                                f.permissions = updatedData.permissions;
                                f.modified = updatedData.modified;
                            }
                            return f;
                        });
                        setFiles(sortFiles(updatedFiles, sortBy));
                    }
                    setSelectedFileKeys([]);
                    setIsSelectionMode(false);
                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                }
            }
        });
    }

    return (
        <>
            {files ?
                <div className="flex-1 flex w-full justify-between items-stretch justify-items-stretch flex-col flex-nowrap overflow-hidden relative">
                    <div className="flex-1 overflow-y-auto max-h-full pb-3">
                        {files.length > 0 ? <div className="auto-rows-min grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-3 lg:grid-cols-3 lg:gap-4">
                            {files.map((file) => <FileInfo projectKey={projectKey} key={file?.key} file={file} isSelected={selectedFileKeys.indexOf(file?.key) !== -1} select={selectFile} unselect={unselectFile} selectionMode={isSelectionMode} />)}
                            {isLoading ? <>
                                <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                                <div className="block animate-pulse bg-slate-200 rounded-lg h-16"></div>
                            </> : (filesData?.next_cursor !== '' ?
                                <button className="block rounded-lg h-16 border text-sm text-gray-600 appearance-none bg-transparent hover:border-gray-300 active:bg-gray-200" onClick={loadMore}>Load more...</button> : null)
                            }
                        </div> : <div className="pt-40 text-lg text-center text-gray-500">
                            No files
                        </div>}
                    </div>
                    <div className="flex-none pt-1 pb-4 pl-0.5 pr-2 border-t border-gray-300 flex items-center flex-row justify-between">
                        <div className="flex-none">
                            {isSelectionMode ? <>
                                <button className="btn btn--secondary btn--sm" onClick={disableSelectionMode}>Cancel</button>
                            </> : <button className="btn btn--secondary btn--sm" onClick={enableSelectionMode}>Select</button>}
                        </div>
                        {selectedFileKeys.length > 0 && <span className="flex-none text-center text-sm">{selectedFileKeys.length} item{selectedFileKeys.length > 1 ? 's' : ''} selected</span>}
                        {isSelectionMode ?
                            selectedFileKeys.length > 0 && <FileActions onSelect={handleActionSelection} />
                            : <FileCreationAction onSelect={handleFileTypeSelection} />
                        }
                    </div>
                </div>
                : <div className="pt-36 text-center">
                    <Loader words={["Data", "Assembler"]} maxRandomCycles={-1} />
                    <p>Scanning files...</p>
                </div>
            }
            <FileActionDialog projectKey={projectKey} onCreate={onCreate} onRename={onRename} onDelete={onDelete} onMove={onMove} onCopy={onCopy} onChangePermissions={onChangePermissions} onCancel={onCancel} action={selectedAction} directoryKey={directoryKey} selectedFileKeys={selectedFileKeys} />
        </>
    );
}

function cleanFiles(files, directoryKey) {
    const cleanFiles = [];
    var i, j, exists;
    for (i = 0; i < files.length; i++) {
        if (files[i].directory === directoryKey) {
            exists = false;
            for (j = 0; j < cleanFiles.length; j++) {
                if (files[i].key === cleanFiles[j].key) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                cleanFiles.push(files[i]);
            }
        }
    }
    return cleanFiles;
}

function sortFiles(files, sortBy) {
    if (files.length > 0) {
        if (sortBy === 'a_to_z') {
            files.sort((a, b) => {
                var nameOrder;
                const aStartsWithUppercase = startsWithUppercase(a.name), bStartsWithUppercase = startsWithUppercase(b.name);
                if (aStartsWithUppercase === bStartsWithUppercase) {
                    nameOrder = 0;
                } else if (aStartsWithUppercase & !bStartsWithUppercase) {
                    nameOrder = -1;
                } else {
                    nameOrder = 1;
                }
                if (nameOrder === 0) {
                    if (a.name > b.name) {
                        nameOrder = 1;
                    } else if (a.name < b.name) {
                        nameOrder = -1;
                    }
                }
                return a.type - b.type || nameOrder;
            });
        } else {
            files.sort((a, b) => a.type - b.type || b.modified - a.modified);
        }
    }
    return files;
}

function startsWithUppercase(str) {
    return str.substr(0, 1) === str.substr(0, 1).toUpperCase();
}
