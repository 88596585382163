import { React, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import FileIcon from './FileIcon';
import timeAndDateHelpers from "../../helpers/timeAndDate";
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import {useUser} from "../../hooks/user";
import { doesUserHaveFullFilePermission } from '../../helpers/file-permission';

export default function FileInfo({ projectKey, file, isSelected, select, unselect, selectionMode }) {
    let history = useHistory();
    const { user } = useUser();
    const isLocked = useMemo(() => {
        return !doesUserHaveFullFilePermission(user, file);
    }, [file, user]);

    function handleClick(e) {
        e.preventDefault();
        var ignore = true;
        if (e.type === 'keydown') {
            if (e.key === 'Enter' || e.key === " " || e.key === "Spacebar") {
                ignore = false;
            }
        } else if (e.type === 'click') {
            ignore = false;
        }
        if (!ignore) {
            if (!selectionMode) {
                history.push(`/app/projects/${projectKey}/workspace?key=${file?.key}`);
            } else if (!isLocked) {
                if (isSelected) {
                    unselect(file?.key);
                } else {
                    select(file?.key);
                }
            }
        }
    }

    return (
        <a href="#" onClick={handleClick} onKeyDown={handleClick} className={`block relative rounded-lg bg-white h-16 pl-16 ${selectionMode && isLocked ? "cursor-default opacity-75" : "hover:border hover:border-primary-dark active:bg-gray-50"}`}>
            <FileIcon type={file?.type} icon={file?.icon} className={`${file?.icon ? 'rounded-md' : ''} block max-h-10 w-10 absolute left-3 top-[50%] -translate-y-[50%]`} />
            <div className="py-3 pr-2">
                <h4 className="font-medium text-sm truncate">{file?.name}</h4>
                <p className="text-xs truncate text-gray-500">{timeAndDateHelpers.timeSince(file?.modified)}</p>
            </div>
            {selectionMode && <div className="absolute w-5 h-5 top-2 right-2">
                {isLocked ? <LockIcon className="h-full w-full block fill-gray-400" /> :
                    (isSelected ? <CheckmarkIcon className="block w-full h-full fill-primary-dark" /> : <div className="w-full h-full rounded-full border-2 border-primary-dark" />)}
            </div>}
        </a>
    );
}