import { React, useState, useEffect, useRef } from 'react';
import { useFileAncestorsFetcher, useFileListFetcher } from "../../hooks/file";
import FileIcon from './FileIcon';
import { ReactComponent as RightIcon } from '../../icons/right.svg';
import { ReactComponent as LeftIcon } from '../../icons/left.svg';
import { ReactComponent as HomeIcon } from '../../icons/home.svg';


export default function LocationPicker({ projectKey, value, onChange, ignoreFiles = [] }) {

    const [fileAncestors, setFileAncestors] = useState(null);
    const [files, setFiles] = useState(null);
    const [cursor, setCursor] = useState("");
    const currDirectory = getCurrentDirectory(fileAncestors);
    const [isLoading, setIsLoading] = useState(true);
    const { responseData: fileAncestorsData } = useFileAncestorsFetcher({ projectKey, fileKey: value || "" });
    const { responseData: filesData } = useFileListFetcher({ projectKey, directoryKey: currDirectory?.key || value, cursor });

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    useEffect(() => {
        if (mounted.current && fileAncestorsData instanceof Array) {
            setFileAncestors(fileAncestorsData);
        }
    }, [fileAncestorsData]);

    useEffect(() => {
        if (mounted.current && filesData?.files instanceof Array) {
            if (files === null) {
                setFiles(cleanFiles(filesData.files, currDirectory?.key || value, ignoreFiles));
            } else {
                setFiles(cleanFiles([...files, ...filesData.files], currDirectory?.key || value, ignoreFiles));
            }
            setIsLoading(false);
        }
    }, [filesData]);

    useEffect(() => {
        setFiles(null);
        setIsLoading(true);
        setCursor("");
        if (typeof onChange === 'function') {
            onChange(getCurrentDirectoryKey(fileAncestors));
        }
    }, [fileAncestors]);

    const handleDirectoryClick = (directory) => {
        return (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (directory.type === 1) {
                setFileAncestors([...fileAncestors, directory]);
            }
        }
    }

    const goBack = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const currDirectoryIndex = getCurrentDirectoryIndex(fileAncestors);
        if (fileAncestors && fileAncestors.length > 0) {
            setFileAncestors(fileAncestors.slice(0, currDirectoryIndex));
        }
    }

    const loadMore = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        setCursor(filesData?.next_cursor);
    }

    return <div className="w-full max-h-[300px] overflow-y-auto overflow-x-hidden">
        <div className="rounded-t-lg bg-gray-100 p-2 w-full">
            {currDirectory != null && <div className="flex flex-nowrap items-center">
                {currDirectory.key === "" && <HomeIcon className="flex-none max-h-[18px] w-[18px] fill-gray-300 p-0 mr-2" />}
                {currDirectory.key !== "" && <>
                    <a href="#" onClick={goBack} className="rounded flex-none text-gray-300 hover:text-gray-600 active:bg-gray-300 px-1 py-0 mr-1">
                        <LeftIcon className="block max-h-[18px] w-[18px] fill-current" />
                    </a>
                    <FileIcon type={currDirectory.type} className="flex-none mr-2 h-[18px] w-[18px]" />
                </>}
                <h3 className="flex-1 text-sm font-semibold break-all tracking-normal whitespace-pre-wrap leading-normal">{currDirectory.key === "" ? "Home" : currDirectory.name}</h3>
            </div>}
        </div>
        <ul className="max-h-[300px] min-h-[200px] block overflow-y-auto overflow-x-hidden">
            {files != null && files.map((file, i) => {
                return <li className="block w-full" key={file.key}>
                    {file.type === 1 ?
                        <a href="#" onClick={handleDirectoryClick(file)} className="hover:bg-primary-lighter active:bg-primary-light py-1 px-2 w-full flex items-center">
                            <div className="flex-1">
                                <FileIcon type={file.type} icon={file.icon} className={`float-left block mr-1.5 h-[25px] w-[25px]`} />
                                <span className="text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{file.name}</span>
                            </div>
                            <RightIcon className="flex-none fill-gray-400 max-h-[16px] w-[16px]" />
                        </a>
                        : <div className="py-1 px-2 w-full opacity-50">
                            <FileIcon type={file.type} icon={file.icon} className={`float-left block border rounded-sm mr-1.5 h-[25px] w-[25px] ${file.icon ? '' : 'p-1'}`} />
                            <span className="text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{file.name}</span>
                        </div>}
                </li>;
            })}
            {isLoading && <>
                <li className="py-1 px-2 w-full flex items-center">
                    <div className="flex-none animate-pulse bg-slate-200 rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" />
                    <div className="flex-none animate-pulse w-[100px] h-3 bg-slate-200 rounded" />
                </li>
                <li className="py-1 px-2 w-full flex items-center">
                    <div className="flex-none animate-pulse bg-slate-200 rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" />
                    <div className="flex-none animate-pulse w-[30px] h-3 bg-slate-200 rounded mr-1" />
                    <div className="flex-none animate-pulse w-[90px] h-3 bg-slate-200 rounded" />
                </li>
            </>}
            {(!isLoading && filesData?.next_cursor !== "") && <li>
                <a href="#" onClick={loadMore} className="bg-gray-50 block text-gray-600 text-xs px-2 py-1 w-full">
                    Load more...
                </a>
            </li>}
        </ul>
    </div>
}

function getCurrentDirectory(fileAncestors) {
    if (fileAncestors instanceof Array) {
        for (var i = fileAncestors.length - 1; i >= 0; i--) {
            if (fileAncestors[i].type === 1) {
                return fileAncestors[i];
            }
        }
    }
    return null;
}

function getCurrentDirectoryIndex(fileAncestors) {
    if (fileAncestors instanceof Array) {
        for (var i = fileAncestors.length - 1; i >= 0; i--) {
            if (fileAncestors[i].type === 1) {
                return i;
            }
        }
    }
    return -1;
}

function getCurrentDirectoryKey(fileAncestors) {
    if (fileAncestors instanceof Array) {
        for (var i = fileAncestors.length - 1; i >= 0; i--) {
            if (fileAncestors[i].type === 1) {
                return fileAncestors[i].key;
            }
        }
    }
    return null;
}

function cleanFiles(files, directoryKey, ignoreFiles) {
    const cleanFiles = [];
    var i, j, exists;
    for (i = 0; i < files.length; i++) {
        if (files[i].directory === directoryKey) {
            exists = false;
            for (j = 0; j < cleanFiles.length; j++) {
                if (files[i].key === cleanFiles[j].key) {
                    exists = true;
                    break;
                }
            }
            if (!exists && ignoreFiles.indexOf(files[i].key) === -1) {
                cleanFiles.push(files[i]);
            }
        }
    }
    return cleanFiles;
}