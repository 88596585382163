import { React, useState, useRef, useEffect } from "react";
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as XIcon } from '../../icons/x.svg';
import { ReactComponent as WidgetIcon } from '../../icons/file/widget.svg';
import { ReactComponent as GroupIcon } from '../../icons/dashboard/group.svg';

export default function ItemInsertAction({ onSelect }) {
    const popupRef = useRef(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPopupVisible(!isPopupVisible);
    }

    useEffect(() => {

        const handleDisregard = (e) => {
            const popupElem = popupRef.current;
            var el = e.target, disregard = true;
            while (el && !el.classList.contains('item-types')) {
                el = el.parentElement;
            }
            if (el && el.classList.contains('item-types')) {
                disregard = false;
            }
            if (disregard || el !== popupElem) {
                setIsPopupVisible(false);
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [])

    const handleTypeClick = (itemType) => {
        return (e) => {
            e.preventDefault();
            if (typeof onSelect === 'function') {
                onSelect(itemType);
            }
            setIsPopupVisible(false);
        }
    }

    return <div className="relative">
        <ul ref={popupRef} className={`absolute z-30 item-types bottom-[100%] overflow-hidden rounded-lg bg-white shadow-md right-1 w-[200px] mb-1.5 ${!isPopupVisible ? 'hidden' : ''}`}>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleTypeClick("widget")}><WidgetIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Widget</span></a></li>
            <li className="px-3"><a href="#" className="flex text-gray-600 border-b items-center overflow-hidden active:bg-gray-50 hover:text-primary-dark py-2" onClick={handleTypeClick("group")}><GroupIcon className="mr-2 max-w-[20px] h-[18px] fill-current flex-none" /><span className="flex-1">Group</span></a></li>
        </ul>
        <button className="block -mt-6 rounded-full shadow-md p-4 bg-primary hover:bg-primary-light active:bg-primary-dark transition-all" onClick={togglePopup}>{isPopupVisible ? <XIcon className="block w-[20px] h-[20px]" /> : <PlusIcon className="block w-[20px] h-[20px]" />}</button>
    </div>
}