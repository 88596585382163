import { React, useState } from "react";

export default function Options({ options, value, onChange, ...rest }) {
    const [selectedOption, setSelectedOption] = useState(() => {
        if (options) {
            for (var i = 0; i < options.length; i++) {
                if (options[i].value === value) {
                    return options[i];
                }
            }
        }
        return null;
    });

    const selectOption = (option) => (e) => {
        e.preventDefault();
        setSelectedOption(option);

        if (typeof onChange === 'function') {
            onChange(option.value);
        }
    }

    return <div {...rest}>
        {(options && options.length > 0) && <div className="flex mb-2 bg-gray-100 flex-nowrap p-0.5 text-xs rounded-md">
            {options.map((option) => {
                return <button key={`option_${option.value}`} className={`flex-1 py-1 px-2 block text-center font-semibold rounded-md ${selectedOption?.value === option?.value ? 'cursor-default bg-white shadow-sm active:bg-white hover:text-black text-black' : 'active:bg-gray-200 hover:text-gray-900 text-gray-500'}`} onClick={selectOption(option)}>{option.label}</button>
            })}
        </div>}
    </div>
}