import { React } from "react";

export default function AlertBox({ title, error, onClose }) {
    return <div className="fixed top-0 left-0 right-0 bottom-0 w-full bg-gray-400 bg-opacity-60 z-50">
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-md bg-white shadow-md max-w-full min-w-[300px] px-2 py-1">
            <h3 className="font-bold border-b border-b-gray-100">{title}</h3>
            <p className="py-2">{error?.data ? error.data.message : (error?.details ? error.details : '')}</p>
            <button className="w-full rounded-md font-semibold text-center py-1 px-2 bg-gray-200 hover:bg-gray-300 focus:bg-gray-400" onClick={onClose}>Close</button>
        </div>
    </div>
}