import { VALUE_TYPES, VALUE_TYPE_NAMES } from "../../constants/value-types";

const USER_INPUT = [
    {
        group: 'All',
        list: [
            {
                value: VALUE_TYPES.TEXT,
                label: VALUE_TYPE_NAMES[VALUE_TYPES.TEXT]
            },
            {
                value: VALUE_TYPES.INTEGER,
                label: VALUE_TYPE_NAMES[VALUE_TYPES.INTEGER]
            },
            {
                value: VALUE_TYPES.DECIMAL,
                label: VALUE_TYPE_NAMES[VALUE_TYPES.DECIMAL]
            },
            {
                value: VALUE_TYPES.BOOLEAN,
                label: VALUE_TYPE_NAMES[VALUE_TYPES.BOOLEAN]
            }
        ]
    }
];

export default { USER_INPUT };