import { React, useState } from 'react';
import rfdc from 'rfdc';
import { WIDGET_TYPES } from "../../constants/widget";
import { NUMBER_FORMATS } from "../../constants/number-format";
import WidgetIcon from './WidgetIcon';
import WidgetLabel from './WidgetLabel';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

const WIDGETS = {
    [WIDGET_TYPES.SCORECARD]: {
        type: WIDGET_TYPES.SCORECARD,
        value: ""
    },
    [WIDGET_TYPES.BAR_CHART]: {
        type: WIDGET_TYPES.BAR_CHART,
        x_axis: {
            label: "",
            values: ""
        },
        y_axis: {
            label: "",
            values: ""
        }
    },
    [WIDGET_TYPES.LINE_CHART]: {
        type: WIDGET_TYPES.LINE_CHART,
        x_axis: {
            label: "",
            values: ""
        },
        y_axis: {
            label: "",
            values: ""
        }
    },
    [WIDGET_TYPES.PIE_CHART]: {
        type: WIDGET_TYPES.PIE_CHART,
        values: "",
        categories: ""
    },
}

function allWidgets() {
    const widgets = [];
    for (var key in WIDGETS) {
        widgets.push(WIDGETS[key]);
    }
    widgets.sort(function (a, b) {
        if (a.type < b.type) { return -1; }
        if (a.type > b.type) { return 1; }
        return 0;
    })
    return widgets;
}

export default function WidgetTypeSelector({ data, setData }) {

    const [searchText, setSearchText] = useState('');
    const [widgets, setWidgets] = useState(allWidgets);

    const filterWidgets = (searchBy) => {
        searchBy = searchBy.trim().toLocaleLowerCase();
        var filteredWidgets = allWidgets();
        filteredWidgets = filteredWidgets.filter((widget) => {
            if (searchBy !== '') {
                return widget.type.replace(/_/, ' ').toLocaleLowerCase().indexOf(searchBy) > -1;
            }
            return true;
        });
        filteredWidgets.sort(function (a, b) {
            if (a.type < b.type) { return -1; }
            if (a.type > b.type) { return 1; }
            return 0;
        })
        return filteredWidgets;
    }

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value);
        setWidgets(filterWidgets(e.target.value));
    }

    const select = (widgetType) => {
        if (WIDGETS[widgetType]) {
            const widget = rfdc()(WIDGETS[widgetType]);
            setData({ ...data, ...widget })
        }
    }


    return (<div>
        <div className="rounded-lg mt-3 pl-2 mb-1 bg-gray-100">
            <div className="flex items-center flex-nowrap">
                <SearchIcon className="flex-none inline-block text-gray-500 mr-1 fill-current h-4" />
                <div className="flex-1 pl-1">
                    <input className="input input--sm input--full" type="text" value={searchText} onChange={onSearchTextChange} placeholder="Search..." />
                </div>
            </div>
        </div>
        <div className="py-2">
            <div className="overflow-y-auto max-h-[200px]">
                {widgets.map((widget, i) => {
                    return <button key={`new_widget_${widget.key}_${i}`} className="border hover:bg-gray-50 active:bg-gray-100 w-full px-1 py-1 rounded-md flex mb-2 flex-nowrap items-center content-start justify-start justify-items-start" onClick={(e) => { e.preventDefault(); select(widget.type); }}>
                        <div className="flex-none border-2 bg-gray-100 text-gray-500 rounded-md w-[28px] h-[28px] relative">
                            <WidgetIcon widgetType={widget.type} />
                        </div>
                        <div className="flex-1 pl-2 text-left">
                            <WidgetLabel widgetType={widget.type} className="text-gray-700 text-sm" />
                        </div>
                    </button>
                })}
            </div>
        </div>
    </div>)
}