import { React } from 'react';
import { ReactComponent as RunIcon } from '../../icons/start.svg';
import { ReactComponent as StopIcon } from '../../icons/stop.svg';

export default function ControlButton({ onRun, onStop, isRunning, ...rest }) {

    const handleClick = (e) => {
        e.preventDefault();
        if (!isRunning) {
            onRun();
        } else {
            onStop();
        }
    }

    return <button {...{ ...rest, className: "focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-block text-black shadow-sm text-sm rounded-md py-1 px-2 " + (rest.className || '') }} onClick={handleClick}>
        {isRunning ? <StopIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> : <RunIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" />}
        {isRunning ? "Stop" : "Run"}
    </button>
}