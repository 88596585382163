import { React, useState } from 'react';
import valueHelpers from "../../helpers/value";
import valueTypes from "./valueTypes";
import Selector from "../common/Selector";
import DataInput from "../common/DataInput";

export default function MetaBox({ stepIndex, parameters, data, handleChange, setItem, removeItem }) {

    const [isCollapsed, setIsCollapsed] = useState(true);

    return <div className={`w-full border-t border-b my-4 transition-all ${isCollapsed ? 'overflow-hidden max-h-12' : 'max-h-min'}`}>
        <button className="flex w-full justify-start text-left items-center hover:text-gray-700 text-gray-500 h-12" onClick={(e) => { e.preventDefault(); setIsCollapsed(!isCollapsed) }}>
            <svg viewBox="0 0 100 100" className={`w-3 h-3 block fill-current transition-all flex-none ${isCollapsed ? 'rotate-90' : 'rotate-180'}`}><polygon points="5.9,88.2 50,11.8 94.1,88.2 "></polygon></svg>
            <h3 className="pl-1 flex-1 text-sm font-semibold">Custom Meta Data</h3>
        </button>
        {data.meta && data.meta.map((field, i) => {
            return <div key={`var_${i}/${data.meta.length}`}>
                <div className="text-gray-600 align-middle">
                    <div className="flex flex-nowrap">
                        <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-red-500 active:bg-red-700 leading-[0.1] text-white text-center flex-grow-0 bg-red-600 rounded-full mt-1 mr-2 block" onClick={(e) => { e.preventDefault(); removeItem(["meta"], i); }}>–</button>
                        <div className={`border-l flex-1 border-r border-t border-gray-200 ${(i === 0) ? 'rounded-t-md' : ''} ${(i + 1 === data.meta.length) ? 'border-b rounded-b-md' : ''}`}>
                            <div className="flex flex-nowrap">
                                <div className="flex-1 align-middle py-1 px-2">
                                    <label className="label block mb-0.5">Name</label>
                                    <DataInput stepIndex={stepIndex} parameters={parameters} className="w-full mb-1" placeholder="Name" value={valueHelpers.textValue(field.name)} onChange={handleChange(["meta", i, "name"])} />
                                </div>
                            </div>
                            <div className="flex flex-nowrap">
                                <div className="flex-1 align-middle py-1 px-2">
                                    <label className="label block mb-0.5">Type</label>
                                    <Selector value={field.type} onChange={handleChange(["meta", i, "type"])} placeholder="Type" options={valueTypes.USER_INPUT} />
                                </div>
                            </div>
                            <div className="flex flex-nowrap">
                                <div className="flex-1 py-1 px-2 align-middle">
                                    <label className="label block mb-0.5">Value</label>
                                    <DataInput stepIndex={stepIndex} parameters={parameters} className="w-full mb-1" placeholder="Value" value={valueHelpers.textValue(field.value)} onChange={handleChange(["meta", i, "value"])} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })}
        <div className="flex items-center py-2 flex-nowrap">
            <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-green-400 active:bg-green-600 leading-[0.1] text-white text-center flex-grow-0 bg-green-500 rounded-full mr-2 block" onClick={(e) => { e.preventDefault(); setItem(["meta"], { name: '', type: '1', value: '' }); }}>+</button>
            <label className="block text-xs font-semibold flex-1 text-gray-400">New field</label>
        </div>
    </div>
}