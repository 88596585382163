import { React } from "react";
import { ReactComponent as WorkflowIcon } from '../../icons/file/colored/workflow.svg';
import { ReactComponent as StorageIcon } from '../../icons/file/colored/storage.svg';
import { ReactComponent as DashboardIcon } from '../../icons/file/colored/dashboard.svg';
import { ReactComponent as DirectoryIcon } from '../../icons/file/colored/directory.svg';
import { ReactComponent as JsonIcon } from '../../icons/file/colored/json.svg';
import { ReactComponent as WidgetIcon } from '../../icons/file/colored/widget.svg';
import { ReactComponent as AnyFileIcon } from '../../icons/file/colored/any-file.svg';
import { FILE_TYPES } from "../../constants/file";
import { UPLOADS_STORAGE_BASE_URL } from '../../config';

export default function FileIcon({ type, icon, ...rest }) {
    if (icon) {
        return <img src={`${UPLOADS_STORAGE_BASE_URL}/images/files/small/${icon}`} {...rest} alt="File icon" />
    }
    switch (type) {
        case FILE_TYPES.DIRECTORY.CODE:
            return <DirectoryIcon {...rest} />
        case FILE_TYPES.WORKFLOW.CODE:
            return <WorkflowIcon {...rest} />
        case FILE_TYPES.JSON.CODE:
            return <JsonIcon {...rest} />
        case FILE_TYPES.WIDGET.CODE:
            return <WidgetIcon {...rest} />
        case FILE_TYPES.DASHBOARD.CODE:
            return <DashboardIcon {...rest} />
        default:
            return <AnyFileIcon {...rest} />
    }
}