import { React, useEffect, useRef, useState, useReducer } from "react";
import WorkflowNode from "./WorkflowNode";
import WorkflowDetails from "./WorkflowDetails";
import { DATA_SOURCE_TYPES, DATA_SOURCE_KEYS } from "../../constants/data-source";
import { dataSourceTypeToDataSourceTypeKey } from "../../helpers/data-source";
import { removeDuplicatesFromListOfObjects } from "../../helpers/array";
import requestHelpers from "../../helpers/request";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import AlertBox from "../common/AlertBox";
import { v4 as uuidv4 } from 'uuid';
import DataSchema from "./DataSchema";
import { useFileFetcher } from "../../hooks/file";
import valueHelpers from "../../helpers/value";
import { STEP_TYPES } from "../../constants/step";
import { isBlockEndStep, isBlockIntersectionStep, isBlockStartStep, isFragmentStep } from "../../helpers/step";
import { streamWorkflowRunStepOutputs, watchWorkflowRunUpdates } from "../../services/firestore";
import { appendParametersFromText, mergeParameters, parametersReducer } from "../../reducers/parameters";
import useWorkflowRunScopeKey from "../../hooks/workflowRunScopeKey";

function useWorkflowRun(projectKey, workflowKey, runScopeKey, runKey, setError) {

    const [workflowRun, setWorkflowRun] = useState(null);
    const [workflowOutputs, setWorkflowOutputs] = useState({});
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        if (runKey) {
            const unsub = watchWorkflowRunUpdates(projectKey, workflowKey, runScopeKey, runKey, (doc) => {
                const updatedWorkflowRun = {
                    key: doc.id,
                    project_key: projectKey,
                    workflow_key: workflowKey,
                    run_scope_key: runScopeKey,
                    ...doc.data()
                };
                if (updatedWorkflowRun.status === 'completed' ||
                    updatedWorkflowRun.status === 'stopped' ||
                    updatedWorkflowRun.status === 'error') {
                    setIsRunning(false);
                }
                setWorkflowRun(updatedWorkflowRun);
            }, (err) => {
                setIsRunning(false);
                setError(err);
            });
            return unsub;
        }
    }, [projectKey, workflowKey, runScopeKey, runKey])

    useEffect(() => {
        if (runKey) {
            const unsub = streamWorkflowRunStepOutputs(projectKey, workflowKey, runScopeKey, runKey, (snapshot) => {
                const stepOutputs = {};
                snapshot.forEach((doc) => {
                    stepOutputs[doc.id] = {
                        key: doc.id,
                        project_key: projectKey,
                        workflow_key: workflowKey,
                        run_scope_key: runScopeKey,
                        run_key: runKey,
                        ...doc.data()
                    };
                });
                setWorkflowOutputs(stepOutputs);
            }, (err) => {
                setIsRunning(false);
                setError(err);
            });
            return unsub;
        }
    }, [projectKey, workflowKey, runScopeKey, runKey])

    return {
        workflowRun,
        setWorkflowRun,
        workflowOutputs,
        setWorkflowOutputs,
        isRunning,
        setIsRunning,
    };
}

export default function Workflow({ projectKey, fileKey }) {
    const [parameters, dispatchParameters] = useReducer(parametersReducer, () => { return updateDefinedParameters(findUsedParameters(workflowFileInfo?.content?.steps), workflowFileInfo?.content?.parameters); });
    const { responseData: workflowFileResponseData } = useFileFetcher({ projectKey, fileKey, withContent: true }, [projectKey, fileKey]);
    const [workflowFileInfo, setWorkflowFileInfo] = useState(workflowFileResponseData);
    const [workflowRunScopeKey, setWorkflowRunScopeKey] = useWorkflowRunScopeKey(workflowFileInfo?.content?.run_scope);
    const [workflowRunKey, setWorkflowRunKey] = useState(null);
    const [workflowTree, setWorkflowTree] = useState(() => generateWorkflowTree(workflowFileInfo?.content?.steps));

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const handleWorkflowFileResponseData = (responseDataToHandle) => {
        if (responseDataToHandle) {
            var fileInfo = { ...responseDataToHandle }
            try {
                fileInfo.content = JSON.parse(fileInfo.content);
            } catch {
                fileInfo.content = { title: "", steps: [], parameters: [], run_scope: "global" };
            }
            setWorkflowTree(generateWorkflowTree(fileInfo?.content?.steps));
            setWorkflowFileInfo(fileInfo);
            dispatchParameters({ type: 'update_all', scopes: updateDefinedParameters(mergeParameters(parameters, findUsedParameters(fileInfo.content?.steps)), fileInfo?.content?.parameters) });
        }
    }

    useEffect(() => {
        handleWorkflowFileResponseData(workflowFileResponseData);
    }, [workflowFileResponseData])


    // run / stop
    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();
    const [error, setError] = useState(null);
    const { workflowRun, setWorkflowRun, workflowOutputs, setWorkflowOutputs, isRunning, setIsRunning } = useWorkflowRun(
        projectKey,
        workflowFileInfo?.key,
        workflowRunScopeKey,
        workflowRunKey,
        setError,
    );

    const run = (toStep) => {
        if (!isRunning) {
            setIsRunning(true);
            setWorkflowRun(null);
            setWorkflowOutputs({});
            if (error) {
                setError(null);
            }
            const steps = workflowFileInfo.content.steps.filter((step) => step.type !== STEP_TYPES.UNSPECIFIED);
            if (steps.length !== workflowFileInfo.content.steps.length) {
                setWorkflowFileInfo({ ...workflowFileInfo, content: { ...workflowFileInfo.content, steps: steps } });
                dispatchParameters({ type: 'update_all', scopes: mergeParameters(parameters, findUsedParameters(steps)) });
            }

            const workflow = { ...workflowFileInfo.content, steps: [] };
            if (toStep) {
                const isStepEnded = {};
                var toStepIsPassed = false;
                for (var i = 0; i < steps.length; i++) {
                    if (!toStepIsPassed) {
                        if (isBlockStartStep(steps[i])) {
                            isStepEnded[steps[i].key] = false;
                        }
                        if (isBlockEndStep(steps[i])) {
                            isStepEnded[steps[i].fragment_of] = true;
                        }
                        if (steps[i].key === toStep.key) {
                            workflow.steps.push(toStep);
                            toStepIsPassed = true;
                        } else {
                            workflow.steps.push(steps[i]);
                        }
                    } else if (isFragmentStep(steps[i])) {
                        if (isStepEnded.hasOwnProperty(steps[i].fragment_of)) {
                            if (!isStepEnded[steps[i].fragment_of] && isBlockEndStep(steps[i])) {
                                workflow.steps.push(steps[i]);
                            }
                        }
                    }
                }
            } else {
                workflow.steps = steps;
            }

            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workflows/${workflowFileInfo?.key}/runs/debug`,
                data: workflow,
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onSuccess: (response) => {
                    if (mounted.current) {
                        setWorkflowRunKey(response?.data?.data?.key);
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setIsRunning(false);
                        setWorkflowRunKey(null);
                        setError(response);
                    }
                }
            });
        }
    }

    const stop = () => {
        if (isRunning) {
            setIsRunning(false);
            if (error) {
                setError(null);
            }
            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workflows/${workflowFileInfo?.key}/runs/${workflowRunKey}/stop`,
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onError: (response) => {
                    if (mounted.current) {
                        setError(response);
                    }
                }
            });
        }
    }

    const reset = (stepKey) => {
        if (error) {
            setError(null);
        }
        requestHelpers.sendApiRequest({
            method: 'post',
            urlPath: `/projects/${projectKey}/workflow/${workflowFileInfo?.key}/reset${stepKey ? `/${stepKey}` : ''}`,
            data: workflowFileInfo?.content,
            cancelToken: cancelToken,
            accessToken: accessToken,
            onAccessDenied: logout,
            onError: (response) => {
                if (mounted.current) {
                    setError(response);
                }
            }
        });
    }

    const closeAlertBox = (e) => {
        e.preventDefault();
        if (error) {
            setError(null);
        }
    }

    const addStep = (stepIndex, step, fragments) => {
        setWorkflowOutputs(clearOutputs(workflowOutputs, stepIndex));
        const stepToAdd = typeof step === 'undefined' ? {
            key: uuidv4(),
            type: STEP_TYPES.UNSPECIFIED
        } : step;

        const updatedSteps = [];
        for (var i = 0; i < workflowFileInfo.content.steps.length + 1; i++) {
            if (i === stepIndex) {
                updatedSteps.push(stepToAdd);
                if (typeof fragments !== 'undefined') {
                    for (var j = 0; j < fragments.length; j++) {
                        updatedSteps.push(fragments[j]);
                    }
                }
            }
            if (i < workflowFileInfo.content.steps.length) {
                if (workflowFileInfo.content.steps[i].type !== STEP_TYPES.UNSPECIFIED) {
                    updatedSteps.push(workflowFileInfo.content.steps[i]);
                }
            }
        }
        setWorkflowTree(generateWorkflowTree(updatedSteps))
        setWorkflowFileInfo({ ...workflowFileInfo, content: { ...workflowFileInfo.content, steps: updatedSteps } });
        dispatchParameters({ type: 'update_all', scopes: mergeParameters(parameters, findUsedParameters(updatedSteps)) });
    }

    const updateStep = (index, step) => {
        if ((workflowFileInfo?.content?.steps?.length || 0) > index) {
            workflowFileInfo.content.steps[index] = step;
            const updatedWorkflowFileInfo = { ...workflowFileInfo };
            setWorkflowFileInfo(updatedWorkflowFileInfo);
            save(updatedWorkflowFileInfo);
            dispatchParameters({ type: 'update_all', scopes: mergeParameters(parameters, findUsedParameters(updatedWorkflowFileInfo.content.steps)) });
        }
    }

    const deleteStep = (index) => {
        if ((workflowFileInfo?.content?.steps?.length || 0) > index) {
            setWorkflowOutputs(clearOutputs(workflowOutputs, index));
            const stepToDelete = workflowFileInfo.content.steps[index];
            var updatedWorkflowFileInfo = { ...workflowFileInfo, content: { ...workflowFileInfo.content, steps: workflowFileInfo.content.steps.filter((step) => step.key !== stepToDelete.key) } };
            // delete all step fragments
            if (!isFragmentStep(stepToDelete)) {
                updatedWorkflowFileInfo = { ...workflowFileInfo, content: { ...workflowFileInfo.content, steps: updatedWorkflowFileInfo.content.steps.filter((step) => step.fragment_of !== stepToDelete.key) } };
            }

            setWorkflowTree(generateWorkflowTree(updatedWorkflowFileInfo.content.steps))
            setWorkflowFileInfo(updatedWorkflowFileInfo);
            save(updatedWorkflowFileInfo);

            const cleanedParameters = {};
            for (var dataSourceKey in parameters) {
                if (dataSourceKey !== DATA_SOURCE_KEYS.INPUT && dataSourceKey !== DATA_SOURCE_KEYS.PARAMETER) {
                    if (parameters[dataSourceKey].step.index !== index) {
                        cleanedParameters[dataSourceKey] = parameters[dataSourceKey];
                    }
                } else {
                    cleanedParameters[dataSourceKey] = parameters[dataSourceKey];
                }
            }

            dispatchParameters({ type: 'update_all', scopes: mergeParameters(cleanedParameters, findUsedParameters(updatedWorkflowFileInfo.content.steps)) });
        }
    }

    const markAsOutputStep = (index, value) => {
        if ((workflowFileInfo?.content?.steps?.length || 0) > index) {
            const stepToUpdate = workflowFileInfo.content.steps[index];
            if (stepToUpdate) {
                stepToUpdate.marked_as_output = value;
                setWorkflowFileInfo({ ...workflowFileInfo, content: { ...workflowFileInfo.content, steps: [...workflowFileInfo.content.steps] } });
            }
        }
    }

    const updateDetails = (updatedDetails) => {
        const updatedWorkflowFileInfo = {
            ...workflowFileInfo,
            icon: updatedDetails?.icon || '',
            content: {
                ...workflowFileInfo.content,
                title: updatedDetails?.title,
                icon: updatedDetails?.icon,
                input_file_key: updatedDetails?.input_file_key,
                parameters: updatedDetails?.parameters,
                run_scope: updatedDetails?.run_scope,
            }
        }
        setWorkflowFileInfo(updatedWorkflowFileInfo);
        save(updatedWorkflowFileInfo);
        dispatchParameters({ type: 'update_all', scopes: updateDefinedParameters(parameters, updatedDetails?.parameters) });
    }

    const [isBeingSaved, setIsBeingSaved] = useState(false);
    const save = (updatedWorkflowFileInfo) => {
        if (!isBeingSaved) {
            setIsBeingSaved(true);
            if (error) {
                setError(null);
            }
            var workflowFileInfoToSave = { ...updatedWorkflowFileInfo, content: { ...updatedWorkflowFileInfo.content, steps: updatedWorkflowFileInfo.content.steps.filter((step) => step.type !== STEP_TYPES.UNSPECIFIED) } };
            workflowFileInfoToSave.content = JSON.stringify(workflowFileInfoToSave.content);
            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workspace/files/${workflowFileInfoToSave?.key}`,
                data: workflowFileInfoToSave,
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onError: (response) => {
                    if (mounted.current) {
                        setError(response);
                    }
                },
                onSuccess: (response) => {
                    if (mounted.current) {
                        handleWorkflowFileResponseData(response?.data?.data);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBeingSaved(false);
                    }
                }
            });
        }
    }

    const [dataSchemaSource, setDataSchemaSource] = useState(null);

    const showDataSchema = (stepIndex, step, dataSourceType, data, tableKey) => {
        setDataSchemaSource({
            title: `${stepIndex + 1}. ${dataSourceType === DATA_SOURCE_TYPES.INPUT ? "Workflow's input" : dataSourceType === DATA_SOURCE_TYPES.PARAMETER ? "Workflow's parameters" : valueHelpers.textValue(step?.title)}`,
            step: step,
            data: data[dataSourceTypeToDataSourceTypeKey(dataSourceType)],
            tableKey: tableKey,
        });
    }

    const hideDataSchema = () => {
        setDataSchemaSource(null);
    }

    return <>
        <WorkflowDetails projectKey={projectKey} workflowRun={workflowRun} details={workflowFileInfo?.content} update={updateDetails} lastCheckpoint={workflowFileInfo?.modified} onRun={run} onStop={stop} isBeingSaved={isBeingSaved} isRunning={isRunning} parameters={parameters} dispatchParameters={dispatchParameters} showDataSchema={showDataSchema} />
        {workflowTree && workflowFileInfo?.content ? <WorkflowNode projectKey={projectKey} nodeIndex={0} node={workflowTree} steps={workflowFileInfo.content.steps} workflowOutputs={workflowOutputs} addStep={addStep} updateStep={updateStep} markAsOutputStep={markAsOutputStep} deleteStep={deleteStep} onRun={run} onStop={stop} onReset={reset} isRunning={isRunning} parameters={parameters} dispatchParameters={dispatchParameters} showDataSchema={showDataSchema} /> : null}
        {error ? <AlertBox title="Error" error={error} onClose={closeAlertBox} /> : null}
        {dataSchemaSource && <DataSchema source={dataSchemaSource} onClose={hideDataSchema} />}
    </>
}

function findUsedParameters(steps) {
    var scopes = {
        [DATA_SOURCE_KEYS.INPUT]: { params: [] },
        [DATA_SOURCE_KEYS.PARAMETER]: { params: [] },
    };
    if (steps) {
        var step, j;
        for (var i = 0; i < steps.length; i++) {
            step = steps[i];
            if (step.type === STEP_TYPES.UNSPECIFIED || (isFragmentStep(step) && !isBlockIntersectionStep(step))) {
                continue;
            }
            if (!scopes.hasOwnProperty(step.key)) {
                scopes[step.key] = {
                    step: { index: i, key: step.key, title: step.title },
                    params: [],
                };
            }
            switch (step.type) {
                case STEP_TYPES.REQUEST:
                    scopes = appendParametersFromText(step.url, scopes);
                    scopes = appendParametersFromText(step.body, scopes);

                    if (step.params) {
                        for (j = 0; j < step.params.length; j++) {
                            scopes = appendParametersFromText(step.params[j].key, scopes);
                            scopes = appendParametersFromText(step.params[j].value, scopes);
                        }
                    }
                    if (step.headers) {
                        for (j = 0; j < step.headers.length; j++) {
                            scopes = appendParametersFromText(step.headers[j].key, scopes);
                            scopes = appendParametersFromText(step.headers[j].value, scopes);
                        }
                    }
                    if (step.proxy) {
                        scopes = appendParametersFromText(step.proxy.host, scopes);
                        scopes = appendParametersFromText(step.proxy.port, scopes);
                        scopes = appendParametersFromText(step.proxy.username, scopes);
                        scopes = appendParametersFromText(step.proxy.password, scopes);
                    }
                    break;
                case STEP_TYPES.SELECTOR:
                    if (step.tables) {
                        var table, k;
                        for (j = 0; j < step.tables.length; j++) {
                            table = step.tables[j];
                            scopes = appendParametersFromText(table.key, scopes);

                            if (table.columns) {
                                for (k = 0; k < table.columns.length; k++) {
                                    scopes = appendParametersFromText(table.columns[k], scopes);
                                }
                            }
                        }
                    }
                    break;
                case STEP_TYPES.FUNCTION:
                    if (step.columns) {
                        var column, k, l;
                        for (j = 0; j < step.columns.length; j++) {
                            column = step.columns[j];
                            scopes = appendParametersFromText(column.input, scopes);

                            if (column.functions) {
                                for (k = 0; k < column.functions.length; k++) {
                                    if (column.functions[k].arguments) {
                                        for (l = 0; l < column.functions[k].arguments.length; l++) {
                                            scopes = appendParametersFromText(column.functions[k].arguments[l], scopes);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    break;
                case STEP_TYPES.FILTER:
                    scopes = appendParametersFromText(step.filter_scope, scopes);

                    if (step.filters) {
                        var rule, k;
                        for (j = 0; j < step.filters.length; j++) {
                            if (step.filters[j]) {
                                for (k = 0; k < step.filters[j].length; k++) {
                                    rule = step.filters[j][k];
                                    scopes = appendParametersFromText(rule.column, scopes);
                                    scopes = appendParametersFromText(rule.value, scopes);
                                }
                            }
                        }
                    }
                    break;
                case STEP_TYPES.STORAGE:
                    scopes = appendParametersFromText(step.key_column, scopes);

                    if (step.columns) {
                        for (j = 0; j < step.columns.length; j++) {
                            scopes = appendParametersFromText(step.columns[j], scopes);
                        }
                    }
                    break;
                case STEP_TYPES.IF:
                    if (step.rules) {
                        var rule, k;
                        for (j = 0; j < step.rules.length; j++) {
                            if (step.rules[j]) {
                                for (k = 0; k < step.rules[j].length; k++) {
                                    rule = step.rules[j][k];
                                    scopes = appendParametersFromText(rule.first_value, scopes);
                                    scopes = appendParametersFromText(rule.second_value, scopes);
                                }
                            }
                        }
                    }
                    break;
                case STEP_TYPES.TABLE_DIMENSIONS:
                    scopes = appendParametersFromText(step.table, scopes);
                    break;
                case STEP_TYPES.REPEAT_IF:
                    if (step.rules) {
                        var rule, k;
                        for (j = 0; j < step.rules.length; j++) {
                            if (step.rules[j]) {
                                for (k = 0; k < step.rules[j].length; k++) {
                                    rule = step.rules[j][k];
                                    scopes = appendParametersFromText(rule.first_value, scopes);
                                    scopes = appendParametersFromText(rule.second_value, scopes);
                                }
                            }
                        }
                    }
                    break;
                case STEP_TYPES.REPEAT:
                    scopes = appendParametersFromText(step.number_of_runs, scopes);
                    break;
                case STEP_TYPES.GO_TO:
                    scopes = appendParametersFromText(step.target, scopes);
                    break;
                case STEP_TYPES.BROWSER_EXTENSION:
                    scopes = appendParametersFromText(step.extension_key, scopes);
                    break;
                case STEP_TYPES.VARIABLE:
                    if (step.variables) {
                        for (j = 0; j < step.variables.length; j++) {
                            scopes = appendParametersFromText(step.variables[j].name, scopes);
                            scopes = appendParametersFromText(step.variables[j].initial_value, scopes);
                        }
                    }
                    break;
                case STEP_TYPES.SET_VALUE:
                    if (step.instructions) {
                        for (j = 0; j < step.instructions.length; j++) {
                            scopes = appendParametersFromText(step.instructions[j].key, scopes);
                            scopes = appendParametersFromText(step.instructions[j].value, scopes);
                        }
                    }
                    break;
                case STEP_TYPES.FIRST_ROW:
                    scopes = appendParametersFromText(step.table, scopes);
                    break;
                case STEP_TYPES.LAST_ROW:
                    scopes = appendParametersFromText(step.table, scopes);
                    break;
                case STEP_TYPES.EMBED_WORKFLOW:
                    scopes = appendParametersFromText(step.workflow_path, scopes);
                    break;
                case STEP_TYPES.TEXT:
                    scopes = appendParametersFromText(step.text, scopes);
                    break;
                case STEP_TYPES.RENAME:
                    if (step.items) {
                        for (j = 0; j < step.items.length; j++) {
                            scopes = appendParametersFromText(step.items[j].key, scopes);
                            scopes = appendParametersFromText(step.items[j].new_name, scopes);
                        }
                    }
                    break;
                case STEP_TYPES.EACH_ROW:
                    scopes = appendParametersFromText(step.table, scopes);
                    break
                case STEP_TYPES.GROUP_BY:
                    scopes = appendParametersFromText(step.table, scopes);
                    if (step.columns) {
                        for (j = 0; j < step.columns.length; j++) {
                            scopes = appendParametersFromText(step.columns[j], scopes);
                        }
                    }
                    if (step.aggregations) {
                        for (j = 0; j < step.aggregations.length; j++) {
                            scopes = appendParametersFromText(step.aggregations[j].input_column, scopes);
                            scopes = appendParametersFromText(step.aggregations[j].output_column, scopes);
                        }
                    }
                    break;
                case STEP_TYPES.SORT_BY:
                    scopes = appendParametersFromText(step.table, scopes);
                    if (step.columns) {
                        for (j = 0; j < step.columns.length; j++) {
                            scopes = appendParametersFromText(step.columns[j].key, scopes);
                        }
                    }
                    break;
                case STEP_TYPES.JOIN:
                    scopes = appendParametersFromText(step.left_data_source, scopes);
                    scopes = appendParametersFromText(step.left_table, scopes);
                    scopes = appendParametersFromText(step.left_join_column, scopes);
                    scopes = appendParametersFromText(step.right_data_source, scopes);
                    scopes = appendParametersFromText(step.right_table, scopes);
                    scopes = appendParametersFromText(step.right_join_column, scopes);
                    scopes = appendParametersFromText(step.output_table_name, scopes);
                    break;
                case STEP_TYPES.JSON:
                    scopes = appendParametersFromText(step.json_file_key, scopes);
                    break;
            }
        }
    }

    // remove duplicates
    for (var dataSourceKey in scopes) {
        scopes[dataSourceKey].params = removeDuplicatesFromListOfObjects(scopes[dataSourceKey].params, (param) => `${param.dataSourceType}-${param.dataKey}`);
    }
    return scopes;
}

function updateDefinedParameters(parameters, definedParameters) {
    parameters = parameters || {};
    definedParameters = definedParameters || [];
    return {
        ...parameters,
        [DATA_SOURCE_KEYS.PARAMETER]: {
            params: definedParameters.map((definedParam) => ({
                dataKey: definedParam.name,
                dataSourceType: DATA_SOURCE_TYPES.PARAMETER,
                type: 'field',
            }))
        }
    }
}

function generateWorkflowTree(steps) {
    const rootNode = { depth: 0, children: [], parent: null };
    if (steps) {
        var currNode = rootNode, newNode;
        for (var i = 0; i < steps.length; i++) {
            if (isBlockEndStep(steps[i]) || isBlockIntersectionStep(steps[i])) {
                currNode = currNode.parent
                if (currNode === null) {
                    currNode = rootNode;
                }
            }

            newNode = {
                stepIndex: i,
                depth: currNode.depth + 1,
                parent: currNode,
                children: []
            };
            currNode.children.push(newNode);

            if (isBlockStartStep(steps[i]) || isBlockIntersectionStep(steps[i])) {
                currNode = newNode;
            }
        }
    }
    return rootNode;
}

function clearOutputs(stepOutputs, fromIndex) {
    const result = {};
    for (var stepKey in stepOutputs) {
        if (stepOutputs[stepKey].step_index < fromIndex) {
            result[stepKey] = stepOutputs[stepKey];
        }
    }
    return result;
}