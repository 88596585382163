import { React, useEffect, useState } from 'react';
import WorkflowData from './WorkflowData';
import { DATA_SOURCE_TYPE_KEYS, DATA_SOURCE_TYPES } from '../../constants/data-source';
import StepError from './StepError';
import { isBlockIntersectionStep, isFragmentStep } from "../../helpers/step";
import useApiRequest from '../../hooks/api-request';
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';

export function StepOutput({ index, step, data, parameters, dispatchParameters, topBarColor, lineColor, showDataSchema }) {

    const { responseData, errorResponse, retry } = useStepOutputContentFetcher(data);
    const [stepOutputResponse, setStepOutputResponse] = useState(null);

    useEffect(() => {
        if (responseData !== null || errorResponse !== null) {
            setStepOutputResponse(responseData);
        }
    }, [responseData, errorResponse])

    return <>
        <div className="w-full max-w-lg"><div className={`relative h-5 w-0.5 mx-auto ${lineColor}`}></div></div>
        {stepOutputResponse ?
            stepOutputResponse.status === 'success' ?
                (!isFragmentStep(step) || isBlockIntersectionStep(step)) && <WorkflowData index={index} step={step} projectKey={data?.project_key} dataSourceKey={step?.key} dataSourceType={stepOutputResponse[DATA_SOURCE_TYPE_KEYS.STEP_OUTPUT] ? DATA_SOURCE_TYPES.STEP_OUTPUT : DATA_SOURCE_TYPES.STEP_META} data={stepOutputResponse} parameters={parameters} dispatchParameters={dispatchParameters} topBarColor={topBarColor} showDataSchema={showDataSchema} />
                : <StepError data={stepOutputResponse} />
            : errorResponse ? <div className="md:flex-none py-4 text-center md:min-w-lg bg-white max-w-full shadow-sm rounded-md relative">
                <p className="text-sm">Oops, something went wrong.</p>
                <button className="mt-2 bg-gray-200 hover:bg-gray-100 active:bg-gray-300 focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-blocktext-black shadow-sm text-sm rounded-md py-1 px-2" onClick={(e) => { e.preventDefault(); retry(); }}>
                    <ResetIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Try again
                </button>
            </div> : <div className={`md:flex-none min-h-[120px] text-center md:min-w-lg max-w-full shadow-sm rounded-md relative block animate-pulse ${topBarColor}`}></div>}
    </>
}

function useStepOutputContentFetcher(stepOutput) {
    return useApiRequest({
        urlPath: `/projects/${stepOutput.project_key}/workflows/${stepOutput.workflow_key}/scopes/${stepOutput.run_scope_key}/runs/${stepOutput.run_key}/outputs/${stepOutput.key}`,
    }, [stepOutput?.key, stepOutput?.run_key, stepOutput?.started]);
}