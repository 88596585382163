function getLabelByValue(value, options){
    for (var i = 0; i < options.length; i++){
        for (var j = 0; j < options[i].list.length; j++){
            if (options[i].list[j].value.toString() === value.toString()){
                return options[i].list[j].label;
            }
        }
    }
    return value;
}

const selectorHelpers = {
    getLabelByValue: getLabelByValue
}

export default selectorHelpers;