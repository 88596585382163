import { React } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepTopBar from "./StepTopBar";
import DataInput from "../common/DataInput";
import valueHelpers from "../../helpers/value";
import DataView from "../common/DataView";
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import ResetButton from "../common/ResetButton";
import MetaBox from "./MetaBox";
import StepInputKey from "./StepInputKey";

function RenameStepForm({ index, step, update, parameters, onRun, onStop, onReset, isRunning, topBarColor }) {

    const { data, handleChange, handleSubmit, setItem, removeItem, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            formData.items = formData?.items || [];
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            update(index, submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    const handleInputKeyChange = (newInputKey) => {
        handleChange('input_key')(newInputKey);
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}>
            <StepInputKey value={data?.input_key} editMode={true} stepIndex={index} parameters={parameters} onChange={handleInputKeyChange} />
        </StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />
            {data.items.map((item, i) => {
                return (<div key={`var_${i}/${data.items.length}`}>
                    <div className="text-gray-600 align-middle">
                        <div className="flex flex-nowrap">
                            <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-red-500 active:bg-red-700 leading-[0.1] text-white text-center flex-grow-0 bg-red-600 rounded-full mt-1 mr-2 block" onClick={(e) => { e.preventDefault(); removeItem(["items"], i); }}>–</button>
                            <div className={`border-l flex-1 border-r border-t border-gray-200 ${(i === 0) ? 'rounded-t-md' : ''} ${(i + 1 === data.items.length) ? 'border-b rounded-b-md' : ''}`}>
                                <div className="flex flex-nowrap">
                                    <div className="flex-1 align-middle py-1 px-2">
                                        <label className="label block mb-0.5">Key of table / column</label>
                                        <DataInput stepIndex={index} parameters={parameters} className="w-full mb-1" placeholder="Key" value={valueHelpers.textValue(item.key)} onChange={handleChange(["items", i, "key"])} />
                                    </div>
                                </div>
                                <div className="flex flex-nowrap">
                                    <div className="flex-1 align-middle py-1 px-2">
                                        <label className="label block mb-0.5">New name</label>
                                        <DataInput stepIndex={index} parameters={parameters} className="w-full mb-1" placeholder="New name" value={valueHelpers.textValue(item.new_name)} onChange={handleChange(["items", i, "new_name"])} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
            <div className="flex items-center mt-2 flex-nowrap">
                <button type="button" className="w-5 h-5 appearance-none font-semibold hover:bg-green-400 active:bg-green-600 leading-[0.1] text-white text-center flex-grow-0 bg-green-500 rounded-full mr-2 block" onClick={(e) => { e.preventDefault(); setItem(["items"], { name: '', type: '1', initial_value: '', is_persistent: false }); }}>+</button>
                <label className="block text-xs font-semibold flex-1 text-gray-400">Rename more items</label>
            </div>
            <MetaBox stepIndex={index} parameters={parameters} data={data} handleChange={handleChange} setItem={setItem} removeItem={removeItem} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 mr-2 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} runToStep={data} />
                    <ResetButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-3000" onReset={onReset} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

export default function RenameStep({ index, step, update, editMode, parameters, onRun, onStop, onReset, isRunning, topBarColor }) {
    return editMode ?
        <RenameStepForm index={index} step={step} update={update} parameters={parameters} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} topBarColor={topBarColor} />
        : (<>
            <StepTopBar bgColor={topBarColor}>
                <StepInputKey value={step?.input_key} stepIndex={index} parameters={parameters} />
            </StepTopBar>
            <div className="bg-white rounded-b-md px-3 py-2">
                <StepHeader className="mb-3" stepType={step.type} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
                {(step.items && step.items.length > 0) ? <div className="text-gray-600 rounded-md border border-gray-200 bg-gray-50 align-middle text-sm">
                    {step.items.map((item, i) => {
                        return (<div key={`var_${i}/${step.items.length}`}>
                            <div className={`${(i + 1 !== step.items.length) ? 'border-b border-gray-200' : ''}}`}>
                                <div className="flex flex-wrap justify-between content-between">
                                    <div className="sm:inline-block flex-auto align-middle py-1 sm:py-2 px-2">
                                        <span className="text-xs font-bold block">Key</span>
                                        <DataView data={item.key} parameters={parameters} />
                                    </div>
                                    <div className="sm:inline-block flex-auto align-middle py-1 sm:py-2 px-2">
                                        <span className="text-xs font-bold block">New name</span>
                                        <DataView data={item.new_name} parameters={parameters} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div> : null}
            </div>
        </>);
}