import { React, useState } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import StepTopBar from "./StepTopBar";
import valueHelpers from "../../helpers/value";
import DataSourceSelector from "./DataSourceSelector";
import MetaBox from "./MetaBox";
import DataInput from "../common/DataInput";
import DataView from "../common/DataView";
import Selector from "../common/Selector";
import selectorHelpers from "../../helpers/selector";
import Checkbox from "../common/Checkbox";

const JOIN_TYPES = [
    {
        group: 'All',
        list: [
            {
                value: 'inner',
                label: 'Inner join'
            },
            {
                value: 'outer',
                label: 'Outer join'
            },
            {
                value: 'left',
                label: 'Left join'
            },
            {
                value: 'right',
                label: 'Right join'
            }
        ]
    }
];

function JoinStepForm({ index, step, update, parameters, onRun, onStop, isRunning, topBarColor }) {

    const { data, handleChange, handleSubmit, setItem, removeItem, errors, setData } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            update(index, submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    const [customJoinColumn, setCustomJoinColumn] = useState(() => {
        return data?.left_join_column && data?.right_join_column;
    });

    const handleCustomJoinColumnCheckbox = (checked) => {
        if (!checked) {
            setData({ ...data, left_join_column: '', right_join_column: '' });
        }
        setCustomJoinColumn(checked);
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}></StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />
            <label className="label block !text-gray-500 !font-bold mb-1">Join type</label>
            <Selector className="mb-2" value={data?.join_type} onChange={handleChange('join_type')} placeholder="Join type" options={JOIN_TYPES} />
            <div className="mb-2">
                <Checkbox value={customJoinColumn} onChange={handleCustomJoinColumnCheckbox} className="items-center">
                    <label className="label block">Custom join column</label>
                </Checkbox>
            </div>
            <label className="label block !text-gray-500 !font-bold mb-1">Left table</label>
            <div className="border rounded-md p-2">
                <label className="label block">Data source</label>
                <DataSourceSelector className="mb-1" editMode={true} value={data?.left_data_source} stepIndex={index} parameters={parameters} onChange={handleChange('left_data_source')} />
                <label className="label block">Table key</label>
                <DataInput stepIndex={index} parameters={parameters} className="w-full mb-1" placeholder="Table key" value={valueHelpers.textValue(data.left_table)} onChange={handleChange("left_table")} />
                {customJoinColumn && <>
                    <label className="label block">Join column</label>
                    <DataInput stepIndex={index} parameters={parameters} className="w-full" placeholder="Join column" value={valueHelpers.textValue(data.left_join_column)} onChange={handleChange("left_join_column")} />
                </>}
            </div>
            <label className="label block !text-gray-500 !font-bold mt-2 mb-1">Right table</label>
            <div className="border rounded-md p-2">
                <label className="label block">Data source</label>
                <DataSourceSelector className="mb-1" editMode={true} value={data?.right_data_source} stepIndex={index} parameters={parameters} onChange={handleChange('right_data_source')} />
                <label className="label block">Table key</label>
                <DataInput stepIndex={index} parameters={parameters} className="w-full mb-1" placeholder="Table key" value={valueHelpers.textValue(data.right_table)} onChange={handleChange("right_table")} />
                {customJoinColumn && <>
                    <label className="label block">Join column</label>
                    <DataInput stepIndex={index} parameters={parameters} className="w-full" placeholder="Join column" value={valueHelpers.textValue(data.right_join_column)} onChange={handleChange("right_join_column")} />
                </>}
            </div>
            <label className="label block !text-gray-500 !font-bold mt-2 mb-1">Output table name</label>
            <DataInput stepIndex={index} parameters={parameters} className="w-full" placeholder="Table name" value={valueHelpers.textValue(data.output_table_name)} onChange={handleChange("output_table_name")} />
            <MetaBox stepIndex={index} parameters={parameters} data={data} handleChange={handleChange} setItem={setItem} removeItem={removeItem} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} runToStep={data} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

export default function JoinStep({ index, step, update, editMode, parameters, onRun, onStop, isRunning, topBarColor }) {
    return editMode ?
        <JoinStepForm index={index} step={step} update={update} parameters={parameters} onRun={onRun} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} />
        : (<>
            <StepTopBar bgColor={topBarColor}></StepTopBar>
            <div className="bg-white rounded-b-md px-3 py-2">
                <StepHeader className="mb-3" stepType={step.type} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
                <div className="text-sm">
                    {selectorHelpers.getLabelByValue(step.join_type, JOIN_TYPES)} <DataView className="inline-block" data={step.left_table} parameters={parameters} /> with <DataView className="inline-block" data={step.right_table} parameters={parameters} />{(step.left_join_column && step.right_join_column) && <> on <DataView className="inline-block" data={step.left_join_column} parameters={parameters} /> == <DataView className="inline-block" data={step.right_join_column} parameters={parameters} /></>}
                </div>
            </div>
        </>);
}