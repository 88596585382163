import { useState } from "react";
import WorkflowNode from "./WorkflowNode";

function calcNumberOfChildNodes(node) {
    var numberOfChildNodes = 0;
    for (var i = 0; i < node.children.length; i++) {
        numberOfChildNodes++;
        numberOfChildNodes += calcNumberOfChildNodes(node.children[i]);
    }
    return numberOfChildNodes;
}

export default function WorkflowNodeGroup({ projectKey, node, steps, workflowOutputs, addStep, updateStep, deleteStep, markAsOutputStep, onRun, onStop, onReset, isRunning, parameters, dispatchParameters, showDataSchema }) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const collapse = (e) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
    }

    const numberOfChildNodes = calcNumberOfChildNodes(node);

    return <div className={`${node.depth !== 0 ? 'border-l-[10px] pl-3 ml-2' : ''} relative ${isCollapsed ? 'border-transparent' : (node.depth % 2 !== 0 ? 'border-gray-300' : 'border-gray-200')}`}>
        {node.depth !== 0 && <button className={`absolute hover:text-gray-500 inline-block leading-none py-0.5 px-[6px] font-bold rounded-md left-0 ml-[-16px] ${node.depth % 2 !== 0 ? 'bg-gray-300' : 'bg-gray-200'} ${isCollapsed ? 'top-[4px]' : 'top-0'}`} onClick={collapse}>{isCollapsed ? '+' : '–'}</button>}
        <div className="overflow-x-auto max-w-full" style={{ maxHeight: isCollapsed ? 0 : 'auto' }}>
            {node.children.map((childNode, i) => {
                return <WorkflowNode projectKey={projectKey} key={`wn-${steps[childNode.stepIndex].key}`} index={i} node={childNode} steps={steps} workflowOutputs={workflowOutputs} addStep={addStep} updateStep={updateStep} deleteStep={deleteStep} markAsOutputStep={markAsOutputStep} onRun={onRun} onStop={onStop} onReset={onReset} isRunning={isRunning} parameters={parameters} dispatchParameters={dispatchParameters} showDataSchema={showDataSchema} />
            })}
        </div>
        {isCollapsed ? <p className="bg-white px-2 py-1 w-full max-w-lg rounded-md shadow-sm">{numberOfChildNodes} step{numberOfChildNodes !== 1 ? 's' : ''}.</p> : null}
    </div>
}