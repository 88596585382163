import { React, useEffect, useMemo, useState } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import store from "store";
import axios from "axios";
import Header from "./components/common/Header";
import LoginPage from "./pages/LoginPage";
import ProjectsPage from "./pages/ProjectsPage";
import ProjectPage from "./pages/ProjectPage";
import PageNotFound from "./pages/PageNotFound";
import AccessTokenContext from './contexts/AccessTokenContext';
import UserContext from './contexts/UserContext';
import { useCancelToken } from "./hooks/cancel-token";
import { API_BASE_URL } from './config';
import useLogout from "./hooks/logout";
import ProtectedRoute from "./components/helpers/ProtectedRoute";
import { authenticateWithCustomToken } from "./services/firestore";

function useLoggedUserFetcher(accessToken) {
  const [user, setUser] = useState(store.get('user'));
  const { newCancelToken, isCancel } = useCancelToken();
  const logout = useLogout();
  const userState = useMemo(() => ({ user, setUser }), [user]);

  useEffect(() => {
    let mounted = true;
    if (accessToken) {
      axios({
        method: 'get',
        url: `${API_BASE_URL}/users/me`,
        cancelToken: newCancelToken(),
        headers: {
          'Authorization': `Bearer ${accessToken.token}`
        },
      }).then((response) => {
        const userData = response?.data?.data;
        if (userData) {
          store.set('user', userData);
          if (mounted) {
            setUser(userData);
          }
        }
      }).catch((error) => {
        if (!isCancel(error)) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401 && mounted) {
              logout();
            }
          } else {

          }
        }
      });
    }
    return () => mounted = false;
  }, [accessToken]);

  return userState;
}

function useFirebaseAuthenticator(accessToken) {
  const [firebaseUser, setFirebaseUser] = useState(null);
  const { newCancelToken, isCancel } = useCancelToken();
  const logout = useLogout();

  useEffect(() => {
    let mounted = true;
    if (accessToken) {
      axios({
        method: 'get',
        url: `${API_BASE_URL}/users/me/firebase-token`,
        cancelToken: newCancelToken(),
        headers: {
          'Authorization': `Bearer ${accessToken.token}`
        },
      }).then((response) => {
        const firebaseCustomToken = response?.data?.data?.firebase_token;
        if (firebaseCustomToken) {
          authenticateWithCustomToken(firebaseCustomToken).then((userCredential) => {
            if (mounted) {
              setFirebaseUser(userCredential.user);
            }
          })
            .catch((error) => {
              console.log(error);
              if (mounted) {
                setFirebaseUser(null);
              }
            });
        }
      }).catch((error) => {
        if (!isCancel(error)) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401 && mounted) {
              logout();
            }
          } else {

          }
        }
      });
    }
    return () => mounted = false;
  }, [accessToken]);

  return { firebaseUser, setFirebaseUser };
}

function App() {
  const [token, setToken] = useState(() => store.get('access_token'));
  const accessToken = useMemo(() => ({ token, setToken }), [token]);

  const user = useLoggedUserFetcher(token);
  useFirebaseAuthenticator(token);

  return (
    <BrowserRouter>
      <AccessTokenContext.Provider value={accessToken}>
        <UserContext.Provider value={user}>
          <div className="flex flex-col flex-nowrap items-stretch justify-items-stretch h-full">
            <Header />
            <Switch>
              <ProtectedRoute path="/app">
                <div className="container h-full flex-1 pt-4 md:pt-6 md:px-0 px-3 mx-auto">
                  <Switch>
                    <Route exact path="/app/projects"><ProjectsPage /></Route>
                    <Route path="/app/projects/:projectKey">
                      <ProjectPage />
                    </Route>
                    <Route path="*">
                      <PageNotFound />
                    </Route>
                  </Switch>
                </div>
              </ProtectedRoute>
              <Route exact path="/login">
                <LoginPage />
              </Route>
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </div>
        </UserContext.Provider>
      </AccessTokenContext.Provider>
    </BrowserRouter>
  );
}

export default App;