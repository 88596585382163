import { React, useState } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepTopBar from "./StepTopBar";
import DataInput from "../common/DataInput";
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import valueHelpers from "../../helpers/value";
import curl from '../../helpers/curl';
import ToggleButton from "../common/ToggleButton";
import DataView from "../common/DataView";
import MetaBox from "./MetaBox";

function RequestStepForm({ index, step, update, parameters, onRun, onStop, isRunning, topBarColor }) {

    const [curlEditorIsEnabled, setCurlEditorIsEnabled] = useState(false);
    const [curlValue, setCurlValue] = useState('');
    const [currentTab, setCurrentTab] = useState('params');

    const { data, setData, handleChange, handleSubmit, setItem, removeItem, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            formData.headers = formData?.headers || [];
            formData.params = formData?.params || [];
            formData.proxy = formData?.proxy || { schema: 'http', host: '', port: '' };
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            update(index, submittedData);
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    const tabContent = () => {
        switch (currentTab) {
            case 'params':
                return <div className="p-2 max-w-lg rounded-md border border-gray-100">
                    <div className="flex flex-nowrap mb-2 pl-7">
                        <label className="label block flex-1 mr-2">Key</label>
                        <label className="label block flex-1">Value</label>
                    </div>
                    {data.params && data.params.map((param, i) => {
                        return <div key={`param_${i.toString()}/${data.params.length}`} className="flex items-start flex-nowrap mb-2">
                            <button className="w-5 h-5 appearance-none font-semibold hover:bg-red-500 active:bg-red-700 leading-[0.1] text-white text-center flex-grow-0 bg-red-600 rounded-full mt-1 mr-2 block" onClick={(e) => { e.preventDefault(); removeItem("params", i); }}>–</button>
                            <DataInput stepIndex={index} parameters={parameters} className="flex-1 mr-2" placeholder="Key" value={valueHelpers.textValue(param.key)} onChange={handleChange(["params", i, "key"])} />
                            <DataInput stepIndex={index} parameters={parameters} className="flex-1" placeholder="Value" value={valueHelpers.textValue(param.value)} onChange={handleChange(["params", i, "value"])} />
                        </div>
                    })}
                    <div className="flex items-center flex-nowrap">
                        <button className="w-5 h-5 appearance-none font-semibold hover:bg-green-400 active:bg-green-600 leading-[0.1] text-white text-center flex-grow-0 bg-green-500 rounded-full mr-2 block" onClick={(e) => { e.preventDefault(); setItem("params", { key: '', value: '' }); }}>+</button>
                        <label className="block text-xs font-semibold flex-1 text-gray-400">Add new param</label>
                    </div>
                </div>;
            case 'headers':
                return <div className="p-2 max-w-lg rounded-md border border-gray-100">
                    <div className="flex flex-nowrap mb-2 pl-7">
                        <label className="label block flex-1 mr-2">Key</label>
                        <label className="label block flex-1">Value</label>
                    </div>
                    {data.headers && data.headers.map((header, i) => {
                        return <div key={`header_${i.toString()}/${data.headers.length}`} className="flex items-start flex-nowrap mb-2">
                            <button className="w-5 h-5 appearance-none font-semibold hover:bg-red-500 active:bg-red-700 leading-[0.1] text-white text-center flex-grow-0 bg-red-600 rounded-full mt-1 mr-2 block" onClick={(e) => { e.preventDefault(); removeItem("headers", i); }}>–</button>
                            <DataInput stepIndex={index} parameters={parameters} className="flex-1 mr-2" placeholder="Key" value={valueHelpers.textValue(header.key)} onChange={handleChange(["headers", i, "key"])} />
                            <DataInput stepIndex={index} parameters={parameters} className="flex-1" placeholder="Value" value={valueHelpers.textValue(header.value)} onChange={handleChange(["headers", i, "value"])} />
                        </div>
                    })}
                    <div className="flex items-center flex-nowrap">
                        <button className="w-5 h-5 appearance-none font-semibold hover:bg-green-400 active:bg-green-600 leading-[0.1] text-white text-center flex-grow-0 bg-green-500 rounded-full mr-2 block" onClick={(e) => { e.preventDefault(); setItem("headers", { key: '', value: '' }); }}>+</button>
                        <label className="block text-xs font-semibold flex-1 text-gray-400">Add new header</label>
                    </div>
                </div>;
            case 'body':
                return <DataInput stepIndex={index} parameters={parameters} multipleLines={true} placeholder="Body" value={valueHelpers.textValue(step?.body)} onChange={handleChange('body')} />
            default:
                return '';
        }
    }

    const handleCurlValueChange = (value) => {
        setCurlValue(value);
        const request = curl.toJSON(value);
        const paramPosition = request.url.indexOf('?');
        setData({
            ...data,
            url: paramPosition !== -1 ? request.url.substr(0, paramPosition) : request.url,
            method: request.method,
            headers: request.headers,
            params: request.params,
            body: request.body,
        });
    }

    const handleCurlEditorSwitch = (checked) => {
        if (checked) {
            setCurlValue(curl.fromJSON(data));
        }
        setCurlEditorIsEnabled(checked);
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}></StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />
            {!curlEditorIsEnabled ?
                <>
                    <div className="flex items-stretch mt-2 flex-nowrap">
                        <label className="flex-none mr-2" htmlFor="method">
                            <div className="label">Method</div>
                            <select className="select select--sm" value={valueHelpers.textValue(data.method)} onChange={handleChange('method')} required>
                                <option value="GET">GET</option>
                                <option value="POST">POST</option>
                                <option value="PUT">PUT</option>
                                <option value="PATCH">PATCH</option>
                                <option value="DELETE">DELETE</option>
                            </select>
                        </label>
                        <label className="flex-1" htmlFor="url">
                            <div className="label">Request URL</div>
                            <DataInput stepIndex={index} parameters={parameters} value={valueHelpers.textValue(data.url)} onChange={handleChange('url')} placeholder="Enter request URL" />
                        </label>
                    </div>
                    <div className="flex my-2 bg-gray-100 flex-nowrap p-0.5 text-xs rounded-md">
                        <button className={`nav-tab ${currentTab === 'params' ? 'nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); setCurrentTab('params') }}>Params</button>
                        <button className={`nav-tab ${currentTab === 'headers' ? 'nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); setCurrentTab('headers') }}>Headers</button>
                        {data?.method?.toUpperCase() !== 'GET' && <button className={`nav-tab ${currentTab === 'body' ? 'nav-tab--active' : ''}`} onClick={(e) => { e.preventDefault(); setCurrentTab('body') }}>Body</button>}
                    </div>
                    {tabContent()}
                </>
                : <div>
                    <label className="label flex-none mr-2">cUrl editor</label>
                    <DataInput stepIndex={index} parameters={parameters} multipleLines={true} placeholder="curl http://example.com" value={curlValue} onChange={handleCurlValueChange} />
                </div>
            }
                        <div>
                <label className="font-semibold text-xs text-gray-600 block mt-3">Advanced options</label>
                <div className="flex items-stretch mt-1 flex-nowrap">
                    <label className="label flex-none mr-2">Enable curl editor</label>
                    <label className="flex-1">
                        <ToggleButton value={curlEditorIsEnabled} onChange={handleCurlEditorSwitch} />
                    </label>
                </div>
                <label className="font-semibold text-xs text-gray-600 block mb-0.5 mt-1">Proxy</label>
                <div className="flex items-start flex-nowrap">
                    <div className="flex-none mr-2">
                        <label className="label">Schema</label>
                        <div>
                            <select className="select select--sm" value={valueHelpers.textValue(data.proxy.schema)} onChange={handleChange(["proxy", "schema"])} required>
                                <option value="http">http</option>
                                <option value="https">https</option>
                                <option value="socks5">socks5</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex-1 mr-2">
                        <label className="label">Host</label>
                        <DataInput stepIndex={index} parameters={parameters} placeholder="Host" value={valueHelpers.textValue(data.proxy.host)} onChange={handleChange(["proxy", "host"])} />
                    </div>
                    <div className="flex-none">
                        <label className="label">Port</label>
                        <DataInput stepIndex={index} parameters={parameters} className="min-w-[80px]" placeholder="Port" value={valueHelpers.textValue(data.proxy.port)} onChange={handleChange(["proxy", "port"])} />
                    </div>
                </div>
                <div className="flex items-start flex-nowrap">
                    <div className="flex-1 mr-2">
                        <label className="label">Username</label>
                        <DataInput stepIndex={index} parameters={parameters} className="flex-1 mr-2" placeholder="Username" value={valueHelpers.textValue(data.proxy.username)} onChange={handleChange(["proxy", "username"])} />
                    </div>
                    <div className="flex-1">
                        <label className="label">Password</label>
                        <DataInput stepIndex={index} parameters={parameters} className="flex-1" placeholder="Password" value={valueHelpers.textValue(data.proxy.password)} onChange={handleChange(["proxy", "password"])} />
                    </div>
                </div>
            </div>
            <MetaBox stepIndex={index} parameters={parameters} data={data} handleChange={handleChange} setItem={setItem} removeItem={removeItem} />
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-4">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} runToStep={data} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

export default function RequestStep({ index, step, update, editMode, parameters, onRun, onStop, isRunning, topBarColor }) {
    return editMode ?
        <RequestStepForm index={index} step={step} update={update} parameters={parameters} onRun={onRun} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} />
        : (<>
            <StepTopBar bgColor={topBarColor}></StepTopBar>
            <div className="bg-white rounded-b-md px-3 py-2">
                <StepHeader className="mb-3" stepType={step.type} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
                <div className="text-sm text-gray-600 truncate">
                    <span className="font-semibold text-primary-dark">{valueHelpers.textValue(step?.method)}</span> <DataView className="inline-block" data={valueHelpers.textValue(step?.url)} parameters={parameters} />
                </div>
            </div>
        </>);
}