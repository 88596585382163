import { React, useState, useEffect } from "react";
import rfdc from 'rfdc';
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config"
import StepHeader from "./StepHeader";
import StepControlButton from "./StepControlButton";
import StepTopBar from "./StepTopBar";
import valueHelpers from "../../helpers/value";
import StepInputKey from "./StepInputKey";
import FilePicker from "../explorer/FilePicker";
import FileIcon from "../explorer/FileIcon";
import { useFileFetcher } from "../../hooks/file";
import DataInput from "../common/DataInput";
import { ReactComponent as HomeIcon } from '../../icons/home.svg';
import DataView from "../common/DataView";
import MetaBox from "./MetaBox";
import { isFragmentStep } from "../../helpers/step";

function EmbedWorkflowStepForm({ projectKey, index, step, fragmentOf, update, parameters, onRun, onStop, isRunning, topBarColor }) {

    const { data, setData, handleChange, handleSubmit, setItem, removeItem, errors } = useForm({
        initialValues: () => {
            let formData = rfdc()(step);
            if (!isFragmentStep(step)) {
                formData.arguments = formData.arguments || [];
            }
            formData.meta = formData?.meta || [];
            return formData;
        },
        onSubmit: (submittedData) => {
            if (!isFragmentStep(step)) {
                submittedData.arguments = submittedData.arguments.map((arg) => ({ name: arg.name, value: arg.value }));
            }
            update(index, submittedData);
        }
    });

    const [embeddedWorkflowFileKey, setEmbeddedWorkflowFileKey] = useState(() => isFragmentStep(step) ? fragmentOf?.workflow_file_key : data.workflow_file_key);
    const { responseData: embeddedWorkflowFileResponseData } = useFileFetcher({ projectKey, fileKey: embeddedWorkflowFileKey, withContent: true }, [projectKey, embeddedWorkflowFileKey]);
    const [embeddedWorkflowFileInfo, setEmbeddedWorkflowFileInfo] = useState(embeddedWorkflowFileResponseData);

    useEffect(() => {
        if (embeddedWorkflowFileResponseData) {
            var fileInfo = { ...embeddedWorkflowFileResponseData }
            try {
                fileInfo.content = JSON.parse(fileInfo.content);
            } catch {
                fileInfo.content = { title: "", steps: [], parameters: [] };
            }
            setEmbeddedWorkflowFileInfo(fileInfo);
        }
    }, [embeddedWorkflowFileResponseData])

    useEffect(() => {
        if (embeddedWorkflowFileInfo?.content?.parameters && !isFragmentStep(step)) {
            const args = embeddedWorkflowFileInfo.content?.parameters.map((parameter) => ({ ...parameter }));
            if (data.arguments) {
                var i, j;
                for (i = 0; i < args.length; i++) {
                    for (j = 0; j < data.arguments.length; j++) {
                        if (args[i].name === data.arguments[j].name) {
                            args[i].value = data.arguments[j].value;
                            break;
                        }
                    }
                }
            }
            setData({ ...data, arguments: args });
        } else {
            setData({ ...data, arguments: [] });
        }
    }, [embeddedWorkflowFileInfo]);

    const cancel = (e) => {
        e.preventDefault();
        update(index, step);
    }

    const handleInputKeyChange = (newInputKey) => {
        handleChange('input_key')(newInputKey);
    }

    const handleEmbeddedWorkflowFileKeyChange = (workflowFileKey) => {
        handleChange("workflow_file_key")(workflowFileKey);
        setEmbeddedWorkflowFileKey(workflowFileKey);
    }

    return (<form onSubmit={handleSubmit}>
        <StepTopBar bgColor={topBarColor}>
            {!isFragmentStep(step) && <StepInputKey value={data?.input_key} editMode={true} stepIndex={index} parameters={parameters} onChange={handleInputKeyChange} />}
        </StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className="mb-3" stepType={data?.type} stepIcon={embeddedWorkflowFileInfo?.icon} title={valueHelpers.textValue(data?.title)} onTitleChange={handleChange('title')} markedAsOutput={data?.marked_as_output} />
            {!isFragmentStep(step) ?
                <>
                    <label className="label block">Workflow file</label>
                    <FilePicker projectKey={projectKey} className="w-full mb-2" fileType={2} placeholder="Select a workflow file..." value={valueHelpers.textValue(data.workflow_file_key)} onChange={handleEmbeddedWorkflowFileKeyChange} />
                    {data.arguments.length > 0 && <div className="mt-2">
                        <span className="text-xs text-gray-400 font-bold block mb-1">Arguments</span>
                        <div className="py-1 px-2 border rounded-md">
                            {data.arguments.map((argument, i) => {
                                return <div key={`var_${i}/${data.arguments.length}`}>
                                    <label className="label block !text-gray-500 mb-0.5">{argument.name}</label>
                                    <DataInput stepIndex={index} parameters={parameters} className="w-full mb-1" placeholder={argument.name.toString()} value={valueHelpers.textValue(argument.value)} onChange={handleChange(["arguments", i, "value"])} />
                                </div>
                            })}
                        </div>
                    </div>}
                </> : null}
            {!isFragmentStep(step) && <MetaBox stepIndex={index} parameters={parameters} data={data} handleChange={handleChange} setItem={setItem} removeItem={removeItem} />}
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
            <div className="flex mt-3">
                <div className="flex-none">
                    <StepControlButton className="bg-gray-200 hover:bg-gray-100 active:bg-gray-300" onRun={onRun} onStop={onStop} isRunning={isRunning} runToStep={data} />
                </div>
                <div className="flex-1 text-right">
                    <button onClick={cancel} className="btn btn--secondary mr-2 btn--sm">Cancel</button>
                    <button className="btn btn--primary btn--sm" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>)
}

function EmbedWorkflowStepView({ projectKey, index, step, fragmentOf, parameters, topBarColor }) {
    const embeddedWorkflowFileKey = (isFragmentStep(step) ? fragmentOf?.workflow_file_key : step?.workflow_file_key) || "";
    const [embeddedWorkflowArguments, setEmbeddedWorkflowArguments] = useState([]);
    const { responseData: embeddedWorkflowFileResponseData } = useFileFetcher({ projectKey, fileKey: embeddedWorkflowFileKey, withContent: true }, [embeddedWorkflowFileKey, projectKey]);
    const [embeddedWorkflowFileInfo, setEmbeddedWorkflowFileInfo] = useState(embeddedWorkflowFileResponseData);

    useEffect(() => {
        if (embeddedWorkflowFileResponseData) {
            var fileInfo = { ...embeddedWorkflowFileResponseData }
            try {
                fileInfo.content = JSON.parse(fileInfo.content);
            } catch {
                fileInfo.content = { title: "", steps: [], parameters: [] };
            }
            setEmbeddedWorkflowFileInfo(fileInfo);
        }
    }, [embeddedWorkflowFileResponseData])

    useEffect(() => {
        if (embeddedWorkflowFileInfo?.content?.parameters && !isFragmentStep(step)) {
            const args = embeddedWorkflowFileInfo.content?.parameters.map((parameter) => ({ ...parameter }));
            if (step.arguments) {
                var i, j;
                for (i = 0; i < args.length; i++) {
                    for (j = 0; j < step.arguments.length; j++) {
                        if (args[i].name === step.arguments[j].name) {
                            args[i].value = step.arguments[j].value;
                            break;
                        }
                    }
                }
            }
            setEmbeddedWorkflowArguments(args);
        } else {
            setEmbeddedWorkflowArguments([]);
        }
    }, [step, embeddedWorkflowFileInfo]);

    return <>
        <StepTopBar bgColor={topBarColor}>
            {!isFragmentStep(step) && <StepInputKey value={step?.input_key} stepIndex={index} parameters={parameters} />}
        </StepTopBar>
        <div className="bg-white rounded-b-md px-3 py-2">
            <StepHeader className={!isFragmentStep(step) ? 'mb-3' : ''} stepType={step.type} stepIcon={embeddedWorkflowFileInfo?.icon} title={`${index + 1}. ${valueHelpers.textValue(step?.title)}`} markedAsOutput={step?.marked_as_output} />
            {(!isFragmentStep(step) && embeddedWorkflowFileInfo) && <>
                <div className="text-sm text-gray-500">Workflow file:</div>
                <div className="w-full file-picker rounded-md relative bg-gray-100 p-1">
                    <a href={`/app/projects/${projectKey}/workspace?key=${embeddedWorkflowFileInfo.key}`} target="_blank" className="flex hover:text-primary-dark">
                        {embeddedWorkflowFileInfo.key === "" ? <HomeIcon className="flex-none border bg-white rounded-sm p-1 mr-1.5 h-[25px] w-[25px]" /> : <FileIcon type={embeddedWorkflowFileInfo.type} icon={embeddedWorkflowFileInfo.icon} className={`flex-none border bg-white rounded-sm mr-1.5 h-[25px] w-[25px] ${embeddedWorkflowFileInfo.icon ? '' : 'p-1'}`} />}
                        <span className="flex-1 text-sm break-all mr-2 tracking-normal whitespace-pre-wrap leading-normal">{embeddedWorkflowFileInfo.key === "" ? "Home" : embeddedWorkflowFileInfo.name}</span>
                    </a>
                </div>
                {embeddedWorkflowArguments.length > 0 && <div className="mt-2">
                    <span className="text-xs text-gray-400 font-bold block mb-1">Arguments</span>
                    <div className="py-1 px-2 border rounded-md">
                        {embeddedWorkflowArguments.map((argument, i) => {
                            return <div key={`var_${i}/${embeddedWorkflowArguments.length}`}>
                                <label className="label block !text-gray-500 mb-0.5">{argument.name}</label>
                                <DataView data={valueHelpers.textValue(argument.value)} parameters={parameters} />
                            </div>
                        })}
                    </div>
                </div>}
            </>}
        </div>
    </>
}

export default function EmbedWorkflowStep({ projectKey, index, step, fragmentOf, update, editMode, parameters, onRun, onStop, isRunning, topBarColor }) {
    return editMode ?
        <EmbedWorkflowStepForm projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} update={update} parameters={parameters} onRun={onRun} onStop={onStop} isRunning={isRunning} topBarColor={topBarColor} />
        : <EmbedWorkflowStepView projectKey={projectKey} index={index} step={step} fragmentOf={fragmentOf} parameters={parameters} topBarColor={topBarColor} />;
}