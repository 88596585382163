import keyHelpers from "../helpers/key";
import * as paramHelpers from "../helpers/param";

export function parametersReducer(state, action) {
    switch (action.type) {
        case 'add_param':
            var scope = state[action.dataSourceKey];
            if (scope) {
                if (scope.params.map((param) => `${param.dataSourceType}-${param.dataKey}`).indexOf(`${action.param.dataSourceType}-${action.param.dataKey}`) === -1) {
                    scope.params = [...scope.params, action.param];
                }
            } else {
                if (action.step) {
                    scope = { step: action.step, params: [action.param] };
                } else {
                    scope = { params: [action.param] };
                }
            }
            return { ...state, [action.dataSourceKey]: scope };
        case 'remove_param':
            var scope = state[action.dataSourceKey];
            if (scope) {
                scope.params = scope.params.filter((param) => param.dataKey !== action.param.dataKey || param.dataSourceType !== action.param.dataSourceType);
            } else {
                if (action.step) {
                    scope = { step: action.step, params: [] };
                } else {
                    scope = { params: [] };
                }
            }
            return { ...state, [action.dataSourceKey]: scope };
        case 'update_all':
            return action.scopes;
    }
}

export function appendParametersFromText(text, scopes) {
    if (typeof text === 'string') {
        const params = paramHelpers.findAllParams(text);
        if (params.length > 0) {
            for (var p = 0; p < params.length; p++) {
                if (scopes.hasOwnProperty(params[p].dataSourceKey)) {
                    scopes[params[p].dataSourceKey].params.push({
                        dataKey: params[p].dataKey,
                        dataSourceType: params[p].dataSourceType,
                        type: keyHelpers.detectKeyType(params[p].dataKey),
                    });
                }
            }
        }
    }
    return scopes;
}

export function mergeParameters(parameters1, parameters2) {
    const result = {};

    var dataSourceKey, i, exists, j;
    if (parameters1) {
        for (dataSourceKey in parameters1) {
            if (!result.hasOwnProperty(dataSourceKey)) {
                result[dataSourceKey] = parameters1[dataSourceKey];
            } else {
                if (parameters1[dataSourceKey].step) {
                    result[dataSourceKey].step = parameters1[dataSourceKey].step;
                }
                for (i = 0; i < parameters1[dataSourceKey].params.length; i++) {
                    exists = false;
                    for (j = 0; j < result[dataSourceKey].params.length; j++) {
                        if (parameters1[dataSourceKey].params[i].dataKey === result[dataSourceKey].params[j].dataKey &&
                            parameters1[dataSourceKey].params[i].dataSourceType === result[dataSourceKey].params[j].dataSourceType) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        result[dataSourceKey].params.push(parameters1[dataSourceKey].params[i]);
                    }
                }
            }
        }
    }
    if (parameters2) {
        for (dataSourceKey in parameters2) {
            if (!result.hasOwnProperty(dataSourceKey)) {
                result[dataSourceKey] = parameters2[dataSourceKey];
            } else {
                if (parameters2[dataSourceKey].step) {
                    result[dataSourceKey].step = parameters2[dataSourceKey].step;
                }
                for (i = 0; i < parameters2[dataSourceKey].params.length; i++) {
                    exists = false;
                    for (j = 0; j < result[dataSourceKey].params.length; j++) {
                        if (parameters2[dataSourceKey].params[i].dataKey === result[dataSourceKey].params[j].dataKey &&
                            parameters2[dataSourceKey].params[i].dataSourceType === result[dataSourceKey].params[j].dataSourceType) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        result[dataSourceKey].params.push(parameters2[dataSourceKey].params[i]);
                    }
                }
            }
        }
    }
    return result;
}
