import { React} from "react";
import { useTeamMemberGroups } from "../../hooks/team-member-group";
import TeamMemberGroupCard from "./TeamMemberGroupCard";

export default function TeamMemberGroups({ selectedGroupKey, editable, onSelect, onEdit, onDelete }) {

    const { teamMemberGroups } = useTeamMemberGroups();

    return (
        <>
            {(teamMemberGroups && teamMemberGroups.length > 0) && teamMemberGroups.map((teamMemberGroup) => {
                return <TeamMemberGroupCard key={teamMemberGroup?.key} editable={editable && teamMemberGroup?.key !== 'admins'} onEdit={onEdit} onDelete={onDelete} onSelect={onSelect} selected={selectedGroupKey === teamMemberGroup?.key} teamMemberGroup={teamMemberGroup} />
            })}
            {teamMemberGroups === null && <>
                <div className="block animate-pulse bg-slate-200 rounded-lg h-8 mb-1.5"></div>
                <div className="block animate-pulse bg-slate-200 rounded-lg h-8 mb-1.5"></div>
                <div className="block animate-pulse bg-slate-200 rounded-lg h-8 mb-1.5"></div>
                <div className="block animate-pulse bg-slate-200 rounded-lg h-8"></div>
            </>}
        </>
    );
}