import { React, useRef, useState, useEffect, useCallback} from 'react';
import {databaseToDocument} from "../../helpers/database";
import useForm from "../../hooks/form";
import { FORM_ERROR_KEY } from "../../config";
import LocationPicker from "../explorer/LocationPicker";
import valueHelpers from "../../helpers/value";
import useAccessToken from "../../hooks/access-token";
import useLogout from "../../hooks/logout";
import { useCancelToken } from "../../hooks/cancel-token";
import requestHelpers from "../../helpers/request";
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';

export default function SaveDataDialog({projectKey, directoryKey, fileData, onClose }) {
    const dialogRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);

    const close = useCallback(() => {
        if (typeof onClose === 'function') {
            onClose();
        }
        setIsVisible(false);
    }, [onClose]);

    useEffect(() => {

        const handleDisregard = (e) => {
            const dialogElem = dialogRef.current;
            if (isVisible) {
                var el = e.target, disregard = true;
                while (el && !el.classList.contains('save-data-dialog')) {
                    el = el.parentElement;
                }
                if (el && el.classList.contains('save-data-dialog')) {
                    disregard = false;
                }
                if (disregard || el !== dialogElem) {
                    close();
                }
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [isVisible, close])

    return <div className={`fixed z-50 px-2 top-0 left-0 bottom-0 right-0 bg-gray-500/50 ${isVisible ? '' : 'hidden'}`}>
        <div ref={dialogRef} className="save-data-dialog w-full max-w-[280px] md:max-w-[400px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg border">
            <SaveDataForm projectKey={projectKey} directoryKey={directoryKey} fileData={fileData} onClose={close} />
        </div>
    </div>
}

function SaveDataForm({projectKey, fileData, onClose, directoryKey = '' }) {
    const [isBusy, setIsBusy] = useState(false);
    const cancelToken = useCancelToken();
    const logout = useLogout();
    const { token: accessToken } = useAccessToken();

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return {
                directory: directoryKey,
                content: JSON.stringify(databaseToDocument(fileData)),
                name: '',
                type: 3,
            };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            requestHelpers.sendApiRequest({
                method: 'post',
                urlPath: `/projects/${projectKey}/workspace/files`,
                data: submittedData,
                cancelToken: cancelToken,
                accessToken: accessToken,
                onAccessDenied: logout,
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onSuccess: () => {
                    if (mounted.current) {
                        onClose();
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onClose();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    return <form onSubmit={submit}>
        <LocationPicker projectKey={projectKey} value={data?.directory} onChange={handleChange('directory')} />
        {errors[FORM_ERROR_KEY] && <div className="form-error px-2">{errors[FORM_ERROR_KEY]}</div>}
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-3 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <input className="input input--sm input--full !bg-white border border-gray-300 focus:border-transparent" type="text" value={valueHelpers.textValue(data?.name)} name="name" required placeholder="Enter file name" onChange={handleChange('name')} />
            <div className="flex-none min-w-0 whitespace-nowrap pl-2">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button onClick={submit} className="btn btn--sm btn--primary" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Save here"}</button>
            </div>
        </div>
    </form>
}