import { React } from 'react';
import { ReactComponent as ResetIcon } from '../../icons/reset.svg';

export default function ResetButton({ onReset, ...rest }) {

    const handleClick = (e) => {
        e.preventDefault();
        onReset()
    }

    return <button {...{ ...rest, className: "disabled:cursor-not-allowed focus:outline-none appearance-none transition focus:ring-2 focus:ring-secondary hover:shadow-none inline-blocktext-black shadow-sm text-sm rounded-md py-1 px-2 " + (rest.className || '' ) }} onClick={handleClick}>
        <ResetIcon className="text-gray-700 fill-current inline-block w-[16px] mr-1" /> Reset
    </button>
}