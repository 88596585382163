import { React } from 'react';
import { useHistory } from "react-router-dom";
import { ReactComponent as ProjectIcon } from '../../icons/project.svg';
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import timeAndDateHelpers from "../../helpers/timeAndDate";

export default function ProjectCard({ project }) {
    let history = useHistory();

    function handleClick(e) {
        e.preventDefault();
        var ignore = true;
        if (e.type === 'keydown') {
            if (e.key === 'Enter' || e.key === " " || e.key === "Spacebar") {
                ignore = false;
            }
        } else if (e.type === 'click') {
            ignore = false;
        }
        if (!ignore) {
            history.push(`/app/projects/${project?.key}/workspace`);
        }
    }

    return (
        <a href="#" onClick={handleClick} onKeyDown={handleClick} className="block relative rounded-lg hover:border hover:border-primary-dark active:bg-gray-50 bg-white h-16 pl-16">
            <div className={`${project?.icon ? 'rounded-md' : ''} block max-h-10 w-10 absolute left-3 top-[50%] -translate-y-[50%]`}>
                {project?.icon ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/projects/medium/${project.icon}`} className="block w-full rounded-md" alt="Project icon" /> : <ProjectIcon className="absolute fill-current text-gray-500 block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[50%]" />}
            </div>
            <div className="py-3 pr-2">
                <h4 className="font-medium text-sm truncate">{project?.name}</h4>
                <p className="text-sm text-gray-600 truncate">{timeAndDateHelpers.timeSince(project?.created)}</p>
            </div>
        </a>
    );
}