import { React, useState, useEffect, useRef, useCallback } from "react";
import useForm from "../../hooks/form";
import valueHelpers from "../../helpers/value";
import { FORM_ERROR_KEY } from "../../config";
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';
import FilePicker from "../explorer/FilePicker";
import ItemSizePicker from "./itemSizePicker";
import Checkbox from "../common/Checkbox";
import Options from "../common/Options";

export default function ItemActionDialog({ projectKey, action, onInsert, onCancel }) {
    const dialogRef = useRef(null);
    const [isVisible, setIsVisible] = useState(action !== null);

    useEffect(() => {
        setIsVisible(action !== null);
    }, [action]);

    const cancel = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
        setIsVisible(false);
    }, [onCancel]);

    useEffect(() => {

        const handleDisregard = (e) => {
            const dialogElem = dialogRef.current;
            if (isVisible) {
                var el = e.target, disregard = true;
                while (el && !el.classList.contains('item-action-dialog')) {
                    el = el.parentElement;
                }
                if (el && el.classList.contains('item-action-dialog')) {
                    disregard = false;
                }
                if (disregard || el !== dialogElem) {
                    cancel();
                }
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [isVisible, cancel])

    const dialogContent = () => {
        if (action) {
            switch (action.name) {
                case 'insert':
                    return <InsertActionDialog projectKey={projectKey} action={action} onInsert={onInsert} onCancel={cancel} />
                default:
                    return null;
            }
        }
        return null
    }

    return <div className={`fixed px-2 top-0 left-0 bottom-0 right-0 bg-gray-500/50 ${isVisible ? '' : 'hidden'}`}>
        <div ref={dialogRef} className="item-action-dialog w-full max-w-[90%] md:max-w-[500px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg border">
            {dialogContent()}
        </div>
    </div>
}

function InsertActionDialog({ projectKey, action, onInsert, onCancel }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors } = useForm({
        initialValues: () => {
            const d = { size: { width: 0, height: 0 }, content_type: action?.data?.itemType };
            if (d.content_type === "widget") {
                d.content = "";
            } else if (d.content_type === "group") {
                d.wrap = false;
                d.alignment = "left";
                d.children = [];
            }
            return d;
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onInsert(action?.data?.position, submittedData);
            if (mounted.current) {
                setIsBusy(false);
                onCancel();
            }
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    return <form className="p-2" onSubmit={submit}>
        {data.content_type === "widget" ? <>
            <h2 className="mb-1">Insert a new widget</h2>
            <label className="font-semibold text-gray-500 text-sm">Widget file</label>
            <FilePicker className="mb-2" projectKey={projectKey} fileType={4} placeholder="Select a Widget file..." value={valueHelpers.textValue(data?.content)} onChange={handleChange('content')} />
        </> : <>
            <h2 className="mb-1">Insert a new group</h2>
            <label className="font-semibold text-gray-500 text-sm">Alignment</label>
            <Options value={valueHelpers.textValue(data?.alignment)} onChange={handleChange('alignment')} options={[{label: 'Left', value: 'left'}, {label: 'Center', value: 'center'}, {label: 'Right', value: 'right'}]} className="max-w-[200px] mb-1"/>
            <label className="font-semibold text-gray-500 text-sm">Wrap</label>
            <Checkbox value={data?.wrap} onChange={handleChange('wrap')} className="items-center mb-1">
                <label className="label block">Allow items in the group to wrap onto multiple lines</label>
            </Checkbox>
        </>}
        <label className="font-semibold text-gray-500 text-sm">Size</label>
        <ItemSizePicker value={data?.size} onChange={handleChange('size')} />
        {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        <div className="mt-2 text-right">
            <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
            <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Insert"}</button>
        </div>
    </form>
}