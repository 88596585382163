import { initializeApp } from "firebase/app";
import {
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    doc,
    collection,
} from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { FIREBASE_CONFIG } from "../config";

const app = initializeApp(FIREBASE_CONFIG);
const db = getFirestore(app);

//collections
const USERS_COLLECTION = "users";
const PROJECTS_COLLECTION = "projects";
const TEAM_COLLECTION = "team";
const USER_PROJECTS_COLLECTION = "projects";
const FILES_COLLECTION = "files";
const WORKFLOW_RUNS_COLLECTION = "runs";
const WORKFLOW_RECENT_RUNS_COLLECTION = "recent-runs";
const WORKFLOW_RUN_SCOPES_COLLECTION = "scopes";
const WORKFLOW_STEP_OUTPUTS_COLLECTION = "outputs";

export const authenticateWithCustomToken = (token) => {
    return signInWithCustomToken(getAuth(app), token);
};

export const watchWorkflowRunUpdates = (projectKey, workflowKey, runScopeKey, runKey, snapshot, error) => {
    const itemDocRef = doc(db,
        PROJECTS_COLLECTION, projectKey,
        FILES_COLLECTION, workflowKey,
        WORKFLOW_RUN_SCOPES_COLLECTION, runScopeKey,
        WORKFLOW_RUNS_COLLECTION, runKey);
    return onSnapshot(itemDocRef, snapshot, error);
};

export const streamWorkflowRunStepOutputs = (projectKey, workflowKey, runScopeKey, runKey, snapshot, error) => {
    const itemsColRef = collection(db,
        PROJECTS_COLLECTION, projectKey,
        FILES_COLLECTION, workflowKey,
        WORKFLOW_RUN_SCOPES_COLLECTION, runScopeKey,
        WORKFLOW_RUNS_COLLECTION, runKey,
        WORKFLOW_STEP_OUTPUTS_COLLECTION);
    const itemsQuery = query(itemsColRef, orderBy('started'));
    return onSnapshot(itemsQuery, snapshot, error);
};

export const watchWorkflowRecentRunUpdates = (projectKey, workflowKey, runScopeKey, recentRunKey, snapshot, error) => {
    const itemDocRef = doc(db,
        PROJECTS_COLLECTION, projectKey,
        FILES_COLLECTION, workflowKey,
        WORKFLOW_RUN_SCOPES_COLLECTION, runScopeKey,
        WORKFLOW_RECENT_RUNS_COLLECTION, recentRunKey);
    return onSnapshot(itemDocRef, snapshot, error);
};
