
function timeSince(unixTimestamp) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const now = new Date();
    const elapsed = now.getTime() - (unixTimestamp * 1000);

    if (elapsed < msPerMinute) {
        if (Math.round(elapsed / 1000) === 0){
            return 'now';
        }
        return Math.round(elapsed / 1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago';
    }
    return formatDate(unixTimestamp);
}

function formatDate(unixTimestamp) {
    var date = new Date(unixTimestamp * 1000);

    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()];
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();

    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;
    sec = (sec < 10 ? "0" : "") + sec;

    return date.getDate() + " " + month + " " + date.getFullYear() + " " + hour + ":" + min + ":" + sec;
}

const timeAndDateHelpers = {
    "timeSince": timeSince,
    "formatDate": formatDate,
};

export default timeAndDateHelpers;