import { React, useState, useEffect } from "react";
import Selector from "../common/Selector";
import { DATA_SOURCE_KEYS } from "../../constants/data-source";

function getOptions(stepIndex, parameters) {
    const options = { group: 'keys', list: [] };
    if (parameters) {
        for (var dataSourceKey in parameters) {
            if (dataSourceKey !== DATA_SOURCE_KEYS.PARAMETER && dataSourceKey !== DATA_SOURCE_KEYS.INPUT && parameters[dataSourceKey].step.index != stepIndex) {
                options.list.push({
                    index: parameters[dataSourceKey].step.index,
                    label: `${parameters[dataSourceKey].step.index + 1}. ${parameters[dataSourceKey].step.title}`,
                    value: dataSourceKey
                });
            }
        }
        options.list.sort((a, b) => a.index - b.index);
    }
    return [options];
}

function useOptions(stepIndex, parameters) {
    const [options, setOptions] = useState(() => {
        return getOptions(stepIndex, parameters);
    });

    useEffect(() => {
        setOptions(getOptions(stepIndex, parameters));
    }, [parameters])

    return options;
}

export default function StepSelector({ value, stepIndex, parameters, editMode, onChange }) {

    const options = useOptions(stepIndex, parameters);

    return <div>
        {
            editMode ?
                <Selector value={value} options={options} onChange={onChange} placeholder="Step" />
                : <div className="bg-gray-100 rounded-md sm:text-sm px-2 py-1">{parameters.hasOwnProperty(value) ? `${parameters[value].step.index + 1}. ${parameters[value].step.title}` : '--'}</div>
        }
    </div>
}