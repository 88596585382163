import { React } from 'react';
import { UPLOADS_STORAGE_BASE_URL } from '../../config';
import { useTeamMemberGroups } from '../../hooks/team-member-group';
import Dropdown from '../common/Dropdown';

export default function TeamMemberCard({ teamMember, editable, onEdit, onDelete }) {
    const { teamMemberGroups } = useTeamMemberGroups();
    const teamMemberGroup = getTeamMemberGroup(teamMember?.group, teamMemberGroups);

    return (
        <div className="block relative rounded-lg bg-white h-16 pl-16">
            <div className={`${teamMember?.picture ? 'rounded-md' : ''} block h-10 w-10 absolute left-3 top-[50%] -translate-y-[50%] text-center font-semibold bg-gray-100 rounded-md uppercase leading-9 text-lg`}>
                {teamMember?.picture ? <img src={`${UPLOADS_STORAGE_BASE_URL}/images/users/medium/${teamMember.picture}`} className="block w-full rounded-md" alt="Team member picture" /> : (teamMember?.full_name ? teamMember.full_name[0] : '')}
            </div>
            <div className="py-3 pr-2">
                <h4 className="font-medium text-sm truncate">{teamMember?.full_name}</h4>
                <p className="text-sm text-gray-600 truncate">{teamMember?.email}</p>
                {editable && <Dropdown className="inline-block absolute right-1 top-1">
                    <button onClick={(e) => { e.preventDefault(); onEdit("member", teamMember); }} className="py-1 text-left block w-full bg-gray-100 hover:bg-gray-50 active:bg-gray-200 px-2 text-sm">Edit</button>
                    <button onClick={(e) => { e.preventDefault(); onDelete("member", teamMember); }} className="py-1 text-left block w-full px-2 text-red-500 bg-gray-100 hover:bg-gray-50 active:bg-gray-200 text-sm">Remove</button>
                </Dropdown>}
            </div>
            <div className="block hover:shadow-md w-[25px] h-[25px] text-center text-md absolute bottom-1.5 right-1.5 bg-white shadow-sm border rounded-full" title={teamMemberGroup.name}>{teamMemberGroup.icon}</div>
        </div>
    );
}

function getTeamMemberGroup(groupKey, groups) {
    if (groupKey && groups) {
        for (var i = 0; i < groups.length; i++) {
            if (groupKey === groups[i].key) {
                return groups[i];
            }
        }
    }
    return { name: "", icon: "❔" };
}