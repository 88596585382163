import { React, useState, useEffect, useRef, useCallback } from "react";
import useForm from "../../hooks/form";
import valueHelpers from "../../helpers/value";
import { FORM_ERROR_KEY } from "../../config";
import { ReactComponent as SpinnerIcon } from '../../icons/spinner.svg';
import Picker from 'emoji-picker-react';
import { ReactComponent as EmojiIcon } from '../../icons/emoji.svg';

export default function GroupActionDialog({ action, onDelete, onAdd, onUpdate, onCancel }) {
    const dialogRef = useRef(null);
    const [isVisible, setIsVisible] = useState(action !== null);

    useEffect(() => {
        setIsVisible(action !== null);
    }, [action]);

    const cancel = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
        setIsVisible(false);
    }, [onCancel]);

    useEffect(() => {
        const handleDisregard = (e) => {
            const dialogElem = dialogRef.current;
            if (isVisible) {
                var el = e.target, disregard = true;
                while (el && !el.classList.contains('group-action-dialog')) {
                    el = el.parentElement;
                }
                if (el && el.classList.contains('group-action-dialog')) {
                    disregard = false;
                }
                if (disregard || el !== dialogElem) {
                    cancel();
                }
            }
        }
        document.addEventListener('click', handleDisregard);
        return () => {
            document.removeEventListener('click', handleDisregard);
        }
    }, [isVisible, cancel])

    const dialogContent = () => {
        if (action) {
            switch (action.name) {
                case 'add':
                    return <AddActionDialog onAdd={onAdd} onCancel={cancel} />
                case 'update':
                    return <UpdateActionDialog data={action.data} onUpdate={onUpdate} onCancel={cancel} />
                case 'delete':
                    return <DeleteActionDialog data={action.data} onDelete={onDelete} onCancel={cancel} />
                default:
                    return null;
            }
        }
        return null
    }

    return <div className={`fixed px-2 top-0 left-0 bottom-0 right-0 bg-gray-500/50 ${isVisible ? '' : 'hidden'}`}>
        <div ref={dialogRef} className="group-action-dialog w-full max-w-[280px] md:max-w-[400px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg border">
            {dialogContent()}
        </div>
    </div>
}

function AddActionDialog({ onAdd, onCancel }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return {
                icon: '',
                name: '',
            };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onAdd({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    const onEmojiClick = (event, emojiObject) => {
        event.stopPropagation();
        handleChange('icon')(emojiObject.emoji);
        setEmojiPickerIsVisible(false);
    };

    const [emojiPickerIsVisible, setEmojiPickerIsVisible] = useState(false);
    const toggleEmojiPicker = (e) => {
        e.preventDefault();
        setEmojiPickerIsVisible(!emojiPickerIsVisible);
    }

    return <form className="p-2" onSubmit={submit}>
        <h2 className="mb-2">Add a new group</h2>
        <div className="flex items-center flex-row">
            <div className="block relative flex-none w-7 h-7 bg-gray-100 text-gray-600 rounded-md">
                <button onClick={toggleEmojiPicker} className="hover:bg-gray-200 active:bg-gray-300 w-full h-full block rounded-md appearance-none bg-transparent shadow-none">{data?.icon ? data.icon : <EmojiIcon className="absolute fill-current block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[60%]" />}</button>
                {emojiPickerIsVisible && <div className="absolute top-[100%] left-0 mt-2"><Picker onEmojiClick={onEmojiClick} /></div>}
            </div>
            <div className="flex-1 pl-1">
                <input className="input input--sm input--full" type="name" value={valueHelpers.textValue(data?.name)} required placeholder="Enter group's name" onChange={handleChange('name')} />
            </div>
        </div>
        {errors?.icon && <div className="input-error">{errors.icon}</div>}
        {errors?.name && <div className="input-error">{errors.name}</div>}
        {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        <div className="mt-2 text-right">
            <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
            <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Add"}</button>
        </div>
    </form>
}

function UpdateActionDialog({ onUpdate, onCancel, data }) {
    const [isBusy, setIsBusy] = useState(false);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const { data: updatedData, handleChange, handleSubmit, errors, setErrorsFromResponse } = useForm({
        initialValues: () => {
            return { ...data };
        },
        onSubmit: (submittedData) => {
            setIsBusy(true);
            onUpdate({
                data: submittedData,
                onSuccess: () => {
                    if (mounted.current) {
                        onCancel();
                    }
                },
                onError: (response) => {
                    if (mounted.current) {
                        setErrorsFromResponse(response);
                    }
                },
                onComplete: () => {
                    if (mounted.current) {
                        setIsBusy(false);
                    }
                }
            });
        }
    });

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        if (isBusy) {
            return false;
        }
        handleSubmit(e);
    }

    const onEmojiClick = (event, emojiObject) => {
        event.stopPropagation();
        handleChange('icon')(emojiObject.emoji);
        setEmojiPickerIsVisible(false);
    };

    const [emojiPickerIsVisible, setEmojiPickerIsVisible] = useState(false);
    const toggleEmojiPicker = (e) => {
        e.preventDefault();
        setEmojiPickerIsVisible(!emojiPickerIsVisible);
    }

    return <form onSubmit={submit}>
        <div className="p-2">
            <h2 className="mb-2">Update group</h2>
            <div className="flex items-center flex-row">
                <div className="block relative flex-none w-7 h-7 bg-gray-100 text-gray-600 rounded-md">
                    <button onClick={toggleEmojiPicker} className="hover:bg-gray-200 active:bg-gray-300 w-full h-full block rounded-md appearance-none bg-transparent shadow-none">{updatedData?.icon ? updatedData.icon : <EmojiIcon className="absolute fill-current block top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[60%]" />}</button>
                    {emojiPickerIsVisible && <div className="absolute top-[100%] left-0 mt-2"><Picker onEmojiClick={onEmojiClick} /></div>}
                </div>
                <div className="flex-1 pl-1">
                    <input className="input input--sm input--full" type="name" value={valueHelpers.textValue(updatedData?.name)} required placeholder="Enter group's name" onChange={handleChange('name')} />
                </div>
            </div>
            {errors?.icon && <div className="input-error">{errors.icon}</div>}
            {errors?.name && <div className="input-error">{errors.name}</div>}
            {errors[FORM_ERROR_KEY] && <div className="form-error">{errors[FORM_ERROR_KEY]}</div>}
        </div>
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-1 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2"></p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button className="btn btn--primary btn--sm" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Update"}</button>
            </div>
        </div>
    </form>
}

function DeleteActionDialog({ onDelete, onCancel, data }) {
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState(null);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, [])

    const cancel = (e) => {
        e.preventDefault();
        onCancel();
    }

    const submit = (e) => {
        e.preventDefault();
        setIsBusy(true);
        onDelete({
            data,
            onSuccess: () => {
                if (mounted.current) {
                    onCancel();
                }
            },
            onError: (response) => {
                if (mounted.current) {
                    if (response?.data?.status === 'fail') {
                        if (response?.data?.message) {
                            const errors = response?.data?.errors;
                            if (errors && errors.length > 0) {
                                setError(errors[0].message);
                            } else {
                                setError(response.data.message);
                            }
                        }
                    }
                }
            },
            onComplete: () => {
                if (mounted.current) {
                    setIsBusy(false);
                }
            }
        });
    }

    return <form onSubmit={submit}>
        <div className="p-2">
            <h2 className="max-w-[360px]">Are you sure you want to remove the group "<strong>{data?.name}</strong>"?</h2>
            {error && <div className="form-error">{error}</div>}
        </div>
        <div className="p-2 rounded-b-lg min-w-0 bg-gray-100 mt-1 max-w-full flex-nowrap flex items-center justify-between overflow-hidden">
            <div className="flex-1 min-w-0">
                <p className="text-gray-500 text-sm truncate pr-2"></p>
            </div>
            <div className="flex-none min-w-0 whitespace-nowrap">
                <button onClick={cancel} type="button" className="btn btn--secondary mr-2 btn--sm" disabled={isBusy}>Cancel</button>
                <button onClick={submit} className="btn btn--sm btn--danger" type="submit" disabled={isBusy}>{isBusy ? <SpinnerIcon className="animate-spin black inline-block w-5 h-5" /> : "Remove"}</button>
            </div>
        </div>
    </form>
}