import useApiRequest from "./api-request";

export function useProjectFetcher({ projectKey}, dependencies = []) {
    return useApiRequest({
        urlPath: '/projects/' + projectKey
    }, dependencies);
}

export function useProjectListFetcher({ cursor, count = 12, sortBy = 'recently_modified' }) {
    return useApiRequest({
        urlPath: '/projects',
        queryParams: {
            cursor: cursor,
            count: count,
            sort_by: sortBy
        }
    }, [cursor, count, sortBy]);
}